"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _Premium = require("@/shared/components/Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactShare = require("react-share");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  inviteMsg: {
    "InviteModal__styles.inviteMsg": "InviteModal__styles.inviteMsg",
    margin: "xvwtkab",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "x1j61zf2",
    textAlign: "x2b8uid",
    maxWidth: "x1j9u4d2",
    $$css: true
  },
  shareButtons: {
    "InviteModal__styles.shareButtons": "InviteModal__styles.shareButtons",
    display: "x78zum5",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "x1oysuqx",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  invitees: {
    "InviteModal__styles.invitees": "InviteModal__styles.invitees",
    maxWidth: "x193iq5w",
    width: "x1l2rt3b",
    background: "x1tudphz",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  tableChild: {
    "InviteModal__styles.tableChild": "InviteModal__styles.tableChild",
    padding: "x14odnwx",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  inviteLinkWrapper: {
    "InviteModal__styles.inviteLinkWrapper": "InviteModal__styles.inviteLinkWrapper",
    width: "xh8yej3",
    maxWidth: "x1j9u4d2",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
const SIGNUP_INVITES = (0, _gql.gql)(`
  query SignupInvites {
    signupInvites {
      confirmed
      used
      user {
        id
        displayName
        username
        avatarUrl
      }
    }
    currentUser {
      id
      inviteCode
      user {
        id
      }
    }
  }
`);
var _default = exports.default = (0, _react.memo)(({
  trigger
}) => {
  var _a;
  const {
    data
  } = (0, _gql.useQuery)(SIGNUP_INVITES);
  const signupInvites = (data == null ? void 0 : data.signupInvites) || [];
  const inviteCode = ((_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.inviteCode) || "";
  const [copySuccess, setCopySuccess] = (0, _react.useState)(false);
  const inputRef = (0, _react.useRef)(null);
  const url = `https://app.kosmi.io/signup/${inviteCode}`;
  const nInvites = signupInvites == null ? void 0 : signupInvites.filter(i => !(i == null ? void 0 : i.used) && (i == null ? void 0 : i.confirmed)).length;
  if (_Premium.hidePremium) {
    return null;
  }
  if (!trigger) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Invite Friends"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteMsg
  }, "Invite 3 friends to sign up and you will get a free month of Kosmi Premium!"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteMsg
  }, "All you need to do is share the link below:"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteLinkWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    ref: inputRef,
    readOnly: true,
    value: copySuccess ? "Copied!" : url
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    disabled: copySuccess,
    onClick: () => __async(void 0, null, function* () {
      var _a2, _b;
      (_a2 = inputRef.current) == null ? void 0 : _a2.select();
      if ((_b = inputRef.current) == null ? void 0 : _b.setSelectionRange) inputRef == null ? void 0 : inputRef.current.setSelectionRange(0, 99999);
      document.execCommand("copy");
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 500);
    }),
    color: "blue"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "copy"
  }), "Copy link")), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.shareButtons
  }, /* @__PURE__ */_react.default.createElement(_reactShare.FacebookShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.FacebookIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.TwitterShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.TwitterIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.RedditShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.RedditIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.WhatsappShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.WhatsappIcon, {
    size: "40",
    round: true
  }))), /* @__PURE__ */_react.default.createElement("table", {
    style: styles.invitees
  }, /* @__PURE__ */_react.default.createElement("thead", null, /* @__PURE__ */_react.default.createElement("th", null, /* @__PURE__ */_react.default.createElement("td", null, "Name")), /* @__PURE__ */_react.default.createElement("th", null, /* @__PURE__ */_react.default.createElement("td", null, "Confirmed")), /* @__PURE__ */_react.default.createElement("th", null, nInvites, " / 3")), /* @__PURE__ */_react.default.createElement("tbody", {
    style: styles.tableChild
  }, signupInvites.map((invite, i) => {
    var _a2, _b;
    return /* @__PURE__ */_react.default.createElement("tr", {
      key: i
    }, /* @__PURE__ */_react.default.createElement("td", {
      style: styles.tableChild
    }, ((_a2 = invite == null ? void 0 : invite.user) == null ? void 0 : _a2.displayName) || ((_b = invite == null ? void 0 : invite.user) == null ? void 0 : _b.username)), /* @__PURE__ */_react.default.createElement("td", {
      style: styles.tableChild
    }, !(invite == null ? void 0 : invite.used) && (invite == null ? void 0 : invite.confirmed) ? "\u2705" : "\u274C"), /* @__PURE__ */_react.default.createElement("td", {
      style: styles.tableChild
    }));
  })))));
});