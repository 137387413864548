"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Html = _interopRequireDefault(require("./Html"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  image: {
    "Image__styles.image": "Image__styles.image",
    position: "x1n2onr6",
    display: "x1lliihq",
    verticalAlign: "xxymvpz",
    maxWidth: "x193iq5w",
    backgroundColor: "xjbqb8w",
    height: "xt7dq6l",
    marginLeft: "xj3b58b",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    $$css: true
  },
  rounded: {
    "Image__styles.rounded": "Image__styles.rounded",
    borderRadius: "x1m25ckn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  circular: {
    "Image__styles.circular": "Image__styles.circular",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    borderRadius: "x1pscjh6",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  avatar: {
    "Image__styles.avatar": "Image__styles.avatar",
    marginRight: "xsxjf7q",
    marginInlineStart: null,
    marginInlineEnd: null,
    display: "x1rg5ohu",
    width: "xk2f0lv",
    height: "x19hywkt",
    borderRadius: "x1pscjh6",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  mini: {
    "Image__styles.mini": "Image__styles.mini",
    width: "x1n7h9c3",
    height: "xt7dq6l",
    fontSize: "x1mphdil",
    $$css: true
  },
  tiny: {
    "Image__styles.tiny": "Image__styles.tiny",
    width: "x1dmp6jm",
    height: "xt7dq6l",
    fontSize: "xs6yfsm",
    $$css: true
  },
  small: {
    "Image__styles.small": "Image__styles.small",
    width: "xrostsh",
    height: "xt7dq6l",
    fontSize: "xrd5znx",
    $$css: true
  },
  medium: {
    "Image__styles.medium": "Image__styles.medium",
    width: "xdzyupr",
    height: "xt7dq6l",
    fontSize: "x1jchvi3",
    $$css: true
  },
  large: {
    "Image__styles.large": "Image__styles.large",
    width: "xbstwhj",
    height: "xt7dq6l",
    fontSize: "xi9rq2s",
    $$css: true
  },
  big: {
    "Image__styles.big": "Image__styles.big",
    width: "x14rvwrp",
    height: "xt7dq6l",
    fontSize: "xlue8l3",
    $$css: true
  },
  huge: {
    "Image__styles.huge": "Image__styles.huge",
    width: "xdpfuu1",
    height: "xt7dq6l",
    fontSize: "x5zinoz",
    $$css: true
  },
  massive: {
    "Image__styles.massive": "Image__styles.massive",
    width: "xbqi55i",
    height: "xt7dq6l",
    fontSize: "x1gbuw7f",
    $$css: true
  }
};
const Image = exports.Image = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(14);
  const {
    src,
    className,
    circular,
    rounded,
    size,
    id,
    avatar,
    width,
    height,
    alt,
    onClick
  } = t0;
  const t1 = circular && styles.circular;
  const t2 = size && styles[size];
  const t3 = rounded && styles.rounded;
  const t4 = avatar && styles.avatar;
  let t5;
  if ($[0] !== className || $[1] !== t1 || $[2] !== t2 || $[3] !== t3 || $[4] !== t4) {
    t5 = [styles.image, t1, t2, t3, t4, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
    $[4] = t4;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] !== alt || $[7] !== height || $[8] !== id || $[9] !== onClick || $[10] !== src || $[11] !== t5 || $[12] !== width) {
    t6 = /* @__PURE__ */_react.default.createElement(_Html.default.img, {
      src,
      width,
      height,
      id,
      alt,
      onClick,
      style: t5
    });
    $[6] = alt;
    $[7] = height;
    $[8] = id;
    $[9] = onClick;
    $[10] = src;
    $[11] = t5;
    $[12] = width;
    $[13] = t6;
  } else {
    t6 = $[13];
  }
  return t6;
});