"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _reactRouterDom = require("react-router-dom");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useTracking = require("@/shared/hooks/useTracking");
var _Settings = require("@/shared/components/Settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  header: {
    "ProfileModal__styles.header": "ProfileModal__styles.header",
    display: "x78zum5",
    $$css: true
  },
  content: {
    "ProfileModal__styles.content": "ProfileModal__styles.content",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  }
};
var _default = ({
  trigger
}) => {
  const track = (0, _useTracking.useTracking)();
  const modalRef = (0, _react.useRef)(null);
  const location = (0, _reactRouterDom.useLocation)();
  const inited = (0, _react.useRef)(true);
  (0, _react.useEffect)(() => {
    var _a;
    if (!inited.current) {
      inited.current = true;
      return;
    }
    (_a = modalRef.current) == null ? void 0 : _a.close();
  }, [location.pathname]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    large: true,
    trigger,
    ref: modalRef,
    onOpen: () => {
      track("UI", "UserProfileModalOpened", "User Profile Modal Opened");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.header
  }, "Profile"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, {
    style: styles.content
  }, /* @__PURE__ */_react.default.createElement(_Settings.AccountSection, {
    dark: true
  })));
};
exports.default = _default;