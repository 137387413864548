"use strict";
import Color from "color";
import { colors } from "@/__generated__/colors";
const setVar = (key, value) => document.documentElement.style.setProperty(`--${key}`, value);
export const getVar = (key) => getComputedStyle(document.documentElement).getPropertyValue(`--${key}`);
const getVarColor = (varColor) => getVar(varColor.replace("var(--", "").replace(")", ""));
export const initColors = () => updateColors(true, colors["primary"], colors["secondary"], colors["foreground"]);
export const lighten = (color, val, darkMode) => {
  if (color.startsWith("var(--")) {
    color = getVarColor(color);
  }
  return darkMode ? Color(color).lighten(val).hex() : Color(color).darken(val).hex();
};
export const darken = (color, val, darkMode) => {
  if (color.startsWith("var(--")) {
    color = getVarColor(color);
  }
  return darkMode ? Color(color).darken(val).hex() : Color(color).lighten(val).hex();
};
export const fade = (color, val) => Color(color).fade(1 - val).string();
export const updateColors = (darkMode, primaryColor, secondaryColor, foreGroundColor) => {
  setVar("primary", primaryColor);
  setVar("secondary", secondaryColor);
  setVar("foreground", foreGroundColor);
  setVar("primary-light-10", lighten(primaryColor, 0.1 * 3, darkMode));
  setVar("primary-light-5", lighten(primaryColor, 0.05 * 3, darkMode));
  setVar("primary-light-20", lighten(primaryColor, 0.2 * 3, darkMode));
  setVar("primary-light-40", lighten(primaryColor, 0.4 * 3, darkMode));
  setVar("primary-dark-5", darken(primaryColor, 0.05 * 3, darkMode));
  setVar("primary-dark-10", darken(primaryColor, 0.1 * 3, darkMode));
  setVar("primary-dark-15", darken(primaryColor, 0.15 * 3, darkMode));
  setVar("primary-dark-20", darken(primaryColor, 0.2 * 3, darkMode));
  setVar("primary-dark-25", darken(primaryColor, 0.25 * 3, darkMode));
  setVar("black", darken(primaryColor, 0.3 * 3, darkMode));
  setVar("foreground-dark-5", darken(foreGroundColor, 0.05 * 3, darkMode));
  setVar("foreground-dark-10", darken(foreGroundColor, 0.1 * 3, darkMode));
  setVar("foreground-dark-20", darken(foreGroundColor, 0.2 * 3, darkMode));
  setVar("foreground-dark-50", darken(foreGroundColor, 0.5 * 3, darkMode));
  setVar("secondary-dark-10", darken(secondaryColor, 0.1 * 3, darkMode));
  setVar("secondary-dark-20", darken(secondaryColor, 0.2 * 3, darkMode));
  setVar("secondary-dark-22", darken(secondaryColor, 0.22 * 3, darkMode));
  setVar("secondary-dark-25", darken(secondaryColor, 0.25 * 3, darkMode));
  setVar("primary-dark-25-fade-90", fade(darken(primaryColor, 0.25 * 3, darkMode), 0.9));
};
