"use strict";
import React, { useCallback } from "react";
import { useResizeObserver } from "usehooks-ts";
import { Html } from "@/uikit";
const NewFitText = (props) => {
  var _a;
  const compressor = props.compressor || 1;
  const [fontSize, setFontSize] = React.useState("inherit");
  const onResize = useCallback(() => {
    var _a2;
    const value = ((_a2 = divRef.current) == null ? void 0 : _a2.offsetWidth) || 0;
    setFontSize(Math.max(Math.min(value / ((compressor || 1) * 10), Number.POSITIVE_INFINITY), Number.NEGATIVE_INFINITY).toString() + "px");
  }, [compressor]);
  const divRef = React.useRef(null);
  useResizeObserver({
    ref: divRef,
    box: "border-box",
    onResize
  });
  return /* @__PURE__ */ React.createElement(Html.div, { ref: divRef, styleProps: {
    fontSize,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: (_a = props.align) != null ? _a : "center"
  } }, props.children);
};
export default NewFitText;
