"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = exports.styles = {
  root: {
    "Spotlight__styles.root": "Spotlight__styles.root",
    position: "x10l6tqk",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  backdrop: {
    "Spotlight__styles.backdrop": "Spotlight__styles.backdrop",
    position: "x10l6tqk",
    zIndex: "x1n327nk",
    width: "xh8yej3",
    height: "x5yr21d",
    transition: "xsn8bb8",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  spotlight: {
    "Spotlight__styles.spotlight": "Spotlight__styles.spotlight",
    position: "xixxii4",
    zIndex: "x1355qak",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x5d3vyf",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  animated: {
    "Spotlight__styles.animated": "Spotlight__styles.animated",
    transition: "xsn8bb8",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  backdropStyle: brightness => [{
    "Spotlight__styles.backdropStyle": "Spotlight__styles.backdropStyle",
    backdropFilter: "xbsq60n",
    WebkitBackdropFilter: "xa7iw3v",
    $$css: true
  }, {
    "--backdropFilter": `brightness(${brightness}%)` != null ? `brightness(${brightness}%)` : "initial",
    "--WebkitBackdropFilter": `brightness(${brightness}%)` != null ? `brightness(${brightness}%)` : "initial"
  }],
  highlightStyle: (screenX, screenY, width, height, brightness) => [{
    "Spotlight__styles.highlightStyle": "Spotlight__styles.highlightStyle",
    width: "x17fnjtu",
    height: "x1jwls1v",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "xrzbxsz",
    backdropFilter: "xbsq60n",
    WebkitBackdropFilter: "xa7iw3v",
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${width + 10}px`),
    "--height": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${height + 10}px`),
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(screenX - 5),
    "--top": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(screenY - 5),
    "--backdropFilter": `brightness(${100 / brightness * 100}%)` != null ? `brightness(${100 / brightness * 100}%)` : "initial",
    "--WebkitBackdropFilter": `brightness(${100 / brightness * 100}%)` != null ? `brightness(${100 / brightness * 100}%)` : "initial"
  }]
};