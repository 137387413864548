"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const dataChannelId = "kosmi";
export class WebRTCStreamHost {
  constructor(streamId, sendOffer, sendCandidate, iceServers) {
    __publicField(this, "connections", {});
    __publicField(this, "dataChannels", {});
    __publicField(this, "sendOffer");
    __publicField(this, "sendCandidate");
    __publicField(this, "streamId");
    __publicField(this, "dataChannelEnabled");
    __publicField(this, "stream");
    __publicField(this, "iceServers");
    __publicField(this, "userIds", {});
    __publicField(this, "dataChannelOnMsgCallback");
    this.streamId = streamId;
    this.sendOffer = sendOffer;
    this.sendCandidate = sendCandidate;
    this.connections = {};
    this.stream = null;
    this.iceServers = iceServers;
    this.dataChannels = {};
    this.dataChannelEnabled = false;
    this.dataChannelOnMsgCallback = null;
  }
  handleAnswer(userId, remoteDescription) {
    const connection = this.connections[userId];
    if (connection) {
      const shouldAddStream = this.stream && !connection.remoteDescription;
      connection.setRemoteDescription(remoteDescription);
      if (shouldAddStream) {
        this.addStream(userId);
      }
    } else if (!connection) {
      throw "received answer before connection";
    }
  }
  setStream(stream) {
    this.stream = stream;
    Object.keys(this.connections).forEach((userId) => {
      this.addStream(userId);
    });
  }
  addStream(userId) {
    const connection = this.connections[userId];
    if (!connection) {
      throw "tried to add stream to non existing connection";
    }
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        if (this.stream) {
          const hasTrack = connection.getSenders().filter((s) => s.track === track).length > 0;
          if (!hasTrack)
            connection.addTrack(track, this.stream);
        }
      });
    }
  }
  launchDataChannel(userId) {
    this.dataChannels[userId] = this.connections[userId].createDataChannel(dataChannelId);
  }
  createDataChannel() {
    this.dataChannelEnabled = true;
    Object.keys(this.connections).forEach((userId) => {
      this.launchDataChannel(userId);
    });
  }
  closeDataChannel(userId) {
    if (this.dataChannels[userId]) {
      this.dataChannels[userId].close();
      delete this.dataChannels[userId];
    }
  }
  removeDataChannel() {
    Object.keys(this.connections).forEach((userId) => {
      this.closeDataChannel(userId);
    });
  }
  dataChannelSend(data) {
    Object.values(this.dataChannels).forEach((dataChannel) => {
      dataChannel.send(data);
    });
  }
  dataChannelOnMessage(callback) {
    this.dataChannelOnMsgCallback = callback;
    Object.values(this.dataChannels).forEach((dataChannel) => {
      dataChannel.onmessage = (ev) => {
        callback(ev);
      };
    });
  }
  setUserIds(userIds) {
    const newUserIds = userIds.filter((userId) => !this.connections[userId]);
    const removedUserIds = Object.keys(this.connections).filter((userId) => !userIds.includes(userId));
    newUserIds.forEach((userId) => this.createConnection(userId));
    removedUserIds.forEach((userId) => this.closeConnection(userId));
  }
  createConnection(userId) {
    const {
      iceServers
    } = this;
    const connection = new RTCPeerConnection({
      iceServers
    });
    this.connections[userId] = connection;
    connection.onnegotiationneeded = () => __async(this, null, function* () {
      const offer = yield connection.createOffer();
      yield connection.setLocalDescription(offer);
      if (connection.localDescription) {
        this.sendOffer(userId, connection.localDescription);
      }
    });
    connection.onicecandidate = ({
      candidate
    }) => {
      if (candidate) {
        this.sendCandidate(userId, candidate);
      }
    };
    if (this.dataChannelEnabled) {
      this.launchDataChannel(userId);
      this.dataChannels[userId].onmessage = this.dataChannelOnMsgCallback;
    }
    if (this.stream)
      this.addStream(userId);
  }
  closeConnection(userId) {
    try {
      this.closeDataChannel(userId);
      this.connections[userId].close();
    } finally {
      delete this.connections[userId];
    }
  }
  addIceCandidate(userId, candidate) {
    const connection = this.connections[userId];
    if (connection) {
      connection.addIceCandidate(candidate);
    } else {
      throw "trying to add a candidate to non existing connection";
    }
  }
  close() {
    const userIds = Object.keys(this.connections);
    userIds.forEach((userId) => this.closeConnection(userId));
  }
}
