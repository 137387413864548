"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSidebarData = exports.default = exports.MobileMessagebox = exports.Chat = void 0;
var _gql = require("@/shared/hooks/gql");
var _useIsAdmin = require("@/room/hooks/useIsAdmin");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _useMediaPlayerData = require("@/room/hooks/useMediaPlayerData");
var _RoomContext = _interopRequireDefault(require("@/room/contexts/RoomContext"));
var _RoomUIContext = _interopRequireDefault(require("@/room/contexts/RoomUIContext"));
var _useRoomId = require("@/room/hooks/useRoomId");
var _Player = _interopRequireDefault(require("../Player"));
var _Members = _interopRequireDefault(require("./Members"));
var _Messagebox = _interopRequireDefault(require("./Messagebox"));
var _Messages = _interopRequireDefault(require("./Messages"));
var _RoomToolbar = _interopRequireWildcard(require("./RoomToolbar"));
var _useMessageData = require("@/room/hooks/useMessageData");
var _useRoomData = require("@/room/hooks/useRoomData");
var _InviteBackModal = _interopRequireDefault(require("../InviteBackModal"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _AppContext = _interopRequireDefault(require("@/app/AppContext"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const SEND_ANNOUNCEMENT = (0, _gql.gql)(`
  mutation SendAnnouncement($title: String!, $text: String!) {
    sendAnnouncement(title: $title, text: $text) {
      ok
    }
  }
`);
const DELETE_MESSAGE = (0, _gql.gql)(`
  mutation DeleteMessage($roomId: String!, $messageId: String!) {
    deleteMessage(roomId: $roomId, messageId: $messageId) {
      ok
    }
  }
`);
const DELETE_MESSAGES_BY_USER = (0, _gql.gql)(`
  mutation DeleteMessagesByUser($roomId: String!, $userId: String!) {
    deleteAllMessagesByUser(roomId: $roomId, userId: $userId) {
      ok
    }
  }
`);
const SEND_MESSAGE = (0, _gql.gql)(`
  mutation SendMessage2($roomId: String!, $body: String!, $channelId: String) {
    sendMessage(roomId: $roomId, body: $body, channelId: $channelId) {
      ok
    }
  }
`);
const styles = {
  overflowHidden: {
    "index__styles.overflowHidden": "index__styles.overflowHidden",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  form: {
    "index__styles.form": "index__styles.form",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  sidebar: {
    "index__styles.sidebar": "index__styles.sidebar",
    alignItems: "x1qjc9v5",
    backgroundColor: "x1vaw2wd",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    width: "xdzyupr",
    "@media (max-width: 1000px)_display": "x1nc0skp",
    "@media (max-width: 1000px)_width": "x1nkzv30",
    "@media (max-width: 1000px)_height": "x1ltrzbh",
    "@media (max-width: 1000px)_alignSelf": "xjy1r2t",
    "@media (max-width: 1000px)_background": "x7vu4dk",
    "@media (max-width: 1000px)_backgroundAttachment": null,
    "@media (max-width: 1000px)_backgroundClip": null,
    "@media (max-width: 1000px)_backgroundColor": null,
    "@media (max-width: 1000px)_backgroundImage": null,
    "@media (max-width: 1000px)_backgroundOrigin": null,
    "@media (max-width: 1000px)_backgroundPosition": null,
    "@media (max-width: 1000px)_backgroundPositionX": null,
    "@media (max-width: 1000px)_backgroundPositionY": null,
    "@media (max-width: 1000px)_backgroundRepeat": null,
    "@media (max-width: 1000px)_backgroundSize": null,
    "@media (max-width: 1000px)_left": "x1emzwdw",
    "@media (max-width: 1000px)_insetInlineStart": null,
    "@media (max-width: 1000px)_insetInlineEnd": null,
    "@media (max-width: 1000px)_zIndex": "xiz3g89",
    "@media (max-width: 1000px)_flex": "x16ywkk9",
    "@media (max-width: 1000px)_flexGrow": null,
    "@media (max-width: 1000px)_flexShrink": null,
    "@media (max-width: 1000px)_flexBasis": null,
    transition: "x1jte46n",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  highlight: {
    "index__styles.highlight": "index__styles.highlight",
    backgroundColor: "x1hhg5nt",
    $$css: true
  },
  hidden: {
    "index__styles.hidden": "index__styles.hidden",
    display: "x1s85apg",
    $$css: true
  },
  memberList: {
    "index__styles.memberList": "index__styles.memberList",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  messageboxWrapper: {
    "index__styles.messageboxWrapper": "index__styles.messageboxWrapper",
    width: "xh8yej3",
    display: "x78zum5",
    $$css: true
  },
  overlay: {
    "index__styles.overlay": "index__styles.overlay",
    display: "x78zum5",
    backgroundColor: "x128cn8a",
    backgroundImage: "xuq8xmn",
    minHeight: "x2lwn1j",
    width: "xh8yej3",
    height: "x5yr21d",
    boxShadow: "x1sxwcxk",
    $$css: true
  },
  playerWrapper: {
    "index__styles.playerWrapper": "index__styles.playerWrapper",
    maxHeight: "x1hkcv85",
    height: "xqtp20y",
    position: "x1n2onr6",
    display: "x78zum5",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  playerWrapperActive: {
    "index__styles.playerWrapperActive": "index__styles.playerWrapperActive",
    minHeight: "xz65tgg",
    flex: "x1b0bnr5",
    flexGrow: null,
    flexShrink: "x2lah0s",
    flexBasis: null,
    $$css: true
  },
  mobileMessageBox: {
    "index__styles.mobileMessageBox": "index__styles.mobileMessageBox",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    maxHeight: "x1egxudb",
    maxWidth: "x193iq5w",
    $$css: true
  },
  chat: {
    "index__styles.chat": "index__styles.chat",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    minHeight: "x2lwn1j",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  hiddenChat: {
    "index__styles.hiddenChat": "index__styles.hiddenChat",
    display: "x1s85apg",
    $$css: true
  }
};
const MessagesWithData = () => {
  var _a;
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    data,
    nextPage,
    loading
  } = (0, _useMessageData.useMessagesCached)(roomId, "general");
  const [_deleteAllMessagesByUser] = (0, _gql.useMutation)(DELETE_MESSAGES_BY_USER);
  const [_deleteMessage] = (0, _gql.useMutation)(DELETE_MESSAGE);
  const _messages = (_a = data == null ? void 0 : data.chatArchive) == null ? void 0 : _a.results;
  const deleteAllMessagesByUser = (0, _react.useCallback)(userId => _deleteAllMessagesByUser({
    variables: {
      roomId,
      userId
    }
  }), [_deleteAllMessagesByUser, roomId]);
  const deleteMessage = (0, _react.useCallback)(messageId => _deleteMessage({
    variables: {
      roomId,
      messageId
    }
  }), [_deleteMessage, roomId]);
  return /* @__PURE__ */_react.default.createElement(_Messages.default, {
    messages: _messages,
    noImages: roomId === "lobby",
    nextPage,
    loading,
    deleteAllMessagesByUser,
    deleteMessage
  });
};
const AdminMenuModal = ({
  trigger
}) => {
  const [title, setTitle] = (0, _react.useState)("");
  const [text, setText] = (0, _react.useState)("");
  const [sendAnnouncement] = (0, _gql.useMutation)(SEND_ANNOUNCEMENT);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    className: styles.form,
    onSubmit: e => __async(void 0, null, function* () {
      e.preventDefault();
      setTitle("");
      setText("");
      yield sendAnnouncement({
        variables: {
          title,
          text
        }
      });
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.label, null, "Announcement"), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    onChange: e => setTitle(e.target.value),
    value: title
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.TextArea, {
    onChange: e => setText(e.target.value),
    value: text
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    color: "yellow",
    inverted: true,
    type: "submit",
    disabled: !title || !text
  }, "Send"))));
};
const MobileMessagebox = () => {
  const roomId = (0, _useRoomId.useRoomId)();
  const [focused, setFocused] = (0, _react.useState)(false);
  const onBlur = (0, _react.useCallback)(() => setFocused(false), []);
  const onFocus = (0, _react.useCallback)(() => setFocused(true), []);
  const [_sendMessage] = (0, _gql.useMutation)(SEND_MESSAGE);
  const isLobby = roomId === "lobby" || (roomId == null ? void 0 : roomId.endsWith("/lobby"));
  const sendMessage = body => _sendMessage({
    variables: {
      roomId,
      channelId: "general",
      body
    }
  });
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.mobileMessageBox
  }, /* @__PURE__ */_react.default.createElement(_Messagebox.default, {
    onFocus,
    onBlur,
    sendMessage,
    isPrivate: false
  }), !focused && !isLobby && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_RoomToolbar.WebcamButton, {
    size: "xs",
    color: "white",
    inverted: true,
    style: {
      borderRadius: "50%",
      width: 40,
      minWidth: 40,
      height: 40,
      marginLeft: 4,
      marginRight: 4
    }
  }), /* @__PURE__ */_react.default.createElement(_RoomToolbar.MicrophoneButton, {
    size: "xs",
    color: "white",
    inverted: true,
    style: {
      borderRadius: "50%",
      minWidth: 40,
      width: 40,
      height: 40,
      marginLeft: 4,
      marginRight: 4
    }
  })));
};
exports.MobileMessagebox = MobileMessagebox;
const Chat = exports.Chat = (0, _react.memo)(({
  hide
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.chat, hide && styles.hiddenChat]
  }, /* @__PURE__ */_react.default.createElement(MessagesWithData, null), /* @__PURE__ */_react.default.createElement(MobileMessagebox, null));
});
const ChatAndMemberlist = (0, _react.memo)(({
  isLobby,
  onTabChange,
  isNsfw
}) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const {
    isLandscape,
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    memberCount
  } = useSidebarData(roomId);
  const [_sendMessage] = (0, _gql.useMutation)(SEND_MESSAGE);
  const sendMessage = (0, _react.useCallback)(body => _sendMessage({
    variables: {
      roomId,
      channelId: "general",
      body
    }
  }), [_sendMessage, roomId]);
  if (isLandscape && isMobile) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.TabView, {
    className: styles.overlay,
    onTabChange
  }, /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "chat"
    }),
    className: styles.overflowHidden
  }, /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, isLobby && isAdmin && /* @__PURE__ */_react.default.createElement(AdminMenuModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      fluid: true,
      size: "m",
      color: "blue"
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "setting"
    }), "Admin Menu")
  }), /* @__PURE__ */_react.default.createElement(_InviteBackModal.default, null), /* @__PURE__ */_react.default.createElement(MessagesWithData, null), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageboxWrapper
  }, /* @__PURE__ */_react.default.createElement(_Messagebox.default, {
    sendMessage,
    isNsfw
  })))), !isLobby && /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "group"
    }), " ", memberCount)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.memberList
  }, /* @__PURE__ */_react.default.createElement(_Members.default, {
    visible: true
  }))));
});
const useSidebarData = roomId => {
  var _a, _b, _c, _d, _e;
  const {
    mediaPlayerState
  } = (0, _useMediaPlayerData.useMediaPlayerStateCached)();
  const roomUIContext = (0, _react.useContext)(_RoomUIContext.default);
  const roomContext = (0, _react.useContext)(_RoomContext.default);
  const isLobby = roomId === "lobby" || roomId.endsWith("/lobby");
  const room = (0, _useRoomData.useRoomStateCached)(roomId);
  const {
    isLandscape,
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  return {
    isLobby,
    isLandscape,
    isMobile,
    hasMediaPlayer: !roomContext.appModule || !!((_a = roomContext == null ? void 0 : roomContext.appModule) == null ? void 0 : _a.hasMediaPlayer),
    mediaPlayerUrl: mediaPlayerState == null ? void 0 : mediaPlayerState.url,
    menuIndex: roomUIContext.menuIndex,
    isNsfw: !!((_c = (_b = room == null ? void 0 : room.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.isNsfw),
    memberCount: ((_e = (_d = room == null ? void 0 : room.state) == null ? void 0 : _d.members) == null ? void 0 : _e.length) || 0
  };
};
exports.useSidebarData = useSidebarData;
const Sidebar = (0, _react.memo)(({
  open
}) => {
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    isLobby,
    isLandscape,
    isMobile,
    hasMediaPlayer,
    mediaPlayerUrl,
    isNsfw
  } = useSidebarData(roomId);
  const [player, setPlayer] = (0, _react.useState)(null);
  const {
    roomsDropdownisHovered
  } = (0, _react.useContext)(_AppContext.default);
  return /* @__PURE__ */_react.default.createElement(_uikit.Transition, {
    animation: "slide left",
    visible: open,
    duration: {
      hide: 100,
      show: 200
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.sidebar, isMobile && isLandscape && styles.hidden, roomsDropdownisHovered && styles.highlight]
  }, !isLobby && !isMobile && /* @__PURE__ */_react.default.createElement(_RoomToolbar.default, {
    player
  }), !isLobby && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !isMobile && !hasMediaPlayer && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.playerWrapper, !!mediaPlayerUrl && styles.playerWrapperActive]
  }, /* @__PURE__ */_react.default.createElement(_Player.default, {
    onPlayer: setPlayer,
    isCompact: true
  }))), /* @__PURE__ */_react.default.createElement(ChatAndMemberlist, {
    isNsfw,
    isLobby: roomId === "lobby"
  })));
});
var _default = exports.default = Sidebar;