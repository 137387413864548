"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Select = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Image = require("./Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  dropdown: {
    "Select__styles.dropdown": "Select__styles.dropdown",
    cursor: "x1ypdohk",
    position: "x1n2onr6",
    display: "x1rg5ohu",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    textAlign: "xdpxx8g",
    transition: "x8g6ub2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    height: "x16wdlz0",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    visibility: "xnpuxes",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    appearance: "xjyslct",
    backgroundImage: "x17gz8yi",
    backgroundRepeat: "xiy17q3",
    backgroundPositionX: "x10mjbor",
    backgroundPositionY: "x1yzgxni",
    $$css: true
  },
  compact: {
    "Select__styles.compact": "Select__styles.compact",
    minWidth: "xeuugli",
    $$css: true
  },
  disabled: {
    "Select__styles.disabled": "Select__styles.disabled",
    cursor: "xt0e3qv",
    pointerEvents: "x47corl",
    opacity: "xyd83as",
    $$css: true
  },
  image: {
    "Select__styles.image": "Select__styles.image",
    paddingLeft: "x1dcfgta",
    $$css: true
  },
  label: {
    "Select__styles.label": "Select__styles.label",
    paddingBottom: "x1a8lsjc",
    fontWeight: "x117nqv4",
    $$css: true
  },
  selection: {
    "Select__styles.selection": "Select__styles.selection",
    cursor: "x1ypdohk",
    wordWrap: "x1vvkbs",
    lineHeight: "xtqhoxe",
    whiteSpace: "xeaf4i8",
    outline: "x1b6ykf0",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    transform: "x1atnlmd",
    minWidth: "x1fh14hl",
    minHeight: "xyt6bas",
    backgroundColor: "x128cn8a",
    display: "x1rg5ohu",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x11jfisy",
    boxShadow: "x1gnnqk1",
    borderRadius: "x1aaq583",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    transition: "x8g6ub2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    fontWeight: "x1fcty0u",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    ":hover_borderColor": "x1saclv8",
    ":hover_borderInlineColor": null,
    ":hover_borderInlineStartColor": null,
    ":hover_borderLeftColor": null,
    ":hover_borderInlineEndColor": null,
    ":hover_borderRightColor": null,
    ":hover_borderBlockColor": null,
    ":hover_borderTopColor": null,
    ":hover_borderBottomColor": null,
    ":focus_borderColor": "x1eg2tmu",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    ":focus-within_borderColor": "x6po30u",
    ":focus-within_borderInlineColor": null,
    ":focus-within_borderInlineStartColor": null,
    ":focus-within_borderLeftColor": null,
    ":focus-within_borderInlineEndColor": null,
    ":focus-within_borderRightColor": null,
    ":focus-within_borderBlockColor": null,
    ":focus-within_borderTopColor": null,
    ":focus-within_borderBottomColor": null,
    $$css: true
  },
  select: {
    "Select__styles.select": "Select__styles.select",
    display: "x78zum5",
    flex: "x98rzlu",
    width: "xh8yej3",
    height: "x5yr21d",
    borderWidth: "xc342km",
    background: "x11g6tue",
    padding: "xsu7wfv",
    cursor: "x1ypdohk",
    outline: "x1a2a7pz",
    appearance: "xjyslct",
    color: "x11jfisy",
    $$css: true
  },
  img: {
    "Select__styles.img": "Select__styles.img",
    position: "x10l6tqk",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    height: "xt7dq6l",
    zIndex: "xhtitgo",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    pointerEvents: "x47corl",
    left: "xc1hvs2",
    insetInlineStart: null,
    insetInlineEnd: null,
    width: "xgd8bvy",
    maxHeight: "xz75wtk",
    cursor: "x1ypdohk",
    $$css: true
  }
};
const Select = exports.Select = (0, _react.memo)(t0 => {
  var _a, _b;
  const $ = (0, _compilerRuntime.c)(41);
  const {
    id,
    name,
    defaultValue,
    className,
    value,
    onChange,
    options,
    disabled,
    label,
    compact,
    placeholder,
    required
  } = t0;
  let t1;
  if ($[0] !== options || $[1] !== value) {
    t1 = (_a = options.find(o => !!o.image && o.value === value)) == null ? void 0 : _a.image;
    $[0] = options;
    $[1] = value;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const image = t1;
  const hasImage = !!((_b = options.find(_temp)) == null ? void 0 : _b.image);
  let t2;
  if ($[3] !== hasImage) {
    t2 = stylex.props(styles.select, hasImage && styles.image);
    $[3] = hasImage;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  const {
    className: selectClassName
  } = t2;
  let t3;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      className: "Select__styles.option x128cn8a x11jfisy"
    };
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  const {
    className: optionClassName
  } = t3;
  let t4;
  if ($[6] !== label) {
    t4 = label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
      style: styles.label
    }, label);
    $[6] = label;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const t5 = compact && styles.compact;
  const t6 = disabled && styles.disabled;
  let t7;
  if ($[8] !== className || $[9] !== t5 || $[10] !== t6) {
    t7 = [styles.selection, styles.dropdown, t5, t6, className];
    $[8] = className;
    $[9] = t5;
    $[10] = t6;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  let t8;
  if ($[12] !== image) {
    t8 = image && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.img
    }, /* @__PURE__ */_react.default.createElement(_Image.Image, {
      src: image,
      avatar: true
    }));
    $[12] = image;
    $[13] = t8;
  } else {
    t8 = $[13];
  }
  let t9;
  if ($[14] !== defaultValue || $[15] !== placeholder || $[16] !== value) {
    t9 = !value && !defaultValue && /* @__PURE__ */_react.default.createElement("option", {
      className: optionClassName,
      value: "",
      disabled: true,
      selected: !value
    }, placeholder || "Select an option");
    $[14] = defaultValue;
    $[15] = placeholder;
    $[16] = value;
    $[17] = t9;
  } else {
    t9 = $[17];
  }
  let t10;
  if ($[18] !== options || $[19] !== value) {
    let t112;
    if ($[21] !== value) {
      t112 = t122 => {
        const {
          text,
          value: optionValue
        } = t122;
        return /* @__PURE__ */_react.default.createElement("option", {
          key: optionValue,
          value: optionValue,
          selected: optionValue === value,
          className: optionClassName
        }, text, " ", optionValue === value);
      };
      $[21] = value;
      $[22] = t112;
    } else {
      t112 = $[22];
    }
    t10 = options.map(t112);
    $[18] = options;
    $[19] = value;
    $[20] = t10;
  } else {
    t10 = $[20];
  }
  let t11;
  if ($[23] !== defaultValue || $[24] !== disabled || $[25] !== name || $[26] !== onChange || $[27] !== required || $[28] !== selectClassName || $[29] !== t10 || $[30] !== t9 || $[31] !== value) {
    t11 = /* @__PURE__ */_react.default.createElement("select", {
      defaultValue,
      disabled,
      onChange,
      value,
      className: selectClassName,
      name,
      required
    }, t9, t10);
    $[23] = defaultValue;
    $[24] = disabled;
    $[25] = name;
    $[26] = onChange;
    $[27] = required;
    $[28] = selectClassName;
    $[29] = t10;
    $[30] = t9;
    $[31] = value;
    $[32] = t11;
  } else {
    t11 = $[32];
  }
  let t12;
  if ($[33] !== id || $[34] !== t11 || $[35] !== t7 || $[36] !== t8) {
    t12 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      id,
      style: t7
    }, t8, t11);
    $[33] = id;
    $[34] = t11;
    $[35] = t7;
    $[36] = t8;
    $[37] = t12;
  } else {
    t12 = $[37];
  }
  let t13;
  if ($[38] !== t12 || $[39] !== t4) {
    t13 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, t4, t12);
    $[38] = t12;
    $[39] = t4;
    $[40] = t13;
  } else {
    t13 = $[40];
  }
  return t13;
});
function _temp(o_0) {
  return !!o_0.image;
}