"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeleteData;
var _Settings = require("@/shared/components/Settings");
var _uikit = require("@/uikit");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
var _LoginModal = require("@/shared/components/LoginModal");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  root: {
    "deleteData__styles.root": "deleteData__styles.root",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxWidth: "xrlsmeg",
    $$css: true
  }
};
function DeleteData() {
  const currentUser = (0, _helpers.currentUserHook)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, (currentUser == null ? void 0 : currentUser.isAnonymous) ? /* @__PURE__ */_react.default.createElement(_LoginModal.LoginModalInner, null) : /* @__PURE__ */_react.default.createElement(_Settings.DataDeletionScreen, null));
}