"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Message = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  message: {
    "Message__styles.message": "Message__styles.message",
    position: "x1n2onr6",
    minHeight: "x1xaadd7",
    margin: "x9t3ola",
    background: "x5yjaeh",
    padding: "xs5nf76",
    lineHeight: "x1kq7lva",
    color: "x11jfisy",
    transition: "x1wzgjk3",
    borderRadius: "x1aaq583",
    boxShadow: "x1f0gzb3",
    fontSize: "xrv4cvt",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "x1rstohm",
    ":first-child_marginTop": "x14l7nz5",
    ":last-child_marginBottom": "xzboxd6",
    $$css: true
  },
  messageHeader: {
    "Message__styles.messageHeader": "Message__styles.messageHeader",
    display: "x1lliihq",
    fontFamily: "xy3j3os",
    fontWeight: "x117nqv4",
    fontSize: "x1uveeis",
    margin: "x12bhije",
    $$css: true
  },
  messageIcon: {
    "Message__styles.messageIcon": "Message__styles.messageIcon",
    marginRight: "xwgd6ph",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  messageCloseIcon: {
    "Message__styles.messageCloseIcon": "Message__styles.messageCloseIcon",
    cursor: "x1ypdohk",
    position: "x10l6tqk",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    top: "x1uieiw1",
    right: "x1gd1x1u",
    insetInlineStart: null,
    insetInlineEnd: null,
    opacity: "x1ks1olk",
    transition: "x19n8f2o",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  visibleMessage: {
    "Message__styles.visibleMessage": "Message__styles.visibleMessage",
    display: "x1lliihq",
    $$css: true
  },
  infoMessage: {
    "Message__styles.infoMessage": "Message__styles.infoMessage",
    backgroundColor: "x15fdgw4",
    color: "x1cjvsfd",
    boxShadow: "xhbq16y",
    $$css: true
  },
  infoMessageHeader: {
    "Message__styles.infoMessageHeader": "Message__styles.infoMessageHeader",
    color: "x1t0nrw6",
    $$css: true
  },
  errorMessage: {
    "Message__styles.errorMessage": "Message__styles.errorMessage",
    backgroundColor: "x1jdrlbx",
    color: "x1cjvsfd",
    boxShadow: "x179zxkx",
    $$css: true
  },
  errorMessageHeader: {
    "Message__styles.errorMessageHeader": "Message__styles.errorMessageHeader",
    color: "xotk58x",
    $$css: true
  }
};
const Message = exports.Message = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(19);
  const {
    children,
    error,
    header,
    onDismiss,
    content,
    info
  } = t0;
  if (!content) {
    return null;
  }
  let t1;
  if ($[0] !== error || $[1] !== info) {
    t1 = stylex.props(styles.message, styles.visibleMessage, error && styles.errorMessage, info && styles.infoMessage);
    $[0] = error;
    $[1] = info;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== onDismiss) {
    t2 = onDismiss && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "close",
      className: [styles.messageIcon, styles.messageCloseIcon],
      link: true,
      onClick: onDismiss
    });
    $[3] = onDismiss;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== error || $[6] !== header || $[7] !== info) {
    t3 = header && /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadValues({}, stylex.props(styles.messageHeader, error && styles.errorMessageHeader, info && styles.infoMessageHeader)), header);
    $[5] = error;
    $[6] = header;
    $[7] = info;
    $[8] = t3;
  } else {
    t3 = $[8];
  }
  let t4;
  if ($[9] !== content) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, null, content);
    $[9] = content;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  let t5;
  if ($[11] !== children || $[12] !== t3 || $[13] !== t4) {
    t5 = /* @__PURE__ */_react.default.createElement(_Html.default.div, null, t3, t4, children);
    $[11] = children;
    $[12] = t3;
    $[13] = t4;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  let t6;
  if ($[15] !== t1 || $[16] !== t2 || $[17] !== t5) {
    t6 = /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadValues({}, t1), t2, t5);
    $[15] = t1;
    $[16] = t2;
    $[17] = t5;
    $[18] = t6;
  } else {
    t6 = $[18];
  }
  return t6;
});