"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useMutation } from "@/shared/hooks/gql";
import React, { useCallback } from "react";
const TRACK = gql(`
  mutation EventMutation(
    $label: String!
    $category: String!
    $action: String!
    $value: String
  ) {
    sendEvent(
      label: $label
      category: $category
      action: $action
      value: $value
    ) {
      ok
    }
  }
`);
export const useTracking = () => {
  const [track] = useMutation(TRACK);
  return useCallback((category, action, label, value) => track({
    variables: {
      category,
      action,
      label,
      value
    }
  }), [track]);
};
export const withTracking = (WrappedComponent) => (props) => {
  const track = useTracking();
  return /* @__PURE__ */ React.createElement(WrappedComponent, __spreadProps(__spreadValues({}, props), { track }));
};
export default withTracking;
