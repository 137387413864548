"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("../Html"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react = _interopRequireWildcard(require("react"));
var _Loader = require("../Loader");
var _useScrollToBottom = require("use-scroll-to-bottom");
var _usehooksTs = require("usehooks-ts");
var _helpers = require("../helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  root: {
    "MessageContainer__styles.root": "MessageContainer__styles.root",
    flex: "x1p2dfur",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "x889kno",
    paddingBottom: null,
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    userSelect: "x1hx0egp",
    width: "xh8yej3",
    display: "x78zum5",
    willChange: "x2i4810",
    flexDirection: "xdt5ytf",
    position: "x1n2onr6",
    $$css: true
  },
  loaderWrapper: {
    "MessageContainer__styles.loaderWrapper": "MessageContainer__styles.loaderWrapper",
    height: "x10wjd1d",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(30);
  const {
    ref,
    nextPage,
    children,
    loading
  } = t0;
  let messageIds;
  let t1;
  if ($[0] !== children) {
    messageIds = (children == null ? void 0 : children.map(_temp)) || [];
    t1 = JSON.stringify(messageIds);
    $[0] = children;
    $[1] = messageIds;
    $[2] = t1;
  } else {
    messageIds = $[1];
    t1 = $[2];
  }
  const messageIdsString = t1;
  const containerRef = (0, _react.useRef)(null);
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      prevScrollHeight: -1,
      autoScroll: true,
      hasScrolled: false
    };
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const scrollStateRef = (0, _react.useRef)(t2);
  const [setBottomRef, bottomElementInView] = (0, _useScrollToBottom.useScrollToBottom)();
  let t3;
  if ($[4] !== bottomElementInView) {
    t3 = () => {
      const container = containerRef.current;
      if (!container) {
        return true;
      }
      return container.scrollHeight - container.scrollTop <= container.clientHeight || bottomElementInView;
    };
    $[4] = bottomElementInView;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  const isBottom = t3;
  let t4;
  if ($[6] !== nextPage) {
    t4 = () => __async(void 0, null, function* () {
      const scrollState = scrollStateRef.current;
      const container_0 = containerRef.current;
      if (!container_0) {
        return;
      }
      scrollState.prevScrollHeight = container_0.scrollHeight;
      yield nextPage();
    });
    $[6] = nextPage;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const _loadMore = t4;
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = {
      leading: true
    };
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const loadMore = (0, _helpers.useDebounce)(_loadMore, 500, t5);
  let t6;
  if ($[9] !== isBottom || $[10] !== loadMore) {
    t6 = () => __async(void 0, null, function* () {
      const container_1 = containerRef.current;
      const scrollState_0 = scrollStateRef.current;
      if (!container_1) {
        return;
      }
      if (!scrollState_0.hasScrolled || isBottom()) {
        scrollState_0.autoScroll = true;
      } else {
        scrollState_0.autoScroll = false;
      }
      if (container_1.scrollTop === 0) {
        yield loadMore();
      }
      scrollState_0.hasScrolled = true;
    });
    $[9] = isBottom;
    $[10] = loadMore;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  const scrollHandler = t6;
  let t7;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = () => {
      const container_2 = containerRef.current;
      if (!container_2) {
        throw "Container not initialized";
      }
      container_2.scrollTop = container_2.scrollHeight;
    };
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  const scrollToBottom = t7;
  let t8;
  if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = t92 => {
      const {
        height: newHeight
      } = t92;
      const container_3 = containerRef.current;
      const scrollState_1 = scrollStateRef.current;
      if (!newHeight) {
        scrollState_1.hasScrolled = false;
      }
      if (!scrollState_1.hasScrolled) {
        scrollToBottom();
        return;
      }
      if (newHeight && container_3) {
        const scrollTopAdjustment = scrollState_1.autoScroll ? container_3.scrollHeight : container_3.scrollTop - newHeight;
        container_3.scrollTop = scrollTopAdjustment;
      }
    };
    $[13] = t8;
  } else {
    t8 = $[13];
  }
  const onResize = t8;
  let t9;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = {
      ref: containerRef,
      box: "border-box",
      onResize
    };
    $[14] = t9;
  } else {
    t9 = $[14];
  }
  (0, _usehooksTs.useResizeObserver)(t9);
  let t10;
  if ($[15] !== messageIds.length) {
    t10 = () => {
      const container_4 = containerRef.current;
      const scrollState_2 = scrollStateRef.current;
      if (messageIds.length === 0) {
        return;
      }
      if (!container_4) {
        return;
      }
      if (scrollState_2.autoScroll) {
        scrollToBottom();
      }
      if (scrollState_2.prevScrollHeight !== -1) {
        container_4.scrollTop = container_4.scrollHeight - scrollState_2.prevScrollHeight;
        scrollState_2.prevScrollHeight = -1;
      }
    };
    $[15] = messageIds.length;
    $[16] = t10;
  } else {
    t10 = $[16];
  }
  let t11;
  if ($[17] !== messageIds.length || $[18] !== messageIdsString) {
    t11 = [messageIdsString, messageIds.length, scrollToBottom];
    $[17] = messageIds.length;
    $[18] = messageIdsString;
    $[19] = t11;
  } else {
    t11 = $[19];
  }
  (0, _react.useEffect)(t10, t11);
  const refs = (0, _helpers.useMergedRefs)(containerRef, ref);
  const t12 = refs;
  let t13;
  if ($[20] !== loading) {
    t13 = loading && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.loaderWrapper
    }, /* @__PURE__ */_react.default.createElement(_Loader.Loader, {
      active: true
    }));
    $[20] = loading;
    $[21] = t13;
  } else {
    t13 = $[21];
  }
  let t14;
  if ($[22] !== children || $[23] !== setBottomRef) {
    t14 = _react.default.Children.map(children, (child_0, i) => {
      if (!child_0) {
        return;
      }
      if (i === _react.default.Children.count(children) - 1) {
        return _react.default.cloneElement(child_0, {
          ref: setBottomRef
        });
      }
      return child_0;
    });
    $[22] = children;
    $[23] = setBottomRef;
    $[24] = t14;
  } else {
    t14 = $[24];
  }
  let t15;
  if ($[25] !== scrollHandler || $[26] !== t12 || $[27] !== t13 || $[28] !== t14) {
    t15 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref: t12,
      style: styles.root,
      onScroll: scrollHandler
    }, t13, t14);
    $[25] = scrollHandler;
    $[26] = t12;
    $[27] = t13;
    $[28] = t14;
    $[29] = t15;
  } else {
    t15 = $[29];
  }
  return t15;
});
function _temp(child) {
  return child.key;
}