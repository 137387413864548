"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Menu = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  menu: {
    "Menu__styles.menu": "Menu__styles.menu",
    display: "x78zum5",
    fontWeight: "x1fcty0u",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x1hta50j",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    boxShadow: "x16nqfeu",
    borderRadius: "x1aaq583",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    minHeight: "xb15sw9",
    position: "x1n2onr6",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    "::after_content": "x1s928wv",
    "::after_display": "xhkezso",
    "::after_height": "x1gmr53x",
    "::after_clear": "xn12031",
    "::after_visibility": "xx0cqyw",
    ":first-child_marginTop": "x14l7nz5",
    ":last-child_marginBottom": "xzboxd6",
    $$css: true
  },
  tabular: {
    "Menu__styles.tabular": "Menu__styles.tabular",
    borderRadius: "x2u8bby",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    boxShadow: "x1gnnqk1",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: "x1qhh985",
    background: "xwp96nz",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderBottomStyle: "x1q0q8m5",
    borderBottomColor: "xoa59nk",
    $$css: true
  },
  inverted: {
    "Menu__styles.inverted": "Menu__styles.inverted",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    boxShadow: "x1gnnqk1",
    $$css: true
  },
  item: {
    "Menu__styles.item": "Menu__styles.item",
    cursor: "x1ypdohk",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    verticalAlign: "xxymvpz",
    lineHeight: "xo5v014",
    textDecoration: "x1hl2dhg",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    flex: "x3psx0u",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    userSelect: "x87ps6o",
    background: "x11g6tue",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x11pnwsa",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    textTransform: "x6mezaz",
    color: "x11jfisy",
    fontWeight: "x1xlr1w8",
    transition: "xswv624",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":hover_filter": "xlcqwk8",
    ":active_backgroundColor": "x1f4qhht",
    $$css: true
  },
  disabled: {
    "Menu__styles.disabled": "Menu__styles.disabled",
    cursor: "xt0e3qv",
    backgroundColor: "xjbqb8w",
    color: "x3vd2ub",
    $$css: true
  },
  active: {
    "Menu__styles.active": "Menu__styles.active",
    color: "x11jfisy",
    boxShadow: "x1gnnqk1",
    fontWeight: "x1xlr1w8",
    $$css: true
  },
  tabularItem: {
    "Menu__styles.tabularItem": "Menu__styles.tabularItem",
    background: "x1md70p1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderBottomWidth: "x1qhh985",
    borderLeftWidth: "xyj58a3",
    borderInlineStartWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: "xgfja2r",
    borderTopWidth: "x178xt8z",
    borderTopStyle: "x13fuv20",
    borderTopColor: "x1v8p93f",
    padding: "x173uqhg",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x11jfisy",
    $$css: true
  },
  activeTabularItem: {
    "Menu__styles.activeTabularItem": "Menu__styles.activeTabularItem",
    color: "x11jfisy",
    borderTopWidth: "x972fbf",
    borderColor: "x1t3oeb2",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    fontWeight: "x117nqv4",
    marginBottom: "xat24cr",
    boxShadow: "x1gnnqk1",
    borderRadius: "xsl8uol",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  tiny: {
    "Menu__styles.tiny": "Menu__styles.tiny",
    fontSize: "xs6yfsm",
    $$css: true
  }
};
const Menu = exports.Menu = Object.assign((0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    children,
    tabular,
    className,
    inverted
  } = t0;
  const t1 = tabular && styles.tabular;
  const t2 = inverted && styles.inverted;
  let t3;
  if ($[0] !== className || $[1] !== t1 || $[2] !== t2) {
    t3 = [styles.menu, t1, t2, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] !== children || $[5] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t3
    }, children);
    $[4] = children;
    $[5] = t3;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  return t4;
}), {
  Item: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(16);
    const {
      name,
      content,
      active,
      onClick,
      inverted,
      tabular,
      className,
      children,
      disabled,
      size,
      id
    } = t0;
    let t1;
    if ($[0] !== name || $[1] !== onClick) {
      t1 = _e => {
        if (onClick) {
          onClick({}, {
            name
          });
        }
      };
      $[0] = name;
      $[1] = onClick;
      $[2] = t1;
    } else {
      t1 = $[2];
    }
    const handleClick = t1;
    const t2 = disabled && styles.disabled;
    const t3 = size && styles[size];
    const t4 = active && styles.active;
    const t5 = inverted && styles.inverted;
    const t6 = tabular && styles.tabularItem;
    const t7 = tabular && active && styles.activeTabularItem;
    let t8;
    if ($[3] !== className || $[4] !== t2 || $[5] !== t3 || $[6] !== t4 || $[7] !== t5 || $[8] !== t6 || $[9] !== t7) {
      t8 = [styles.item, t2, t3, t4, t5, t6, t7, className];
      $[3] = className;
      $[4] = t2;
      $[5] = t3;
      $[6] = t4;
      $[7] = t5;
      $[8] = t6;
      $[9] = t7;
      $[10] = t8;
    } else {
      t8 = $[10];
    }
    const t9 = children || content || name;
    let t10;
    if ($[11] !== handleClick || $[12] !== id || $[13] !== t8 || $[14] !== t9) {
      t10 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        id,
        style: t8,
        onClick: handleClick
      }, t9);
      $[11] = handleClick;
      $[12] = id;
      $[13] = t8;
      $[14] = t9;
      $[15] = t10;
    } else {
      t10 = $[15];
    }
    return t10;
  })
});