"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Form = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Input = require("./Input");
var _Select = require("./Select");
var _Button = require("./Button");
var _Checkbox = require("./Checkbox");
var _TextArea = require("./TextArea");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  form: {
    "Form__styles.form": "Form__styles.form",
    textAlign: "xdpxx8g",
    userSelect: "x87ps6o",
    color: "x11jfisy",
    lineHeight: "x37zpob",
    boxSizing: "x1p6iq4g",
    textSizeAdjust: "x1javzw2",
    touchAction: "xggy1nq",
    position: "x1n2onr6",
    maxWidth: "x193iq5w",
    padding: "xggk2y7",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontSize: "x1jchvi3",
    $$css: true
  },
  grouped: {
    "Form__styles.grouped": "Form__styles.grouped",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    justifyContent: "x1nhvcw1",
    "@media (max-width: 1000px)_flexDirection": "xm6fj9k",
    $$css: true
  },
  label: {
    "Form__styles.label": "Form__styles.label",
    paddingBottom: "x1a8lsjc",
    whiteSpace: "xuxw1ft",
    fontWeight: "x117nqv4",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inlineLabel: {
    "Form__styles.inlineLabel": "Form__styles.inlineLabel",
    paddingBottom: "x18d9i69",
    whiteSpace: "xuxw1ft",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  field: {
    "Form__styles.field": "Form__styles.field",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    clear: "x18xfj1p",
    color: "x11jfisy",
    flex: "x12lumcd",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    marginTop: "xr9ek0c",
    marginRight: "x1yf7rl7",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "x12nagc",
    marginLeft: "xj3b58b",
    padding: "x1i3ajwb",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  disabled: {
    "Form__styles.disabled": "Form__styles.disabled",
    pointerEvents: "x47corl",
    opacity: "xyd83as",
    $$css: true
  },
  required: {
    "Form__styles.required": "Form__styles.required",
    "::after_display": "x1e1aq9x",
    "::after_verticalAlign": "xc5i1tb",
    $$css: true
  },
  vertical: {
    "Form__styles.vertical": "Form__styles.vertical",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  inline: {
    "Form__styles.inline": "Form__styles.inline",
    display: "x3nfvp2",
    $$css: true
  },
  formErrorLabel: {
    "Form__styles.formErrorLabel": "Form__styles.formErrorLabel",
    color: "x19lk4tq",
    $$css: true
  },
  formErrorInputIcon: {
    "Form__styles.formErrorInputIcon": "Form__styles.formErrorInputIcon",
    borderColor: "x1bmaitd",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  equal: {
    "Form__styles.equal": "Form__styles.equal",
    $$css: true
  },
  error: {
    "Form__styles.error": "Form__styles.error",
    $$css: true
  },
  small: {
    "Form__styles.small": "Form__styles.small",
    fontSize: "xrd5znx",
    $$css: true
  }
};
const Group = (0, _react.memo)(({
  children,
  className,
  inline,
  label
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.field, styles.grouped, !inline && styles.vertical, inline && styles.inline, className]
  }, label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
    style: [styles.label, inline && styles.inlineLabel]
  }, label), children);
});
const Field = (0, _react.memo)(({
  children,
  disabled,
  required,
  className,
  onClick
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    onClick,
    style: [styles.field, disabled && styles.disabled, required && styles.required, className]
  }, children);
});
const FormSelect = (0, _react.memo)(props => /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Select.Select, __spreadValues({}, props))));
const FormButton = (0, _react.memo)(props => /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Button.Button, __spreadValues({}, props))));
const FormInput = (0, _react.memo)((0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Input.Input, __spreadProps(__spreadValues({}, props), {
  ref
})))));
const FormLabel = (0, _react.memo)(({
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.label, {
    style: styles.label
  }, children);
});
const FormTextArea = (0, _react.memo)(props => /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_TextArea.TextArea, __spreadValues({}, props))));
const FormCheckbox = (0, _react.memo)(props => /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Checkbox.Checkbox, __spreadValues({}, props))));
const Form = exports.Form = Object.assign((0, _react.memo)(({
  children,
  className,
  onSubmit,
  autoComplete,
  size,
  disabled,
  error
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.form, {
    style: [styles.form, size && styles[size], disabled && styles.disabled, error && styles.error, className],
    onSubmit: onSubmit ? e => {
      e.preventDefault();
      onSubmit(e);
    } : void 0,
    autoComplete
  }, children);
}), {
  Select: FormSelect,
  Button: FormButton,
  Input: FormInput,
  InputWrapper: _Input.InputWrapper,
  Group,
  Label: FormLabel,
  Field,
  TextArea: FormTextArea,
  Checkbox: FormCheckbox
});