"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = exports.styles = {
  readIndicator: {
    "ChatMessages__styles.readIndicator": "ChatMessages__styles.readIndicator",
    width: "xeq5yr9",
    display: "x78zum5",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginTop: "xr1yuqi",
    padding: "xfawy5m",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    height: "xlup9mm",
    minHeight: "x1hshjfz",
    $$css: true
  },
  readIndicatorAvatar: {
    "ChatMessages__styles.readIndicatorAvatar": "ChatMessages__styles.readIndicatorAvatar",
    height: "xlup9mm",
    width: "x1kky2od",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginLeft: "x1swdo50",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1ldx8e",
    fontSize: "x1j6dyjg",
    $$css: true
  },
  sentIndicator: {
    "ChatMessages__styles.sentIndicator": "ChatMessages__styles.sentIndicator",
    color: "x3bicyu",
    $$css: true
  },
  diamond: {
    "ChatMessages__styles.diamond": "ChatMessages__styles.diamond",
    bottom: "x1ey2m1c",
    right: "x6smm1b",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "x1t8mvi6",
    fontSize: "x1j61zf2",
    position: "x10l6tqk",
    width: "x1fsd2vl",
    height: "x170jfvy",
    $$css: true
  },
  messageOptions: {
    "ChatMessages__styles.messageOptions": "ChatMessages__styles.messageOptions",
    position: "x10l6tqk",
    top: "x13vifvy",
    width: "x1pju0fl",
    height: "xg7h5cd",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  ownMessageOptions: {
    "ChatMessages__styles.ownMessageOptions": "ChatMessages__styles.ownMessageOptions",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  messageOptionsHasPrev: {
    "ChatMessages__styles.messageOptionsHasPrev": "ChatMessages__styles.messageOptionsHasPrev",
    paddingTop: "xexx8yu",
    $$css: true
  },
  scale: {
    "ChatMessages__styles.scale": "ChatMessages__styles.scale",
    transform: "x9mn55f",
    $$css: true
  },
  chatMessage: {
    "ChatMessages__styles.chatMessage": "ChatMessages__styles.chatMessage",
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    background: "x11g6tue",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "x1y1aw1k",
    paddingBottom: "x1120s5i",
    borderTopWidth: "x972fbf",
    lineHeight: "x1u7k74",
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    userSelect: "x87ps6o",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "xvr3rsr xlihjin",
    $$css: true
  },
  hasPrevMessage: {
    "ChatMessages__styles.hasPrevMessage": "ChatMessages__styles.hasPrevMessage",
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    $$css: true
  },
  avatar: {
    "ChatMessages__styles.avatar": "ChatMessages__styles.avatar",
    display: "x1lliihq",
    width: "xvy4d1p",
    height: "xxk0z11",
    float: "x1faq86j",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: "x1db2dqx",
    marginBlock: null,
    marginTop: "xr1yuqi",
    marginBottom: "x1e56ztr",
    position: "x1n2onr6",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    backgroundColor: "xjlc4g5",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  noAuthor: {
    "ChatMessages__styles.noAuthor": "ChatMessages__styles.noAuthor",
    marginBottom: "x4ii5y1",
    $$css: true
  },
  noHover: {
    "ChatMessages__styles.noHover": "ChatMessages__styles.noHover",
    display: "x78zum5",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x7qgb2c",
    $$css: true
  },
  pending: {
    "ChatMessages__styles.pending": "ChatMessages__styles.pending",
    opacity: "xbyyjgo",
    $$css: true
  },
  hidden: {
    "ChatMessages__styles.hidden": "ChatMessages__styles.hidden",
    visibility: "xlshs6z",
    $$css: true
  },
  rubyGlow: {
    "ChatMessages__styles.rubyGlow": "ChatMessages__styles.rubyGlow",
    color: "xozz0dy",
    $$css: true
  },
  chatMessageMenu: {
    "ChatMessages__styles.chatMessageMenu": "ChatMessages__styles.chatMessageMenu",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  chatMessageMenuButtons: {
    "ChatMessages__styles.chatMessageMenuButtons": "ChatMessages__styles.chatMessageMenuButtons",
    display: "x78zum5",
    $$css: true
  },
  mobileTime: {
    "ChatMessages__styles.mobileTime": "ChatMessages__styles.mobileTime",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  avatarImg: {
    "ChatMessages__styles.avatarImg": "ChatMessages__styles.avatarImg",
    position: "x10l6tqk",
    display: "x1lliihq",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xh8yej3",
    height: "x5yr21d",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  transposeAvatar: (i, length) => [{
    "ChatMessages__styles.transposeAvatar": "ChatMessages__styles.transposeAvatar",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderWidth: "x1rihvjd",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    $$css: true
  }, {
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")((length < 2 ? 0 : -4) + i * 6),
    "--borderWidth": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(length < 2 ? 0 : 1)
  }],
  author: {
    "ChatMessages__styles.author": "ChatMessages__styles.author",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    color: "x11jfisy",
    fontWeight: "x117nqv4",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    width: "xeq5yr9",
    $$css: true
  },
  authorName: {
    "ChatMessages__styles.authorName": "ChatMessages__styles.authorName",
    maxWidth: "x1ncir08",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    marginLeft: "xet2fuk",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "x12nagc",
    fontSize: "xif65rj",
    $$css: true
  },
  authorClickable: {
    "ChatMessages__styles.authorClickable": "ChatMessages__styles.authorClickable",
    cursor: "x1ypdohk",
    ":active_transform": "xvruv2t",
    $$css: true
  },
  textWrapper: {
    "ChatMessages__styles.textWrapper": "ChatMessages__styles.textWrapper",
    display: "x78zum5",
    $$css: true
  },
  textInnerWrapper: {
    "ChatMessages__styles.textInnerWrapper": "ChatMessages__styles.textInnerWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  date: {
    "ChatMessages__styles.date": "ChatMessages__styles.date",
    display: "x78zum5",
    color: "xrkdtlx",
    fontSize: "xfifm61",
    marginBottom: "x12nagc",
    marginTop: "x1gslohp",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    ":last-child_marginRight": "x552jh6",
    ":last-child_marginInlineStart": null,
    ":last-child_marginInlineEnd": null,
    "@media (max-width: 1000px)_fontSize": "xzgahhe",
    $$css: true
  },
  text: {
    "ChatMessages__styles.text": "ChatMessages__styles.text",
    marginTop: "xr9ek0c",
    marginBottom: "xjpr12u",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    overflowWrap: "xj0a0fe",
    flexDirection: "xdt5ytf",
    color: "x11jfisy",
    backgroundColor: "x8897o3",
    lineHeight: "xwn7fz2",
    userSelect: "x1hx0egp",
    WebkitUserSelect: "x1rh3ycu",
    borderRadius: "xgqmno8",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "xe8ttls",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "xf18ygs",
    paddingEnd: null,
    paddingRight: "xnuq7ks",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    paddingInlineStart: null,
    paddingInlineEnd: null,
    width: "xeq5yr9",
    maxWidth: "xv0phki",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_userSelect": "x1de8jf8",
    "@media (max-width: 1000px)_WebkitUserSelect": "x10d2bzd",
    "@media (max-width: 1000px)_maxWidth": "x110dtvw",
    $$css: true
  },
  emoji: {
    "ChatMessages__styles.emoji": "ChatMessages__styles.emoji",
    fontSize: "x1s3cmhv",
    $$css: true
  },
  isOwn: {
    "ChatMessages__styles.isOwn": "ChatMessages__styles.isOwn",
    backgroundColor: "x8qxh4v",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    maxWidth: "x4d2062",
    "@media (max-width: 1000px)_maxWidth": "x1ouf8b1",
    $$css: true
  },
  small: {
    "ChatMessages__styles.small": "ChatMessages__styles.small",
    fontSize: "xif65rj",
    "@media (max-width: 1000px)_fontSize": "x15saapg",
    $$css: true
  }
};