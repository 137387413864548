"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _ScreenshareModal = _interopRequireDefault(require("@/room/components/Player/Menu/ScreenshareModal"));
var _screenshare = _interopRequireDefault(require("./screenshare.mp4"));
var _BackButton = _interopRequireDefault(require("@/room/components/Player/Menu/BackButton"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  steps: {
    "index__styles.steps": "index__styles.steps",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  stepsVisual: {
    "index__styles.stepsVisual": "index__styles.stepsVisual",
    display: "x78zum5",
    width: "x1l2rt3b",
    maxWidth: "x193iq5w",
    justifyContent: "xl56j7k",
    $$css: true
  },
  stepLabels: {
    "index__styles.stepLabels": "index__styles.stepLabels",
    display: "x78zum5",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  stepLabelActive: {
    "index__styles.stepLabelActive": "index__styles.stepLabelActive",
    color: "x11jfisy",
    cursor: "x1ypdohk",
    $$css: true
  },
  step: {
    "index__styles.step": "index__styles.step",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    width: "xezl2tj",
    height: "xlrawln",
    backgroundColor: "x6kzxpx",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    $$css: true
  },
  line: {
    "index__styles.line": "index__styles.line",
    width: "x1exxlbk",
    height: "x1ycjhwn",
    margin: "xe7mz8o",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "x6kzxpx",
    $$css: true
  },
  active: {
    "index__styles.active": "index__styles.active",
    backgroundColor: "x1r6tqhq",
    color: "x11jfisy",
    cursor: "x1ypdohk",
    $$css: true
  },
  root: {
    "index__styles.root": "index__styles.root",
    position: "x1n2onr6",
    height: "x5yr21d",
    width: "xh8yej3",
    display: "x78zum5",
    $$css: true
  },
  guide: {
    "index__styles.guide": "index__styles.guide",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    alignItems: "x6s0dn4",
    justifyContent: "x1qughib",
    height: "x5yr21d",
    textAlign: "x2b8uid",
    $$css: true
  },
  stepContent: {
    "index__styles.stepContent": "index__styles.stepContent",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    justifyContent: "x1l1ennw",
    maxHeight: "x1phlbz0",
    alignItems: "x6s0dn4",
    marginBottom: "x4ii5y1",
    $$css: true
  },
  logo: {
    "index__styles.logo": "index__styles.logo",
    width: "x1oysuqx",
    $$css: true
  },
  video: {
    "index__styles.video": "index__styles.video",
    height: "x1vd4hg5",
    "@media(max-height: 630px)_display": "x1ykbwhn",
    $$css: true
  },
  shareTabText: {
    "index__styles.shareTabText": "index__styles.shareTabText",
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  actions: {
    "index__styles.actions": "index__styles.actions",
    height: "xpyat2d",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  launchButton: {
    "index__styles.launchButton": "index__styles.launchButton",
    fontWeight: "x1xlr1w8",
    padding: "xdfu9ks",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    cursor: "x1ypdohk",
    backgroundColor: "x8qxh4v",
    color: "x11jfisy",
    $$css: true
  },
  screenshareSelector: {
    "index__styles.screenshareSelector": "index__styles.screenshareSelector",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    height: "x1ymw6g",
    flexDirection: "xdt5ytf",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  a: {
    "index__styles.a": "index__styles.a",
    cursor: "x1ypdohk",
    $$css: true
  }
};
const Steps = ({
  steps,
  currentStep,
  setStep
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.steps
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.stepsVisual
  }, steps.map((_step, i) => /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.step, i + 1 <= currentStep && styles.active],
    onClick: () => i + 1 <= currentStep ? setStep(i + 1) : void 0
  }, i + 1), i !== steps.length - 1 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.line, i + 2 <= currentStep && styles.active]
  })))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.stepLabels
  }, steps.map((step, i) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    key: i,
    style: i + 1 <= currentStep && styles.stepLabelActive,
    onClick: () => i + 1 <= currentStep ? setStep(i + 1) : void 0
  }, step))));
};
var _default = ({
  title,
  url,
  logo,
  goBack,
  onClose
}) => {
  const [step, setStep] = (0, _react.useState)(1);
  const windowChecker = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    return () => {
      if (windowChecker.current) clearInterval(windowChecker.current);
    };
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, /* @__PURE__ */_react.default.createElement(_BackButton.default, {
    onClick: goBack
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.guide
  }, /* @__PURE__ */_react.default.createElement(Steps, {
    steps: ["launch app", "screen share"],
    currentStep: step,
    setStep
  }), step === 1 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.stepContent
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Launch ", title, ", log into your account and select something to watch.", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Keep it open and return to this screen.")), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.logo,
    src: logo
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.actions
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.launchButton,
    rel: "noreferrer",
    href: url,
    target: "_blank",
    onClick: e => {
      e.preventDefault();
      if (windowChecker.current) clearInterval(windowChecker.current);
      const target = e.target;
      const popup = window.open(target.href, "popUpWindow", "popup=1,height=459,width=816,left=0,top=0,scrollbars=yes,toolbar=yes,menubar=no");
      if (!popup || popup.closed) {
        window.open(target.href, "_blank");
      } else {
        windowChecker.current = setInterval(() => {
          if (popup.closed) {
            setStep(1);
            if (windowChecker.current) clearInterval(windowChecker.current);
          }
        }, 1e3);
      }
      setStep(2);
    }
  }, "Launch ", title), /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.a,
    onClick: goBack
  }, "Cancel"))), step === 2 && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.shareTabText
  }, "Share your tab to watch on Kosmi.", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Make sure share tab audio is turned on.")), /* @__PURE__ */_react.default.createElement(_uikit.Html.video, {
    style: styles.video,
    src: _screenshare.default,
    autoPlay: true,
    loop: true,
    muted: true
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.screenshareSelector
  }, /* @__PURE__ */_react.default.createElement(_ScreenshareModal.default, {
    onClose: () => {
      goBack();
      onClose();
    },
    simple: true,
    customLabel: `Share ${title} tab`
  })))));
};
exports.default = _default;