"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("../Html"));
var _react = _interopRequireWildcard(require("react"));
var _Input = require("../Input");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  messagebox: {
    "Messagebox__styles.messagebox": "Messagebox__styles.messagebox",
    position: "x1n2onr6",
    display: "x78zum5",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x10d6zji",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x14odnwx",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    background: "x1dq10d1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    height: "xsdox4t",
    maxHeight: "x1egxudb",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    minWidth: "xeuugli",
    $$css: true
  },
  focused: {
    "Messagebox__styles.focused": "Messagebox__styles.focused",
    background: "x1odt36a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    $$css: true
  },
  transparent: {
    "Messagebox__styles.transparent": "Messagebox__styles.transparent",
    backgroundColor: "x1d1vqyr",
    $$css: true
  },
  input: {
    "Messagebox__styles.input": "Messagebox__styles.input",
    background: "x1md70p1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    boxShadow: "x1gnnqk1",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    fontSize: "x1j61zf2",
    ":focus_borderColor": "x18raj76",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    $$css: true
  },
  inputWrapper: {
    "Messagebox__styles.inputWrapper": "Messagebox__styles.inputWrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }
};
var _default = t0 => {
  const $ = (0, _compilerRuntime.c)(20);
  const {
    ref,
    children,
    disabled,
    onFocus,
    onBlur,
    onEnter,
    inputRef,
    onChange,
    transparent
  } = t0;
  const [isFocused, setIsFocused] = (0, _react.useState)(false);
  const t1 = isFocused && styles.focused;
  const t2 = transparent && styles.transparent;
  let t3;
  if ($[0] !== t1 || $[1] !== t2) {
    t3 = [styles.messagebox, t1, t2];
    $[0] = t1;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      spellcheck: "true"
    };
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== onFocus) {
    t5 = () => {
      setIsFocused(true);
      if (onFocus) {
        onFocus();
      }
    };
    $[4] = onFocus;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] !== onBlur) {
    t6 = () => {
      setIsFocused(false);
      if (onBlur) {
        onBlur();
      }
    };
    $[6] = onBlur;
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  let t7;
  if ($[8] !== disabled || $[9] !== inputRef || $[10] !== onChange || $[11] !== onEnter || $[12] !== t5 || $[13] !== t6) {
    t7 = /* @__PURE__ */_react.default.createElement(_Input.Input, __spreadProps(__spreadValues({
      ref: inputRef,
      className: styles.input,
      wrapperClassName: styles.inputWrapper,
      disabled,
      placeholder: "Aa",
      onEnter
    }, t4), {
      onChange,
      onFocus: t5,
      onBlur: t6
    }));
    $[8] = disabled;
    $[9] = inputRef;
    $[10] = onChange;
    $[11] = onEnter;
    $[12] = t5;
    $[13] = t6;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  let t8;
  if ($[15] !== children || $[16] !== ref || $[17] !== t3 || $[18] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref,
      style: t3
    }, t7, children);
    $[15] = children;
    $[16] = ref;
    $[17] = t3;
    $[18] = t7;
    $[19] = t8;
  } else {
    t8 = $[19];
  }
  return t8;
};
exports.default = _default;