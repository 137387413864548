"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _Premium = require("@/shared/components/Premium");
var _helpers = require("kosmi-sdk/helpers");
var _gql = require("@/shared/hooks/gql");
var _hooks = require("../hooks");
var _uikit = require("@/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const THEMES = (0, _gql.gql)(`
  query Themes {
    themes {
      backgroundImageUrl
      colorScheme
      name
      sofaUrl
      tvPlaceholderUrl
    }
  }
`);
const styles = {
  themes: {
    "index__styles.themes": "index__styles.themes",
    display: "x78zum5",
    position: "x1n2onr6",
    flexWrap: "x1a02dak",
    flexDirection: "x1q0g3np",
    width: "xh8yej3",
    maxWidth: "xrgej4m",
    height: "xg7h5cd",
    gap: "x883omv",
    rowGap: null,
    columnGap: null,
    justifyContent: "xl56j7k",
    padding: "xsozion",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  theme: {
    "index__styles.theme": "index__styles.theme",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xifkd7f",
    height: "xpyat2d",
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x18pyamd",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    ":hover_borderColor": "x17wnsh",
    ":hover_borderInlineColor": null,
    ":hover_borderInlineStartColor": null,
    ":hover_borderLeftColor": null,
    ":hover_borderInlineEndColor": null,
    ":hover_borderRightColor": null,
    ":hover_borderBlockColor": null,
    ":hover_borderTopColor": null,
    ":hover_borderBottomColor": null,
    ":hover_cursor": "x1277o0a",
    ":hover_filter": "xd1q81k",
    $$css: true
  },
  themeInner: {
    "index__styles.themeInner": "index__styles.themeInner",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "xr1yuqi",
    marginBottom: null,
    position: "x1n2onr6",
    background: "xifp8j",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    height: "x1gnnpzl",
    $$css: true
  },
  diamond: {
    "index__styles.diamond": "index__styles.diamond",
    width: "x1a00udw",
    height: "xx3o462",
    fontSize: "xbjbd00",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "xat24cr",
    $$css: true
  },
  themeImg: {
    "index__styles.themeImg": "index__styles.themeImg",
    width: "xh8yej3",
    objectFit: "xl1xv1r",
    height: "xnxb3zj",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    $$css: true
  }
};
var _default = exports.default = (0, _helpers.withDialogs)(({
  alert
}) => {
  const {
    data
  } = (0, _gql.useQuery)(THEMES);
  let themes = (data == null ? void 0 : data.themes) || [];
  const currentUser = (0, _helpers.currentUserHook)();
  const {
    setValue,
    removeValue
  } = (0, _hooks.useSpacesActions)();
  const freeThreshold = 2;
  if (_Premium.hidePremium && !currentUser.isSubscribed) {
    themes = themes.filter((_t, i) => i <= freeThreshold);
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.themes
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.theme,
    onClick: () => {
      removeValue("background");
      removeValue("colorScheme");
      removeValue("sofa");
      removeValue("tvPlaceholder");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.themeInner
  }, "Default")), themes.map((t, i) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    key: i,
    style: styles.theme,
    onClick: () => {
      if (i > freeThreshold && !currentUser.isSubscribed) {
        alert(/* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
          message: "Please get Premium to get access to all the themes!",
          source: "Themes"
        }), true);
      } else {
        setValue("background", "https://img.kosmi.io/" + (t == null ? void 0 : t.backgroundImageUrl));
        setValue("colorScheme", (t == null ? void 0 : t.colorScheme) || "");
        setValue("sofa", "https://img.kosmi.io/" + (t == null ? void 0 : t.sofaUrl));
        if (t == null ? void 0 : t.tvPlaceholderUrl) {
          setValue("tvPlaceholder", "https://img.kosmi.io/" + t.tvPlaceholderUrl);
        } else {
          removeValue("tvPlaceholder");
        }
      }
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.img, {
    style: styles.themeImg,
    src: `https://img.kosmi.io/${t == null ? void 0 : t.backgroundImageUrl}?w=300`
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.themeInner
  }, t == null ? void 0 : t.name, i > freeThreshold && !currentUser.isSubscribed && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: styles.diamond
  })))));
});