"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Transition = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireWildcard(require("react"));
var _Transition = require("./Transition.stylex");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Transition = exports.Transition = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(37);
  const {
    visible,
    children,
    duration,
    animation,
    onShow
  } = t0;
  const [toggled, setToggled] = _react.default.useState(false);
  let t1;
  if ($[0] !== animation) {
    t1 = () => {
      if (animation === "slide down") {
        return _Transition.styles.slideDownIn;
      }
      if (animation === "slide left") {
        return _Transition.styles.slideLeftIn;
      }
      if (animation === "slide up") {
        return _Transition.styles.slideUpIn;
      }
      if (animation === "fade in") {
        return _Transition.styles.fadeIn;
      }
    };
    $[0] = animation;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const inClass = t1;
  let t2;
  if ($[2] !== animation) {
    t2 = () => {
      if (animation === "slide down") {
        return _Transition.styles.slideDownOut;
      }
      if (animation === "slide left") {
        return _Transition.styles.slideLeftOut;
      }
      if (animation === "slide up") {
        return _Transition.styles.slideUpOut;
      }
      if (animation === "fade in") {
        return _Transition.styles.fadeIn;
      }
    };
    $[2] = animation;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const outClass = t2;
  let t3;
  let t4;
  if ($[4] !== onShow || $[5] !== visible) {
    t3 = () => {
      if (visible && onShow) {
        onShow();
      }
    };
    t4 = [visible, onShow];
    $[4] = onShow;
    $[5] = visible;
    $[6] = t3;
    $[7] = t4;
  } else {
    t3 = $[6];
    t4 = $[7];
  }
  (0, _react.useEffect)(t3, t4);
  let t5;
  let t6;
  if ($[8] !== visible) {
    t5 = () => {
      if (!visible) {
        setToggled(true);
      }
    };
    t6 = [visible];
    $[8] = visible;
    $[9] = t5;
    $[10] = t6;
  } else {
    t5 = $[9];
    t6 = $[10];
  }
  (0, _react.useEffect)(t5, t6);
  let t7;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = [];
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  let existingStyles = t7;
  const propsStyle = children.props.style;
  if (propsStyle) {
    if (Array.isArray(propsStyle)) {
      existingStyles = propsStyle;
    } else {
      let t82;
      if ($[12] !== propsStyle) {
        t82 = [propsStyle];
        $[12] = propsStyle;
        $[13] = t82;
      } else {
        t82 = $[13];
      }
      existingStyles = t82;
    }
  }
  let t8;
  if ($[14] !== children || $[15] !== (duration == null ? void 0 : duration.hide) || $[16] !== (duration == null ? void 0 : duration.show) || $[17] !== existingStyles || $[18] !== inClass || $[19] !== outClass || $[20] !== toggled || $[21] !== visible) {
    let t9;
    if ($[23] !== (duration == null ? void 0 : duration.hide) || $[24] !== (duration == null ? void 0 : duration.show) || $[25] !== existingStyles || $[26] !== inClass || $[27] !== outClass || $[28] !== toggled || $[29] !== visible) {
      let t102;
      if ($[31] !== inClass || $[32] !== toggled || $[33] !== visible) {
        t102 = visible && toggled && inClass();
        $[31] = inClass;
        $[32] = toggled;
        $[33] = visible;
        $[34] = t102;
      } else {
        t102 = $[34];
      }
      t9 = existingStyles.concat([!visible && outClass(), t102, _Transition.styles.transition, _Transition.styles.withDuration(visible ? duration == null ? void 0 : duration.show : duration == null ? void 0 : duration.hide), visible ? _Transition.styles.visible : _Transition.styles.invisible, _Transition.styles.transition]);
      $[23] = duration == null ? void 0 : duration.hide;
      $[24] = duration == null ? void 0 : duration.show;
      $[25] = existingStyles;
      $[26] = inClass;
      $[27] = outClass;
      $[28] = toggled;
      $[29] = visible;
      $[30] = t9;
    } else {
      t9 = $[30];
    }
    let t10;
    if ($[35] !== t9) {
      t10 = {
        style: t9
      };
      $[35] = t9;
      $[36] = t10;
    } else {
      t10 = $[36];
    }
    t8 = _react.default.cloneElement(children, t10);
    $[14] = children;
    $[15] = duration == null ? void 0 : duration.hide;
    $[16] = duration == null ? void 0 : duration.show;
    $[17] = existingStyles;
    $[18] = inClass;
    $[19] = outClass;
    $[20] = toggled;
    $[21] = visible;
    $[22] = t8;
  } else {
    t8 = $[22];
  }
  return t8;
});