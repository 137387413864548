"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _AVEngine = _interopRequireDefault(require("@/services/AVEngine"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  root: {
    "MediaSourceSettings__styles.root": "MediaSourceSettings__styles.root",
    display: "x78zum5",
    flexFlow: "x68uc7k",
    flexDirection: "x1q0g3np",
    flexWrap: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  loaderContainer: {
    "MediaSourceSettings__styles.loaderContainer": "MediaSourceSettings__styles.loaderContainer",
    height: "xpyat2d",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  col: {
    "MediaSourceSettings__styles.col": "MediaSourceSettings__styles.col",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    minWidth: "x1klvvdw",
    flexShrink: "x2lah0s",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  form: {
    "MediaSourceSettings__styles.form": "MediaSourceSettings__styles.form",
    minHeight: "x879a55",
    $$css: true
  },
  select: {
    "MediaSourceSettings__styles.select": "MediaSourceSettings__styles.select",
    width: "xafpxmx",
    $$css: true
  },
  videoContainer: {
    "MediaSourceSettings__styles.videoContainer": "MediaSourceSettings__styles.videoContainer",
    margin: "xkc5qi",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x1fb2l0r",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  videoLoading: {
    "MediaSourceSettings__styles.videoLoading": "MediaSourceSettings__styles.videoLoading",
    position: "x10l6tqk",
    width: "xafpxmx",
    height: "x1fb2l0r",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  hidden: {
    "MediaSourceSettings__styles.hidden": "MediaSourceSettings__styles.hidden",
    display: "x1s85apg",
    $$css: true
  },
  videoPreview: {
    "MediaSourceSettings__styles.videoPreview": "MediaSourceSettings__styles.videoPreview",
    width: "xafpxmx",
    height: "x1fb2l0r",
    transform: "xpk2tj9",
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    objectFit: "xl1xv1r",
    background: "xqqjilj",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x1hta50j",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  audioCanvas: {
    "MediaSourceSettings__styles.audioCanvas": "MediaSourceSettings__styles.audioCanvas",
    width: "xafpxmx",
    height: "x1fb2l0r",
    margin: "xkc5qi",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x1hta50j",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  }
};
const MediaSourceSettings = ({
  onClose
}) => {
  const [availableAudioDevices, setAvailableAudioDevices] = (0, _react.useState)([]);
  const [availableVideoDevices, setAvailableVideoDevices] = (0, _react.useState)([]);
  const [preferredAudioSourceId, setPreferredAudioSourceId] = (0, _react.useState)(null);
  const [preferredVideoSourceId, setPreferredVideoSourceId] = (0, _react.useState)(null);
  const [previewVideoStream, setPreviewVideoStream] = (0, _react.useState)(null);
  const [previewAudioStream, setPreviewAudioStream] = (0, _react.useState)(null);
  const [permissionsRejected, _setPermissionsRejected] = (0, _react.useState)(false);
  const [devicesRequested, setDevicesRequested] = (0, _react.useState)(false);
  const [videoShown, setVideoShown] = (0, _react.useState)(false);
  const videoRef = (0, _react.useRef)(null);
  const canvasRef = (0, _react.useRef)(null);
  const meteringRef = (0, _react.useRef)(false);
  const canvasCtxRef = (0, _react.useRef)(null);
  const _drawAudioMeter = (0, _react.useCallback)(() => {
    if (!_AVEngine.default._userAudioAnalyserBufferLength) return;
    if (!_AVEngine.default._userAudioAnalyserDataArray) return;
    if (!_AVEngine.default._userMediaAudioAnalyser) return;
    const canvasCtx = canvasCtxRef.current;
    if (canvasRef.current && canvasCtx) {
      requestAnimationFrame(_drawAudioMeter);
      _AVEngine.default._userMediaAudioAnalyser.getByteTimeDomainData(_AVEngine.default._userAudioAnalyserDataArray);
      canvasCtx.fillStyle = "#130a25";
      canvasCtx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#fff377";
      canvasCtx.beginPath();
      const sliceWidth = canvasRef.current.width * 1 / _AVEngine.default._userAudioAnalyserBufferLength;
      let x = 0;
      for (let i = 0; i < _AVEngine.default._userAudioAnalyserBufferLength; i++) {
        const v = _AVEngine.default._userAudioAnalyserDataArray[i] / 128;
        const y = v * canvasRef.current.height / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }
      canvasCtx.lineTo(canvasRef.current.width, canvasRef.current.height / 2);
      canvasCtx.stroke();
    }
  }, []);
  const _setupAudioMeter = (0, _react.useCallback)(() => {
    var _a;
    canvasCtxRef.current = (_a = canvasRef.current) == null ? void 0 : _a.getContext("2d");
    _drawAudioMeter();
    meteringRef.current = true;
  }, [_drawAudioMeter]);
  const _getUsersMediaDevices = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const mediaDevices = yield _AVEngine.default.getUserMediaDevices();
    if (mediaDevices) {
      setDevicesRequested(true);
      setAvailableAudioDevices(mediaDevices.audioDevices);
      setAvailableVideoDevices(mediaDevices.videoDevices);
      setPreferredAudioSourceId(_AVEngine.default.getPreferredAudioInputDeviceId());
      setPreferredVideoSourceId(_AVEngine.default.getPreferredVideoInputDeviceId());
      if (_AVEngine.default.getPreferredVideoInputDeviceId()) {
        if (_AVEngine.default.userMediaVideoStream) {
          setPreviewVideoStream(_AVEngine.default.userMediaVideoStream);
        } else {
          setPreviewVideoStream(yield _AVEngine.default.getUserMediaVideoStream(_AVEngine.default.getPreferredVideoInputDeviceId() || void 0));
        }
        if (previewVideoStream && videoRef.current) {
          videoRef.current.srcObject = previewVideoStream;
          setVideoShown(true);
        }
      }
      if (_AVEngine.default.getPreferredAudioInputDeviceId()) {
        if (_AVEngine.default.userMediaAudioStream) {
          setPreviewAudioStream(_AVEngine.default.userMediaAudioStream);
        } else {
          setPreviewAudioStream(yield _AVEngine.default.getUserMediaAudioStream(_AVEngine.default.getPreferredAudioInputDeviceId(), true));
        }
        if (previewAudioStream && canvasRef.current) {
          _setupAudioMeter();
        }
      }
    } else {
      if (onClose) {
        onClose();
      }
    }
  }), [_setupAudioMeter, onClose, previewAudioStream, previewVideoStream]);
  (0, _react.useEffect)(() => {
    _getUsersMediaDevices();
    return () => {
      meteringRef.current = false;
      if (!_AVEngine.default.videoRequestedForCall) {
        _AVEngine.default.stopUserMediaVideoStream();
      }
      if (!_AVEngine.default.audioRequestedForCall) {
        _AVEngine.default.stopUserMediaAudioStream();
      }
    };
  }, [_getUsersMediaDevices]);
  (0, _react.useEffect)(() => {
    if (_AVEngine.default.userMediaVideoStream && videoRef.current) {
      videoRef.current.srcObject = _AVEngine.default.userMediaVideoStream;
    }
  });
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, devicesRequested && !permissionsRejected && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.col
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.h2, null, "Video source"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.videoContainer
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.videoLoading, videoShown && styles.hidden]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Loader, null)), /* @__PURE__ */_react.default.createElement(_uikit.Html.video, {
    playsInline: true,
    autoPlay: true,
    style: styles.videoPreview,
    ref: videoRef
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    className: styles.form
  }, /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    value: preferredVideoSourceId || void 0,
    onChange: e => __async(void 0, null, function* () {
      setPreferredVideoSourceId(e.target.value);
      _AVEngine.default.setPreferredVideoInputDeviceId(e.target.value);
      setPreviewVideoStream(yield _AVEngine.default.getUserMediaVideoStream(_AVEngine.default.getPreferredVideoInputDeviceId() || void 0));
      if (videoRef.current) {
        videoRef.current.srcObject = previewVideoStream;
      }
    }),
    className: styles.select,
    options: availableVideoDevices.map(d => ({
      text: d.label,
      value: d.deviceId
    }))
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.col
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.h2, null, "Audio source"), /* @__PURE__ */_react.default.createElement("canvas", {
    style: styles.audioCanvas,
    width: "240",
    height: "135",
    ref: canvasRef
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form, null, /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    value: preferredAudioSourceId || void 0,
    onChange: e => __async(void 0, null, function* () {
      setPreferredAudioSourceId(e.target.value);
      _AVEngine.default.setPreferredAudioInputDeviceId(e.target.value);
      yield _AVEngine.default.getUserMediaAudioStream(_AVEngine.default.getPreferredAudioInputDeviceId());
      if (!meteringRef.current) {
        _setupAudioMeter();
      }
    }),
    className: styles.select,
    options: availableAudioDevices.map(d => ({
      text: d.label,
      value: d.deviceId
    }))
  })))), !devicesRequested && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.loaderContainer
  }, /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    active: true
  })));
};
var _default = exports.default = MediaSourceSettings;