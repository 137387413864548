"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const baseStyles = {
  base: {
    "Html__baseStyles.base": "Html__baseStyles.base",
    boxSizing: "x9f619",
    textSizeAdjust: "x1javzw2",
    touchAction: "xggy1nq",
    userSelect: "x87ps6o",
    WebKitUserSelect: "x1bib5ie",
    outline: "x1a2a7pz",
    WebKitTapHighlightColor: "x1gp3oje",
    WebKitAppearance: "x67wf27",
    appearance: "xjyslct",
    colorScheme: "xntwwlm",
    fontFamily: "x1o3ll5r",
    fontSize: "x1qlqyl8",
    ":focus_outlineColor": "x1xwnvu1",
    ":focus_outlineStyle": "xhlp2gg",
    "::before_boxSizing": "xtql2tq",
    "::before_textSizeAdjust": "x1wooiz5",
    "::before_touchAction": "xpefml7",
    "::before_userSelect": "x1rbqspl",
    "::before_WebKitUserSelect": "x1hxrwom",
    "::after_boxSizing": "xx38sju",
    "::after_textSizeAdjust": "xxcbro4",
    "::after_touchAction": "x3f4zlw",
    "::after_userSelect": "x1jbr1xh",
    "::after_WebKitUserSelect": "x1gxgjh0",
    "::selection_color": "x1pfyes6",
    "::selection_background": "x86bo8",
    $$css: true
  },
  videoBase: {
    "Html__baseStyles.videoBase": "Html__baseStyles.videoBase",
    "::-internal-media-controls-overlay-cast-button_display": "xupis34",
    $$css: true
  }
};
const isDev = process.env.NODE_ENV === "development";
function compareFun(_a, _c) {
  var _b = _a,
    {
      style: prevStyle
    } = _b,
    prev = __objRest(_b, ["style"]);
  var _d = _c,
    {
      style
    } = _d,
    next = __objRest(_d, ["style"]);
  const stylexProps = stylex.props(style);
  const prevStylexProps = stylex.props(prevStyle);
  if (stylexProps.className !== prevStylexProps.className) return false;
  if (JSON.stringify(stylexProps.style) !== JSON.stringify(prevStylexProps.style)) return false;
  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);
  if (prevKeys.length !== nextKeys.length) return false;
  for (const key of prevKeys) {
    if (prev[key] !== next[key]) return false;
  }
  return true;
}
function elMemo(Component) {
  return (0, _react.memo)(Component, compareFun);
}
var _default = exports.default = {
  div: elMemo((0, _react.forwardRef)((_e, ref) => {
    var _f = _e,
      {
        style,
        styleProps,
        children
      } = _f,
      props = __objRest(_f, ["style", "styleProps", "children"]);
    var _a, _b, _c, _d;
    const {
      className,
      style: styleProp
    } = stylex.props(baseStyles.base, style);
    const visualClasses = isDev ? (((_d = (_c = (_b = (_a = className == null ? void 0 : className.split(" ")) == null ? void 0 : _a.filter(c => c == null ? void 0 : c.includes("__"))) == null ? void 0 : _b.filter(c => !(c == null ? void 0 : c.includes("baseStyles.base")))) == null ? void 0 : _c.map(c => c == null ? void 0 : c.replace(/^.*[.]/, "").trim())) == null ? void 0 : _d.join(" ")) + " " || "").trim() : void 0;
    return /* @__PURE__ */_react.default.createElement("div", __spreadProps(__spreadValues({
      ref
    }, props), {
      "data-id": visualClasses,
      className,
      style: __spreadValues(__spreadValues({}, styleProp), styleProps)
    }), children);
  })),
  a: elMemo((0, _react.forwardRef)((_g, ref) => {
    var _h = _g,
      {
        style
      } = _h,
      props = __objRest(_h, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("a", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  video: elMemo((0, _react.forwardRef)((_i, ref) => {
    var _j = _i,
      {
        style
      } = _j,
      props = __objRest(_j, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, baseStyles.videoBase, style);
    return /* @__PURE__ */_react.default.createElement("video", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  iframe: elMemo((0, _react.forwardRef)((_k, ref) => {
    var _l = _k,
      {
        style
      } = _l,
      props = __objRest(_l, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("iframe", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  dialog: elMemo((0, _react.forwardRef)((_m, ref) => {
    var _n = _m,
      {
        style
      } = _n,
      props = __objRest(_n, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("dialog", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  label: elMemo((0, _react.forwardRef)((_o, ref) => {
    var _p = _o,
      {
        style
      } = _p,
      props = __objRest(_p, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("label", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  input: elMemo((0, _react.forwardRef)((_q, ref) => {
    var _r = _q,
      {
        style
      } = _r,
      props = __objRest(_r, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("input", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  output: elMemo((0, _react.forwardRef)((_s, ref) => {
    var _t = _s,
      {
        style
      } = _t,
      props = __objRest(_t, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("output", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  progress: elMemo((0, _react.forwardRef)((_u, ref) => {
    var _v = _u,
      {
        style
      } = _v,
      props = __objRest(_v, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("progress", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  img: elMemo((0, _react.forwardRef)((_w, ref) => {
    var _x = _w,
      {
        style
      } = _x,
      props = __objRest(_x, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("img", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  form: elMemo((0, _react.forwardRef)((_y, ref) => {
    var _z = _y,
      {
        style
      } = _z,
      props = __objRest(_z, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("form", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  h2: elMemo((0, _react.forwardRef)((_A, ref) => {
    var _B = _A,
      {
        style
      } = _B,
      props = __objRest(_B, ["style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("h2", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  ol: elMemo((0, _react.forwardRef)((_C, ref) => {
    var _D = _C,
      {
        style,
        type: _type
      } = _D,
      props = __objRest(_D, ["style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("ol", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  ul: elMemo((0, _react.forwardRef)((_E, ref) => {
    var _F = _E,
      {
        style,
        type: _type
      } = _F,
      props = __objRest(_F, ["style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("ul", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  })),
  li: elMemo((0, _react.forwardRef)((_G, ref) => {
    var _H = _G,
      {
        style,
        type: _type
      } = _H,
      props = __objRest(_H, ["style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("li", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  }))
};