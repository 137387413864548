"use strict";
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _analyserNode, _fftBins, _sourceNode, _audioContext, _running, _options, _speaking, _speakingHistory, _pollingInterval, _listeners, _determineSpeaking, determineSpeaking_fn, _emit, emit_fn;
import { getMaxVolume } from "./helpers";
const FFT_SIZE = 512;
const defaultOptions = {
  smoothing: 0.1,
  interval: 100,
  threshold: -70,
  history: 3
};
class StreamAnalyser {
  constructor(audioContext, sourceNode, options = {}) {
    __privateAdd(this, _determineSpeaking);
    __privateAdd(this, _emit);
    __privateAdd(this, _analyserNode, void 0);
    __privateAdd(this, _fftBins, void 0);
    __privateAdd(this, _sourceNode, void 0);
    __privateAdd(this, _audioContext, void 0);
    __privateAdd(this, _running, false);
    __privateAdd(this, _options, void 0);
    __privateAdd(this, _speaking, false);
    __privateAdd(this, _speakingHistory, []);
    __privateAdd(this, _pollingInterval, void 0);
    __privateAdd(this, _listeners, void 0);
    __privateSet(this, _audioContext, audioContext);
    __privateSet(this, _sourceNode, sourceNode);
    __privateSet(this, _options, Object.assign(defaultOptions, options));
    __privateSet(this, _listeners, {});
    __privateSet(this, _analyserNode, __privateGet(this, _audioContext).createAnalyser());
    __privateGet(this, _analyserNode).fftSize = FFT_SIZE;
    __privateGet(this, _analyserNode).smoothingTimeConstant = __privateGet(this, _options).smoothing;
    __privateSet(this, _fftBins, new Float32Array(__privateGet(this, _analyserNode).frequencyBinCount));
    for (let i = 0; i < __privateGet(this, _options).history; i++) {
      __privateGet(this, _speakingHistory).push(0);
    }
    __privateGet(this, _sourceNode).connect(__privateGet(this, _analyserNode));
  }
  /**
   * Starts the speech detection analysis of the Audio node provided in the constructor
   */
  start() {
    if (!__privateGet(this, _running)) {
      __privateSet(this, _pollingInterval, setInterval(
        () => __privateMethod(this, _determineSpeaking, determineSpeaking_fn).call(this),
        __privateGet(this, _options).interval
      ));
      __privateSet(this, _running, true);
    } else {
      console.error("The StreamAnalyser is already running");
    }
  }
  /**
   * Stops the speech detection analysis of the Audio node provided in the constructor
   */
  stop() {
    var _a, _b;
    if (__privateGet(this, _pollingInterval)) {
      clearInterval(__privateGet(this, _pollingInterval));
    } else {
      console.error("Cannjto stop StreamAnalyser as it was never started");
    }
    (_a = __privateGet(this, _sourceNode)) == null ? void 0 : _a.disconnect();
    (_b = __privateGet(this, _analyserNode)) == null ? void 0 : _b.disconnect();
    __privateSet(this, _sourceNode, null);
    __privateSet(this, _analyserNode, null);
    __privateGet(this, _listeners)["started_speaking"] = null;
    __privateGet(this, _listeners)["stopped_speaking"] = null;
  }
  /**
   * Add event listener to the StreamAnalyser
   */
  addEventListener(method, callback) {
    __privateGet(this, _listeners)[method] = callback;
  }
  /**
   * Remove event listener to the StreamAnalyser
   */
  removeEventListener(method) {
    __privateGet(this, _listeners)[method] = null;
  }
}
_analyserNode = new WeakMap();
_fftBins = new WeakMap();
_sourceNode = new WeakMap();
_audioContext = new WeakMap();
_running = new WeakMap();
_options = new WeakMap();
_speaking = new WeakMap();
_speakingHistory = new WeakMap();
_pollingInterval = new WeakMap();
_listeners = new WeakMap();
_determineSpeaking = new WeakSet();
determineSpeaking_fn = function() {
  if (__privateGet(this, _analyserNode)) {
    const currentVolume = getMaxVolume(__privateGet(this, _analyserNode), __privateGet(this, _fftBins));
    let history = 0;
    if (currentVolume > __privateGet(this, _options).threshold && !__privateGet(this, _speaking)) {
      for (let i = __privateGet(this, _speakingHistory).length - 3; i < __privateGet(this, _speakingHistory).length; i++) {
        history += __privateGet(this, _speakingHistory)[i];
      }
      if (history >= 2) {
        __privateSet(this, _speaking, true);
        __privateMethod(this, _emit, emit_fn).call(this, "started_speaking");
      }
    } else if (currentVolume < __privateGet(this, _options).threshold && __privateGet(this, _speaking)) {
      for (let i = 0; i < __privateGet(this, _speakingHistory).length; i++) {
        history += __privateGet(this, _speakingHistory)[i];
      }
      if (history == 0) {
        __privateSet(this, _speaking, false);
        __privateMethod(this, _emit, emit_fn).call(this, "stopped_speaking");
      }
    }
    __privateGet(this, _speakingHistory).shift();
    __privateGet(this, _speakingHistory).push(
      0 + (currentVolume > __privateGet(this, _options).threshold ? 1 : 0)
    );
  }
};
_emit = new WeakSet();
emit_fn = function(method, payload = null) {
  const callback = __privateGet(this, _listeners)[method];
  if (typeof callback === "function") {
    callback(payload);
  }
};
export default StreamAnalyser;
