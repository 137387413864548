"use strict";
import { gql, useQuery } from "@/shared/hooks/gql";
const SETTINGS_QUERY = gql(`
  query SettingsQuery {
    settings {
      id
      colorScheme
      hideCountry
    }
  }
`);
export default () => {
  const { data } = useQuery(SETTINGS_QUERY, { fetchPolicy: "cache-first" });
  return (data == null ? void 0 : data.settings) || {};
};
