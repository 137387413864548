"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WebRTCUserVideoTrack = void 0;
var _useCurrentUser = require("@/shared/hooks/useCurrentUser");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  mirror: {
    "WebRTCUserVideoTrack__styles.mirror": "WebRTCUserVideoTrack__styles.mirror",
    transform: "x1msufx5",
    $$css: true
  }
};
const WebRTCUserVideoTrack = exports.WebRTCUserVideoTrack = (0, _react.memo)(({
  userId,
  className,
  style
}) => {
  const {
    webcamStreams
  } = _react.default.useContext(_RoomContext.default);
  const currentUser = (0, _useCurrentUser.currentUserHook)();
  const videoRef = _react.default.useRef(null);
  let {
    className: classNameProp
  } = stylex.props(currentUser.id === userId && styles.mirror, className instanceof Object && className);
  if (typeof className === "string") {
    classNameProp = `${classNameProp} ${className}`;
  }
  const stream = webcamStreams && webcamStreams[userId];
  (0, _react.useEffect)(() => {
    if (!stream) return;
    if (stream && stream.id === "LOADING") {
      return;
    }
    const video = videoRef.current;
    if (video) {
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.muted = true;
        try {
          video.play();
        } catch (e) {
          console.warn(e);
        }
      };
    }
  }, [stream]);
  if (stream && stream.id === "LOADING") {
    return /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
      active: true
    });
  }
  return stream ? /* @__PURE__ */_react.default.createElement("video", {
    playsInline: true,
    disableRemotePlayback: true,
    className: classNameProp,
    style,
    ref: videoRef,
    muted: true
  }) : null;
});
var _default = exports.default = WebRTCUserVideoTrack;