"use strict";
import React, { memo, useCallback, useState } from "react";
import MediaPlayerContext from "../contexts/MediaPlayerContext";
export default memo(({ children }) => {
  const [localUrl, _setLocalUrl] = useState("");
  const [localStream, setLocalStream] = useState(null);
  const [relayStream, setRelayStream] = useState(false);
  const [player, setPlayer] = useState(null);
  const setLocalUrl = useCallback(
    (url) => _setLocalUrl(url || ""),
    [_setLocalUrl]
  );
  return /* @__PURE__ */ React.createElement(
    MediaPlayerContext.Provider,
    {
      value: {
        localUrl,
        localStream,
        setLocalStream,
        relayStream,
        setRelayStream,
        setLocalUrl,
        player,
        setPlayer
      }
    },
    children
  );
});
