"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextMenu = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Transition = require("./Transition");
var _Icon = require("./Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  menuWrapper: {
    "ContextMenu__styles.menuWrapper": "ContextMenu__styles.menuWrapper",
    display: "x78zum5",
    position: "x1n2onr6",
    pointerEvents: "x71s49j",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  menu: {
    "ContextMenu__styles.menu": "ContextMenu__styles.menu",
    position: "x10l6tqk",
    backgroundColor: "x18zb6r9",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "xu96u03",
    zIndex: "x1n327nk",
    width: "xeq5yr9",
    flexDirection: "xdt5ytf",
    boxShadow: "xvxy23r",
    display: "x78zum5",
    minWidth: "xgqtt45",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "x1rea2x4",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  fluid: {
    "ContextMenu__styles.fluid": "ContextMenu__styles.fluid",
    width: "xh8yej3",
    minWidth: "xgqtt45",
    maxWidth: "x193iq5w",
    $$css: true
  },
  slideUp: {
    "ContextMenu__styles.slideUp": "ContextMenu__styles.slideUp",
    bottom: "x10w3d4m",
    $$css: true
  },
  slideDown: {
    "ContextMenu__styles.slideDown": "ContextMenu__styles.slideDown",
    top: "xdsb8wn",
    $$css: true
  },
  center: {
    "ContextMenu__styles.center": "ContextMenu__styles.center",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  right: {
    "ContextMenu__styles.right": "ContextMenu__styles.right",
    marginRight: "xack27t",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginLeft: "xj3b58b",
    $$css: true
  },
  left: {
    "ContextMenu__styles.left": "ContextMenu__styles.left",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    $$css: true
  },
  menuItem: {
    "ContextMenu__styles.menuItem": "ContextMenu__styles.menuItem",
    position: "x1n2onr6",
    fontSize: "xif65rj",
    color: "x11jfisy",
    whiteSpace: "xuxw1ft",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "x1igx7t4",
    paddingEnd: null,
    paddingRight: "x172e5tb",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    paddingInlineStart: null,
    paddingInlineEnd: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    minWidth: "xgqtt45",
    "@media (max-width: 1000px)_fontSize": "x15saapg",
    "@media (max-width: 1000px)_padding": "x1byiabj",
    "@media (max-width: 1000px)_paddingInline": null,
    "@media (max-width: 1000px)_paddingStart": null,
    "@media (max-width: 1000px)_paddingLeft": "xn8cnpx",
    "@media (max-width: 1000px)_paddingEnd": null,
    "@media (max-width: 1000px)_paddingRight": "x16t5keo",
    "@media (max-width: 1000px)_paddingBlock": null,
    "@media (max-width: 1000px)_paddingTop": null,
    "@media (max-width: 1000px)_paddingBottom": null,
    "@media (max-width: 1000px)_paddingInlineStart": null,
    "@media (max-width: 1000px)_paddingInlineEnd": null,
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x1j37zrf",
    ":hover_cursor": "x1277o0a",
    ":active_backgroundColor": "xn6vc48",
    $$css: true
  }
};
const ContextMenuItem = (0, _react.memo)((0, _react.forwardRef)(({
  onClick,
  icon,
  text
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref,
    style: styles.menuItem,
    onClick
  }, icon && (typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    name: icon
  }) : icon), text);
}));
const ContextMenu = exports.ContextMenu = Object.assign((0, _react.memo)((0, _react.forwardRef)(({
  open,
  onClose,
  trigger,
  children,
  direction,
  position,
  onOpen,
  fluid,
  className: classNameProp
}, ref) => {
  const containerRef = (0, _react.useRef)(null);
  const btnRef = (0, _react.useRef)(null);
  (0, _helpers.useOnClickOutside)([containerRef, "current" in trigger ? trigger : btnRef], onClose || (() => {}));
  (0, _react.useEffect)(() => {
    if (onOpen && open) {
      onOpen();
    }
  }, [open, onOpen]);
  (0, _react.useImperativeHandle)(ref, () => containerRef.current, [containerRef]);
  const btnRefStored = (0, _helpers.useMergedRefs)(btnRef);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.menuWrapper, classNameProp instanceof Object && classNameProp]
  }, "current" in trigger ? null : _react.default.cloneElement(trigger, {
    ref: btnRefStored
  }), /* @__PURE__ */_react.default.createElement(_Transition.Transition, {
    duration: {
      hide: 100,
      show: 100
    },
    animation: "slide " + (direction || "down"),
    visible: open
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref: containerRef,
    style: [styles.menu, direction === "up" ? styles.slideUp : styles.slideDown, position === "right" && styles.right, (!position || position === "left") && styles.left, position === "center" && styles.center, fluid && styles.fluid]
  }, children)));
})), {
  Item: ContextMenuItem
});