"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputWrapper = exports.Input = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _lib = require("react-debounce-input/lib");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Icon = require("./Icon");
var _Loader = require("./Loader");
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const styles = {
  input: {
    "Input__styles.input": "Input__styles.input",
    height: "x16wdlz0",
    position: "x1n2onr6",
    fontWeight: "xo1l8bm",
    fontSize: "x4z9k3i",
    display: "x3nfvp2",
    minWidth: "xeuugli",
    maxWidth: "x193iq5w",
    $$css: true
  },
  inputTag: {
    "Input__styles.inputTag": "Input__styles.inputTag",
    maxWidth: "x193iq5w",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    lineHeight: "x1ylh3l6",
    padding: "xc7ga6q",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    color: "x11jfisy",
    borderRadius: "x1aaq583",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    flex: "xc8qplx",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    textAlign: "xdpxx8g",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    appearance: "xjyslct",
    fontSize: "xrv4cvt",
    width: "x14atkfc",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    backgroundColor: "x128cn8a",
    boxShadow: "x9gmhs3",
    ":focus_borderColor": "x1eg2tmu",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    "::placeholder_color": "x1h4wt8n",
    "::placeholder_opacity": "xrxx4p4",
    $$css: true
  },
  focusedInputTag: {
    "Input__styles.focusedInputTag": "Input__styles.focusedInputTag",
    borderColor: "x5d3vyf",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "::placeholder_color": "x1h4wt8n",
    $$css: true
  },
  disabledInput: {
    "Input__styles.disabledInput": "Input__styles.disabledInput",
    opacity: "xyd83as",
    pointerEvents: "x47corl",
    $$css: true
  },
  readOnlyInput: {
    "Input__styles.readOnlyInput": "Input__styles.readOnlyInput",
    pointerEvents: "x47corl",
    $$css: true
  },
  loadingIcon: {
    "Input__styles.loadingIcon": "Input__styles.loadingIcon",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  focusedIcon: {
    "Input__styles.focusedIcon": "Input__styles.focusedIcon",
    color: "x11jfisy",
    $$css: true
  },
  inputIcon: {
    "Input__styles.inputIcon": "Input__styles.inputIcon",
    alignItems: "x6s0dn4",
    borderRadius: "x146gtmz",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    color: "x1gtsmwv",
    display: "x3nfvp2",
    fontSize: "xrv4cvt",
    fontStyle: "x1j61x8r",
    fontWeight: "xo1l8bm",
    height: "x5yr21d",
    justifyContent: "xl56j7k",
    lineHeight: "xo5v014",
    opacity: "xbyyjgo",
    position: "x10l6tqk",
    right: "x131sewu",
    insetInlineStart: null,
    insetInlineEnd: null,
    textAlign: "x2b8uid",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    width: "x14srk22",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    $$css: true
  },
  label: {
    "Input__styles.label": "Input__styles.label",
    paddingBottom: "x1a8lsjc",
    fontWeight: "x117nqv4",
    $$css: true
  },
  large: {
    "Input__styles.large": "Input__styles.large",
    height: "x1peatla",
    fontSize: "xosj86m",
    $$css: true
  },
  fluid: {
    "Input__styles.fluid": "Input__styles.fluid",
    display: "x78zum5",
    width: "xh8yej3",
    $$css: true
  },
  prefix: {
    "Input__styles.prefix": "Input__styles.prefix",
    position: "x10l6tqk",
    left: "x12lbrt0",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1eu8d0j",
    color: "x16cd2qt",
    lineHeight: "x1evy7pa",
    $$css: true
  }
};
const InputTag = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(6);
  let props;
  let ref;
  if ($[0] !== t0) {
    _a = t0, {
      ref
    } = _a, props = __objRest(_a, ["ref"]);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] !== props || $[4] !== ref) {
    t1 = /* @__PURE__ */_react.default.createElement("input", __spreadValues({
      ref
    }, props));
    $[3] = props;
    $[4] = ref;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  return t1;
};
const DebouncedInputTag = (debounce, minLength) => {
  const $ = (0, _compilerRuntime.c)(3);
  let t0;
  if ($[0] !== debounce || $[1] !== minLength) {
    t0 = t1 => {
      const _a = t1,
        {
          ref
        } = _a,
        props = __objRest(_a, ["ref"]);
      return /* @__PURE__ */_react.default.createElement(_lib.DebounceInput, __spreadProps(__spreadValues({}, props), {
        debounceTimeout: debounce,
        minLength,
        ref
      }));
    };
    $[0] = debounce;
    $[1] = minLength;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  return t0;
};
const Input = exports.Input = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(66);
  const {
    ref,
    value,
    icon,
    className,
    wrapperClassName,
    autoComplete,
    placeholder,
    action,
    onChange,
    id,
    type,
    fluid,
    large,
    onFocus,
    onBlur,
    autoFocus,
    disabled,
    readOnly,
    min,
    max,
    required,
    label,
    name,
    maxLength,
    minLength,
    loading,
    debounce,
    debounceMinLength,
    prefix,
    onKeyPress,
    onKeyDown,
    onEnter
  } = t0;
  const [focused, setFocused] = (0, _react.useState)(false);
  let t1;
  if ($[0] !== className || $[1] !== disabled || $[2] !== focused || $[3] !== readOnly) {
    t1 = stylex.props(styles.inputTag, focused && styles.focusedInputTag, disabled && styles.disabledInput, readOnly && styles.readOnlyInput, className);
    $[0] = className;
    $[1] = disabled;
    $[2] = focused;
    $[3] = readOnly;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  const {
    className: inputClassName
  } = t1;
  const inputRef = (0, _react.useRef)(null);
  let t2;
  let t3;
  if ($[5] !== debounce || $[6] !== debounceMinLength) {
    t3 = debounce ? DebouncedInputTag(debounce, debounceMinLength || 0) : InputTag;
    $[5] = debounce;
    $[6] = debounceMinLength;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  t2 = t3;
  const Tag = t2;
  let t4;
  let t5;
  if ($[8] !== autoFocus) {
    t4 = () => {
      let timeout;
      if (autoFocus && inputRef.current) {
        timeout = setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 200);
      }
      return () => {
        clearTimeout(timeout);
      };
    };
    t5 = [autoFocus];
    $[8] = autoFocus;
    $[9] = t4;
    $[10] = t5;
  } else {
    t4 = $[9];
    t5 = $[10];
  }
  (0, _react.useEffect)(t4, t5);
  let t6;
  if ($[11] !== onFocus) {
    t6 = () => {
      setFocused(true);
      if (onFocus) {
        onFocus();
      }
    };
    $[11] = onFocus;
    $[12] = t6;
  } else {
    t6 = $[12];
  }
  const focus = t6;
  let t7;
  if ($[13] !== onBlur) {
    t7 = () => {
      setFocused(false);
      if (onBlur) {
        onBlur();
      }
    };
    $[13] = onBlur;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  const blur = t7;
  let t8;
  let t9;
  if ($[15] !== onEnter || $[16] !== onKeyDown) {
    t9 = onEnter ? e => {
      if (e.key === "Enter") {
        onEnter();
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    } : onKeyDown;
    $[15] = onEnter;
    $[16] = onKeyDown;
    $[17] = t9;
  } else {
    t9 = $[17];
  }
  t8 = t9;
  const enterHandler = t8;
  const refs = (0, _helpers.useMergedRefs)(ref, inputRef);
  let t10;
  if ($[18] !== label) {
    t10 = label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
      style: styles.label
    }, label);
    $[18] = label;
    $[19] = t10;
  } else {
    t10 = $[19];
  }
  const t11 = fluid && styles.fluid;
  const t12 = large && styles.large;
  let t13;
  if ($[20] !== t11 || $[21] !== t12 || $[22] !== wrapperClassName) {
    t13 = [styles.input, t11, t12, wrapperClassName];
    $[20] = t11;
    $[21] = t12;
    $[22] = wrapperClassName;
    $[23] = t13;
  } else {
    t13 = $[23];
  }
  let t14;
  if ($[24] !== prefix) {
    t14 = prefix && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.prefix
    }, prefix);
    $[24] = prefix;
    $[25] = t14;
  } else {
    t14 = $[25];
  }
  const t15 = refs;
  const t16 = prefix ? prefix.length * 6.4 : void 0;
  let t17;
  if ($[26] !== t16) {
    t17 = {
      paddingLeft: t16
    };
    $[26] = t16;
    $[27] = t17;
  } else {
    t17 = $[27];
  }
  let t18;
  if ($[28] !== Tag || $[29] !== autoComplete || $[30] !== blur || $[31] !== disabled || $[32] !== enterHandler || $[33] !== focus || $[34] !== inputClassName || $[35] !== max || $[36] !== maxLength || $[37] !== min || $[38] !== minLength || $[39] !== name || $[40] !== onChange || $[41] !== onKeyPress || $[42] !== placeholder || $[43] !== readOnly || $[44] !== required || $[45] !== t15 || $[46] !== t17 || $[47] !== type || $[48] !== value) {
    t18 = /* @__PURE__ */_react.default.createElement(Tag, {
      onKeyDown: enterHandler,
      onKeyPress,
      name,
      maxLength,
      minLength,
      ref: t15,
      value,
      autoComplete,
      placeholder,
      className: inputClassName,
      readOnly,
      disabled,
      onChange,
      min,
      max,
      required,
      style: t17,
      type,
      onFocus: focus,
      onBlur: blur
    });
    $[28] = Tag;
    $[29] = autoComplete;
    $[30] = blur;
    $[31] = disabled;
    $[32] = enterHandler;
    $[33] = focus;
    $[34] = inputClassName;
    $[35] = max;
    $[36] = maxLength;
    $[37] = min;
    $[38] = minLength;
    $[39] = name;
    $[40] = onChange;
    $[41] = onKeyPress;
    $[42] = placeholder;
    $[43] = readOnly;
    $[44] = required;
    $[45] = t15;
    $[46] = t17;
    $[47] = type;
    $[48] = value;
    $[49] = t18;
  } else {
    t18 = $[49];
  }
  let t19;
  if ($[50] !== focused || $[51] !== icon) {
    t19 = icon && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      className: [styles.inputIcon, focused && styles.focusedIcon],
      name: icon
    });
    $[50] = focused;
    $[51] = icon;
    $[52] = t19;
  } else {
    t19 = $[52];
  }
  let t20;
  if ($[53] !== loading) {
    t20 = loading && /* @__PURE__ */_react.default.createElement(_Loader.Loader, {
      className: styles.loadingIcon
    });
    $[53] = loading;
    $[54] = t20;
  } else {
    t20 = $[54];
  }
  let t21;
  if ($[55] !== action || $[56] !== id || $[57] !== t13 || $[58] !== t14 || $[59] !== t18 || $[60] !== t19 || $[61] !== t20) {
    t21 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      id,
      style: t13
    }, t14, t18, action, t19, t20);
    $[55] = action;
    $[56] = id;
    $[57] = t13;
    $[58] = t14;
    $[59] = t18;
    $[60] = t19;
    $[61] = t20;
    $[62] = t21;
  } else {
    t21 = $[62];
  }
  let t22;
  if ($[63] !== t10 || $[64] !== t21) {
    t22 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, t10, t21);
    $[63] = t10;
    $[64] = t21;
    $[65] = t22;
  } else {
    t22 = $[65];
  }
  return t22;
});
const InputWrapper = exports.InputWrapper = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    className,
    children,
    focused
  } = t0;
  const t1 = focused && styles.focusedInputTag;
  const t2 = typeof className !== "string" && className;
  let t3;
  if ($[0] !== t1 || $[1] !== t2) {
    t3 = [styles.inputTag, t1, t2];
    $[0] = t1;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] !== children || $[4] !== focused || $[5] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      "data-bla": focused,
      style: t3
    }, children);
    $[3] = children;
    $[4] = focused;
    $[5] = t3;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  return t4;
});