"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var Subtitle = _interopRequireWildcard(require("subtitle"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  hidden: {
    "SubtitleModal__styles.hidden": "SubtitleModal__styles.hidden",
    display: "x1s85apg",
    $$css: true
  },
  modalContent: {
    "SubtitleModal__styles.modalContent": "SubtitleModal__styles.modalContent",
    textAlign: "x2b8uid",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  open,
  subtitles,
  onSubtitles,
  onRemoveSubtitles,
  onClose
}) => {
  const labelRef = (0, _react.useRef)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Subtitles"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, {
    style: styles.modalContent
  }, (subtitles == null ? void 0 : subtitles.cues) ? /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, subtitles.filename), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    style: {
      marginTop: 20,
      width: 150,
      marginLeft: "auto",
      marginRight: "auto"
    },
    onClick: () => onRemoveSubtitles()
  }, "Remove subtitles")) : /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.label, {
    ref: labelRef,
    htmlFor: "subtitle_file"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    onClick: () => {
      var _a;
      return (_a = labelRef.current) == null ? void 0 : _a.click();
    },
    color: "yellow",
    style: {
      margin: "auto"
    }
  }, "Load a Subtitle File (.srt or .vtt)...")), /* @__PURE__ */_react.default.createElement(_uikit.Html.input, {
    type: "file",
    id: "subtitle_file",
    name: "subtitle_file",
    style: styles.hidden,
    onChange: e => {
      var _a, _b;
      const fileName = ((_a = e == null ? void 0 : e.target) == null ? void 0 : _a.files) && e.target.files[0].name;
      const reader = new FileReader();
      reader.onload = event => {
        var _a2, _b2;
        if (!((_a2 = event == null ? void 0 : event.target) == null ? void 0 : _a2.result)) return;
        const cues = Subtitle.parse((_b2 = event == null ? void 0 : event.target) == null ? void 0 : _b2.result).map(s => ({
          start: s.start,
          end: s.end,
          text: s.text
        })).filter(s => !!s.start && !!s.end && !!s.text);
        onSubtitles({
          filename: fileName || "",
          cues
        });
      };
      const text = ((_b = e == null ? void 0 : e.target) == null ? void 0 : _b.files) && e.target.files[0];
      if (text) {
        reader.readAsText(text);
      }
    }
  }))));
});