"use strict";
import { Icon } from "@/uikit";
import React, { memo } from "react";
export default memo(
  ({
    className
  }) => {
    return /* @__PURE__ */ React.createElement(Icon, { className, name: "kosmi diamond" });
  }
);
