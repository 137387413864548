"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString from "query-string";
import React, { memo, useContext, useEffect, useState } from "react";
import APPS from "./apps";
import RoomContext from "./contexts/RoomContext";
import RoomUI from "./RoomUI";
import WebRTCAudio from "./components/WebRTCUserAudioTrack";
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
import useRoomData from "./hooks/useRoomData";
import useMessageData from "./hooks/useMessageData";
import useConferenceStreams from "./hooks/useConferenceStreams";
import { useLinkedMembers } from "./hooks/useMembersData";
import useMediaPlayerData from "./hooks/useMediaPlayerData";
import { useLocation, useParams } from "react-router-dom";
import useJoinRoom from "./hooks/useJoinRoom";
import MediaPlayerContextProvider from "./components/MediaPlayerContextProvider";
import { ConnectionContext } from "@/api/ConnectionContext";
export function getAppModule(appSlug) {
  return __async(this, null, function* () {
    const APP = APPS[appSlug];
    if (APP) {
      return yield APP();
    }
    return null;
  });
}
const Room = memo(({
  roomId
}) => {
  var _a, _b, _c, _d;
  const location = useLocation();
  const app = (location == null ? void 0 : location.search) && ((_a = queryString.parse(location.search).app) == null ? void 0 : _a.toString());
  const prestartApp = app && APPS[app.toUpperCase()] && (app == null ? void 0 : app.toUpperCase());
  const currentUser = currentUserHook();
  const currentUserId = currentUser.id;
  const {
    error,
    rejoin,
    loading: joining,
    rejoining
  } = useJoinRoom(roomId);
  const {
    data
  } = useRoomData(roomId, joining || !!error);
  const roomState = ((_b = data == null ? void 0 : data.room) == null ? void 0 : _b.state) || {
    members: []
  };
  const isPlayingMedia = !!((_c = data == null ? void 0 : data.mediaPlayerState) == null ? void 0 : _c.url);
  const {
    connectionId,
    disconnected
  } = useContext(ConnectionContext);
  const cachedConn = connectionId && disconnected;
  useMediaPlayerData();
  useMessageData(roomId, "general");
  useLinkedMembers(roomId);
  const webrtcEnabled = !!roomState.webrtcEnabled && !((_d = roomState == null ? void 0 : roomState.metadata) == null ? void 0 : _d.disableWebcamAndMicrophone);
  const {
    hideSidebarForNonAdmins
  } = (roomState == null ? void 0 : roomState.metadata) || {};
  const {
    runningApp,
    members
  } = roomState;
  const {
    enableMicrophone,
    disableMicrophone,
    enableWebcam,
    disableWebcam,
    webcamStreams,
    microphoneStreams,
    mutedMicrophones,
    muteMicrophone,
    unmuteMicrophone
  } = useConferenceStreams(webrtcEnabled, members);
  const [appSlug, setAppslug] = useState(null);
  const [appName, setAppName] = useState(null);
  const [appModule, setAppModule] = useState(void 0);
  const [showWebcamFallback, setShowWebcamFallback] = useState(false);
  const [showChatBoxWhenChatHidden, setShowChatBoxWhenChatHidden] = useState(false);
  const [Settings, setSettings] = useState(void 0);
  const [Titlebar, setTitlebar] = useState(void 0);
  const [AppComponent, setAppComponent] = useState(void 0);
  const startAppClient = (appSlug2) => __async(void 0, null, function* () {
    if (!appSlug2)
      return;
    setAppslug(null);
    setAppComponent(void 0);
    setSettings(void 0);
    setTitlebar(void 0);
    const appModule2 = yield getAppModule(appSlug2);
    setAppModule(appModule2);
    setAppslug(appSlug2);
    setAppName(appModule2.name);
    const AppComponent2 = memo(appModule2.App);
    setAppComponent(AppComponent2);
    setShowWebcamFallback(!appModule2.supportsWebcam);
    setShowChatBoxWhenChatHidden(!!appModule2.showChatBoxWhenChatHidden);
    setSettings(appModule2.Settings);
    setTitlebar(appModule2.Titlebar);
  });
  useEffect(() => {
    if (runningApp)
      startAppClient(runningApp);
  }, [runningApp]);
  useEffect(() => {
    window.history.replaceState(null, "", window.location.pathname);
  }, []);
  return /* @__PURE__ */ React.createElement(RoomContext.Provider, { value: {
    runningApp,
    isPlayingMedia,
    AppComponent,
    Titlebar,
    Settings,
    appModule,
    appName,
    appSlug,
    enableMicrophone,
    disableMicrophone,
    enableWebcam,
    disableWebcam,
    webcamStreams,
    microphoneStreams,
    mutedMicrophones,
    muteMicrophone,
    unmuteMicrophone,
    showChatBoxWhenChatHidden,
    showWebcamFallback,
    joining
  } }, Object.keys(microphoneStreams || {}).filter((userId) => userId !== currentUserId).map((userId) => /* @__PURE__ */ React.createElement(WebRTCAudio, { key: userId, muted: !!mutedMicrophones[userId], userId })), /* @__PURE__ */ React.createElement(RoomUI, { joining: joining && !rejoining && !cachedConn, error, rejoin, appName: appSlug, roomId, AppComponent, appModule, prestartApp, showWebcamFallback: webrtcEnabled && showWebcamFallback, hideSidebarForNonAdmins: !!hideSidebarForNonAdmins }));
});
export default memo(({
  roomId: roomIdProp
}) => {
  const params = useParams();
  const roomId = roomIdProp || params.room;
  if (!roomId)
    return null;
  return /* @__PURE__ */ React.createElement(MediaPlayerContextProvider, { key: roomId }, /* @__PURE__ */ React.createElement(Room, { roomId, key: roomId }));
});
