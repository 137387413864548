"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useMutation, useQuery } from "@/shared/hooks/gql";
import { useCallback, useEffect } from "react";
import { useRoomId } from "@/room/hooks/useRoomId";
const GET_STATE = gql(`
  query MediaSoupStateQuery($roomId: String!) {
    mediasoupState(roomId: $roomId) {
      conference {
        userId
        mediaSources {
          id
          type
        }
      }
    }
  }
`);
const ENABLE_AUDIO = gql(`
  mutation MediaSoupConferenceEnableAudio(
    $roomId: String!
    $streamId: String!
  ) {
    mediasoupConferenceEnableAudio(roomId: $roomId, streamId: $streamId) {
      ok
    }
  }
`);
const ENABLE_VIDEO = gql(`
  mutation MediaSoupConferenceEnableVideo(
    $roomId: String!
    $streamId: String!
  ) {
    mediasoupConferenceEnableVideo(roomId: $roomId, streamId: $streamId) {
      ok
    }
  }
`);
const DISABLE_AUDIO = gql(`
  mutation MediaSoupConferenceDisableAudio($roomId: String!) {
    mediasoupConferenceDisableAudio(roomId: $roomId) {
      ok
    }
  }
`);
const DISABLE_VIDEO = gql(`
  mutation MediaSoupConferenceDisableVideo($roomId: String!) {
    mediasoupConferenceDisableVideo(roomId: $roomId) {
      ok
    }
  }
`);
const ON_UPDATE_STATE = gql(`
  subscription OnMediaSoupUpdateState($roomId: String!) {
    mediasoupUpdateState(roomId: $roomId) {
      conference {
        userId
        mediaSources {
          id
          type
        }
      }
    }
  }
`);
export const useMediaSoupConference = () => {
  const roomId = useRoomId();
  const { data, subscribeToMore } = useQuery(GET_STATE, {
    variables: { roomId }
  });
  useEffect(() => {
    subscribeToMore({
      document: ON_UPDATE_STATE,
      variables: {
        roomId
      },
      updateQuery: (prev, { subscriptionData: { data: data2 } }) => {
        return __spreadProps(__spreadValues({}, prev), {
          mediasoupState: data2.mediasoupUpdateState
        });
      }
    });
  }, [subscribeToMore, roomId]);
  const mediasoupState = data == null ? void 0 : data.mediasoupState;
  const [_enableAudio] = useMutation(ENABLE_AUDIO);
  const [_disableAudio] = useMutation(DISABLE_AUDIO);
  const [_enableVideo] = useMutation(ENABLE_VIDEO);
  const [_disableVideo] = useMutation(DISABLE_VIDEO);
  const enableAudio = useCallback(
    (streamId) => _enableAudio({ variables: { roomId, streamId } }),
    [_enableAudio, roomId]
  );
  const disableAudio = useCallback(
    () => _disableAudio({ variables: { roomId } }),
    [_disableAudio, roomId]
  );
  const enableVideo = useCallback(
    (streamId) => _enableVideo({ variables: { roomId, streamId } }),
    [_enableVideo, roomId]
  );
  const disableVideo = useCallback(
    () => _disableVideo({ variables: { roomId } }),
    [_disableVideo, roomId]
  );
  return {
    enableAudio,
    disableAudio,
    enableVideo,
    disableVideo,
    mediasoupState,
    loading: !mediasoupState
  };
};
