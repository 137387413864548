"use strict";
export const colors = {
  "black": "#130a25",
  "danger": "#d75555",
  "success": "#82db7d",
  "ruby": "#e38989",
  "foreground": "#e6e6e6",
  "foreground-dark-5": "var(--foreground)",
  "foreground-dark-10": "var(--foreground)",
  "foreground-dark-20": "var(--foreground)",
  "foreground-dark-50": "var(--foreground)",
  "primary": "#5d37c1",
  "primary-dark-5": "var(--primary)",
  "primary-dark-10": "var(--primary)",
  "primary-dark-15": "#221343",
  "primary-dark-20": "var(--primary)",
  "primary-dark-25": "#0f091e",
  "primary-dark-25-fade-90": "var(--primary)",
  "primary-light-5": "var(--primary)",
  "primary-light-10": "var(--primary)",
  "primary-light-20": "var(--primary)",
  "primary-light-40": "var(--primary)",
  "secondary": "#faec3a",
  "secondary-dark-10": "var(--secondary)",
  "secondary-dark-20": "var(--secondary)",
  "secondary-dark-22": "var(--secondary)",
  "secondary-dark-25": "var(--secondary)",
  "flags": "url('./flags.png')"
};
