"use strict";
import { useEffect } from "react";
export const useGetUserConfirmation = () => {
  const getUserConfirmation = (payload, callback) => {
    const {
      action,
      message
    } = JSON.parse(payload);
    const confirmed = window.confirm(message);
    callback(confirmed);
    if (!confirmed && action === "POP") {
      ;
      window.skipHistoryPrompt = true;
      window.history.forward();
    }
  };
  useEffect(() => {
    return () => {
      delete window.skipHistoryPrompt;
    };
  }, []);
  return getUserConfirmation;
};
