"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
import { gql, useMutation } from "@/shared/hooks/gql";
import sw from "@/pwa/sw.externjs";
const publicVapidKey = "BM9UT90cEy93v40y59YHk06hCcPURL6B_uaAOvNn5oidxXl77RM19y3ZmowCMRWg8R2mIDEDuK5pjf-vz_1MrXk";
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
const REGISTER_PUSH_NOTIFICATIONS = gql(`
  mutation RegisterPushNotificationsMutation(
    $endpoint: String!
    $keys: String!
  ) {
    registerPushNotifications(endpoint: $endpoint, keys: $keys) {
      ok
    }
  }
`);
export default () => {
  const currentUser = currentUserHook();
  const [registerPushNotificationsMutation] = useMutation(REGISTER_PUSH_NOTIFICATIONS);
  const registerPushNotifications = (endpoint, keys) => __async(void 0, null, function* () {
    yield registerPushNotificationsMutation({
      variables: {
        endpoint,
        keys
      }
    });
  });
  return () => __async(void 0, null, function* () {
    if (currentUser.isAnonymous)
      return;
    if ("serviceWorker" in navigator && window.Notification) {
      try {
        const registration = yield navigator.serviceWorker.register(sw);
        Notification.requestPermission();
        const subscription = yield registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });
        registerPushNotifications(subscription.endpoint, JSON.stringify(subscription.toJSON().keys));
      } catch (e) {
        console.warn(e);
      }
    }
  });
};
