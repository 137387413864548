"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Image = require("./Image");
var _Button = require("./Button");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  label: {
    "ImageUploader__styles.label": "ImageUploader__styles.label",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    display: "x78zum5",
    cursor: "x1ypdohk",
    $$css: true
  },
  styledLabel: {
    "ImageUploader__styles.styledLabel": "ImageUploader__styles.styledLabel",
    position: "x1n2onr6",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginTop: "xdj266r",
    marginBottom: "xat24cr",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    padding: "xggk2y7",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "xgqmno8",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "@media (hover: hover) and (pointer: fine)_borderColor": "x1ft6eci",
    "@media (hover: hover) and (pointer: fine)_borderInlineColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineStartColor": null,
    "@media (hover: hover) and (pointer: fine)_borderLeftColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineEndColor": null,
    "@media (hover: hover) and (pointer: fine)_borderRightColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBlockColor": null,
    "@media (hover: hover) and (pointer: fine)_borderTopColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBottomColor": null,
    $$css: true
  },
  undo: {
    "ImageUploader__styles.undo": "ImageUploader__styles.undo",
    position: "x10l6tqk",
    bottom: "x1ey2m1c",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    background: "x1bqm7q8",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    width: "xeq5yr9",
    padding: "x15fnm84",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "xuz7yzd",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1pvqxga",
    $$css: true
  },
  image: {
    "ImageUploader__styles.image": "ImageUploader__styles.image",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxWidth: "x193iq5w",
    width: "x14atkfc",
    minWidth: "x1jzhcrs",
    minHeight: "xz65tgg",
    maxHeight: "x1hkcv85",
    display: "x78zum5",
    $$css: true
  },
  hidden: {
    "ImageUploader__styles.hidden": "ImageUploader__styles.hidden",
    display: "x1s85apg",
    $$css: true
  }
};
const Video = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(6);
  let classNameProp;
  let props;
  if ($[0] !== t0) {
    _a = t0, {
      className: classNameProp
    } = _a, props = __objRest(_a, ["className"]);
    $[0] = t0;
    $[1] = classNameProp;
    $[2] = props;
  } else {
    classNameProp = $[1];
    props = $[2];
  }
  let t1;
  if ($[3] !== classNameProp || $[4] !== props) {
    t1 = /* @__PURE__ */_react.default.createElement(_Html.default.video, __spreadProps(__spreadValues({}, props), {
      style: classNameProp
    }));
    $[3] = classNameProp;
    $[4] = props;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  return t1;
};
var _default = exports.default = (0, _react.memo)(t0 => {
  var _a, _b;
  const $ = (0, _compilerRuntime.c)(42);
  let forceDefault;
  let imageProps;
  let onFile;
  let src;
  let unstyled;
  if ($[0] !== t0) {
    _a = t0, {
      onFile,
      src,
      forceDefault,
      unstyled
    } = _a, imageProps = __objRest(_a, ["onFile", "src", "forceDefault", "unstyled"]);
    $[0] = t0;
    $[1] = forceDefault;
    $[2] = imageProps;
    $[3] = onFile;
    $[4] = src;
    $[5] = unstyled;
  } else {
    forceDefault = $[1];
    imageProps = $[2];
    onFile = $[3];
    src = $[4];
    unstyled = $[5];
  }
  let t1;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = Math.random().toString(36).substring(2);
    $[6] = t1;
  } else {
    t1 = $[6];
  }
  const [randomId] = (0, _react.useState)(t1);
  const [image, setImage] = (0, _react.useState)(null);
  const imageSrc = !forceDefault && image || typeof src === "string" && src || "";
  let t2;
  if ($[7] !== imageSrc) {
    t2 = (imageSrc == null ? void 0 : imageSrc.endsWith(".mp4")) || (imageSrc == null ? void 0 : imageSrc.startsWith("data:video"));
    $[7] = imageSrc;
    $[8] = t2;
  } else {
    t2 = $[8];
  }
  const isVideo = t2;
  const isComponent = typeof src === "object" && !imageSrc;
  const t3 = !unstyled && styles.styledLabel;
  let t4;
  if ($[9] !== t3) {
    t4 = [styles.label, t3];
    $[9] = t3;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  let t5;
  if ($[11] !== imageProps || $[12] !== imageSrc || $[13] !== isVideo) {
    t5 = isVideo && /* @__PURE__ */_react.default.createElement(Video, {
      autoPlay: true,
      loop: true,
      muted: true,
      playsInline: true,
      src: imageSrc,
      className: [styles.image, imageProps.className]
    });
    $[11] = imageProps;
    $[12] = imageSrc;
    $[13] = isVideo;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  let t6;
  if ($[15] !== imageProps || $[16] !== imageSrc || $[17] !== isComponent || $[18] !== isVideo) {
    t6 = !isVideo && !isComponent && /* @__PURE__ */_react.default.createElement(_Image.Image, __spreadProps(__spreadValues({
      src: imageSrc
    }, imageProps), {
      className: [styles.image, imageProps.className]
    }));
    $[15] = imageProps;
    $[16] = imageSrc;
    $[17] = isComponent;
    $[18] = isVideo;
    $[19] = t6;
  } else {
    t6 = $[19];
  }
  let t7;
  if ($[20] !== isComponent || $[21] !== src) {
    t7 = isComponent && _react.default.cloneElement(src, {
      style: [styles.image, (_b = src == null ? void 0 : src.props) == null ? void 0 : _b.style]
    });
    $[20] = isComponent;
    $[21] = src;
    $[22] = t7;
  } else {
    t7 = $[22];
  }
  let t8;
  if ($[23] !== image || $[24] !== imageSrc || $[25] !== onFile) {
    t8 = image && imageSrc && /* @__PURE__ */_react.default.createElement(_Button.Button, {
      color: "rgba(0,0,0,0.7)",
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        setImage(null);
        onFile(null);
      },
      className: styles.undo,
      icon: "undo"
    });
    $[23] = image;
    $[24] = imageSrc;
    $[25] = onFile;
    $[26] = t8;
  } else {
    t8 = $[26];
  }
  let t9;
  if ($[27] !== randomId || $[28] !== t4 || $[29] !== t5 || $[30] !== t6 || $[31] !== t7 || $[32] !== t8) {
    t9 = /* @__PURE__ */_react.default.createElement(_Html.default.label, {
      style: t4,
      htmlFor: randomId
    }, t5, t6, t7, t8);
    $[27] = randomId;
    $[28] = t4;
    $[29] = t5;
    $[30] = t6;
    $[31] = t7;
    $[32] = t8;
    $[33] = t9;
  } else {
    t9 = $[33];
  }
  let t10;
  if ($[34] !== onFile) {
    t10 = e_0 => __async(void 0, null, function* () {
      var _a2;
      const files = (_a2 = e_0.target) == null ? void 0 : _a2.files;
      const file = files && files[0];
      if (file && (file.type.slice(0, 5) === "image" || file.type.slice(0, 5) === "video")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setImage(reader.result);
            onFile(file);
          }
        };
        reader.readAsDataURL(file);
      }
    });
    $[34] = onFile;
    $[35] = t10;
  } else {
    t10 = $[35];
  }
  let t11;
  if ($[36] !== randomId || $[37] !== t10) {
    t11 = /* @__PURE__ */_react.default.createElement(_Html.default.input, {
      id: randomId,
      name: randomId,
      type: "file",
      style: styles.hidden,
      onChange: t10
    });
    $[36] = randomId;
    $[37] = t10;
    $[38] = t11;
  } else {
    t11 = $[38];
  }
  let t12;
  if ($[39] !== t11 || $[40] !== t9) {
    t12 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, t9, t11);
    $[39] = t11;
    $[40] = t9;
    $[41] = t12;
  } else {
    t12 = $[41];
  }
  return t12;
});