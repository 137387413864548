"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Icon = require("./Icon");
var _Loader = require("./Loader");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  button: {
    "Button__styles.button": "Button__styles.button",
    alignItems: "x6s0dn4",
    backgroundColor: "x8qxh4v",
    color: "x11jfisy",
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    boxSizing: "x9f619",
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    zIndex: "x1vjfegm",
    cursor: "x1ypdohk",
    display: "x78zum5",
    position: "x1n2onr6",
    flexDirection: "x1q0g3np",
    fontFamily: "x1l1c18b",
    fontSize: "xif65rj",
    whiteSpace: "xuxw1ft",
    boxShadow: "x9gmhs3",
    fontWeight: "x117nqv4",
    height: "xt7dq6l",
    justifyContent: "xl56j7k",
    maxWidth: "x193iq5w",
    minHeight: "xu0aao5",
    minWidth: "x900493",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    paddingBottom: "xwib8y2",
    paddingLeft: "x1igx7t4",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "x172e5tb",
    paddingTop: "x1y1aw1k",
    pointerEvents: "x71s49j",
    userSelect: "x87ps6o",
    width: "xeq5yr9",
    WebkitTransform: "xoyzusl",
    transform: "xa2dozc",
    transition: "x1trvaba",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media (max-width: 1000px)_whiteSpace": "xwzlg83",
    ":active_filter": "xacu4zq",
    ":active_WebkitTransform": "x1hi3wi8",
    ":active_transform": "xm4o3sg",
    filter: "xudkgsl",
    $$css: true
  },
  blue: {
    "Button__styles.blue": "Button__styles.blue",
    color: "x11jfisy",
    backgroundColor: "x8qxh4v",
    $$css: true
  },
  yellow: {
    "Button__styles.yellow": "Button__styles.yellow",
    color: "xk4qdrg",
    backgroundColor: "x1ut2sa4",
    $$css: true
  },
  red: {
    "Button__styles.red": "Button__styles.red",
    backgroundColor: "x2hu6h8",
    $$css: true
  },
  "google plus": {
    "Button__styles.googleplus": "Button__styles.googleplus",
    backgroundColor: "x119tdg6",
    $$css: true
  },
  black: {
    "Button__styles.black": "Button__styles.black",
    backgroundColor: "x1gwr3j1",
    $$css: true
  },
  grey: {
    "Button__styles.grey": "Button__styles.grey",
    color: "x1l75d1c",
    backgroundColor: "x1o8b",
    $$css: true
  },
  purple: {
    "Button__styles.purple": "Button__styles.purple",
    backgroundColor: "xhg5gn8",
    $$css: true
  },
  green: {
    "Button__styles.green": "Button__styles.green",
    backgroundColor: "x1x8pmy7",
    $$css: true
  },
  white: {
    "Button__styles.white": "Button__styles.white",
    color: "xk4qdrg",
    backgroundColor: "x14xb6xe",
    $$css: true
  },
  disabled: {
    "Button__styles.disabled": "Button__styles.disabled",
    opacity: "xbyyjgo",
    cursor: "x1h6gzvc",
    ":active_filter": "xsfs9q5",
    ":active_WebkitTransform": "xeyg6yr",
    ":active_transform": "x1tgr6oz",
    $$css: true
  },
  fluid: {
    "Button__styles.fluid": "Button__styles.fluid",
    width: "xh8yej3",
    $$css: true
  },
  centered: {
    "Button__styles.centered": "Button__styles.centered",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  active: {
    "Button__styles.active": "Button__styles.active",
    $$css: true
  },
  cornered: {
    "Button__styles.cornered": "Button__styles.cornered",
    borderRadius: "x2u8bby",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  inverted: {
    "Button__styles.inverted": "Button__styles.inverted",
    color: "xzwifym",
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    backgroundColor: "xjbqb8w",
    boxShadow: "x1gnnqk1",
    $$css: true
  },
  borderLess: {
    "Button__styles.borderLess": "Button__styles.borderLess",
    color: "xzwifym",
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    boxShadow: "x1gnnqk1",
    backgroundColor: "xjbqb8w x1j37zrf",
    $$css: true
  },
  loaderWrapper: {
    "Button__styles.loaderWrapper": "Button__styles.loaderWrapper",
    maxHeight: "x1b12d3d",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  xs: {
    "Button__styles.xs": "Button__styles.xs",
    height: "xxk0z11",
    fontSize: "xfifm61",
    paddingLeft: "x163pfp",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "xy13l1i",
    paddingTop: "x1y1aw1k",
    paddingBottom: "xwib8y2",
    $$css: true
  },
  s: {
    "Button__styles.s": "Button__styles.s",
    height: "x10w6t97",
    fontSize: "xfifm61",
    $$css: true
  },
  m: {
    "Button__styles.m": "Button__styles.m",
    height: "xc9qbxq",
    fontSize: "xif65rj",
    $$css: true
  },
  l: {
    "Button__styles.l": "Button__styles.l",
    height: "x1vqgdyp",
    fontSize: "xosj86m",
    $$css: true
  },
  xl: {
    "Button__styles.xl": "Button__styles.xl",
    height: "xng8ra",
    fontSize: "xosj86m",
    $$css: true
  },
  xxl: {
    "Button__styles.xxl": "Button__styles.xxl",
    height: "xwzfr38",
    fontSize: "xwsyq91",
    $$css: true
  }
};
const bgColorMap = {
  blue: "var(--primary)",
  yellow: "var(--secondary)",
  red: "var(--danger)",
  "google plus": "#dd4b39",
  black: "var(--black)",
  grey: "#767676",
  purple: "#a333c8",
  green: "#21ba45",
  white: "var(--foreground)"
};
const fgColorMap = {
  blue: "var(--foreground)",
  yellow: "var(--primary-dark-25)",
  red: "var(--foreground)",
  "google plus": "var(--foreground)",
  black: "var(--foreground)",
  grey: "#767676",
  white: "var(--black)",
  purple: "var(--foreground)",
  green: "var(--foreground)"
};
function getBgColor(color) {
  if (color in bgColorMap) {
    return bgColorMap[color];
  }
  return color;
}
function getFgColor(color) {
  if (color in fgColorMap) {
    return fgColorMap[color];
  }
  return "var(--foreground)";
}
const createStyle = (color, inverted, borderLess) => {
  const $ = (0, _compilerRuntime.c)(10);
  let t0;
  if ($[0] !== color) {
    t0 = getFgColor(color);
    $[0] = color;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const fgColor = t0;
  let t1;
  if ($[2] !== color) {
    t1 = getBgColor(color);
    $[2] = color;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const bgColor = t1;
  if (inverted) {
    const t22 = borderLess ? "transparent" : bgColor;
    let t3;
    if ($[4] !== bgColor || $[5] !== t22) {
      t3 = {
        color: bgColor,
        borderColor: t22
      };
      $[4] = bgColor;
      $[5] = t22;
      $[6] = t3;
    } else {
      t3 = $[6];
    }
    return t3;
  }
  let t2;
  if ($[7] !== bgColor || $[8] !== fgColor) {
    t2 = {
      backgroundColor: bgColor,
      color: fgColor
    };
    $[7] = bgColor;
    $[8] = fgColor;
    $[9] = t2;
  } else {
    t2 = $[9];
  }
  return t2;
};
const Button = exports.Button = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(39);
  const {
    ref,
    children,
    disabled,
    loading,
    onClick,
    inverted,
    style,
    icon,
    fluid,
    color,
    size,
    centered,
    autoFocus,
    type,
    active,
    cornered,
    className,
    borderLess
  } = t0;
  const isStandardColor = !!styles[color];
  let t1;
  if ($[0] !== active || $[1] !== borderLess || $[2] !== centered || $[3] !== className || $[4] !== color || $[5] !== cornered || $[6] !== disabled || $[7] !== fluid || $[8] !== inverted || $[9] !== isStandardColor || $[10] !== size) {
    t1 = stylex.props(styles.button, disabled && styles.disabled, inverted && styles.inverted, borderLess && styles.borderLess, fluid && styles.fluid, size && styles[size], centered && styles.centered, active && styles.active, cornered && styles.cornered, isStandardColor && !inverted && styles[color], className instanceof Object && className);
    $[0] = active;
    $[1] = borderLess;
    $[2] = centered;
    $[3] = className;
    $[4] = color;
    $[5] = cornered;
    $[6] = disabled;
    $[7] = fluid;
    $[8] = inverted;
    $[9] = isStandardColor;
    $[10] = size;
    $[11] = t1;
  } else {
    t1 = $[11];
  }
  const {
    className: styleXClassName
  } = t1;
  let classNames;
  if ($[12] !== className || $[13] !== styleXClassName) {
    classNames = [styleXClassName];
    if (typeof className === "string") {
      classNames.push(className);
    }
    $[12] = className;
    $[13] = styleXClassName;
    $[14] = classNames;
  } else {
    classNames = $[14];
  }
  let t2;
  if ($[15] !== borderLess || $[16] !== color || $[17] !== inverted || $[18] !== isStandardColor) {
    t2 = !isStandardColor || inverted || borderLess ? createStyle(color, inverted, borderLess) : {};
    $[15] = borderLess;
    $[16] = color;
    $[17] = inverted;
    $[18] = isStandardColor;
    $[19] = t2;
  } else {
    t2 = $[19];
  }
  const colorStyle = t2;
  const t3 = classNames.join(" ");
  const t4 = disabled || loading;
  let t5;
  if ($[20] !== colorStyle || $[21] !== style) {
    t5 = __spreadValues(__spreadValues({}, colorStyle), style);
    $[20] = colorStyle;
    $[21] = style;
    $[22] = t5;
  } else {
    t5 = $[22];
  }
  const t6 = t5;
  const t7 = type || "button";
  let t8;
  if ($[23] !== icon) {
    t8 = icon && typeof icon === "string" && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: icon
    });
    $[23] = icon;
    $[24] = t8;
  } else {
    t8 = $[24];
  }
  let t9;
  if ($[25] !== children || $[26] !== color || $[27] !== inverted || $[28] !== loading) {
    t9 = loading ? /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.loaderWrapper
    }, /* @__PURE__ */_react.default.createElement(_Loader.Loader, {
      color: inverted ? getBgColor(color) : getFgColor(color)
    })) : children;
    $[25] = children;
    $[26] = color;
    $[27] = inverted;
    $[28] = loading;
    $[29] = t9;
  } else {
    t9 = $[29];
  }
  let t10;
  if ($[30] !== autoFocus || $[31] !== onClick || $[32] !== t3 || $[33] !== t4 || $[34] !== t6 || $[35] !== t7 || $[36] !== t8 || $[37] !== t9) {
    t10 = /* @__PURE__ */_react.default.createElement("button", {
      className: t3,
      disabled: t4,
      autoFocus,
      style: t6,
      ref,
      onClick,
      type: t7
    }, t8, t9);
    $[30] = autoFocus;
    $[31] = onClick;
    $[32] = t3;
    $[33] = t4;
    $[34] = t6;
    $[35] = t7;
    $[36] = t8;
    $[37] = t9;
    $[38] = t10;
  } else {
    t10 = $[38];
  }
  return t10;
});