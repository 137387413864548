"use strict";
export function getPosition(paused, position, duration, unixTimePositionSent) {
  if (paused)
    return position;
  if (!duration)
    return 0;
  const timeDiff = (window.getServerTime() - unixTimePositionSent) / 1e3;
  let _position = position + timeDiff;
  if (Math.ceil(_position) > duration) {
    _position = _position % duration;
  }
  return Math.round(_position);
}
