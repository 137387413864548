"use strict";
import React, { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import LoadingScreen from "../shared/components/LoadingScreen";
const MobileHandler = () => {
  const history = useHistory();
  useEffect(() => {
    window.sessionStorage.setItem("isAndroidTWA", "true");
    window.isAndroidTWA = true;
    history.replace("/");
  }, [history]);
  return /* @__PURE__ */ React.createElement(LoadingScreen, null);
};
export default withRouter(MobileHandler);
