"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Button = require("./Button");
var _Icon = require("./Icon");
var _Image = require("./Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  diamond: {
    "UserProfile__styles.diamond": "UserProfile__styles.diamond",
    bottom: "xqo3gd",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    marginBottom: "xat24cr",
    fontSize: "x1pvqxga",
    position: "x10l6tqk",
    $$css: true
  },
  userProfile: {
    "UserProfile__styles.userProfile": "UserProfile__styles.userProfile",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    textAlign: "x2b8uid",
    minHeight: "xbwy6ji",
    flexDirection: "xdt5ytf",
    width: "xeq5yr9",
    $$css: true
  },
  avatarWrapper: {
    "UserProfile__styles.avatarWrapper": "UserProfile__styles.avatarWrapper",
    position: "x1n2onr6",
    width: "xezivpi",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  actions: {
    "UserProfile__styles.actions": "UserProfile__styles.actions",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    flexWrap: "x1a02dak",
    margin: "x1wxscg9",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  avatar: {
    "UserProfile__styles.avatar": "UserProfile__styles.avatar",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: "xyorhqc",
    width: "x1exxlbk",
    height: "xpyat2d",
    maxWidth: "x193iq5w",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  nameWrapper: {
    "UserProfile__styles.nameWrapper": "UserProfile__styles.nameWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    margin: "xz4czej",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    alignItems: "x1cy8zhl",
    $$css: true
  },
  displayName: {
    "UserProfile__styles.displayName": "UserProfile__styles.displayName",
    display: "x1rg5ohu",
    fontSize: "x1pvqxga",
    fontWeight: "x1s688f",
    maxWidth: "x18qnofl",
    wordBreak: "x13faqbe",
    lineBreak: "x1c724ye",
    textAlign: "xdpxx8g",
    textOverflow: "xlyipyv",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    userSelect: "x1hx0egp",
    $$css: true
  },
  username: {
    "UserProfile__styles.username": "UserProfile__styles.username",
    margin: "x1hlb6i2",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "xj3b58b",
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    userSelect: "x1hx0egp",
    fontSize: "x1j61zf2",
    $$css: true
  },
  flex: {
    "UserProfile__styles.flex": "UserProfile__styles.flex",
    display: "x78zum5",
    $$css: true
  }
};
var _default = t0 => {
  const $ = (0, _compilerRuntime.c)(26);
  const {
    isAdmin,
    displayName,
    username,
    isAnonymous,
    actions,
    avatarUrl,
    isSubscribed
  } = t0;
  const t1 = avatarUrl || "";
  let t2;
  if ($[0] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Image.Image, {
      src: t1,
      circular: true,
      loading: "lazy",
      width: 100,
      height: 100,
      className: styles.avatar
    });
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== isSubscribed) {
    t3 = isSubscribed && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "kosmi diamond",
      className: styles.diamond
    });
    $[2] = isSubscribed;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] !== t2 || $[5] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.avatarWrapper
    }, t2, t3);
    $[4] = t2;
    $[5] = t3;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== isAdmin) {
    t5 = isAdmin && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      color: "yellow",
      name: "key"
    });
    $[7] = isAdmin;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== displayName || $[10] !== t5) {
    t6 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.displayName
    }, t5, displayName);
    $[9] = displayName;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== isAnonymous || $[13] !== username) {
    t7 = !isAnonymous && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.username
    }, "@", username);
    $[12] = isAnonymous;
    $[13] = username;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  let t8;
  if ($[15] !== t6 || $[16] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.nameWrapper
    }, t6, t7);
    $[15] = t6;
    $[16] = t7;
    $[17] = t8;
  } else {
    t8 = $[17];
  }
  let t9;
  if ($[18] !== t4 || $[19] !== t8) {
    t9 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.flex
    }, t4, t8);
    $[18] = t4;
    $[19] = t8;
    $[20] = t9;
  } else {
    t9 = $[20];
  }
  let t10;
  if ($[21] !== actions) {
    t10 = actions && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.actions
    }, actions.map(_temp));
    $[21] = actions;
    $[22] = t10;
  } else {
    t10 = $[22];
  }
  let t11;
  if ($[23] !== t10 || $[24] !== t9) {
    t11 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.userProfile
    }, t9, t10);
    $[23] = t10;
    $[24] = t9;
    $[25] = t11;
  } else {
    t11 = $[25];
  }
  return t11;
};
exports.default = _default;
function _temp(action, i) {
  return /* @__PURE__ */_react.default.createElement(_Button.Button, {
    key: i,
    icon: action.icon,
    size: "xs",
    inverted: true,
    onClick: action.onClick,
    color: action.color || "yellow",
    loading: action.loading,
    style: {
      margin: 4,
      width: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left"
    }
  }, action.text);
}