"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "@/shared/hooks/gql";
import React, { useContext } from "react";
import { ConnectionContext } from "@/api/ConnectionContext";
import { withDialogs } from "@/shared/hooks/withDialogs";
const ANON_LOGIN = gql(`
  mutation AnonLogin1 {
    anonLogin {
      token
    }
  }
`);
export default withDialogs(
  ({
    trigger,
    confirm
  }) => {
    const [_anonLogin] = useMutation(ANON_LOGIN);
    const { updateClient } = useContext(ConnectionContext);
    const anonLogin = () => __async(void 0, null, function* () {
      var _a, _b;
      const result = yield _anonLogin();
      const token = (_b = (_a = result == null ? void 0 : result.data) == null ? void 0 : _a.anonLogin) == null ? void 0 : _b.token;
      if (token) {
        window.localStorage.setItem("token", token);
      }
    });
    return React.cloneElement(trigger, {
      onClick: () => __async(void 0, null, function* () {
        if (yield confirm("Are you sure you want to log out?")) {
          anonLogin().then(() => updateClient());
        }
      })
    });
  }
);
