"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _ConnectionContext = require("@/api/ConnectionContext");
var _DateSelector = _interopRequireDefault(require("./DateSelector"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  backButton: {
    "Signup__styles.backButton": "Signup__styles.backButton",
    color: "x1awj2ng",
    float: "x1faq86j",
    width: "xw4jnvo",
    cursor: "x1ypdohk",
    marginBottom: "xieb3on",
    $$css: true
  }
};
const SignupForm = ({
  inviteCode,
  goBack,
  onSignup
}) => {
  const [error, setError] = (0, _react.useState)("");
  const [username, setUsername] = (0, _react.useState)("");
  const [password, setPassword] = (0, _react.useState)("");
  const [passwordRepeat, setPasswordRepeat] = (0, _react.useState)("");
  const [email, setEmail] = (0, _react.useState)("");
  const [birthday, setBirthday] = (0, _react.useState)([null, null, null]);
  const {
    updateClient
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  const backButton = /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.backButton,
    size: "big",
    name: "angle left",
    onClick: () => {
      if (goBack) {
        goBack();
      }
    }
  });
  const [signup, {
    loading
  }] = (0, _gql.useMutation)(SIGNUP);
  return /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    error: !!error,
    onSubmit: () => __async(void 0, null, function* () {
      var _a;
      try {
        const {
          data
        } = yield signup({
          variables: {
            username,
            password,
            email,
            birthday: birthday.map(v => v && String(v).padStart(2, "0")).join("-"),
            inviteCode
          }
        });
        const token = (_a = data == null ? void 0 : data.signup) == null ? void 0 : _a.token;
        if (token) {
          window.localStorage.setItem("token", token);
        }
        updateClient();
        if (onSignup) {
          onSignup();
        }
      } catch (e) {
        if (e.graphQLErrors) {
          setError(e.graphQLErrors[0].message);
        }
      }
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Message, {
    error: true,
    header: "Signup Failed!",
    content: error,
    onDismiss: () => setError("")
  }), goBack && backButton, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    placeholder: "Username",
    value: username,
    onChange: e => {
      e.preventDefault();
      setUsername(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    type: "email",
    placeholder: "Email",
    value: email,
    onChange: e => {
      e.preventDefault();
      setEmail(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    placeholder: "Password",
    type: "password",
    value: password,
    onChange: e => {
      e.preventDefault();
      setPassword(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    placeholder: "Password Repeat",
    type: "password",
    value: passwordRepeat,
    onChange: e => {
      e.preventDefault();
      setPasswordRepeat(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_DateSelector.default, {
    value: birthday,
    onChange: d => setBirthday(d)
  })), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    disabled: !birthday || birthday.some(val => !val) || !username || !password || password !== passwordRepeat || !email,
    centered: true,
    style: {
      width: 100
    },
    color: "yellow",
    inverted: true,
    loading,
    type: "submit"
  }, "Sign up"));
};
const SIGNUP = (0, _gql.gql)(`
  mutation Signup(
    $username: String!
    $password: String!
    $email: String!
    $birthday: Date!
    $inviteCode: String
  ) {
    signup(
      username: $username
      password: $password
      email: $email
      birthday: $birthday
      inviteCode: $inviteCode
    ) {
      token
    }
  }
`);
var _default = exports.default = SignupForm;