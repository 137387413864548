"use strict";
import useColorSchemes from "./useColorSchemes";
import useRealmInfo from "./useRealmInfo";
export default (colorSchemeId) => {
  const colorSchemes = useColorSchemes();
  const realmInfo = useRealmInfo();
  const realmColorSchemeId = realmInfo == null ? void 0 : realmInfo.colorScheme;
  if (!colorSchemeId && realmColorSchemeId) {
    return colorSchemes.filter((c) => (c == null ? void 0 : c.id) === realmColorSchemeId)[0] || null;
  }
  return colorSchemes.filter((c) => (c == null ? void 0 : c.id) === colorSchemeId)[0] || null;
};
