"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const defaultConfig = {
  engineHost: null,
  WS_URI: "wss://engine.kosmi.io/gql-ws",
  HTTP_URI: "https://engine.kosmi.io",
  realm: null
};
let fileOverrides = {};
const localStorageOverrides = {};
if (process.env.NODE_ENV === "development") {
  try {
    fileOverrides = require("~/.kosmi.config.json");
  } catch (e) {
  }
}
Object.keys(__spreadValues({}, localStorage)).forEach((k) => {
  if (defaultConfig[k] !== void 0 && localStorage[k] !== void 0) {
    localStorageOverrides[k] = localStorage[k];
  }
});
export default __spreadValues(__spreadValues(__spreadValues({}, defaultConfig), fileOverrides), localStorageOverrides);
