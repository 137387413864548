"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _helpers = require("kosmi-sdk/helpers");
var _reactRouterDom = require("react-router-dom");
var _PaymentSection = _interopRequireDefault(require("./PaymentSection"));
var _LoginWall = _interopRequireDefault(require("../LoginWall"));
var _InviteModal = _interopRequireDefault(require("./InviteModal"));
var _Diamond = _interopRequireDefault(require("./Diamond"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Premium = require("@/shared/components/Premium");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  diamondListItem: {
    "PremiumScreen__styles.diamondListItem": "PremiumScreen__styles.diamondListItem",
    fontSize: "x1pvqxga",
    width: "x17z2i9w",
    height: "x17rw0jw",
    marginRight: "x1db2dqx",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginLeft: "xj3b58b",
    display: "x1rg5ohu",
    $$css: true
  },
  screen: {
    "PremiumScreen__styles.screen": "PremiumScreen__styles.screen",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflowX: "x6ikm8r",
    $$css: true
  },
  subscriptionHero: {
    "PremiumScreen__styles.subscriptionHero": "PremiumScreen__styles.subscriptionHero",
    background: "x1tudphz",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xieb3on",
    $$css: true
  },
  info: {
    "PremiumScreen__styles.info": "PremiumScreen__styles.info",
    fontSize: "xosj86m",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: null,
    color: "x16cd2qt",
    textAlign: "x2b8uid",
    $$css: true
  },
  perkList: {
    "PremiumScreen__styles.perkList": "PremiumScreen__styles.perkList",
    listStyle: "xe8uvvx",
    listStyleImage: null,
    listStylePosition: null,
    listStyleType: null,
    fontSize: "x1pvqxga",
    $$css: true
  },
  perk: {
    "PremiumScreen__styles.perk": "PremiumScreen__styles.perk",
    margin: "x1bxwjp6",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  subscriptionChild: {
    "PremiumScreen__styles.subscriptionChild": "PremiumScreen__styles.subscriptionChild",
    marginBottom: "xieb3on",
    $$css: true
  },
  subscription: {
    "PremiumScreen__styles.subscription": "PremiumScreen__styles.subscription",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    $$css: true
  },
  freeOption: {
    "PremiumScreen__styles.freeOption": "PremiumScreen__styles.freeOption",
    display: "x78zum5",
    cursor: "x1ypdohk",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xif65rj",
    $$css: true
  },
  emphasis: {
    "PremiumScreen__styles.emphasis": "PremiumScreen__styles.emphasis",
    color: "x16cd2qt",
    fontWeight: "x1xlr1w8",
    marginLeft: "xd8780z",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  }
};
const GET_PRODUCT = (0, _gql.gql)(`
  query GetSubscriptionProduct {
    getStripeSubscriptionProduct {
      stripePriceId
      usdAmount
      stripePublishableKey
    }
  }
`);
const GET_SUBSCRIPTION = (0, _gql.gql)(`
  query GetSubscription {
    currentStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
    currentUser {
      id
      connectionId
      user {
        id
        isSubscribed
      }
    }
  }
`);
const PerkList = props => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, __spreadValues({
    style: styles.perkList
  }, props));
};
const Perk = props => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, __spreadValues({
    style: styles.perk
  }, props));
};
const BuySubscriptionScreen = (0, _helpers.withDialogs)(({
  source,
  straightToPayment
}) => {
  const buyBtnRef = (0, _react.useRef)(null);
  const {
    data
  } = (0, _gql.useQuery)(GET_PRODUCT);
  const {
    usdAmount
  } = (data == null ? void 0 : data.getStripeSubscriptionProduct) || {};
  const currentUser = (0, _helpers.currentUserHook)();
  const [hasNavigated, setHasNavigated] = (0, _react.useState)(false);
  if (_Premium.hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "See Perks"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.screen
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionHero
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.info
  }, "For only $", usdAmount, " a month you get:"), /* @__PURE__ */_react.default.createElement(PerkList, {
    style: styles.perkList
  }, /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F310} Unlimited Virtual Browsing"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F4FA} 1080p & 4K Screensharing"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F3A8} Custom Themes and Color Schemes"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F3A9} Exclusive access to the Kosmi VIP Lounge"), /* @__PURE__ */_react.default.createElement(Perk, null, /* @__PURE__ */_react.default.createElement(_Diamond.default, {
    className: styles.diamondListItem
  }), "Extra surprise for the first 1000 members!"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F389} Many more upcoming features"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u2764\uFE0F Help Kosmi improve and grow"))), /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, showLoginModal => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    style: {
      margin: "auto"
    },
    ref: buyBtnRef,
    onClick: showLoginModal
  }, "Lets do it!")), /* @__PURE__ */_react.default.createElement(_InviteModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.freeOption
    }, "Money tight right now? Get Free kosmi Premium by", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.emphasis
    }, "inviting friends."))
  })), !_Premium.hidePremium && !(currentUser == null ? void 0 : currentUser.isAnonymous) && /* @__PURE__ */_react.default.createElement(_PaymentSection.default, {
    trigger: hasNavigated ? buyBtnRef : straightToPayment || buyBtnRef,
    source,
    checkout: true,
    onBack: () => {
      setHasNavigated(true);
    }
  }));
});
const UPDATE_PAYMENT_METHOD = (0, _gql.gql)(`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updateStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      ok
    }
  }
`);
const CANCEL_SUBSCRIPTION = (0, _gql.gql)(`
  mutation ReactivateSubscription2 {
    cancelStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const REACTIVATE_SUBSCRIPTION = (0, _gql.gql)(`
  mutation ReactivateSubscription {
    reactivateStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const PremiumSettings = (0, _helpers.withDialogs)(({
  confirm,
  dark
}) => {
  const history = (0, _reactRouterDom.useHistory)();
  const [_paymentMethod, _setPaymentMethod] = (0, _react.useState)(null);
  const {
    data: subscriptionData,
    loading
  } = (0, _gql.useQuery)(GET_SUBSCRIPTION, {});
  const [updateStripePaymentMethod] = (0, _gql.useMutation)(UPDATE_PAYMENT_METHOD);
  const [cancelStripeSubscription] = (0, _gql.useMutation)(CANCEL_SUBSCRIPTION);
  const [reactivateStripeSubscription] = (0, _gql.useMutation)(REACTIVATE_SUBSCRIPTION);
  const {
    cancelAtPeriodEnd,
    periodEnds,
    status
  } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (loading) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  if (status !== "active" && status !== "trialing") {
    return /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
      size: "massive",
      active: true,
      content: "Processing payment..."
    });
  }
  if (_Premium.hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Kosmi Premium",
    dark
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscription
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "You are subscribed!"), cancelAtPeriodEnd ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "Cancels on ", periodEnds) : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "Renews on ", periodEnds)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    name: "Join VIP Room",
    icon: "star",
    onClick: () => {
      history.push("/room/vip");
    }
  }), cancelAtPeriodEnd ? /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "success",
    onClick: () => __async(void 0, null, function* () {
      if (!confirm) return;
      if (yield confirm("Resubscribe?")) {
        reactivateStripeSubscription();
      }
    }),
    name: "Reactivate"
  }) : /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !_Premium.hidePremium && /* @__PURE__ */_react.default.createElement(_PaymentSection.default, {
    toggle: true,
    source: "PremiumSettings",
    onPaymentMethod: pm => __async(void 0, null, function* () {
      yield updateStripePaymentMethod({
        variables: {
          paymentMethodId: pm.id
        }
      });
    })
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "danger",
    icon: "close",
    onClick: () => __async(void 0, null, function* () {
      if (!confirm) return;
      if (yield confirm("Cancel subscription?")) {
        cancelStripeSubscription();
      }
    }),
    name: "Cancel Subscription"
  })));
});
var _default = exports.default = (0, _react.memo)(({
  dark,
  source,
  straightToPayment
}) => {
  const {
    data: subscriptionData,
    loading
  } = (0, _gql.useQuery)(GET_SUBSCRIPTION, {});
  if (loading) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  const {
    status
  } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (status) return /* @__PURE__ */_react.default.createElement(PremiumSettings, {
    source: "",
    dark
  });
  return /* @__PURE__ */_react.default.createElement(BuySubscriptionScreen, {
    source,
    straightToPayment
  });
});