"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Html } from "@/uikit";
import { Button, Icon } from "@/uikit";
import React, { memo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "@/uikit";
const LeaveRoomButton = memo(({ trigger }) => {
  const history = useHistory();
  const modalRef = useRef(null);
  return /* @__PURE__ */ React.createElement(Modal, { trigger, ref: modalRef }, /* @__PURE__ */ React.createElement(Modal.Header, null, "Leave Room"), /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(Html.div, null, "Are you sure you want to leave this room?")), /* @__PURE__ */ React.createElement(Modal.Actions, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      basic: true,
      style: { margin: 5 },
      color: "red",
      inverted: true,
      onClick: () => {
        var _a;
        (_a = modalRef.current) == null ? void 0 : _a.close();
      }
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "remove" }),
    " No"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "yellow",
      style: { margin: 5 },
      inverted: true,
      onClick: (e) => __async(void 0, null, function* () {
        e.preventDefault();
        e.stopPropagation();
        window.skipHistoryPrompt = true;
        history.push("/");
      })
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "checkmark" }),
    " Yes"
  )));
});
export default LeaveRoomButton;
