"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMembersData = require("../hooks/useMembersData");
var _useRoomId = require("../hooks/useRoomId");
var _gql = require("@/shared/hooks/gql");
var _helpers = require("kosmi-sdk/helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const INVITE_MEMBERS_BACK = (0, _gql.gql)(`
  mutation InviteMembersBackToRoom(
    $roomId: String!
    $message: String!
    $userIds: [String]!
  ) {
    inviteMembersBackToRoom(
      roomId: $roomId
      userIds: $userIds
      message: $message
    ) {
      ok
    }
  }
`);
const INVITE_ALL_MEMBERS_BACK = (0, _gql.gql)(`
  mutation InviteAllMembersBackToRoom($roomId: String!, $message: String!) {
    inviteAllMembersBackToRoom(message: $message, roomId: $roomId) {
      ok
    }
  }
`);
const styles = {
  inviteEveryone: {
    "InviteBackModal__styles.inviteEveryone": "InviteBackModal__styles.inviteEveryone",
    display: "x78zum5",
    width: "xh8yej3",
    backgroundColor: "x1hhg5nt",
    flexDirection: "xdt5ytf",
    padding: "xetlade",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    zIndex: "xzkaem6",
    alignItems: "x6s0dn4",
    $$css: true
  },
  inviteChild: {
    "InviteBackModal__styles.inviteChild": "InviteBackModal__styles.inviteChild",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    width: "xh8yej3",
    margin: "xnfbntj",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  content: {
    "InviteBackModal__styles.content": "InviteBackModal__styles.content",
    color: "x164e47y",
    $$css: true
  },
  members: {
    "InviteBackModal__styles.members": "InviteBackModal__styles.members",
    height: "x5yr21d",
    maxHeight: "x1phlbz0",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1k3vv4n",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  member: {
    "InviteBackModal__styles.member": "InviteBackModal__styles.member",
    display: "x78zum5",
    cursor: "x1ypdohk",
    padding: "x6w2896",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  avatar: {
    "InviteBackModal__styles.avatar": "InviteBackModal__styles.avatar",
    width: "xw4jnvo",
    height: "x1qx5ct2",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1suov85",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
const MembersModal = ({
  trigger,
  onSent,
  onError
}) => {
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    linkedMembers
  } = (0, _useMembersData.useLinkedMembersCached)(roomId);
  const [selectedUserIds, setSelectedUserIds] = (0, _react.useState)([]);
  const [modal, setModal] = (0, _react.useState)(null);
  const [message, setMessage] = (0, _react.useState)("");
  const selectAll = selectedUserIds.length === linkedMembers.length;
  const toggleUserId = userId => {
    if (!selectedUserIds.includes(userId)) {
      setSelectedUserIds([...selectedUserIds, userId]);
    } else {
      setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    }
  };
  const [inviteMembersBackToRoom] = (0, _gql.useMutation)(INVITE_MEMBERS_BACK, {
    variables: {
      roomId,
      message,
      userIds: selectedUserIds
    }
  });
  const [inviteAllMembersBackToRoom] = (0, _gql.useMutation)(INVITE_ALL_MEMBERS_BACK, {
    variables: {
      roomId,
      message
    }
  });
  const close = () => (modal ? modal.handleClose : () => {})();
  const sendInvites = () => __async(void 0, null, function* () {
    try {
      yield selectAll ? inviteAllMembersBackToRoom() : inviteMembersBackToRoom();
      close();
      if (onSent) onSent();
    } catch (e) {
      if (onError) onError((e == null ? void 0 : e.message) || "Unknown Error");
      close();
    }
  });
  const onModal = (0, _react.useCallback)(modal2 => setModal(modal2), []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger,
    onModal
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Select members to invite to the room"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, {
    style: styles.content
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    value: message,
    onChange: e => {
      setMessage(e.target.value);
    },
    placeholder: "Message (optional)"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    checked: selectAll,
    onChange: () => {
      if (selectAll) setSelectedUserIds([]);else {
        setSelectedUserIds(linkedMembers.map(member => {
          var _a;
          return ((_a = member == null ? void 0 : member.user) == null ? void 0 : _a.id) || "";
        }));
      }
    },
    label: "All room members"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.members
  }, linkedMembers.map(member => {
    var _a, _b, _c, _d;
    return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.member,
      key: member.id,
      onClick: () => {
        var _a2;
        return toggleUserId(((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.id) || "");
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
      checked: selectAll || selectedUserIds.includes(((_a = member == null ? void 0 : member.user) == null ? void 0 : _a.id) || ""),
      onChange: () => {
        var _a2;
        toggleUserId(((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.id) || "");
      }
    }), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
      className: styles.avatar,
      src: ((_b = member == null ? void 0 : member.user) == null ? void 0 : _b.avatarUrl) || ""
    }), ((_c = member == null ? void 0 : member.user) == null ? void 0 : _c.displayName) || ((_d = member == null ? void 0 : member.user) == null ? void 0 : _d.username));
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Actions, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    inverted: true,
    onClick: close,
    style: {
      margin: 5
    }
  }, "Cancel"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    disabled: !selectedUserIds.length,
    color: "blue",
    onClick: sendInvites,
    style: {
      margin: 5
    }
  }, "Send Invite")));
};
var _default = exports.default = (0, _helpers.withDialogs)(({
  alert
}) => {
  const currentUser = (0, _helpers.currentUserHook)();
  const track = (0, _helpers.useTracking)();
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    linkedMembers
  } = (0, _useMembersData.useLinkedMembersCached)(roomId);
  const members = (0, _useMembersData.useMembers)(roomId);
  const [_dismiss, setDismiss] = (0, _react.useState)(false);
  const dismiss = () => {
    ;
    window.inviteBackDismissed = true;
    setDismiss(true);
    track("UI", "InviteBackDismissed", "Invite Back Dismissed");
  };
  const realm = location.host.endsWith(".kosmi.business") ? location.host.split(".kosmi.business")[0] : null;
  if (roomId === "lobby" || !!realm) return null;
  if (currentUser == null ? void 0 : currentUser.isAnonymous) return null;
  if (members.length > 1) return null;
  if (linkedMembers.length === 0) return null;
  if (_dismiss) return null;
  if (window.inviteBackDismissed) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteEveryone
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteChild
  }, "None of this room`s members are here. Invite them back?"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteChild
  }, /* @__PURE__ */_react.default.createElement(MembersModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      size: "s",
      color: "yellow",
      style: {
        margin: 5
      }
    }, "See members"),
    onSent: () => {
      setDismiss(true);
      track("UI", "InviteBackSent", "Invites Back Sent");
      alert && alert("Invites sent!");
    },
    onError: alert || (() => {})
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    size: "s",
    onClick: dismiss,
    style: {
      margin: 5
    },
    inverted: true
  }, "Dismiss")));
});