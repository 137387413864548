"use strict";
import { gql, useQuery } from "@/shared/hooks/gql";
import { useMemo } from "react";
const ICE_SERVER_QUERY = gql(`
  query WebRTCIceServerQuery {
    webrtcIceServers {
      urls
      credential
      username
    }
  }
`);
export const useIceservers = () => {
  const { data } = useQuery(ICE_SERVER_QUERY, { fetchPolicy: "cache-first" });
  const iceServers = useMemo(
    () => {
      var _a;
      return (_a = data == null ? void 0 : data.webrtcIceServers) == null ? void 0 : _a.map((val) => {
        const serverInfo = {};
        if (val == null ? void 0 : val.credential) {
          serverInfo.credential = val == null ? void 0 : val.credential;
        }
        if (val == null ? void 0 : val.username) {
          serverInfo.username = val == null ? void 0 : val.username;
        }
        if (val == null ? void 0 : val.urls) {
          serverInfo.urls = val == null ? void 0 : val.urls;
        }
        return serverInfo;
      });
    },
    [data == null ? void 0 : data.webrtcIceServers]
  );
  return iceServers || null;
};
