"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _reactDom = require("react-dom");
var _Spotlight = require("./Spotlight.stylex");
var _react2 = require("@floating-ui/react");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = t0 => {
  var _a, _b, _c2, _d;
  const $ = (0, _compilerRuntime.c)(25);
  const {
    brightness,
    children,
    enabled,
    animated,
    onClickOutside
  } = t0;
  const [element, setElement] = (0, _react.useState)(null);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = node => {
      if (node !== null) {
        setElement(node);
      }
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const elementRef = t1;
  const [screenX, setScreenX] = (0, _react.useState)(0);
  const [screenY, setScreenY] = (0, _react.useState)(0);
  const [width, setWidth] = (0, _react.useState)(0);
  const [height, setHeight] = (0, _react.useState)(0);
  let t2;
  let t3;
  if ($[1] !== element || $[2] !== enabled) {
    t2 = () => {
      if (element && enabled) {
        const updateCoordinates = () => {
          const {
            x,
            y
          } = element.getBoundingClientRect();
          setScreenX(window.pageXOffset + x);
          setScreenY(window.pageYOffset + y);
          setWidth(element.offsetWidth);
          setHeight(element.offsetHeight);
        };
        updateCoordinates();
        const updateLoop = setInterval(updateCoordinates, 100);
        window.addEventListener("resize", updateCoordinates);
        return () => {
          window.removeEventListener("resize", updateCoordinates);
          clearInterval(updateLoop);
        };
      }
    };
    t3 = [element, enabled];
    $[1] = element;
    $[2] = enabled;
    $[3] = t2;
    $[4] = t3;
  } else {
    t2 = $[3];
    t3 = $[4];
  }
  (0, _react.useEffect)(t2, t3);
  const t4 = ((_c2 = (_b = (_a = children == null ? void 0 : children.props) == null ? void 0 : _a.trigger) == null ? void 0 : _b.props) == null ? void 0 : _c2.ref) || ((_d = children == null ? void 0 : children.props) == null ? void 0 : _d.ref);
  let t5;
  if ($[5] !== t4) {
    t5 = [t4, elementRef];
    $[5] = t4;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  const mergedRefs = (0, _react2.useMergeRefs)(t5);
  let t6;
  if ($[7] !== children || $[8] !== mergedRefs) {
    let t72;
    if ($[10] !== mergedRefs) {
      t72 = {
        ref: mergedRefs
      };
      $[10] = mergedRefs;
      $[11] = t72;
    } else {
      t72 = $[11];
    }
    t6 = _react.default.cloneElement(children, t72);
    $[7] = children;
    $[8] = mergedRefs;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  const clonedChildren = t6;
  let t7;
  if ($[12] !== animated || $[13] !== brightness || $[14] !== element || $[15] !== enabled || $[16] !== height || $[17] !== onClickOutside || $[18] !== screenX || $[19] !== screenY || $[20] !== width) {
    t7 = element && enabled && (0, _reactDom.createPortal)(/* @__PURE__ */_react.default.createElement(_Html.default.div, {
      "data-spotlight": true,
      style: [_Spotlight.styles.root, animated && _Spotlight.styles.animated],
      onClick: onClickOutside
    }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [_Spotlight.styles.backdrop, _Spotlight.styles.backdropStyle(brightness)]
    }), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [_Spotlight.styles.spotlight, _Spotlight.styles.highlightStyle(screenX, screenY, width, height, brightness)]
    })), document.body, "spotlight");
    $[12] = animated;
    $[13] = brightness;
    $[14] = element;
    $[15] = enabled;
    $[16] = height;
    $[17] = onClickOutside;
    $[18] = screenX;
    $[19] = screenY;
    $[20] = width;
    $[21] = t7;
  } else {
    t7 = $[21];
  }
  let t8;
  if ($[22] !== clonedChildren || $[23] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, t7, clonedChildren);
    $[22] = clonedChildren;
    $[23] = t7;
    $[24] = t8;
  } else {
    t8 = $[24];
  }
  return t8;
};
exports.default = _default;