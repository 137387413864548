"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql } from "@/shared/hooks/gql";
import { useQuery } from "@apollo/client/react";
import React from "react";
import { useRoomId } from "./useRoomId";
const GET_MEMBERS = gql(`
  query WithGetMembers($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        runningApp
        webrtcEnabled
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const withMembers = (WrappedComponent) => {
  return (props) => {
    const members = useMembers();
    return /* @__PURE__ */ React.createElement(WrappedComponent, __spreadProps(__spreadValues({}, props), { members }));
  };
};
export const useMembers = () => {
  var _a, _b;
  const roomId = useRoomId();
  const { data } = useQuery(GET_MEMBERS, {
    variables: { roomId },
    fetchPolicy: "cache-first"
  });
  return (_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.members;
};
export default withMembers;
