"use strict";
import female1 from "./female1.mp4";
import female3 from "./female3.mp4";
import male1 from "./male1.mp4";
import male2 from "./male2.mp4";
import male3 from "./male3.mp4";
const demoVideos = {
  DEMO1: male1,
  DEMO2: male2,
  DEMO3: male3,
  DEMO4: female1,
  DEMO6: female3
};
const _demoStreams = {};
export function demoStreams(members) {
  const memberIds = members.map((m) => m.user.id);
  Object.keys(_demoStreams).forEach((memberId) => {
    if (memberIds.indexOf(memberId) === -1) {
      ;
      [..._demoStreams[memberId].getTracks()].forEach((track) => {
        track.stop();
      });
      delete _demoStreams[memberId];
    }
  });
  memberIds.filter((memberId) => memberId.startsWith("DEMO")).forEach((memberId) => {
    if (!_demoStreams[memberId]) {
      const demoVideoSource = demoVideos[memberId];
      if (demoVideoSource) {
        const video = document.createElement("video");
        video.autoplay = true;
        video.loop = true;
        video.src = demoVideoSource;
        _demoStreams[memberId] = video.captureStream && video.captureStream();
      }
    }
  });
  return _demoStreams;
}
