"use strict";
import withCurrentUser, { currentUserHook } from "@/shared/hooks/useCurrentUser";
import withDialogs from "@/shared/hooks/withDialogs";
import withMobileDetection from "@/shared/hooks/useMobileDetection";
import withTracking, { useTracking } from "@/shared/hooks/useTracking";
import withRoomId, { useRoomId } from "@/room/hooks/useRoomId";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
export {
  withDialogs,
  withCurrentUser,
  currentUserHook,
  withRoomId,
  withMobileDetection,
  withTracking,
  isMobile,
  useRoomId,
  useTracking
};
