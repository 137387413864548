"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _gql = require("@/shared/hooks/gql");
var _react = _interopRequireWildcard(require("react"));
var _useRoomId = require("@/room/hooks/useRoomId");
var _client = require("@apollo/client");
var _BannedMembersModal = require("./BannedMembersModal");
var _BannedIpsModal = require("./BannedIpsModal");
var _helpers = require("kosmi-sdk/helpers");
var _app = require("@/room/apps/spaces/app");
var _bg = _interopRequireDefault(require("@/room/apps/spaces/bg.svg"));
var _sofa = require("@/room/apps/spaces/sofa.svg");
var _hooks = require("@/room/apps/spaces/hooks");
var _useRoomData = require("@/room/hooks/useRoomData");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  background: {
    "RoomSettings__styles.background": "RoomSettings__styles.background",
    backgroundColor: "x8qxh4v",
    backgroundBlendMode: "xgddvq7",
    backgroundRepeat: "xiy17q3",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    width: "x1fj1wa4",
    aspectRatio: "x186iv6y",
    $$css: true
  },
  setBackground: background => [{
    "RoomSettings__styles.setBackground": "RoomSettings__styles.setBackground",
    backgroundImage: "x1g0zcm7",
    $$css: true
  }, {
    "--backgroundImage": `url("${background}")` != null ? `url("${background}")` : "initial"
  }],
  sofa: {
    "RoomSettings__styles.sofa": "RoomSettings__styles.sofa",
    minWidth: "x18n5i07",
    $$css: true
  }
};
const GET_METADATA = (0, _gql.gql)(`
  query MetaDataQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          avatarUrl
          roomName
          public
          requireAdminForWebcamAndMicrophone
          relayWebcamAndMicrophone
          relayServerRegion
          description
          autoAcceptJoins
          promoteNewUsersToAdmin
          requireLogin
          disableAutoplay
          disableWebcamAndMicrophone
          hideSidebarForNonAdmins
        }
      }
    }
  }
`);
const UPDATE_METADATA = (0, _gql.gql)(`
  mutation UpdateMetadata(
    $roomId: String!
    $autoAcceptJoins: Boolean
    $avatarFilename: String
    $description: String
    $disableAutoplay: Boolean
    $disableWebcamAndMicrophone: Boolean
    $hideSidebarForNonAdmins: Boolean
    $promoteNewUsersToAdmin: Boolean
    $public: Boolean
    $relayServerRegion: String
    $relayWebcamAndMicrophone: Boolean
    $requireAdminForWebcamAndMicrophone: Boolean
    $requireLogin: Boolean
    $roomName: String
  ) {
    updateMetadata(
      roomId: $roomId
      autoAcceptJoins: $autoAcceptJoins
      avatarFilename: $avatarFilename
      description: $description
      disableAutoplay: $disableAutoplay
      disableWebcamAndMicrophone: $disableWebcamAndMicrophone
      hideSidebarForNonAdmins: $hideSidebarForNonAdmins
      promoteNewUsersToAdmin: $promoteNewUsersToAdmin
      public: $public
      relayServerRegion: $relayServerRegion
      relayWebcamAndMicrophone: $relayWebcamAndMicrophone
      requireAdminForWebcamAndMicrophone: $requireAdminForWebcamAndMicrophone
      requireLogin: $requireLogin
      roomName: $roomName
    ) {
      ok
    }
  }
`);
const CLEAR_CHAT = (0, _gql.gql)(`
  mutation ClearChat($roomId: String!) {
    clearChat(roomId: $roomId) {
      ok
    }
  }
`);
const DESTROY_ROOM = (0, _gql.gql)(`
  mutation DestroyRoom($roomId: String!) {
    destroyRoom(roomId: $roomId) {
      ok
    }
  }
`);
const PermissionsSection = () => {
  var _a, _b;
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    data
  } = (0, _client.useQuery)(GET_METADATA, {
    variables: {
      roomId
    }
  });
  const metadata = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const [updateMetadata] = (0, _client.useMutation)(UPDATE_METADATA);
  const updateAdminPermissions = promoteNewUsersToAdmin => {
    updateMetadata({
      variables: {
        roomId,
        promoteNewUsersToAdmin
      }
    });
  };
  const updateRequireLogin = requireLogin => {
    updateMetadata({
      variables: {
        roomId,
        requireLogin
      }
    });
  };
  const updateHideSidebar = hideSidebarForNonAdmins => {
    updateMetadata({
      variables: {
        roomId,
        hideSidebarForNonAdmins
      }
    });
  };
  const updateRequireAdminForWebcamAndMicrophone = requireAdminForWebcamAndMicrophone => {
    updateMetadata({
      variables: {
        roomId,
        requireAdminForWebcamAndMicrophone
      }
    });
  };
  const updateDisableWebcamAndMicrophone = disableWebcamAndMicrophone => {
    updateMetadata({
      variables: {
        roomId,
        disableWebcamAndMicrophone
      }
    });
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Permissions",
    icon: "lock"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.InputGroup, null, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: updateAdminPermissions,
    checked: !!metadata.promoteNewUsersToAdmin,
    label: "Give new members admin permissions by default"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: updateRequireLogin,
    checked: !!metadata.requireLogin,
    label: "Only allow logged in users to join the room"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: updateHideSidebar,
    checked: !!metadata.hideSidebarForNonAdmins,
    label: "Hide Sidebar from non-admins"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: updateDisableWebcamAndMicrophone,
    checked: !!metadata.disableWebcamAndMicrophone,
    label: "Disable Webcams and Microphones"
  }), !metadata.disableWebcamAndMicrophone && /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: updateRequireAdminForWebcamAndMicrophone,
    checked: !!metadata.requireAdminForWebcamAndMicrophone,
    label: "Only allow admins to use their webcam and microphone"
  })));
};
const BanSection = () => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Bans",
    icon: "ban"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Bans"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "User Bans",
    icon: "ban"
  }, /* @__PURE__ */_react.default.createElement(_BannedMembersModal.BannedMembers, null)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "IP Bans",
    icon: "ban"
  }, /* @__PURE__ */_react.default.createElement(_BannedIpsModal.BannedIps, null))));
};
const SecuritySection = (0, _helpers.withDialogs)(({
  confirm
}) => {
  const roomId = (0, _useRoomId.useRoomId)();
  const [clearChat] = (0, _client.useMutation)(CLEAR_CHAT);
  const [destroyRoom] = (0, _client.useMutation)(DESTROY_ROOM);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Security",
    icon: "exclamation circle"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Security"
  }, /* @__PURE__ */_react.default.createElement(PermissionsSection, null), /* @__PURE__ */_react.default.createElement(BanSection, null), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    name: "Clear chat history",
    icon: "trash",
    onClick: () => __async(void 0, null, function* () {
      if (confirm && (yield confirm("This will permanently delete all messages in the room. Are you sure?"))) {
        yield clearChat({
          variables: {
            roomId
          }
        });
      }
    })
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "danger",
    name: "Destroy room",
    icon: "remove",
    onClick: () => __async(void 0, null, function* () {
      if (confirm && (yield confirm("This will permanently destroy the room and all it's data. Are you sure?"))) {
        yield destroyRoom({
          variables: {
            roomId
          }
        });
      }
    })
  })));
});
const AppearanceSection = (0, _helpers.withDialogs)(({
  alert
}) => {
  const {
    setValue,
    removeValue
  } = (0, _hooks.useSpacesActions)();
  const spacesState = (0, _useRoomData.useSpacesStateCached)() || {};
  const updateVal = (resetVal, f, key) => __async(void 0, null, function* () {
    if (resetVal) {
      removeValue(key);
      return true;
    }
    if (!f) return true;
    const data = new FormData();
    data.append("file", f);
    const response = yield fetch("https://img.kosmi.io/", {
      method: "POST",
      body: data
    });
    const response_json = yield response.json();
    const avatarFilename = response_json.filename;
    try {
      yield setValue(key, "https://img.kosmi.io/" + avatarFilename);
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updateTvPlaceholder = () => {
    const result = updateVal(resetTVPlaceholder, tvPlaceholderFile, "tvPlaceholder");
    setTVPlaceholderFile(null);
    return result;
  };
  const updateBackground = () => {
    const result = updateVal(resetBackground, backgroundFile, "background");
    setBackgroundFile(null);
    return result;
  };
  const updateSofa = () => {
    const result = updateVal(resetSofa, sofaFile, "sofa");
    setSofaFile(null);
    return result;
  };
  const [tvPlaceholderFile, setTVPlaceholderFile] = _react.default.useState(null);
  const [sofaFile, setSofaFile] = _react.default.useState(null);
  const [resetTVPlaceholder, setResetTVPlaceholder] = _react.default.useState(false);
  const ResetButton = ({
    onClick
  }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    style: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 12,
      marginBottom: 12
    },
    onClick
  }, "Reset to default");
  const [resetBackground, setResetBackground] = _react.default.useState(false);
  const [resetSofa, setResetSofa] = _react.default.useState(false);
  const [backgroundFile, setBackgroundFile] = _react.default.useState(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Appearance",
    icon: "paint brush"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Appearance"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Theme",
    icon: "paint brush"
  }, /* @__PURE__ */_react.default.createElement(_app.Themes, null)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Customize",
    icon: "object group"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Appearance"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "TV Placeholder",
    icon: "tv",
    onSave: updateTvPlaceholder,
    onBack: () => setTVPlaceholderFile(null)
  }, /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    src: !resetTVPlaceholder && spacesState.tvPlaceholder || /* @__PURE__ */_react.default.createElement(_app.DefaultTVPlaceholder, null),
    onFile: f => {
      setResetTVPlaceholder(false);
      setTVPlaceholderFile(f);
    },
    forceDefault: resetTVPlaceholder
  }), !tvPlaceholderFile && spacesState.tvPlaceholder && /* @__PURE__ */_react.default.createElement(ResetButton, {
    onClick: () => setResetTVPlaceholder(true)
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Room background",
    icon: "image outline",
    onSave: updateBackground
  }, /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    src: !resetBackground && spacesState.background || /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: [styles.background, styles.setBackground(_bg.default)]
    }),
    onFile: f => {
      setResetBackground(false);
      setBackgroundFile(f);
    },
    forceDefault: resetBackground
  }), !backgroundFile && spacesState.background && /* @__PURE__ */_react.default.createElement(ResetButton, {
    onClick: () => setResetBackground(true)
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Sofa",
    icon: "window minimize",
    onSave: updateSofa
  }, /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    src: !resetSofa && spacesState.sofa || /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.sofa
    }, /* @__PURE__ */_react.default.createElement(_sofa.ReactComponent, null)),
    onFile: f => {
      setResetSofa(false);
      setSofaFile(f);
    },
    forceDefault: resetSofa
  }), !backgroundFile && spacesState.sofa && /* @__PURE__ */_react.default.createElement(ResetButton, {
    onClick: () => setResetSofa(true)
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: val => __async(void 0, null, function* () {
      setValue("enableSofa", !val);
    }),
    checked: spacesState.enableSofa === false,
    label: "Hide Sofa"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Color Scheme",
    icon: "eye dropper"
  }, /* @__PURE__ */_react.default.createElement(_app.ColorSchemeSelector, null))))));
});
var _default = exports.default = (0, _helpers.withDialogs)(({
  confirm,
  alert
}) => {
  var _a, _b;
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    data
  } = (0, _client.useQuery)(GET_METADATA, {
    variables: {
      roomId
    }
  });
  const metadata = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const [updateMetadata] = (0, _client.useMutation)(UPDATE_METADATA);
  const [roomName, setRoomName] = (0, _react.useState)(metadata.roomName || "");
  const [description, setDescription] = (0, _react.useState)(metadata.description || "");
  const [avatarFile, setAvatarFile] = _react.default.useState(null);
  const currentUser = (0, _helpers.currentUserHook)();
  const setPublic = publicRoom => {
    updateMetadata({
      variables: {
        roomId,
        public: publicRoom
      }
    });
  };
  const updateAvatar = () => __async(void 0, null, function* () {
    try {
      if (!avatarFile) return true;
      const data2 = new FormData();
      data2.append("file", avatarFile);
      const response = yield fetch("https://img.kosmi.io/", {
        method: "POST",
        body: data2
      });
      const response_json = yield response.json();
      const avatarFilename = response_json.filename;
      yield updateMetadata({
        variables: {
          roomId,
          avatarFilename
        }
      });
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updateName = () => __async(void 0, null, function* () {
    if (roomName) {
      updateMetadata({
        variables: {
          roomId,
          roomName
        }
      });
      return true;
    }
    return false;
  });
  (0, _react.useEffect)(() => {
    setRoomName(metadata.roomName || "");
    setDescription(metadata.description || "");
    setAvatarFile(null);
  }, [metadata.roomName, metadata.description]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Room settings",
    dark: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Avatar",
    avatarUrl: metadata.avatarUrl || void 0,
    onSave: updateAvatar,
    icon: "user circle"
  }, /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    circular: true,
    src: metadata.avatarUrl || "",
    onFile: f => setAvatarFile(f)
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Room name",
    onSave: updateName,
    value: metadata.roomName || void 0,
    icon: "pencil",
    onBack: () => {
      setRoomName(metadata.roomName || "");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    setValue: setRoomName,
    name: "Display name",
    value: roomName
  })), metadata.public && /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Room Description",
    icon: "pen square",
    onSave: () => __async(void 0, null, function* () {
      yield updateMetadata({
        variables: {
          roomId,
          description
        }
      });
      return true;
    }),
    onBack: () => {
      setDescription(metadata.description || "");
    },
    value: metadata.description
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.TextArea, {
    name: "Room description",
    value: (description == null ? metadata.description : description) || "",
    setValue: setDescription
  })), /* @__PURE__ */_react.default.createElement(AppearanceSection, null), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Visibility",
    value: metadata.public ? "Public" : "Private",
    icon: "eye"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.InputGroup, null, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: val => __async(void 0, null, function* () {
      if (val && !(currentUser == null ? void 0 : currentUser.emailConfirmed) && alert) {
        alert("Please confirm your email");
        return;
      }
      if (val && confirm && (yield confirm("Please be aware that any explicit content(18+) is not allowed in public rooms. A violation of this rule may result in a permanent ban."))) {
        setPublic(val);
      } else {
        setPublic(val);
      }
    }),
    checked: !!metadata.public,
    label: "Show room in the public lobby"
  }))), /* @__PURE__ */_react.default.createElement(SecuritySection, null));
});