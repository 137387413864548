"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _uikit = require("@/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  wrapper: {
    "ImageZoomer__styles.wrapper": "ImageZoomer__styles.wrapper",
    position: "x1n2onr6",
    cursor: "x1ypdohk",
    display: "x3nfvp2",
    margin: "xnfbntj",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "x1hkcv85",
    minHeight: "xz65tgg",
    $$css: true
  },
  overlay: {
    "ImageZoomer__styles.overlay": "ImageZoomer__styles.overlay",
    position: "x10l6tqk",
    height: "x5yr21d",
    width: "xh8yej3",
    background: "xqz86ws",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    opacity: "xg01cxk",
    transition: "x1jrjasc",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    fontSize: "x176odoi",
    $$css: true
  },
  hovered: {
    "ImageZoomer__styles.hovered": "ImageZoomer__styles.hovered",
    opacity: "x1hc1fzr",
    $$css: true
  }
};
const ImageZoomer = ({
  children,
  onClick
}) => {
  const [isHovered, setIsHovered] = _react.default.useState(false);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.wrapper,
    onClick,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false)
  }, children, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.overlay, isHovered && styles.hovered]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "zoom icon"
  })));
};
var _default = exports.default = ImageZoomer;