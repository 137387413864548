"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckboxInner = exports.Checkbox = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Html = _interopRequireDefault(require("./Html"));
var _Icon = require("./Icon");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  checkBoxWrapperStyles: {
    "Checkbox__styles.checkBoxWrapperStyles": "Checkbox__styles.checkBoxWrapperStyles",
    display: "x78zum5",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontSize: "xif65rj",
    flexDirection: "x1q0g3np",
    cursor: "x1ypdohk",
    $$css: true
  },
  checkboxInnerStyles: {
    "Checkbox__styles.checkboxInnerStyles": "Checkbox__styles.checkboxInnerStyles",
    display: "x78zum5",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    flexDirection: "x1q0g3np",
    $$css: true
  },
  checkBoxLabel: {
    "Checkbox__styles.checkBoxLabel": "Checkbox__styles.checkBoxLabel",
    color: "x1heor9g",
    fontFamily: "xjb2p0i",
    $$css: true
  },
  switch: {
    "Checkbox__styles.switch": "Checkbox__styles.switch",
    backgroundColor: "x14xb6xe",
    width: "x100vrsf",
    height: "x1kpxq89",
    borderRadius: "x4pepcl",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "x1rea2x4",
    overflowX: null,
    overflowY: null,
    position: "x1n2onr6",
    $$css: true
  },
  switchOn: {
    "Checkbox__styles.switchOn": "Checkbox__styles.switchOn",
    backgroundColor: "x1ut2sa4",
    $$css: true
  },
  switchCircle: {
    "Checkbox__styles.switchCircle": "Checkbox__styles.switchCircle",
    color: "x1awj2ng",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "x1qx5ct2",
    width: "xw4jnvo",
    position: "x10l6tqk",
    backgroundColor: "x14xb6xe",
    top: "x1fo6t33",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    transition: "x1sspk41",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    boxShadow: "x18yb0xl",
    $$css: true
  },
  switchCircleOn: {
    "Checkbox__styles.switchCircleOn": "Checkbox__styles.switchCircleOn",
    left: "x1nrll8i",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  checkbox: {
    "Checkbox__styles.checkbox": "Checkbox__styles.checkbox",
    backgroundColor: "x128cn8a",
    height: "x1qx5ct2",
    width: "xw4jnvo",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  }
};
const Switch = (0, _react.memo)(({
  disabled,
  onChange,
  value
}) => {
  const toggle = (0, _react.useCallback)(() => {
    if (!disabled) {
      if (onChange) {
        onChange(!value);
      }
    }
  }, [disabled, onChange, value]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.switch, value && styles.switchOn],
    onClick: toggle
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.switchCircle, value && styles.switchCircleOn]
  }));
});
const CheckboxInner = exports.CheckboxInner = (0, _react.memo)(({
  disabled,
  onChange,
  value
}) => {
  const toggle = (0, _react.useCallback)(() => {
    if (!disabled) {
      if (onChange) {
        onChange(!value);
      }
    }
  }, [disabled, onChange, value]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkbox,
    onClick: toggle
  }, /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    color: "white",
    name: value ? "check" : "check-empty"
  }));
});
const Checkbox = exports.Checkbox = (0, _react.memo)(({
  onChange,
  checked: _checked,
  defaultChecked,
  label,
  disabled,
  toggle,
  className
}) => {
  const checked = _checked === void 0 ? defaultChecked : _checked;
  const ref = _react.default.useRef(null);
  (0, _react.useEffect)(() => {
    if (ref.current) {
      ;
      ref.current.className = stylex.props(className).className;
    }
  }, [className]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref,
    style: styles.checkBoxWrapperStyles,
    onClick: _ => {
      if (onChange) {
        onChange(null, {
          checked: !checked
        });
      }
    }
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkBoxWrapperStyles
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkboxInnerStyles
  }, toggle ? /* @__PURE__ */_react.default.createElement(Switch, {
    disabled,
    onChange: val => {
      const data = {
        checked: val
      };
      if (onChange) {
        onChange(null, data);
      }
    },
    value: checked === true
  }) : /* @__PURE__ */_react.default.createElement(CheckboxInner, {
    disabled,
    onChange: val => {
      const data = {
        checked: val
      };
      if (onChange) {
        onChange(null, data);
      }
    },
    value: checked === true
  })), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkBoxLabel
  }, label)));
});