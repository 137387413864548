"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Container = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  container: {
    "Container__styles.container": "Container__styles.container",
    display: "x1lliihq",
    maxWidth: "x193iq5w",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  textContainer: {
    "Container__styles.textContainer": "Container__styles.textContainer",
    fontFamily: "xy3j3os",
    lineHeight: "x1evy7pa",
    maxWidth: "x193iq5w",
    width: "x14rvwrp",
    fontSize: "xi9rq2s",
    $$css: true
  }
};
const Container = exports.Container = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    text,
    className,
    children
  } = t0;
  const t1 = text && styles.textContainer;
  let t2;
  if ($[0] !== className || $[1] !== t1) {
    t2 = [styles.container, t1, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== children || $[4] !== t2 || $[5] !== text) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t2
    }, text, children);
    $[3] = children;
    $[4] = t2;
    $[5] = text;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  return t3;
});