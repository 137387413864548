"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@apollo/client");
var _interactjs = _interopRequireDefault(require("interactjs"));
var _Dimensions = require("@/shared/components/Dimensions");
var _WebRTCUserVideoTrack = require("@/room/components/WebRTCUserVideoTrack");
var _ReactFitText = _interopRequireDefault(require("@/shared/components/ReactFitText"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var _useRoomId = require("../hooks/useRoomId");
var _useMembersData = require("../hooks/useMembersData");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const DIAMETER = 25;
const MIN_DIAMETER = 15;
function getCoordsForIndex(index) {
  index = index % 8;
  const iteration = Math.floor(index / 4);
  let coordMultiplier = 100;
  for (let i = 0; i < iteration; i++) {
    coordMultiplier = coordMultiplier / 2;
  }
  const coords = [[coordMultiplier, 20], [100, coordMultiplier], [coordMultiplier === 100 ? 0 : coordMultiplier, 100], [0, coordMultiplier == 100 ? 0 : coordMultiplier]];
  return coords[index % 4];
}
const styles = {
  webcamOverlayItem: {
    "WebcamOverlay__styles.webcamOverlayItem": "WebcamOverlay__styles.webcamOverlayItem",
    pointerEvents: "x71s49j",
    position: "x10l6tqk",
    opacity: "x27vdmw",
    transform: "x11lhmoz",
    cursor: "xv5twrn",
    borderRadius: "xudqyn7",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    zIndex: "xhtitgo",
    borderWidth: "xf0lols",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x1h59c62",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    ":hover_backgroundColor": "x16zhmgw",
    ":hover_borderStyle": "x1gkvz1f",
    ":hover_borderInlineStyle": null,
    ":hover_borderInlineStartStyle": null,
    ":hover_borderLeftStyle": null,
    ":hover_borderInlineEndStyle": null,
    ":hover_borderRightStyle": null,
    ":hover_borderBlockStyle": null,
    ":hover_borderTopStyle": null,
    ":hover_borderBottomStyle": null,
    ":hover_borderWidth": "x1052x0u",
    ":hover_borderInlineWidth": null,
    ":hover_borderInlineStartWidth": null,
    ":hover_borderLeftWidth": null,
    ":hover_borderInlineEndWidth": null,
    ":hover_borderRightWidth": null,
    ":hover_borderBlockWidth": null,
    ":hover_borderTopWidth": null,
    ":hover_borderBottomWidth": null,
    ":hover_borderColor": "x17wnsh",
    ":hover_borderInlineColor": null,
    ":hover_borderInlineStartColor": null,
    ":hover_borderLeftColor": null,
    ":hover_borderInlineEndColor": null,
    ":hover_borderRightColor": null,
    ":hover_borderBlockColor": null,
    ":hover_borderTopColor": null,
    ":hover_borderBottomColor": null,
    $$css: true
  },
  dragging: {
    "WebcamOverlay__styles.dragging": "WebcamOverlay__styles.dragging",
    backgroundColor: "xsjsedy",
    borderStyle: "xha96y3",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderWidth: "xf0lols",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  webcamOverlayItemDynamic: (top, left, circleDiameter) => [{
    "WebcamOverlay__styles.webcamOverlayItemDynamic": "WebcamOverlay__styles.webcamOverlayItemDynamic",
    width: "x17fnjtu",
    height: "x1jwls1v",
    top: "xrzbxsz",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(circleDiameter),
    "--height": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(circleDiameter),
    "--top": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(top),
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(left)
  }],
  hidden: {
    "WebcamOverlay__styles.hidden": "WebcamOverlay__styles.hidden",
    display: "x1s85apg",
    $$css: true
  },
  video: {
    "WebcamOverlay__styles.video": "WebcamOverlay__styles.video",
    width: "xh8yej3",
    height: "x5yr21d",
    borderWidth: "xf0lols",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    objectFit: "xl1xv1r",
    $$css: true
  },
  displayName: {
    "WebcamOverlay__styles.displayName": "WebcamOverlay__styles.displayName",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    width: "xh8yej3",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "x1jjfqgs",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    zIndex: "x1ja2u2z",
    objectFit: "x19kjcj4",
    display: "x78zum5",
    textAlign: "x2b8uid",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    fontSize: "x1l97dnv",
    textShadow: "x16e0nh6",
    bottom: "x1ciegij",
    opacity: "x1ks1olk",
    $$css: true
  }
};
const WebcamItem = (0, _react.memo)(({
  onMove,
  diameter,
  coords,
  memberId,
  hidden,
  containerWidth,
  containerHeight
}) => {
  const elRef = (0, _react.useRef)(null);
  const [_itemDiameter, setItemDiameter] = (0, _react.useState)(0);
  const [dragging, setDragging] = (0, _react.useState)(false);
  const member = (0, _useMembersData.useMember)(memberId);
  const cachedVals = (0, _react.useRef)({
    itemDiameter: 0,
    maxY: 0
  });
  (0, _react.useEffect)(() => {
    const itemDiameter = _itemDiameter || DIAMETER;
    const el = elRef.current;
    let maxY = 100;
    let minY = 0;
    let maxX = 100;
    let minX = 0;
    const parentHeight = containerHeight;
    const parentWidth = containerWidth;
    if (el) {
      const itemRadius = (el == null ? void 0 : el.clientHeight) / 2 || 0;
      const elMaxTop = parentHeight - itemRadius;
      const elMaxTopPercent = elMaxTop / parentHeight * 100;
      minY = 100 - elMaxTopPercent;
      maxY = elMaxTopPercent;
      const elMaxLeft = parentWidth - itemRadius;
      const elMaxLeftPercent = elMaxLeft / parentWidth * 100;
      minX = 100 - elMaxLeftPercent;
      maxX = elMaxLeftPercent;
    }
    const circleDiameter2 = diameter * itemDiameter / 100;
    let [x, y] = coords;
    if (y < minY) {
      y = minY;
    }
    if (y > maxY) {
      y = maxY;
    }
    if (x < minX) {
      x = minX;
    }
    if (x > maxX) {
      x = maxX;
    }
    let left2 = x + "%";
    let top2 = y + "%";
    if (x >= 100) left2 = "calc(" + left2 + " - " + circleDiameter2 / 2 + "px)";else if (x <= 0) left2 = "calc(" + left2 + " + " + circleDiameter2 / 2 + "px)";
    if (y >= 100) top2 = "calc(" + top2 + " - " + circleDiameter2 / 2 + "px)";
    if (y <= 0) top2 = "calc(" + top2 + " + " + circleDiameter2 / 2 + "px)";
    cachedVals.current = {
      itemDiameter,
      maxY
    };
    setCircleDiameter(circleDiameter2);
    setTop(top2);
    setLeft(left2);
  }, [_itemDiameter, containerHeight, containerWidth, coords, diameter]);
  const [circleDiameter, setCircleDiameter] = (0, _react.useState)(0);
  const [top, setTop] = (0, _react.useState)("0%");
  const [left, setLeft] = (0, _react.useState)("0%");
  const onDrag = (0, _react.useCallback)(e => {
    var _a;
    const {
      itemDiameter,
      maxY
    } = cachedVals.current;
    const el = elRef.current;
    if (!el) return;
    const {
      clientX,
      clientY
    } = e;
    const {
      left: left2,
      top: top2
    } = ((_a = el.parentElement) == null ? void 0 : _a.getBoundingClientRect()) || {
      left: 0,
      top: 0
    };
    const x = clientX - left2;
    const y = clientY - top2;
    let xPercent = Math.min(Math.max(x / containerWidth * 100, 0), 100);
    if (xPercent < itemDiameter / 2) xPercent = 0;
    if (xPercent > 100 - itemDiameter / 2) xPercent = 100;
    let yPercent = Math.min(Math.max(y / containerHeight * 100, 0), 100);
    if (yPercent < itemDiameter / 2) yPercent = 0;
    if (yPercent > 100 - itemDiameter / 2) yPercent = 100;
    yPercent = Math.min(maxY, yPercent);
    onMove(memberId, [xPercent, yPercent]);
  }, [containerHeight, containerWidth, memberId, onMove]);
  (0, _react.useEffect)(() => {
    const el = elRef.current;
    let direction = "";
    if (!el) return;
    (0, _interactjs.default)(el).draggable({
      inertia: true
    }).resizable({
      edges: {
        left: true,
        right: true,
        bottom: true,
        top: true
      },
      listeners: {
        start: event => {
          setDragging(true);
          const targetRect = event.target.getBoundingClientRect();
          const relativeX = event.clientX - targetRect.left;
          const relativeY = event.clientY - targetRect.top;
          const widthThird = targetRect.width / 3;
          const heightThird = targetRect.height / 3;
          if (relativeX < widthThird && relativeY < heightThird) direction = "topLeft";else if (relativeX > 2 * widthThird && relativeY < heightThird) direction = "topRight";else if (relativeX < widthThird && relativeY > 2 * heightThird) direction = "bottomLeft";else if (relativeX > 2 * widthThird && relativeY > 2 * heightThird) direction = "bottomRight";else if (relativeY < heightThird) direction = "top";else if (relativeY > 2 * heightThird) direction = "bottom";else if (relativeX < widthThird) direction = "left";else if (relativeX > 2 * widthThird) direction = "right";
        },
        move: event => {
          setItemDiameter(Math.min(Math.max((direction === "top" || direction === "bottom" ? event.rect.height : event.rect.width) / diameter * 100, MIN_DIAMETER), 100));
          if (direction === "top") {
            onDrag({
              clientX: event.clientX,
              clientY: event.clientY + event.rect.height / 2
            });
          } else if (direction === "bottom") {
            onDrag({
              clientX: event.clientX,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "left") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY
            });
          } else if (direction === "right") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY
            });
          } else if (direction === "bottomLeft") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "bottomRight") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "topRight") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY + event.rect.height / 2
            });
          } else if (direction === "topLeft") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY + event.rect.height / 2
            });
          }
        },
        end: () => setDragging(false)
      },
      modifiers: [_interactjs.default.modifiers.restrictEdges({
        outer: "parent"
      })],
      inertia: true
    }).on("dragmove", e => {
      onDrag(e);
    });
    return () => {
      (0, _interactjs.default)(el).unset();
    };
  }, [containerHeight, containerWidth, diameter, onDrag]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    ref: elRef,
    style: [styles.webcamOverlayItem, styles.webcamOverlayItemDynamic(top, left, circleDiameter), dragging && styles.dragging, hidden && styles.hidden]
  }, /* @__PURE__ */_react.default.createElement(_WebRTCUserVideoTrack.WebRTCUserVideoTrack, {
    className: styles.video,
    userId: memberId
  }), /* @__PURE__ */_react.default.createElement(_ReactFitText.default, {
    compressor: 0.9
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.displayName
  }, (member == null ? void 0 : member.isAnonymous) ? "Anonymous" : (member == null ? void 0 : member.displayName) || (member == null ? void 0 : member.username))));
});
const CURRENT_USER = (0, _client.gql)(`
  query CurrentUserQuery2($roomId: String!) {
    currentUser {
      id
      connectionId
      user {
        id
        displayName
        username
        isAnonymous
      }
    }
    room(id: $roomId) {
      id
      state {
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const WebcamOverlay = (0, _react.memo)(({
  containerHeight,
  containerWidth,
  hidden
}) => {
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    data
  } = (0, _client.useQuery)(CURRENT_USER, {
    variables: {
      roomId
    }
  });
  const roomMemberIds = (0, _react.useMemo)(() => (data == null ? void 0 : data.room.state.members.map(m => m.user.id)) || [], [data == null ? void 0 : data.room.state.members]);
  const [positions, setPositions] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    setPositions(positions2 => {
      const positionMemberIds = Object.keys(positions2);
      const updatedPositions = __spreadValues({}, positions2);
      for (const userId of positionMemberIds) {
        if (roomMemberIds.indexOf(userId) === -1) {
          delete updatedPositions[userId];
        }
      }
      return updatedPositions;
    });
  }, [roomMemberIds]);
  const diameter = Math.min(containerWidth, containerHeight);
  const {
    webcamStreams
  } = (0, _react.useContext)(_RoomContext.default);
  const memberIds = Object.keys(webcamStreams || {});
  const onMove = (0, _react.useCallback)((memberId, xy) => {
    setPositions(prevPositions => __spreadProps(__spreadValues({}, prevPositions), {
      [memberId]: xy
    }));
  }, []);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, memberIds.map((mid, i) => {
    const coords = positions[mid] || getCoordsForIndex(i);
    return /* @__PURE__ */_react.default.createElement(WebcamItem, {
      key: mid,
      hidden,
      coords,
      diameter,
      containerWidth,
      containerHeight,
      memberId: mid,
      onMove
    });
  }));
});
var _default = exports.default = (0, _Dimensions.Dimensions)()(WebcamOverlay);