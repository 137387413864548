"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Html = _interopRequireDefault(require("../Html"));
var _Image = require("../Image");
var _momentMini = _interopRequireDefault(require("moment-mini"));
var _react = _interopRequireWildcard(require("react"));
var _Spotlight = _interopRequireDefault(require("../Spotlight"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _Button = require("../Button");
var _ContextMenu = require("../ContextMenu");
var _reactResponsive = require("react-responsive");
var _Premium = require("../../shared/components/Premium");
var _MessageContainer = _interopRequireDefault(require("./MessageContainer"));
var _helpers = require("../helpers");
var _Messagebox = _interopRequireDefault(require("./Messagebox"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const formatTimeOrDate = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : yourDate.format("YYYY-MM-DD");
};
const formatTime = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : `${yourDate.fromNow()} at ${yourDate.format("hh:mm")}`;
};
const TouchableOpacityWithClass = (0, _react.forwardRef)((_a, ref) => {
  var _b = _a,
    {
      className,
      onLongPress
    } = _b,
    props = __objRest(_b, ["className", "onLongPress"]);
  const innerRef = (0, _react.useRef)(null);
  const [isPressed, setIsPressed] = (0, _react.useState)(false);
  const [clicked, setClicked] = (0, _react.useState)(false);
  const callback = (0, _react.useCallback)(() => {
    setIsPressed(true);
  }, []);
  const {
    onTouchStart,
    onTouchEnd,
    onTouchMove
  } = (0, _helpers.useLongPress)(callback);
  (0, _react.useEffect)(() => {
    if (onLongPress && isPressed) {
      onLongPress();
      setClicked(false);
      setIsPressed(false);
    }
  }, [isPressed, onLongPress]);
  const refs = (0, _helpers.useMergedRefs)(ref, innerRef);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadValues({
    onTouchStart: e => {
      onTouchStart(e);
      setClicked(true);
      return false;
    },
    onTouchEnd: e => {
      onTouchEnd(e);
      setClicked(false);
      return false;
    },
    onTouchMove,
    style: [clicked && styles.scale, className],
    ref: refs
  }, props));
});
const styles = {
  diamond: {
    "index__styles.diamond": "index__styles.diamond",
    bottom: "x1ey2m1c",
    right: "x6smm1b",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "x1t8mvi6",
    fontSize: "x1j61zf2",
    position: "x10l6tqk",
    width: "x1fsd2vl",
    height: "x170jfvy",
    $$css: true
  },
  messageOptions: {
    "index__styles.messageOptions": "index__styles.messageOptions",
    position: "x10l6tqk",
    top: "x13vifvy",
    width: "x1pju0fl",
    height: "xg7h5cd",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  ownMessageOptions: {
    "index__styles.ownMessageOptions": "index__styles.ownMessageOptions",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  messageOptionsHasPrev: {
    "index__styles.messageOptionsHasPrev": "index__styles.messageOptionsHasPrev",
    paddingTop: "xexx8yu",
    $$css: true
  },
  scale: {
    "index__styles.scale": "index__styles.scale",
    transform: "x9mn55f",
    $$css: true
  },
  chatMessage: {
    "index__styles.chatMessage": "index__styles.chatMessage",
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    background: "x11g6tue",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "x1y1aw1k",
    paddingBottom: "x1120s5i",
    borderTopWidth: "x972fbf",
    lineHeight: "x1u7k74",
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    userSelect: "x87ps6o",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "xvr3rsr xlihjin",
    $$css: true
  },
  hasPrevMessage: {
    "index__styles.hasPrevMessage": "index__styles.hasPrevMessage",
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    $$css: true
  },
  avatar: {
    "index__styles.avatar": "index__styles.avatar",
    display: "x1lliihq",
    width: "xvy4d1p",
    height: "xxk0z11",
    float: "x1faq86j",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: "x1db2dqx",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x1n2onr6",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":active_transform": "xvruv2t",
    $$css: true
  },
  rubyGlow: {
    "index__styles.rubyGlow": "index__styles.rubyGlow",
    color: "xozz0dy",
    $$css: true
  },
  chatMessageMenu: {
    "index__styles.chatMessageMenu": "index__styles.chatMessageMenu",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  chatMessageMenuButtons: {
    "index__styles.chatMessageMenuButtons": "index__styles.chatMessageMenuButtons",
    display: "x78zum5",
    $$css: true
  },
  mobileTime: {
    "index__styles.mobileTime": "index__styles.mobileTime",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  avatarImg: {
    "index__styles.avatarImg": "index__styles.avatarImg",
    display: "x1lliihq",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xh8yej3",
    height: "x5yr21d",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  author: {
    "index__styles.author": "index__styles.author",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    color: "x11jfisy",
    fontWeight: "x117nqv4",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    width: "xeq5yr9",
    $$css: true
  },
  authorName: {
    "index__styles.authorName": "index__styles.authorName",
    maxWidth: "x1ncir08",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    cursor: "x1ypdohk",
    $$css: true
  },
  date: {
    "index__styles.date": "index__styles.date",
    display: "x78zum5",
    color: "xrkdtlx",
    fontSize: "xfifm61",
    marginBottom: "x12nagc",
    marginTop: "x1gslohp",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    ":last-child_marginRight": "x552jh6",
    ":last-child_marginInlineStart": null,
    ":last-child_marginInlineEnd": null,
    "@media (max-width: 1000px)_fontSize": "xzgahhe",
    $$css: true
  },
  text: {
    "index__styles.text": "index__styles.text",
    marginTop: "xr9ek0c",
    marginBottom: "xjpr12u",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    marginLeft: "x1gh5o4n",
    marginInlineStart: null,
    marginInlineEnd: null,
    overflowWrap: "xj0a0fe",
    flexDirection: "xdt5ytf",
    color: "x11jfisy",
    backgroundColor: "x8897o3",
    lineHeight: "xwn7fz2",
    userSelect: "x1hx0egp",
    WebkitUserSelect: "x1rh3ycu",
    borderRadius: "xgqmno8",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "xe8ttls",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "xf18ygs",
    paddingEnd: null,
    paddingRight: "xnuq7ks",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    paddingInlineStart: null,
    paddingInlineEnd: null,
    width: "xeq5yr9",
    maxWidth: "xv0phki",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_userSelect": "x1de8jf8",
    "@media (max-width: 1000px)_WebkitUserSelect": "x10d2bzd",
    $$css: true
  },
  emoji: {
    "index__styles.emoji": "index__styles.emoji",
    fontSize: "x1s3cmhv",
    $$css: true
  },
  isOwn: {
    "index__styles.isOwn": "index__styles.isOwn",
    backgroundColor: "x8qxh4v",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  }
};
const ChatMessageMenu = (0, _react.forwardRef)(({
  actions,
  className,
  onClick,
  onClose,
  time,
  styleProps
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref,
    style: [styles.chatMessageMenu, className],
    onClick,
    styleProps
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.mobileTime
  }, time), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.chatMessageMenuButtons
  }, actions.map((a, i) => a.onClick && /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "white",
    inverted: true,
    key: i,
    icon: a.icon,
    onClick: () => __async(void 0, null, function* () {
      if (a.onClick && (yield a.onClick())) {
        onClose();
      }
    }),
    style: {
      marginLeft: 4,
      marginRight: 4
    }
  }))));
});
const ChatMessageDate = (0, _react.memo)(({
  className,
  time,
  onClick
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    onClick,
    style: [styles.date, className]
  }, formatTimeOrDate(time));
});
const ChatMessageText = (0, _react.memo)(({
  children,
  isOwn,
  removeMessage,
  text,
  time,
  rootNode
}) => {
  const timeStr = formatTime(time);
  const isEmoji = Array.from(text).every(c => new RegExp("\\p{Emoji}", "u").test(c) && isNaN(parseInt(c)));
  const ref = (0, _react.useRef)(null);
  const [focused, setFocused] = _react.default.useState(false);
  const childrenRef = (0, _helpers.useMergedRefs)(ref);
  const clonedChildren = _react.default.cloneElement(children, {
    ref: childrenRef
  });
  const isHover = (0, _reactResponsive.useMediaQuery)({
    query: "(hover: hover) and (pointer: fine)"
  });
  const onLongPress = (0, _react.useCallback)(() => {
    setFocused(true);
  }, []);
  const outsideClickHandler = (0, _react.useCallback)(() => {
    setFocused(false);
  }, []);
  const closeMessageMenu = (0, _react.useCallback)(() => {
    setFocused(false);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_Spotlight.default, {
    brightness: 50,
    enabled: focused,
    onClickOutside: outsideClickHandler
  }, /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
    controlled: true,
    open: focused,
    content: /* @__PURE__ */_react.default.createElement(ChatMessageMenu, {
      time: timeStr,
      onClose: closeMessageMenu,
      actions: [{
        icon: "trash",
        text: "Remove message",
        onClick: removeMessage
      }, {
        icon: "copy",
        text: "Copy message",
        onClick: () => __async(void 0, null, function* () {
          navigator.clipboard.writeText(text);
          return true;
        })
      }]
    }),
    trigger: /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
      content: timeStr,
      position: "left",
      disabled: !isHover,
      mountNode: rootNode,
      trigger: /* @__PURE__ */_react.default.createElement(TouchableOpacityWithClass, {
        onLongPress,
        className: [styles.text, isOwn && styles.isOwn, isEmoji && styles.emoji]
      }, clonedChildren)
    })
  }));
});
const ChatMessageAuthor = (0, _react.memo)(({
  children,
  className,
  onClick,
  isSubscribed
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    onClick,
    style: [styles.author, isSubscribed && styles.rubyGlow, className]
  }, children);
});
const ChatMessageAvatar = (0, _react.memo)(({
  children,
  className,
  src,
  onClick
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    onClick,
    style: [styles.avatar, className]
  }, src && /* @__PURE__ */_react.default.createElement(_Image.Image, {
    className: styles.avatarImg,
    src,
    loading: "lazy"
  }), children);
});
const ChatMessageAuthorName = ({
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.authorName
  }, children);
};
const ChatMessage = (0, _react.memo)((0, _react.forwardRef)(({
  id,
  isOwn,
  removeMessage,
  hasPrevMessage,
  prevTime,
  time,
  content,
  rootNode,
  text,
  isSubscribed,
  viewUserProfile,
  avatarUrl,
  authorName,
  authorId
}, ref) => {
  const [showOptions, setShowOptions] = (0, _react.useState)(false);
  const [openOptions, setOpenOptions] = (0, _react.useState)(false);
  const isHover = (0, _reactResponsive.useMediaQuery)({
    query: "(hover: hover) and (pointer: fine)"
  });
  const hideTime = prevTime && time - prevTime < 3600;
  const toggleOptions = (0, _react.useCallback)(() => setOpenOptions(!openOptions), [openOptions]);
  const setOptionsOpen = (0, _react.useCallback)(() => setOpenOptions(true), []);
  const setOptionsClosed = (0, _react.useCallback)(() => setOpenOptions(false), []);
  const removeThisMessage = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    if (!removeMessage) return false;
    return yield removeMessage(id);
  }), [id, removeMessage]);
  const viewThisUserProfile = (0, _react.useCallback)(() => viewUserProfile(authorId), [authorId, viewUserProfile]);
  const onMouseEnter = (0, _react.useCallback)(() => isHover && setShowOptions(true), [isHover]);
  const onMouseLeave = (0, _react.useCallback)(() => isHover && setShowOptions(false), [isHover]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref,
    style: [styles.chatMessage, hasPrevMessage && styles.hasPrevMessage],
    onMouseEnter,
    onMouseLeave
  }, !hasPrevMessage && !hideTime && /* @__PURE__ */_react.default.createElement(ChatMessageDate, {
    time
  }), (showOptions || openOptions) && removeMessage && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.messageOptions, isOwn && styles.ownMessageOptions, hasPrevMessage && styles.messageOptionsHasPrev]
  }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu, {
    open: openOptions,
    onOpen: setOptionsOpen,
    onClose: setOptionsClosed,
    position: isOwn ? "center" : "left",
    trigger: /* @__PURE__ */_react.default.createElement(_Button.Button, {
      key: id,
      style: {
        borderWidth: 0
      },
      color: "white",
      inverted: true,
      icon: "ellipsis horizontal",
      onClick: toggleOptions
    })
  }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu.Item, {
    icon: "trash",
    text: "Delete Message",
    onClick: removeThisMessage
  }))), !hasPrevMessage && !isOwn && /* @__PURE__ */_react.default.createElement(ChatMessageAuthor, {
    isSubscribed,
    onClick: viewThisUserProfile
  }, /* @__PURE__ */_react.default.createElement(ChatMessageAvatar, {
    src: avatarUrl
  }, isSubscribed && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: styles.diamond
  })), /* @__PURE__ */_react.default.createElement(ChatMessageAuthorName, null, authorName)), /* @__PURE__ */_react.default.createElement(ChatMessageText, {
    isOwn,
    text,
    time,
    removeMessage: removeThisMessage,
    rootNode
  }, content));
}));
const ChatMessages = ({
  messages,
  nextPage,
  loading
}) => {
  const [rootNode, setRootNode] = (0, _react.useState)(void 0);
  const rootRef = (0, _react.useCallback)(node => {
    setRootNode(node);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_MessageContainer.default, {
    nextPage,
    loading,
    ref: rootRef
  }, rootNode && (messages == null ? void 0 : messages.map(({
    id,
    isOwn,
    time,
    hasPrevMessage,
    prevTime,
    text,
    avatarUrl,
    authorId,
    isSubscribed,
    authorName,
    content,
    removeMessage,
    viewUserProfile
  }) => {
    return /* @__PURE__ */_react.default.createElement(ChatMessage, {
      key: id,
      id,
      isOwn,
      time,
      hasPrevMessage,
      prevTime,
      text,
      rootNode,
      avatarUrl,
      authorId,
      isSubscribed,
      authorName,
      content,
      removeMessage,
      viewUserProfile
    });
  })));
};
ChatMessages.Messagebox = _Messagebox.default;
var _default = exports.default = ChatMessages;