"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSettingsRouter = exports.useSettingsLevel = exports.TextArea = exports.SettingsContextReset = exports.Section = exports.InputGroup = exports.Input = exports.Container = exports.Checkbox = exports.Button = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var _Image = require("./Image");
var _Button = require("./Button");
var _Input = require("./Input");
var _TextArea = require("./TextArea");
var _Checkbox = require("./Checkbox");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _TabView = require("./TabView");
var _reactDom = require("react-dom");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  wrapper: {
    "Settings__styles.wrapper": "Settings__styles.wrapper",
    display: "x78zum5",
    height: "x5yr21d",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    position: "x1n2onr6",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  header: {
    "Settings__styles.header": "Settings__styles.header",
    display: "x78zum5",
    justifyContent: "x1nhvcw1",
    alignItems: "x6s0dn4",
    height: "xwzfr38",
    width: "xh8yej3",
    $$css: true
  },
  topTitle: {
    "Settings__styles.topTitle": "Settings__styles.topTitle",
    fontSize: "xosj86m",
    fontWeight: "x1xlr1w8",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x10l6tqk",
    textAlign: "x2b8uid",
    $$css: true
  },
  settingsScreen: {
    "Settings__styles.settingsScreen": "Settings__styles.settingsScreen",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    minHeight: "x2lwn1j",
    width: "xh8yej3",
    maxWidth: "x193iq5w",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  dark: {
    "Settings__styles.dark": "Settings__styles.dark",
    backgroundColor: "xjlc4g5",
    $$css: true
  },
  settingsButton: {
    "Settings__styles.settingsButton": "Settings__styles.settingsButton",
    display: "x78zum5",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontWeight: "xk50ysn",
    height: "xng8ra",
    minHeight: "x879a55",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    justifyContent: "x1qughib",
    fontSize: "x1j61zf2",
    alignItems: "x6s0dn4",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    minWidth: "x1jzhcrs",
    width: "xm6i5cn",
    cursor: "x1ypdohk",
    color: "x11jfisy",
    ":active_backgroundColor": "xalcejg",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "@media (hover: hover) and (pointer: fine)_borderColor": "xssfd3w",
    "@media (hover: hover) and (pointer: fine)_borderInlineColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineStartColor": null,
    "@media (hover: hover) and (pointer: fine)_borderLeftColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineEndColor": null,
    "@media (hover: hover) and (pointer: fine)_borderRightColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBlockColor": null,
    "@media (hover: hover) and (pointer: fine)_borderTopColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBottomColor": null,
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x19a35c3",
    backgroundColor: "x1hhg5nt",
    $$css: true
  },
  inputWrapperWrapper: {
    "Settings__styles.inputWrapperWrapper": "Settings__styles.inputWrapperWrapper",
    display: "x78zum5",
    height: "xg7h5cd",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "x1uhho1l",
    paddingEnd: null,
    paddingRight: "x1xpa7k",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    flexDirection: "xdt5ytf",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    maxWidth: "x95b5qq",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  inputWrapper: {
    "Settings__styles.inputWrapper": "Settings__styles.inputWrapper",
    display: "x78zum5",
    height: "xnxb3zj",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  checkboxWrapper: {
    "Settings__styles.checkboxWrapper": "Settings__styles.checkboxWrapper",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    height: "xg7h5cd",
    justifyContent: "x1qughib",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    maxWidth: "x95b5qq",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  checkboxLabel: {
    "Settings__styles.checkboxLabel": "Settings__styles.checkboxLabel",
    fontWeight: "xk50ysn",
    fontSize: "x1j61zf2",
    $$css: true
  },
  inputGroup: {
    "Settings__styles.inputGroup": "Settings__styles.inputGroup",
    alignItems: "x6s0dn4",
    backgroundColor: "x1u50pdq",
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "xg7h5cd",
    justifyContent: "x1qughib",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    maxWidth: "x95b5qq",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  divider: {
    "Settings__styles.divider": "Settings__styles.divider",
    width: "xgqk73l",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: "xso031l",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  input: {
    "Settings__styles.input": "Settings__styles.input",
    display: "x78zum5",
    height: "x10wjd1d",
    fontSize: "x1j61zf2",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    maxWidth: "x193iq5w",
    backgroundColor: "x128cn8a",
    cursor: "x1ed109x",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  textArea: {
    "Settings__styles.textArea": "Settings__styles.textArea",
    height: "x1m3v4wt",
    $$css: true
  },
  withAvatar: {
    "Settings__styles.withAvatar": "Settings__styles.withAvatar",
    height: "xwzfr38",
    $$css: true
  },
  settingsButtonContent: {
    "Settings__styles.settingsButtonContent": "Settings__styles.settingsButtonContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  settingsButtonContentText: {
    "Settings__styles.settingsButtonContentText": "Settings__styles.settingsButtonContentText",
    display: "x78zum5",
    alignItems: "x1cy8zhl",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  settingsButtonContentTextInner: {
    "Settings__styles.settingsButtonContentTextInner": "Settings__styles.settingsButtonContentTextInner",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  settingsButtonIcon: {
    "Settings__styles.settingsButtonIcon": "Settings__styles.settingsButtonIcon",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    marginLeft: "xet2fuk",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1db2dqx",
    $$css: true
  },
  settingsButtonContentValue: {
    "Settings__styles.settingsButtonContentValue": "Settings__styles.settingsButtonContentValue",
    maxWidth: "x16q51m2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    opacity: "x197sbye",
    whiteSpace: "xuxw1ft",
    "@media (max-width: 1000px)_maxWidth": "x1hzmjh1",
    "@media (max-width: 1000px)_width": "xf80e3e",
    $$css: true
  },
  avatar: {
    "Settings__styles.avatar": "Settings__styles.avatar",
    width: "x1849jeq",
    height: "x1gnnpzl",
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  buttonWrapper: {
    "Settings__styles.buttonWrapper": "Settings__styles.buttonWrapper",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  rightArrow: {
    "Settings__styles.rightArrow": "Settings__styles.rightArrow",
    marginRight: "x1f5jaxz",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  danger: {
    "Settings__styles.danger": "Settings__styles.danger",
    borderColor: "x1wrw7br",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    color: "x1sr8853",
    $$css: true
  },
  success: {
    "Settings__styles.success": "Settings__styles.success",
    borderColor: "x13mpoqa",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    color: "x16yd4ao",
    $$css: true
  }
};
const Button = exports.Button = (0, _react.memo)(({
  name,
  avatarUrl,
  value,
  onClick,
  color,
  icon
}) => {
  const {
    dark
  } = (0, _react.useContext)(SettingsContext);
  return /* @__PURE__ */_react.default.createElement(_Button.Button, {
    onClick,
    color: "blue",
    className: [styles.settingsButton, !!dark && styles.dark, !!color && styles[color]]
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.settingsButtonContent
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.settingsButtonContentText
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.settingsButtonContentTextInner
  }, icon && typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    className: styles.settingsButtonIcon,
    name: icon
  }) : icon, " ", name)), value && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.settingsButtonContentValue
  }, value), avatarUrl && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.avatar
  }, avatarUrl && /* @__PURE__ */_react.default.createElement(_Image.Image, {
    src: avatarUrl,
    circular: true
  }))), /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    className: styles.rightArrow,
    name: "angle right"
  }));
});
const Section = exports.Section = (0, _react.memo)(({
  id,
  name,
  value,
  avatarUrl,
  icon,
  trigger,
  children,
  onSave,
  saveText,
  onBack,
  emphasizeSave
}) => {
  const {
    push,
    stack
  } = useSettingsRouter();
  const {
    parentName,
    pageContainer
  } = (0, _react.useContext)(SettingsContext);
  const level = useSettingsLevel();
  const identifier = (id || name) + `_${level}`;
  const isOpen = stack.some(s => s.id === identifier);
  const isOpenRef = (0, _react.useRef)(isOpen);
  const openSection = (0, _react.useCallback)(() => push({
    id: identifier,
    sectionProps: {
      name,
      value,
      avatarUrl,
      icon,
      trigger,
      onSave,
      saveText,
      emphasizeSave
    },
    level,
    parentName
  }), [avatarUrl, emphasizeSave, icon, identifier, level, name, onSave, parentName, push, saveText, trigger, value]);
  const openSectionRef = (0, _react.useRef)(openSection);
  (0, _react.useEffect)(() => {
    openSectionRef.current = openSection;
  }, [openSection]);
  const onClick = (0, _react.useCallback)(() => {
    openSection();
  }, [openSection]);
  (0, _react.useEffect)(() => {
    const openSection2 = openSectionRef.current;
    if (isOpen) {
      openSection2();
    }
  }, [name, value, avatarUrl, icon, parentName, level, saveText, emphasizeSave, isOpen]);
  (0, _react.useEffect)(() => {
    if (onBack && !isOpen && isOpenRef.current !== isOpen) {
      onBack();
    }
    isOpenRef.current = isOpen;
  }, [isOpen, onBack]);
  const triggerIsTrue = trigger === true;
  (0, _react.useEffect)(() => {
    if (triggerIsTrue && !isOpen) {
      onClick();
    }
  }, [triggerIsTrue, onClick, isOpen]);
  (0, _react.useEffect)(() => {
    if (!onSave) return;
    const currentItem = stack.find(s => s.id === identifier);
    if (currentItem == null ? void 0 : currentItem.sectionProps) {
      if (onSave) {
        currentItem.sectionProps.onSave = onSave;
      }
      if (onBack) {
        currentItem.sectionProps.onBack = onBack;
      }
    }
  }, [onSave, onBack, stack, identifier]);
  (0, _react.useEffect)(() => {
    if (trigger === true || trigger === false) return;
    const triggerRef = trigger;
    if (triggerRef && triggerRef.current) {
      triggerRef.current.addEventListener("click", onClick);
    }
    return () => {
      if (triggerRef && triggerRef.current) {
        triggerRef.current.removeEventListener("click", onClick);
      }
    };
  }, [trigger, onClick]);
  const content = isOpen && pageContainer && (0, _reactDom.createPortal)(children, pageContainer);
  let button = /* @__PURE__ */_react.default.createElement(Button, __spreadValues({}, {
    name,
    value,
    avatarUrl,
    icon,
    onClick
  }));
  if (trigger || typeof trigger === "boolean") {
    if (typeof trigger === "boolean" || (trigger == null ? void 0 : trigger.current)) {
      button = null;
    } else if (_react.default.isValidElement(trigger)) {
      button = _react.default.cloneElement(trigger, {
        onClick
      });
    }
  }
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, content, button);
});
const InputGroup = exports.InputGroup = (0, _react.memo)(({
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.inputGroup
  }, _react.default.Children.map(children, (child, i) => {
    return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, i > 0 && child && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.divider
    }), child);
  }));
});
const Checkbox = exports.Checkbox = (0, _react.memo)(({
  setChecked,
  checked,
  label
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkboxWrapper
  }, label && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.checkboxLabel
  }, label), /* @__PURE__ */_react.default.createElement(_Checkbox.Checkbox, {
    checked,
    toggle: true,
    onChange: (_e, {
      checked: checked2
    }) => setChecked(checked2)
  }));
});
const Input = exports.Input = (0, _react.memo)(({
  name,
  value,
  setValue,
  label,
  type,
  autoFocus
}) => {
  const settingsRouter = useSettingsRouter();
  const stack = settingsRouter.stack;
  const lastEl = stack[stack.length - 1];
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.inputWrapperWrapper
  }, /* @__PURE__ */_react.default.createElement(_Input.Input, {
    label,
    type,
    autoFocus: autoFocus !== false,
    wrapperClassName: styles.inputWrapper,
    className: styles.input,
    placeholder: name,
    value,
    onChange: e => setValue(e.target.value),
    onEnter: () => __async(void 0, null, function* () {
      var _a;
      if ((_a = lastEl == null ? void 0 : lastEl.sectionProps) == null ? void 0 : _a.onSave) {
        if (yield lastEl.sectionProps.onSave()) {
          settingsRouter.pop();
        }
      }
    })
  }));
});
const TextArea = exports.TextArea = (0, _react.memo)(({
  name,
  value,
  setValue
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.inputWrapperWrapper
  }, /* @__PURE__ */_react.default.createElement(_TextArea.TextArea, {
    className: [styles.input, styles.textArea],
    placeholder: name,
    value,
    onChange: e => setValue(e.target.value)
  }));
});
const SettingsContext = _react.default.createContext({
  settingsRouter: null,
  parentName: "",
  pageContainer: null,
  dark: false
});
const SettingsContextReset = ({
  children
}) => /* @__PURE__ */_react.default.createElement(SettingsContext.Provider, {
  value: {
    settingsRouter: null,
    parentName: "",
    pageContainer: null,
    dark: void 0
  }
}, children);
exports.SettingsContextReset = SettingsContextReset;
const TopNav = (0, _react.memo)(() => {
  const settingsRouter = useSettingsRouter();
  const currentItem = settingsRouter == null ? void 0 : settingsRouter.stack[(settingsRouter == null ? void 0 : settingsRouter.stack.length) - 1];
  const currentSectionProps = currentItem == null ? void 0 : currentItem.sectionProps;
  let backName = (currentItem == null ? void 0 : currentItem.parentName) || "";
  if (backName.length > 9) {
    backName = "Back";
  }
  const currentName = (currentSectionProps == null ? void 0 : currentSectionProps.name) || "";
  const onSave = (currentSectionProps == null ? void 0 : currentSectionProps.onSave) || null;
  const emphasizeSave = (currentSectionProps == null ? void 0 : currentSectionProps.emphasizeSave) || false;
  const saveText = (currentSectionProps == null ? void 0 : currentSectionProps.saveText) || null;
  const getSectionProps = (0, _react.useCallback)(() => currentSectionProps, [currentSectionProps]);
  const [loading, setLoading] = (0, _react.useState)(false);
  const popRouter = (0, _react.useCallback)(() => settingsRouter.pop(), [settingsRouter]);
  const handleSave = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const {
      onSave: onSave2
    } = getSectionProps();
    try {
      setLoading(true);
      if (onSave2 && (yield onSave2())) {
        settingsRouter.pop();
      }
    } finally {
      setLoading(false);
    }
  }), [getSectionProps, settingsRouter]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.header
  }, /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "var(--primary-light-20)",
    inverted: true,
    size: "xxl",
    onClick: popRouter,
    style: {
      borderStyle: "none",
      position: "absolute",
      padding: 0,
      left: 8,
      fontSize: 18
    }
  }, /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    name: "angle left"
  }), backName), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.topTitle
  }, currentName), onSave && /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "var(--primary-light-20)",
    inverted: true,
    size: "xxl",
    loading,
    onClick: handleSave,
    style: {
      borderStyle: "none",
      position: "absolute",
      padding: 0,
      right: 24,
      fontSize: 18,
      color: emphasizeSave ? "var(--secondary)" : "var(--primary-light-20)"
    }
  }, saveText ? saveText : "Save"));
});
const useSettingsRouter = () => {
  const [stack, setStack] = (0, _react.useState)([]);
  const {
    settingsRouter
  } = (0, _react.useContext)(SettingsContext);
  const pop = (0, _react.useCallback)((numElements = 1) => {
    if (stack.length === 0) return [];
    const newStack = [...stack];
    const poppedElements = [];
    for (let i = 0; i < numElements && newStack.length > 0; i++) {
      const poppedElement = newStack.pop();
      if (poppedElement) {
        poppedElements.push(poppedElement);
      }
    }
    setStack(newStack);
    return poppedElements;
  }, [stack]);
  const exists = (0, _react.useCallback)(key => {
    return !!stack.find(e => e.id === key);
  }, [stack]);
  const replace = (0, _react.useCallback)((key, el) => {
    setStack(prevStack => [...prevStack.map(e => {
      var _a;
      if (((_a = e == null ? void 0 : e.sectionProps) == null ? void 0 : _a.key) === key) {
        return el;
      }
      return e;
    })]);
  }, []);
  const push = (0, _react.useCallback)(el => {
    if ((el == null ? void 0 : el.id) && exists(el.id)) {
      replace(el.id, el);
    } else {
      setStack(prevStack => [...prevStack.filter(e => (e == null ? void 0 : e.id) !== (el == null ? void 0 : el.id)), el]);
    }
  }, [exists, replace]);
  if (settingsRouter) return __spreadProps(__spreadValues({}, settingsRouter), {
    isRoot: false
  });
  return {
    pop,
    push,
    replace,
    stack,
    topItem: stack[stack.length - 1],
    isRoot: true,
    level: 0
  };
};
exports.useSettingsRouter = useSettingsRouter;
const useSettingsLevel = () => {
  const settingsRouter = useSettingsRouter();
  return settingsRouter.level;
};
exports.useSettingsLevel = useSettingsLevel;
const Container = ({
  children,
  name: parentName,
  dark: darkProp
}) => {
  const {
    dark
  } = (0, _react.useContext)(SettingsContext);
  const settingsRouter = useSettingsRouter();
  const level = settingsRouter.level + 1;
  const pageOpen = settingsRouter.stack.filter(p => p.level === level).length > 0;
  const [pageContainer, setPageContainer] = (0, _react.useState)(null);
  return /* @__PURE__ */_react.default.createElement(SettingsContext.Provider, {
    value: {
      settingsRouter: __spreadProps(__spreadValues({}, settingsRouter), {
        level
      }),
      parentName,
      pageContainer,
      dark: darkProp || dark
    }
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.wrapper
  }, /* @__PURE__ */_react.default.createElement(_TabView.TabView, {
    forceIndex: pageOpen ? 1 : 0,
    menu: false
  }, /* @__PURE__ */_react.default.createElement(_TabView.TabView.Item, {
    name: "Settings"
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.settingsScreen
  }, children)), /* @__PURE__ */_react.default.createElement(_TabView.TabView.Item, {
    name: "Subpage"
  }, settingsRouter.isRoot && /* @__PURE__ */_react.default.createElement(TopNav, null), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref: setPageContainer,
    style: styles.settingsScreen
  })))));
};
exports.Container = Container;