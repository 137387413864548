"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _uikit = require("@/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Settings = require("@/shared/components/Settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  header: {
    "SettingsModal__styles.header": "SettingsModal__styles.header",
    display: "x78zum5",
    $$css: true
  },
  content: {
    "SettingsModal__styles.content": "SettingsModal__styles.content",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  }
};
var _default = ({
  trigger
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    large: true,
    trigger
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.header
  }, "App Settings"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, {
    style: styles.content
  }, /* @__PURE__ */_react.default.createElement(_Settings.AppSettings, {
    dark: true
  })));
};
exports.default = _default;