"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Content = exports.Actions = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Settings = require("./Settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const fadeIn = "xekv6nw-B";
const scale = "xuza879-B";
const slideUp = "x1qilh8d-B";
const Header = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(8);
  let children;
  let props;
  if ($[0] !== t0) {
    _a = t0, {
      children
    } = _a, props = __objRest(_a, ["children"]);
    $[0] = t0;
    $[1] = children;
    $[2] = props;
  } else {
    children = $[1];
    props = $[2];
  }
  let t1;
  if ($[3] !== props.style) {
    t1 = [styles.header, props.style];
    $[3] = props.style;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  let t2;
  if ($[5] !== children || $[6] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t1
    }, children);
    $[5] = children;
    $[6] = t1;
    $[7] = t2;
  } else {
    t2 = $[7];
  }
  return t2;
};
const styles = {
  modal: {
    "Modal__styles.modal": "Modal__styles.modal",
    position: "x10l6tqk",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    backgroundColor: "x1d1vqyr",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    color: "x11jfisy",
    boxShadow: "xvxy23r",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "xg7h5cd",
    maxHeight: "x179dxpb",
    minHeight: "xz65tgg",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    userSelect: "x87ps6o",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    maxWidth: "xapjn7j",
    width: "xh8yej3",
    animationName: "x1oyg3g3",
    animationTimingFunction: "x1w258dv",
    animationDuration: "x5hsz1j",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "@media (max-width: 1000px)_maxWidth": "xde8ll5",
    "@media (max-width: 1000px)_maxHeight": "x85exu9",
    "::backdrop_background": "xqz7dei",
    "::backdrop_backgroundAttachment": null,
    "::backdrop_backgroundClip": null,
    "::backdrop_backgroundColor": null,
    "::backdrop_backgroundImage": null,
    "::backdrop_backgroundOrigin": null,
    "::backdrop_backgroundPosition": null,
    "::backdrop_backgroundPositionX": null,
    "::backdrop_backgroundPositionY": null,
    "::backdrop_backgroundRepeat": null,
    "::backdrop_backgroundSize": null,
    "::backdrop_animationDuration": "x1haz01c",
    "::backdrop_animationName": "xwtu3cs",
    $$css: true
  },
  content: {
    "Modal__styles.content": "Modal__styles.content",
    display: "x78zum5",
    overflow: "x1rea2x4",
    overflowX: null,
    overflowY: null,
    transition: "x1hhud1w",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    height: "xg7h5cd",
    minHeight: "x2lwn1j",
    fontSize: "xrv4cvt",
    lineHeight: "x37zpob",
    padding: "xis3749",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    justifyContent: "xl56j7k",
    $$css: true
  },
  largeInner: {
    "Modal__styles.largeInner": "Modal__styles.largeInner",
    height: "x5yr21d",
    $$css: true
  },
  dialog: {
    "Modal__styles.dialog": "Modal__styles.dialog",
    maxWidth: "xrlsmeg",
    $$css: true
  },
  large: {
    "Modal__styles.large": "Modal__styles.large",
    height: "x5yr21d",
    "@media (max-width: 1000px)_maxWidth": "xq1f2kx",
    "@media (max-width: 1000px)_animationDuration": "x1hzw2c3",
    "@media (max-width: 1000px)_animationName": "x1uh1ue0",
    $$css: true
  },
  huge: {
    "Modal__styles.huge": "Modal__styles.huge",
    height: "x5yr21d",
    maxWidth: "xted6l9",
    maxHeight: "xhcnd13",
    "@media (max-width: 1000px)_maxWidth": "xq1f2kx",
    "@media (max-width: 1000px)_animationName": "x1uh1ue0",
    "@media (max-width: 1000px)_animationDuration": "x1hzw2c3",
    $$css: true
  },
  actions: {
    "Modal__styles.actions": "Modal__styles.actions",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x4couov",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x11jfisy",
    fontSize: "x1k87nke",
    lineHeight: "xcsx53u",
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    justifyContent: "x13a6bvl",
    $$css: true
  },
  header: {
    "Modal__styles.header": "Modal__styles.header",
    maxHeight: "x1jquxbb",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x73anc6",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontSize: "xobrv83",
    lineHeight: "xcsx53u",
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  main: {
    "Modal__styles.main": "Modal__styles.main",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  closeIcon: {
    "Modal__styles.closeIcon": "Modal__styles.closeIcon",
    position: "x10l6tqk",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    width: "x1247r65",
    height: "xng8ra",
    fontSize: "x1pvqxga",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    cursor: "x1ypdohk",
    zIndex: "xhtitgo",
    transition: "x1trvaba",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":active_transform": "xvruv2t",
    $$css: true
  }
};
const Modal = t0 => {
  const $ = (0, _compilerRuntime.c)(49);
  const {
    ref,
    children,
    dialog,
    onClose,
    open: propsOpen,
    preventClosing,
    trigger,
    style,
    onModal,
    onOpen,
    large,
    huge
  } = t0;
  const [open, setOpen] = (0, _react.useState)(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      onClose: _temp,
      onOpen: _temp2,
      onModal: _temp3
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const handlerRefs = (0, _react.useRef)(t1);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = () => {
      const {
        onClose: onClose_0
      } = handlerRefs.current;
      setOpen(false);
      if (onClose_0) {
        onClose_0();
      }
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const close = t2;
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = e => {
      e.preventDefault();
      e.stopPropagation();
      close();
    };
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  const closeHandler = t3;
  const dialogRef = (0, _react.useRef)(null);
  let t4;
  let t5;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = () => ({
      close
    });
    t5 = [close];
    $[3] = t4;
    $[4] = t5;
  } else {
    t4 = $[3];
    t5 = $[4];
  }
  (0, _react.useImperativeHandle)(ref, t4, t5);
  let t6;
  let t7;
  if ($[5] !== onClose || $[6] !== onModal || $[7] !== onOpen) {
    t6 = () => {
      handlerRefs.current = {
        onClose,
        onOpen,
        onModal
      };
    };
    t7 = [onOpen, onClose, onModal];
    $[5] = onClose;
    $[6] = onModal;
    $[7] = onOpen;
    $[8] = t6;
    $[9] = t7;
  } else {
    t6 = $[8];
    t7 = $[9];
  }
  (0, _react.useEffect)(t6, t7);
  let t8;
  let t9;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = () => {
      const observer = new IntersectionObserver(entries => {
        var _a;
        const entry = entries[0];
        if (!entry.isIntersecting) {
          (_a = dialogRef.current) == null ? void 0 : _a.close();
        }
      }, {
        root: null,
        threshold: 0.1
      });
      if (dialogRef.current) {
        observer.observe(dialogRef.current);
      }
      return () => {
        observer.disconnect();
      };
    };
    t9 = [];
    $[10] = t8;
    $[11] = t9;
  } else {
    t8 = $[10];
    t9 = $[11];
  }
  (0, _react.useEffect)(t8, t9);
  let t10;
  let t11;
  if ($[12] !== open || $[13] !== propsOpen) {
    t10 = () => {
      const {
        onOpen: onOpen_0
      } = handlerRefs.current;
      const dialogNode = dialogRef.current;
      if (!dialogNode) {
        return;
      }
      dialogNode.onclose = closeHandler;
      if (open || propsOpen) {
        dialogNode.inert = true;
        dialogNode.showModal();
        dialogNode.inert = false;
        if (onOpen_0) {
          onOpen_0();
        }
      } else {
        dialogNode.close();
      }
    };
    t11 = [open, propsOpen, closeHandler];
    $[12] = open;
    $[13] = propsOpen;
    $[14] = t10;
    $[15] = t11;
  } else {
    t10 = $[14];
    t11 = $[15];
  }
  (0, _react.useEffect)(t10, t11);
  let t12;
  if ($[16] !== preventClosing) {
    t12 = () => {
      const dialogNode_0 = dialogRef.current;
      if (!dialogNode_0) {
        return;
      }
      dialogNode_0.onclick = function (event) {
        const rect = dialogNode_0.getBoundingClientRect();
        const isInDialog = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
        const dialogs = document.querySelectorAll("dialog[open]");
        const topDialog = dialogs[dialogs.length - 1];
        if (!isInDialog && !preventClosing && topDialog === dialogNode_0) {
          dialogNode_0.close();
        }
      };
    };
    $[16] = preventClosing;
    $[17] = t12;
  } else {
    t12 = $[17];
  }
  let t13;
  if ($[18] !== open || $[19] !== preventClosing || $[20] !== propsOpen) {
    t13 = [open, propsOpen, preventClosing];
    $[18] = open;
    $[19] = preventClosing;
    $[20] = propsOpen;
    $[21] = t13;
  } else {
    t13 = $[21];
  }
  (0, _react.useEffect)(t12, t13);
  let t14;
  let t15;
  if ($[22] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = () => {
      const {
        onModal: onModal_0
      } = handlerRefs.current;
      const dialogNode_1 = dialogRef.current;
      if (onModal_0) {
        onModal_0({
          handleClose: () => {
            dialogNode_1 == null ? void 0 : dialogNode_1.close();
          }
        });
      }
    };
    t15 = [];
    $[22] = t14;
    $[23] = t15;
  } else {
    t14 = $[22];
    t15 = $[23];
  }
  (0, _react.useEffect)(t14, t15);
  let t16;
  if ($[24] !== children) {
    t16 = _react.default.Children.toArray(children).some(_temp4);
    $[24] = children;
    $[25] = t16;
  } else {
    t16 = $[25];
  }
  const containsHeader = t16;
  let t17;
  if ($[26] === Symbol.for("react.memo_cache_sentinel")) {
    t17 = () => {
      var _a;
      (_a = dialogRef.current) == null ? void 0 : _a.close();
    };
    $[26] = t17;
  } else {
    t17 = $[26];
  }
  const handleClose = t17;
  let t18;
  if ($[27] !== trigger) {
    t18 = typeof trigger === "function" ? trigger(() => setOpen(true)) : trigger && _react.default.cloneElement(trigger, {
      onClick: trigger.props.onClick ? trigger.props.onClick : () => setOpen(true)
    });
    $[27] = trigger;
    $[28] = t18;
  } else {
    t18 = $[28];
  }
  const t19 = large && styles.large;
  const t20 = huge && styles.huge;
  const t21 = dialog && styles.dialog;
  let t22;
  if ($[29] !== style || $[30] !== t19 || $[31] !== t20 || $[32] !== t21) {
    t22 = [styles.modal, t19, t20, t21, style];
    $[29] = style;
    $[30] = t19;
    $[31] = t20;
    $[32] = t21;
    $[33] = t22;
  } else {
    t22 = $[33];
  }
  const t23 = large && styles.largeInner;
  let t24;
  if ($[34] !== t23) {
    t24 = [styles.main, t23];
    $[34] = t23;
    $[35] = t24;
  } else {
    t24 = $[35];
  }
  let t25;
  if ($[36] === Symbol.for("react.memo_cache_sentinel")) {
    t25 = /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      "aria-hidden": "true",
      name: "close",
      className: styles.closeIcon,
      onClick: handleClose
    });
    $[36] = t25;
  } else {
    t25 = $[36];
  }
  let t26;
  if ($[37] !== containsHeader) {
    t26 = !containsHeader && /* @__PURE__ */_react.default.createElement(Header, null);
    $[37] = containsHeader;
    $[38] = t26;
  } else {
    t26 = $[38];
  }
  let t27;
  if ($[39] !== children || $[40] !== t24 || $[41] !== t26) {
    t27 = /* @__PURE__ */_react.default.createElement(_Settings.SettingsContextReset, null, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t24
    }, t25, t26, children));
    $[39] = children;
    $[40] = t24;
    $[41] = t26;
    $[42] = t27;
  } else {
    t27 = $[42];
  }
  let t28;
  if ($[43] !== t22 || $[44] !== t27) {
    t28 = /* @__PURE__ */_react.default.createElement(_Html.default.dialog, {
      style: t22,
      ref: dialogRef
    }, t27);
    $[43] = t22;
    $[44] = t27;
    $[45] = t28;
  } else {
    t28 = $[45];
  }
  let t29;
  if ($[46] !== t18 || $[47] !== t28) {
    t29 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, t18, t28);
    $[46] = t18;
    $[47] = t28;
    $[48] = t29;
  } else {
    t29 = $[48];
  }
  return t29;
};
const Actions = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(9);
  let children;
  let props;
  if ($[0] !== t0) {
    _a = t0, {
      children
    } = _a, props = __objRest(_a, ["children"]);
    $[0] = t0;
    $[1] = children;
    $[2] = props;
  } else {
    children = $[1];
    props = $[2];
  }
  let t1;
  if ($[3] !== props.style) {
    t1 = [styles.actions, props.style];
    $[3] = props.style;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  let t2;
  if ($[5] !== children || $[6] !== props || $[7] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadProps(__spreadValues({}, props), {
      style: t1
    }), children);
    $[5] = children;
    $[6] = props;
    $[7] = t1;
    $[8] = t2;
  } else {
    t2 = $[8];
  }
  return t2;
};
exports.Actions = Actions;
const Content = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(9);
  let children;
  let props;
  if ($[0] !== t0) {
    _a = t0, {
      children
    } = _a, props = __objRest(_a, ["children"]);
    $[0] = t0;
    $[1] = children;
    $[2] = props;
  } else {
    children = $[1];
    props = $[2];
  }
  let t1;
  if ($[3] !== props.style) {
    t1 = [styles.content, props.style];
    $[3] = props.style;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  let t2;
  if ($[5] !== children || $[6] !== props || $[7] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadProps(__spreadValues({}, props), {
      style: t1
    }), children);
    $[5] = children;
    $[6] = props;
    $[7] = t1;
    $[8] = t2;
  } else {
    t2 = $[8];
  }
  return t2;
};
exports.Content = Content;
var _default = exports.default = Object.assign(Modal, {
  Actions,
  Content,
  Header
});
function _temp() {}
function _temp2() {}
function _temp3() {}
function _temp4(child) {
  return _react.default.isValidElement(child) && child.type === Header;
}