"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadVideoModal = void 0;
Object.defineProperty(exports, "VideoLoadMenu", {
  enumerable: true,
  get: function () {
    return _Menu.default;
  }
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _helpers = require("kosmi-sdk/helpers");
var _Controls = _interopRequireDefault(require("./Controls"));
var _SubtitleModal = _interopRequireDefault(require("./SubtitleModal"));
var _SyncedVideo = _interopRequireDefault(require("./SyncedVideo"));
var _Menu = _interopRequireDefault(require("./Menu"));
var _AppContext = _interopRequireDefault(require("@/app/AppContext"));
var _MediaPlayerContext = _interopRequireDefault(require("@/room/contexts/MediaPlayerContext"));
var _useMediaPlayerData = require("@/room/hooks/useMediaPlayerData");
var _useMediaPlayerActions = require("@/room/hooks/useMediaPlayerActions");
var _useMembers = require("@/room/hooks/useMembers");
var _helpers2 = require("./helpers");
var _helpers3 = require("./Video/helpers");
var _useIsAdmin = require("@/room/hooks/useIsAdmin");
var _useDoubleClick = _interopRequireDefault(require("use-double-click"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  player: {
    "index__styles.player": "index__styles.player",
    width: "xh8yej3",
    height: "x5yr21d",
    maxWidth: "x193iq5w",
    maxHeight: "xmz0i5r",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "x1vaw2wd",
    transition: "x140bshh",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    cursor: "xjfk50j",
    display: "x78zum5",
    $$css: true
  },
  loadVideoModal: {
    "index__styles.loadVideoModal": "index__styles.loadVideoModal",
    width: "x8fmnwm",
    maxWidth: "x193iq5w",
    $$css: true
  },
  modalIcon: {
    "index__styles.modalIcon": "index__styles.modalIcon",
    height: "x1gnnpzl",
    width: "x14atkfc",
    $$css: true
  },
  hidden: {
    "index__styles.hidden": "index__styles.hidden",
    display: "x1s85apg",
    $$css: true
  },
  hovering: {
    "index__styles.hovering": "index__styles.hovering",
    cursor: "xt0e3qv",
    $$css: true
  },
  modalContent: {
    "index__styles.modalContent": "index__styles.modalContent",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    height: "xqtp20y",
    $$css: true
  }
};
const LoadVideoModal = exports.LoadVideoModal = (0, _react.memo)(({
  onClose,
  open
}) => {
  const track = (0, _helpers.useTracking)();
  const [modalTitle, setModalTitle] = (0, _react.useState)("Select Media");
  const onOpen = (0, _react.useCallback)(() => {
    track("UI", "LoadVideoModalOpened", "Load video Modal opened");
  }, [track]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open: typeof open === "boolean" ? open : void 0,
    large: true,
    style: styles.loadVideoModal,
    onOpen,
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, modalTitle.startsWith("https://") ? /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.modalIcon,
    src: modalTitle
  }) : modalTitle), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, {
    style: styles.modalContent
  }, /* @__PURE__ */_react.default.createElement(_Menu.default, {
    onClose: onClose || (() => {}),
    setModalTitle
  })));
}, (props, nextProps) => {
  return props.open === nextProps.open;
});
var _default = exports.default = (0, _react.memo)(({
  visible,
  onFullscreen,
  isCompact,
  forceFullScreen,
  onPlayer
}) => {
  var _a, _b, _c, _d, _e;
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  const mediaplayerContext = (0, _react.useContext)(_MediaPlayerContext.default);
  const {
    mediaPlayerState,
    subtitles
  } = (0, _useMediaPlayerData.useMediaPlayerStateCached)();
  const appContext = (0, _react.useContext)(_AppContext.default);
  const {
    removeSubtitles,
    setSubtitles,
    setPaused,
    setPosition,
    stopVideo
  } = (0, _useMediaPlayerActions.useMediaPlayerActions)();
  const members = (0, _useMembers.useMembers)();
  const mediaPlayerStateRef = (0, _react.useRef)(null);
  const membersRef = (0, _react.useRef)([]);
  const onReady = (0, _react.useCallback)(player2 => {
    setEnableCC(!(0, _helpers3.isScreenShare)((mediaPlayerState == null ? void 0 : mediaPlayerState.url) || "") && !mediaPlayerState.live && (player2 == null ? void 0 : player2.video) instanceof Element);
    setPlayer(player2);
  }, [mediaPlayerState == null ? void 0 : mediaPlayerState.url, mediaPlayerState.live]);
  const {
    url
  } = mediaPlayerState;
  const [player, setPlayer] = (0, _react.useState)(null);
  const [hovering, setHovering] = (0, _react.useState)(false);
  const hoveringTimeoutRef = (0, _react.useRef)(null);
  const doubleClickTimeoutRef = (0, _react.useRef)(null);
  const [nativeControls, setNativeControls] = (0, _react.useState)(false);
  const hover = (0, _react.useCallback)((event = void 0) => {
    var _a2, _b2;
    if (event == null ? void 0 : event.target) {
      const elClassName = (_a2 = event == null ? void 0 : event.target) == null ? void 0 : _a2.className;
      if (elClassName && elClassName.split && ((_b2 = elClassName.split(" ")) == null ? void 0 : _b2.indexOf("autoPlayBuster")) !== -1) return;
    }
    setHovering(true);
    if (hoveringTimeoutRef.current) {
      clearTimeout(hoveringTimeoutRef.current);
    }
    if (nativeControls) {
      hoveringTimeoutRef.current = null;
    } else {
      hoveringTimeoutRef.current = setTimeout(() => {
        setHovering(false);
      }, 5e3);
    }
  }, [nativeControls]);
  const [enableCC, setEnableCC] = (0, _react.useState)(false);
  const [localPlayerState, setLocalPlayerState] = (0, _react.useState)({});
  const [modal, setModal] = (0, _react.useState)(null);
  const [showSubtitleModal, setShowSubtitleModal] = (0, _react.useState)(false);
  const wrapperRef = (0, _react.useRef)(null);
  (0, _useDoubleClick.default)({
    onSingleClick: () => {},
    onDoubleClick: () => {
      setNativeControls(true);
      if (doubleClickTimeoutRef.current) {
        clearTimeout(doubleClickTimeoutRef.current);
      }
      doubleClickTimeoutRef.current = setTimeout(() => {
        setNativeControls(false);
      }, 5e3);
    },
    ref: wrapperRef,
    latency: 250
  });
  (0, _react.useEffect)(() => {
    if (!onPlayer) return;
    onPlayer({
      loadMedia: () => {
        setModal("pick");
      }
    });
  }, [onPlayer]);
  (0, _react.useEffect)(() => {
    const prevMediaPlayerState = mediaPlayerStateRef.current;
    if ((prevMediaPlayerState == null ? void 0 : prevMediaPlayerState.url) !== (mediaPlayerState == null ? void 0 : mediaPlayerState.url)) {
      hover();
    }
  }, [mediaPlayerState == null ? void 0 : mediaPlayerState.url, hover]);
  (0, _react.useEffect)(() => {
    const prevMembers = membersRef.current || [];
    if (!members || !prevMembers) return;
    if (prevMembers.length < 3 && members.length >= 3) {
      mediaplayerContext.setRelayStream(true);
    }
  }, [mediaPlayerState == null ? void 0 : mediaPlayerState.url, members, mediaplayerContext]);
  const pause = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    if (!((mediaPlayerState == null ? void 0 : mediaPlayerState.live) && !mediaplayerContext.localUrl)) {
      setPaused(true);
      if (localPlayerState.duration != null) {
        const position = (0, _helpers2.getPosition)(!!mediaPlayerState.paused, mediaPlayerState.position || 0, localPlayerState.duration, mediaPlayerState.unixTimePositionSent || 0);
        setPosition(position);
      }
    }
  }), [localPlayerState.duration, mediaPlayerState, mediaplayerContext.localUrl, setPosition, setPaused]);
  const play = (0, _react.useCallback)(() => setPaused(false), [setPaused]);
  const stop = (0, _react.useCallback)(() => stopVideo(), [stopVideo]);
  const loadMedia = (0, _react.useCallback)(() => setModal("pick"), []);
  const showCcMenu = (0, _react.useCallback)(() => setShowSubtitleModal(true), []);
  const seekTo = (0, _react.useCallback)(val => setPosition(val), [setPosition]);
  const toggleNativeControls = (0, _react.useCallback)(() => {
    if (doubleClickTimeoutRef.current) {
      clearTimeout(doubleClickTimeoutRef.current);
    }
    setNativeControls(!nativeControls);
  }, [nativeControls]);
  const closeLoadVideoModal = (0, _react.useCallback)(() => {
    setModal(null);
  }, []);
  const mouseLeave = (0, _react.useCallback)(() => {
    if (!showSubtitleModal && !modal) {
      if (hoveringTimeoutRef.current) {
        clearTimeout(hoveringTimeoutRef.current);
      }
      setHovering(false);
    }
  }, [modal, showSubtitleModal]);
  const onSubtitles = (0, _react.useCallback)(subtitles2 => {
    if (subtitles2.filename && subtitles2.cues) {
      setSubtitles(subtitles2.filename, subtitles2.cues);
    }
    setShowSubtitleModal(false);
  }, [setSubtitles]);
  const closeSubtitleModal = (0, _react.useCallback)(() => {
    setShowSubtitleModal(false);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(LoadVideoModal, {
    open: !!modal,
    onClose: closeLoadVideoModal
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.player, visible === false && styles.hidden, hovering && styles.hovering],
    ref: wrapperRef,
    onMouseMove: hover,
    onMouseOver: hover,
    onMouseLeave: mouseLeave
  }, /* @__PURE__ */_react.default.createElement(_SubtitleModal.default, {
    open: showSubtitleModal,
    subtitles,
    onRemoveSubtitles: removeSubtitles,
    onSubtitles,
    onClose: closeSubtitleModal
  }), ((mediaPlayerState == null ? void 0 : mediaPlayerState.paused) || hovering || (0, _helpers3.isVbrowser)(url || "")) && /* @__PURE__ */_react.default.createElement(_Controls.default, {
    key: mediaPlayerState.url,
    isCompact,
    isSmall: isMobile && !isLandscape,
    paused: !!(mediaPlayerState == null ? void 0 : mediaPlayerState.paused),
    duration: localPlayerState.duration,
    muted: !!localPlayerState.muted,
    volume: localPlayerState.volume,
    isFullscreen: forceFullScreen,
    freeUpRightSide: forceFullScreen && (!appContext.showNav || isMobile),
    timestamp: (0, _helpers2.getPosition)(!!mediaPlayerState.paused, mediaPlayerState.position || 0, localPlayerState.duration || 0, mediaPlayerState.unixTimePositionSent || 0),
    isLive: mediaPlayerState.live !== false && !mediaplayerContext.localUrl,
    videoInfo: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, (0, _helpers3.getVideoTitle)(url || "")),
    url: mediaPlayerState.url,
    nativeControls,
    controls: __spreadValues({
      mute: (_a = player == null ? void 0 : player.player) == null ? void 0 : _a.mute,
      unmute: (_b = player == null ? void 0 : player.player) == null ? void 0 : _b.unmute,
      setVolume: (0, _helpers3.isVbrowser)(url || "") ? null : (_c = player == null ? void 0 : player.player) == null ? void 0 : _c.setVolume,
      toggleNativeControls
    }, isAdmin ? {
      play,
      mute: (_d = player == null ? void 0 : player.player) == null ? void 0 : _d.mute,
      unmute: (_e = player == null ? void 0 : player.player) == null ? void 0 : _e.unmute,
      pause: !((mediaPlayerState == null ? void 0 : mediaPlayerState.live) && !mediaplayerContext.localUrl) ? pause : null,
      stop,
      loadMedia,
      showCcMenu: (!(mediaPlayerState == null ? void 0 : mediaPlayerState.live) || !!mediaplayerContext.localUrl) && enableCC ? showCcMenu : null,
      seekTo: !mediaPlayerState.live ? seekTo : null,
      fullscreen: onFullscreen
    } : {})
  }), /* @__PURE__ */_react.default.createElement(_SyncedVideo.default, {
    onUpdate: setLocalPlayerState,
    controls: nativeControls,
    onReady
  })));
});