"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
const TENOR_API_KEY = "AIzaSyCiHQrSzR3njs7V2l65pCXGl8hTsFAGz_o";
const TENOR_SEARCH_URL = "https://tenor.googleapis.com/v2/search";
const TENOR_TRENDING_URL = "https://tenor.googleapis.com/v2/featured";
export default (searchString) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchGifs = () => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const response = yield fetch(searchString ? `${TENOR_SEARCH_URL}?q=${encodeURIComponent(searchString)}&key=${TENOR_API_KEY}&limit=10` : `${TENOR_TRENDING_URL}?key=${TENOR_API_KEY}&limit=10`);
        const data = yield response.json();
        if (response.ok) {
          const gifs = data.results.map((result) => {
            var _a, _b;
            return (_b = (_a = result == null ? void 0 : result.media_formats) == null ? void 0 : _a.webp_transparent) == null ? void 0 : _b.url;
          });
          setResults(gifs);
        } else {
          throw new Error(data.error || "Error fetching GIFs");
        }
      } catch (error) {
        setResults([]);
      } finally {
        setLoading(false);
      }
    });
    fetchGifs();
  }, [searchString]);
  return {
    results,
    loading
  };
};
