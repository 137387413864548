"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
import React, { memo } from "react";
import { useCurrentMember } from "./useCurrentMember";
import { useRoomId } from "./useRoomId";
export const useIsAdmin = () => {
  const currentMember = useCurrentMember();
  return (currentMember == null ? void 0 : currentMember.role) === "ADMIN";
};
export const withIsAdmin = (WrappedComponent) => memo((props) => {
  const isAdmin = useIsAdmin();
  const roomId = useRoomId();
  const currentMember = useCurrentMember();
  const currentUser = currentUserHook();
  return /* @__PURE__ */ React.createElement(
    WrappedComponent,
    __spreadProps(__spreadValues({}, props), {
      isAdmin,
      roomId,
      currentMember,
      currentUser
    })
  );
});
export default withIsAdmin;
