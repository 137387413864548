"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RoomList = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _JoinRoomModal = _interopRequireDefault(require("./JoinRoomModal"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react = _interopRequireWildcard(require("react"));
var _CreateRoomModal = _interopRequireDefault(require("@/shared/components/CreateRoomModal"));
var _VerifiedEmailWall = _interopRequireDefault(require("./VerifiedEmailWall"));
var _LoginWall = _interopRequireDefault(require("@/shared/components/LoginWall"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const PUBLIC_ROOMS = (0, _gql.gql)(`
  query PublicRoomQuery {
    publicRooms {
      appInfo
      appName
      countryCode
      joinCode
      memberCount
      roomName
      description
      avatarUrl
      autoAcceptJoins
      boosted
    }
  }
`);
const PUBLIC_ROOMS_UPDATE = (0, _gql.gql)(`
subscription PublicRoomUpdateSubscription {
  publicRoomsUpdate {
      appInfo
      appName
      countryCode
      joinCode
      memberCount
      roomName
      description
      avatarUrl
      autoAcceptJoins
      boosted
  }
}
`);
const styles = {
  wrapper: {
    "app__styles.wrapper": "app__styles.wrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flexWrap: "x1a02dak",
    justifyContent: "x1l1ennw",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    height: "x5yr21d",
    maxWidth: "x1kvbfxc",
    maxHeight: "xjd8us4",
    backgroundImage: "xvixog2",
    $$css: true
  },
  tileContainer: {
    "app__styles.tileContainer": "app__styles.tileContainer",
    display: "x78zum5",
    flexWrap: "x1a02dak",
    justifyContent: "xl56j7k",
    overflowY: "x1odjw0f",
    backgroundColor: "xjbqb8w",
    minHeight: "x2lwn1j",
    height: "xt7dq6l",
    width: "xh8yej3",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  tile: {
    "app__styles.tile": "app__styles.tile",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "xq1dxzn",
    height: "x1ymw6g",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderRadius: "x4pepcl",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    position: "x1n2onr6",
    transition: "x1trvaba",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    backgroundRepeat: "xiy17q3",
    ":hover_backgroundColor": "x1imby3o",
    ":hover_cursor": "x1277o0a",
    ":active_transform": "xm4o3sg",
    "@media (max-width: 1000px)_width": "x1nkzv30",
    "@media (max-width: 1000px)_height": "x7kre8o",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundSize: "x18d0r48",
    boxShadow: "x18gnp7g",
    $$css: true
  },
  header: {
    "app__styles.header": "app__styles.header",
    display: "x78zum5",
    paddingLeft: "xkjbukk",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "x1tdmjzm",
    paddingTop: "xlaft8j",
    paddingBottom: "x1m7pmia",
    justifyContent: "x1qughib",
    alignItems: "x6s0dn4",
    width: "xh8yej3",
    fontSize: "x1pvqxga",
    fontWeight: "x1xlr1w8",
    "@media (max-width: 1000px)_paddingTop": "xtteif3",
    "@media (max-width: 1000px)_paddingBottom": "x177txvy",
    "@media (max-width: 1000px)_paddingLeft": "x1xxpysl",
    "@media (max-width: 1000px)_paddingInlineStart": null,
    "@media (max-width: 1000px)_paddingInlineEnd": null,
    "@media (max-width: 1000px)_paddingRight": "x1lcgh4d",
    "@media (max-width: 1000px)_alignItems": "x1lkc0au",
    $$css: true
  },
  roomInfo: {
    "app__styles.roomInfo": "app__styles.roomInfo",
    padding: "xc7ga6q",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    zIndex: "x1vjfegm",
    backgroundColor: "x1ayl8o0 x1n5bzlp",
    backdropFilter: "xjc76 x1stzspi",
    WebkitBackdropFilter: "x7pn08a",
    textShadow: "x19349zl",
    width: "xh8yej3",
    height: "x5yr21d",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    flexDirection: "xdt5ytf",
    transition: "x1h2kqdt",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  members: {
    "app__styles.members": "app__styles.members",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    position: "x10l6tqk",
    top: "x1tk7jg1",
    right: "xlftelb",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  roomName: {
    "app__styles.roomName": "app__styles.roomName",
    marginTop: "x11fxgd9",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    display: "x78zum5",
    $$css: true
  },
  roomDescription: {
    "app__styles.roomDescription": "app__styles.roomDescription",
    display: "x1lliihq",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xif65rj",
    whiteSpace: "xuxw1ft",
    textAlign: "x2b8uid",
    height: "x1gnnpzl",
    width: "x1f2tiqu",
    maxHeight: "x990d50",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    $$css: true
  },
  flag: {
    "app__styles.flag": "app__styles.flag",
    position: "x10l6tqk",
    top: "x1tk7jg1",
    left: "xpnmzw7",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  setBackgroundImage: url => [{
    "app__styles.setBackgroundImage": "app__styles.setBackgroundImage",
    backgroundImage: "x1g0zcm7",
    $$css: true
  }, {
    "--backgroundImage": `url(${url})` != null ? `url(${url})` : "initial"
  }],
  setBoxShadow: memberCount => [{
    "app__styles.setBoxShadow": "app__styles.setBoxShadow",
    boxShadow: "xigitpm",
    $$css: true
  }, {
    "--boxShadow": `0 0 15px ${memberCount}px var(--secondary)` != null ? `0 0 15px ${memberCount}px var(--secondary)` : "initial"
  }]
};
const Tile = ({
  memberCount,
  roomName,
  roomDescription,
  countryCode,
  avatarUrl,
  onClick,
  joinCode,
  boosted
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.tile, styles.setBackgroundImage(avatarUrl), memberCount > 1 && styles.setBoxShadow(memberCount)],
    onClick: () => onClick(joinCode)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomInfo
  }, countryCode && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.flag
  }, /* @__PURE__ */_react.default.createElement(_uikit.Flag, {
    name: "us"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.members
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "group"
  }), " ", memberCount), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomName
  }, boosted && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "lightning",
    color: "yellow",
    size: "small"
  }), roomName), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomDescription
  }, roomDescription)));
};
const TileContainer = ({
  children
}) => {
  const [room, setRoom] = (0, _react.useState)(null);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_JoinRoomModal.default, {
    open: room,
    joinCode: room == null ? void 0 : room.joinCode,
    onClose: () => setRoom(null)
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.tileContainer
  }, _react.default.Children.map(children, c => _react.default.cloneElement(c, {
    onClick: () => setRoom({
      joinCode: c.props.joinCode
    })
  }))));
};
const RoomList = () => {
  const {
    data,
    subscribeToMore,
    loading
  } = (0, _gql.useQuery)(PUBLIC_ROOMS);
  const rooms = (data == null ? void 0 : data.publicRooms) || [];
  (0, _react.useEffect)(() => {
    const unsubscribeToLobbyUpdates = subscribeToMore({
      document: PUBLIC_ROOMS_UPDATE,
      updateQuery: (prev, {
        subscriptionData: {
          data: data2
        }
      }) => {
        return __spreadProps(__spreadValues({}, prev), {
          publicRooms: data2.publicRoomsUpdate
        });
      }
    });
    return () => {
      unsubscribeToLobbyUpdates();
    };
  }, [subscribeToMore]);
  if (loading) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  return /* @__PURE__ */_react.default.createElement(TileContainer, null, rooms.map((r, i) => /* @__PURE__ */_react.default.createElement(Tile, {
    key: i,
    roomDescription: r.description,
    memberCount: r.memberCount,
    roomName: r.roomName,
    boosted: r.boosted,
    countryCode: r.countryCode,
    avatarUrl: r.avatarUrl,
    joinCode: r.joinCode
  })));
};
exports.RoomList = RoomList;
var _default = exports.default = (0, _react.memo)(() => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.wrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.header
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Public Rooms"), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: "Create a public room",
    trigger: /* @__PURE__ */_react.default.createElement(_CreateRoomModal.default, {
      type: "public",
      trigger: /* @__PURE__ */_react.default.createElement(_VerifiedEmailWall.default, null, /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "white",
        size: "l",
        borderLess: true,
        inverted: true,
        icon: "plus"
      })))
    })
  })), /* @__PURE__ */_react.default.createElement(RoomList, null));
});