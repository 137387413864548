"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSettingsRouter = exports.useSettingsLevel = exports.TextArea = exports.SettingsContextReset = exports.Section = exports.InputGroup = exports.Input = exports.Container = exports.Checkbox = exports.Button = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var _Image = require("./Image");
var _Button = require("./Button");
var _Input = require("./Input");
var _TextArea = require("./TextArea");
var _Checkbox = require("./Checkbox");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _TabView = require("./TabView");
var _reactDom = require("react-dom");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  wrapper: {
    "Settings__styles.wrapper": "Settings__styles.wrapper",
    display: "x78zum5",
    height: "x5yr21d",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    position: "x1n2onr6",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  header: {
    "Settings__styles.header": "Settings__styles.header",
    display: "x78zum5",
    justifyContent: "x1nhvcw1",
    alignItems: "x6s0dn4",
    height: "xwzfr38",
    width: "xh8yej3",
    $$css: true
  },
  topTitle: {
    "Settings__styles.topTitle": "Settings__styles.topTitle",
    fontSize: "xosj86m",
    fontWeight: "x1xlr1w8",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x10l6tqk",
    textAlign: "x2b8uid",
    $$css: true
  },
  settingsScreen: {
    "Settings__styles.settingsScreen": "Settings__styles.settingsScreen",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    minHeight: "x2lwn1j",
    width: "xh8yej3",
    maxWidth: "x193iq5w",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  dark: {
    "Settings__styles.dark": "Settings__styles.dark",
    backgroundColor: "xjlc4g5",
    $$css: true
  },
  settingsButton: {
    "Settings__styles.settingsButton": "Settings__styles.settingsButton",
    display: "x78zum5",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontWeight: "xk50ysn",
    height: "xng8ra",
    minHeight: "x879a55",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    justifyContent: "x1qughib",
    fontSize: "x1j61zf2",
    alignItems: "x6s0dn4",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    minWidth: "x1jzhcrs",
    width: "xm6i5cn",
    cursor: "x1ypdohk",
    color: "x11jfisy",
    ":active_backgroundColor": "xalcejg",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "@media (hover: hover) and (pointer: fine)_borderColor": "xssfd3w",
    "@media (hover: hover) and (pointer: fine)_borderInlineColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineStartColor": null,
    "@media (hover: hover) and (pointer: fine)_borderLeftColor": null,
    "@media (hover: hover) and (pointer: fine)_borderInlineEndColor": null,
    "@media (hover: hover) and (pointer: fine)_borderRightColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBlockColor": null,
    "@media (hover: hover) and (pointer: fine)_borderTopColor": null,
    "@media (hover: hover) and (pointer: fine)_borderBottomColor": null,
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x19a35c3",
    backgroundColor: "x1hhg5nt",
    $$css: true
  },
  inputWrapperWrapper: {
    "Settings__styles.inputWrapperWrapper": "Settings__styles.inputWrapperWrapper",
    display: "x78zum5",
    height: "xg7h5cd",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "x1uhho1l",
    paddingEnd: null,
    paddingRight: "x1xpa7k",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    flexDirection: "xdt5ytf",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    maxWidth: "x95b5qq",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  inputWrapper: {
    "Settings__styles.inputWrapper": "Settings__styles.inputWrapper",
    display: "x78zum5",
    height: "xnxb3zj",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  checkboxWrapper: {
    "Settings__styles.checkboxWrapper": "Settings__styles.checkboxWrapper",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    height: "xg7h5cd",
    justifyContent: "x1qughib",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    maxWidth: "x95b5qq",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  checkboxLabel: {
    "Settings__styles.checkboxLabel": "Settings__styles.checkboxLabel",
    fontWeight: "xk50ysn",
    fontSize: "x1j61zf2",
    $$css: true
  },
  inputGroup: {
    "Settings__styles.inputGroup": "Settings__styles.inputGroup",
    alignItems: "x6s0dn4",
    backgroundColor: "x1u50pdq",
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "xg7h5cd",
    justifyContent: "x1qughib",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    maxWidth: "x95b5qq",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  divider: {
    "Settings__styles.divider": "Settings__styles.divider",
    width: "xgqk73l",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: "xso031l",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  input: {
    "Settings__styles.input": "Settings__styles.input",
    display: "x78zum5",
    height: "x10wjd1d",
    fontSize: "x1j61zf2",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderRadius: "xur7f20",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    maxWidth: "x193iq5w",
    backgroundColor: "x128cn8a",
    cursor: "x1ed109x",
    borderColor: "x17lso95",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  textArea: {
    "Settings__styles.textArea": "Settings__styles.textArea",
    height: "x1m3v4wt",
    $$css: true
  },
  withAvatar: {
    "Settings__styles.withAvatar": "Settings__styles.withAvatar",
    height: "xwzfr38",
    $$css: true
  },
  settingsButtonContent: {
    "Settings__styles.settingsButtonContent": "Settings__styles.settingsButtonContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  settingsButtonContentText: {
    "Settings__styles.settingsButtonContentText": "Settings__styles.settingsButtonContentText",
    display: "x78zum5",
    alignItems: "x1cy8zhl",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  settingsButtonContentTextInner: {
    "Settings__styles.settingsButtonContentTextInner": "Settings__styles.settingsButtonContentTextInner",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  settingsButtonIcon: {
    "Settings__styles.settingsButtonIcon": "Settings__styles.settingsButtonIcon",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    marginLeft: "xet2fuk",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1db2dqx",
    $$css: true
  },
  settingsButtonContentValue: {
    "Settings__styles.settingsButtonContentValue": "Settings__styles.settingsButtonContentValue",
    maxWidth: "x16q51m2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    opacity: "x197sbye",
    whiteSpace: "xuxw1ft",
    "@media (max-width: 1000px)_maxWidth": "x1hzmjh1",
    "@media (max-width: 1000px)_width": "xf80e3e",
    $$css: true
  },
  avatar: {
    "Settings__styles.avatar": "Settings__styles.avatar",
    width: "x1849jeq",
    height: "x1gnnpzl",
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  buttonWrapper: {
    "Settings__styles.buttonWrapper": "Settings__styles.buttonWrapper",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  rightArrow: {
    "Settings__styles.rightArrow": "Settings__styles.rightArrow",
    marginRight: "x1f5jaxz",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  danger: {
    "Settings__styles.danger": "Settings__styles.danger",
    borderColor: "x1wrw7br",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    color: "x1sr8853",
    $$css: true
  },
  success: {
    "Settings__styles.success": "Settings__styles.success",
    borderColor: "x13mpoqa",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    color: "x16yd4ao",
    $$css: true
  }
};
const Button = exports.Button = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(22);
  const {
    name,
    avatarUrl,
    value,
    onClick,
    color,
    icon,
    disabled
  } = t0;
  const {
    dark
  } = (0, _react.useContext)(SettingsContext);
  const t1 = !!dark && styles.dark;
  const t2 = !!color && styles[color];
  let t3;
  if ($[0] !== t1 || $[1] !== t2) {
    t3 = [styles.settingsButton, t1, t2];
    $[0] = t1;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] !== icon) {
    t4 = icon && typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      className: styles.settingsButtonIcon,
      name: icon
    }) : icon;
    $[3] = icon;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  let t5;
  if ($[5] !== name || $[6] !== t4) {
    t5 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.settingsButtonContentText
    }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.settingsButtonContentTextInner
    }, t4, " ", name));
    $[5] = name;
    $[6] = t4;
    $[7] = t5;
  } else {
    t5 = $[7];
  }
  let t6;
  if ($[8] !== value) {
    t6 = value && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.settingsButtonContentValue
    }, value);
    $[8] = value;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  let t7;
  if ($[10] !== avatarUrl) {
    t7 = avatarUrl && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.avatar
    }, avatarUrl && /* @__PURE__ */_react.default.createElement(_Image.Image, {
      src: avatarUrl,
      circular: true
    }));
    $[10] = avatarUrl;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  let t8;
  if ($[12] !== t5 || $[13] !== t6 || $[14] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.settingsButtonContent
    }, t5, t6, t7);
    $[12] = t5;
    $[13] = t6;
    $[14] = t7;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  let t9;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      className: styles.rightArrow,
      name: "angle right"
    });
    $[16] = t9;
  } else {
    t9 = $[16];
  }
  let t10;
  if ($[17] !== disabled || $[18] !== onClick || $[19] !== t3 || $[20] !== t8) {
    t10 = /* @__PURE__ */_react.default.createElement(_Button.Button, {
      onClick,
      color: "blue",
      className: t3,
      disabled
    }, t8, t9);
    $[17] = disabled;
    $[18] = onClick;
    $[19] = t3;
    $[20] = t8;
    $[21] = t10;
  } else {
    t10 = $[21];
  }
  return t10;
});
const Section = exports.Section = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(72);
  const {
    id,
    name,
    value,
    avatarUrl,
    icon,
    trigger,
    children,
    onSave,
    saveText,
    onBack,
    emphasizeSave
  } = t0;
  const {
    push,
    stack
  } = useSettingsRouter();
  const {
    parentName,
    pageContainer
  } = (0, _react.useContext)(SettingsContext);
  const level = useSettingsLevel();
  const identifier = (id || name) + `_${level}`;
  let t1;
  if ($[0] !== identifier || $[1] !== stack) {
    let t22;
    if ($[3] !== identifier) {
      t22 = s => s.id === identifier;
      $[3] = identifier;
      $[4] = t22;
    } else {
      t22 = $[4];
    }
    t1 = stack.some(t22);
    $[0] = identifier;
    $[1] = stack;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const isOpen = t1;
  const isOpenRef = (0, _react.useRef)(isOpen);
  let t2;
  if ($[5] !== avatarUrl || $[6] !== emphasizeSave || $[7] !== icon || $[8] !== identifier || $[9] !== level || $[10] !== name || $[11] !== onSave || $[12] !== parentName || $[13] !== push || $[14] !== saveText || $[15] !== trigger || $[16] !== value) {
    t2 = () => push({
      id: identifier,
      sectionProps: {
        name,
        value,
        avatarUrl,
        icon,
        trigger,
        onSave,
        saveText,
        emphasizeSave
      },
      level,
      parentName
    });
    $[5] = avatarUrl;
    $[6] = emphasizeSave;
    $[7] = icon;
    $[8] = identifier;
    $[9] = level;
    $[10] = name;
    $[11] = onSave;
    $[12] = parentName;
    $[13] = push;
    $[14] = saveText;
    $[15] = trigger;
    $[16] = value;
    $[17] = t2;
  } else {
    t2 = $[17];
  }
  const openSection = t2;
  const openSectionRef = (0, _react.useRef)(openSection);
  let t3;
  let t4;
  if ($[18] !== openSection) {
    t3 = () => {
      openSectionRef.current = openSection;
    };
    t4 = [openSection];
    $[18] = openSection;
    $[19] = t3;
    $[20] = t4;
  } else {
    t3 = $[19];
    t4 = $[20];
  }
  (0, _react.useEffect)(t3, t4);
  let t5;
  if ($[21] !== openSection) {
    t5 = () => {
      openSection();
    };
    $[21] = openSection;
    $[22] = t5;
  } else {
    t5 = $[22];
  }
  const onClick = t5;
  let t6;
  if ($[23] !== isOpen) {
    t6 = () => {
      const openSection_0 = openSectionRef.current;
      if (isOpen) {
        openSection_0();
      }
    };
    $[23] = isOpen;
    $[24] = t6;
  } else {
    t6 = $[24];
  }
  let t7;
  if ($[25] !== avatarUrl || $[26] !== emphasizeSave || $[27] !== icon || $[28] !== isOpen || $[29] !== level || $[30] !== name || $[31] !== parentName || $[32] !== saveText || $[33] !== value) {
    t7 = [name, value, avatarUrl, icon, parentName, level, saveText, emphasizeSave, isOpen];
    $[25] = avatarUrl;
    $[26] = emphasizeSave;
    $[27] = icon;
    $[28] = isOpen;
    $[29] = level;
    $[30] = name;
    $[31] = parentName;
    $[32] = saveText;
    $[33] = value;
    $[34] = t7;
  } else {
    t7 = $[34];
  }
  (0, _react.useEffect)(t6, t7);
  let t8;
  let t9;
  if ($[35] !== isOpen || $[36] !== onBack) {
    t8 = () => {
      if (onBack && !isOpen && isOpenRef.current !== isOpen) {
        onBack();
      }
      isOpenRef.current = isOpen;
    };
    t9 = [isOpen, onBack];
    $[35] = isOpen;
    $[36] = onBack;
    $[37] = t8;
    $[38] = t9;
  } else {
    t8 = $[37];
    t9 = $[38];
  }
  (0, _react.useEffect)(t8, t9);
  const triggerIsTrue = trigger === true;
  let t10;
  let t11;
  if ($[39] !== isOpen || $[40] !== onClick || $[41] !== triggerIsTrue) {
    t10 = () => {
      if (triggerIsTrue && !isOpen) {
        onClick();
      }
    };
    t11 = [triggerIsTrue, onClick, isOpen];
    $[39] = isOpen;
    $[40] = onClick;
    $[41] = triggerIsTrue;
    $[42] = t10;
    $[43] = t11;
  } else {
    t10 = $[42];
    t11 = $[43];
  }
  (0, _react.useEffect)(t10, t11);
  let t12;
  let t13;
  if ($[44] !== identifier || $[45] !== onBack || $[46] !== onSave || $[47] !== stack) {
    t12 = () => {
      if (!onSave) {
        return;
      }
      const currentItem = stack.find(s_0 => s_0.id === identifier);
      if (currentItem == null ? void 0 : currentItem.sectionProps) {
        if (onSave) {
          currentItem.sectionProps.onSave = onSave;
        }
        if (onBack) {
          currentItem.sectionProps.onBack = onBack;
        }
      }
    };
    t13 = [onSave, onBack, stack, identifier];
    $[44] = identifier;
    $[45] = onBack;
    $[46] = onSave;
    $[47] = stack;
    $[48] = t12;
    $[49] = t13;
  } else {
    t12 = $[48];
    t13 = $[49];
  }
  (0, _react.useEffect)(t12, t13);
  let t14;
  let t15;
  if ($[50] !== onClick || $[51] !== trigger) {
    t14 = () => {
      if (trigger === true || trigger === false) {
        return;
      }
      const triggerRef = trigger;
      if (triggerRef && triggerRef.current) {
        triggerRef.current.addEventListener("click", onClick);
      }
      return () => {
        if (triggerRef && triggerRef.current) {
          triggerRef.current.removeEventListener("click", onClick);
        }
      };
    };
    t15 = [trigger, onClick];
    $[50] = onClick;
    $[51] = trigger;
    $[52] = t14;
    $[53] = t15;
  } else {
    t14 = $[52];
    t15 = $[53];
  }
  (0, _react.useEffect)(t14, t15);
  let t16;
  if ($[54] !== children || $[55] !== isOpen || $[56] !== pageContainer) {
    t16 = isOpen && pageContainer && (0, _reactDom.createPortal)(children, pageContainer);
    $[54] = children;
    $[55] = isOpen;
    $[56] = pageContainer;
    $[57] = t16;
  } else {
    t16 = $[57];
  }
  const content = t16;
  let t17;
  if ($[58] !== avatarUrl || $[59] !== icon || $[60] !== name || $[61] !== onClick || $[62] !== value) {
    t17 = /* @__PURE__ */_react.default.createElement(Button, __spreadValues({}, {
      name,
      value,
      avatarUrl,
      icon,
      onClick
    }));
    $[58] = avatarUrl;
    $[59] = icon;
    $[60] = name;
    $[61] = onClick;
    $[62] = value;
    $[63] = t17;
  } else {
    t17 = $[63];
  }
  let button = t17;
  if (trigger || typeof trigger === "boolean") {
    if (typeof trigger === "boolean" || (trigger == null ? void 0 : trigger.current)) {
      button = null;
    } else {
      if (_react.default.isValidElement(trigger)) {
        let t182;
        if ($[64] !== onClick || $[65] !== trigger) {
          let t19;
          if ($[67] !== onClick) {
            t19 = {
              onClick
            };
            $[67] = onClick;
            $[68] = t19;
          } else {
            t19 = $[68];
          }
          t182 = _react.default.cloneElement(trigger, t19);
          $[64] = onClick;
          $[65] = trigger;
          $[66] = t182;
        } else {
          t182 = $[66];
        }
        button = t182;
      }
    }
  }
  let t18;
  if ($[69] !== button || $[70] !== content) {
    t18 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, content, button);
    $[69] = button;
    $[70] = content;
    $[71] = t18;
  } else {
    t18 = $[71];
  }
  return t18;
});
const InputGroup = exports.InputGroup = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(4);
  const {
    children
  } = t0;
  let t1;
  if ($[0] !== children) {
    t1 = _react.default.Children.map(children, _temp);
    $[0] = children;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.inputGroup
    }, t1);
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
});
const Checkbox = exports.Checkbox = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(10);
  const {
    setChecked,
    checked,
    label
  } = t0;
  let t1;
  if ($[0] !== label) {
    t1 = label && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.checkboxLabel
    }, label);
    $[0] = label;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== setChecked) {
    t2 = (_e, t32) => {
      const {
        checked: checked_0
      } = t32;
      return setChecked(checked_0);
    };
    $[2] = setChecked;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] !== checked || $[5] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(_Checkbox.Checkbox, {
      checked,
      toggle: true,
      onChange: t2
    });
    $[4] = checked;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== t1 || $[8] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.checkboxWrapper
    }, t1, t3);
    $[7] = t1;
    $[8] = t3;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  return t4;
});
const Input = exports.Input = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(13);
  const {
    name,
    value,
    setValue,
    label,
    type,
    autoFocus
  } = t0;
  const settingsRouter = useSettingsRouter();
  const stack = settingsRouter.stack;
  const lastEl = stack[stack.length - 1];
  const t1 = autoFocus !== false;
  let t2;
  if ($[0] !== setValue) {
    t2 = e => setValue(e.target.value);
    $[0] = setValue;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== lastEl || $[3] !== settingsRouter) {
    t3 = () => __async(void 0, null, function* () {
      var _a;
      if ((_a = lastEl == null ? void 0 : lastEl.sectionProps) == null ? void 0 : _a.onSave) {
        if (yield lastEl.sectionProps.onSave()) {
          settingsRouter.pop();
        }
      }
    });
    $[2] = lastEl;
    $[3] = settingsRouter;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== label || $[6] !== name || $[7] !== t1 || $[8] !== t2 || $[9] !== t3 || $[10] !== type || $[11] !== value) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.inputWrapperWrapper
    }, /* @__PURE__ */_react.default.createElement(_Input.Input, {
      label,
      type,
      autoFocus: t1,
      wrapperClassName: styles.inputWrapper,
      className: styles.input,
      placeholder: name,
      value,
      onChange: t2,
      onEnter: t3
    }));
    $[5] = label;
    $[6] = name;
    $[7] = t1;
    $[8] = t2;
    $[9] = t3;
    $[10] = type;
    $[11] = value;
    $[12] = t4;
  } else {
    t4 = $[12];
  }
  return t4;
});
const TextArea = exports.TextArea = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    name,
    value,
    setValue
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [styles.input, styles.textArea];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== setValue) {
    t2 = e => setValue(e.target.value);
    $[1] = setValue;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== name || $[4] !== t2 || $[5] !== value) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.inputWrapperWrapper
    }, /* @__PURE__ */_react.default.createElement(_TextArea.TextArea, {
      className: t1,
      placeholder: name,
      value,
      onChange: t2
    }));
    $[3] = name;
    $[4] = t2;
    $[5] = value;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  return t3;
});
const SettingsContext = _react.default.createContext({
  settingsRouter: null,
  parentName: "",
  pageContainer: null,
  dark: false
});
const SettingsContextReset = t0 => {
  const $ = (0, _compilerRuntime.c)(3);
  const {
    children
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      settingsRouter: null,
      parentName: "",
      pageContainer: null,
      dark: void 0
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== children) {
    t2 = /* @__PURE__ */_react.default.createElement(SettingsContext.Provider, {
      value: t1
    }, children);
    $[1] = children;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  return t2;
};
exports.SettingsContextReset = SettingsContextReset;
const TopNav = (0, _react.memo)(() => {
  const settingsRouter = useSettingsRouter();
  const currentItem = settingsRouter == null ? void 0 : settingsRouter.stack[(settingsRouter == null ? void 0 : settingsRouter.stack.length) - 1];
  const currentSectionProps = currentItem == null ? void 0 : currentItem.sectionProps;
  let backName = (currentItem == null ? void 0 : currentItem.parentName) || "";
  if (backName.length > 9) {
    backName = "Back";
  }
  const currentName = (currentSectionProps == null ? void 0 : currentSectionProps.name) || "";
  const onSave = (currentSectionProps == null ? void 0 : currentSectionProps.onSave) || null;
  const emphasizeSave = (currentSectionProps == null ? void 0 : currentSectionProps.emphasizeSave) || false;
  const saveText = (currentSectionProps == null ? void 0 : currentSectionProps.saveText) || null;
  const getSectionProps = (0, _react.useCallback)(() => currentSectionProps, [currentSectionProps]);
  const [loading, setLoading] = (0, _react.useState)(false);
  const popRouter = (0, _react.useCallback)(() => settingsRouter.pop(), [settingsRouter]);
  const handleSave = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const {
      onSave: onSave_0
    } = getSectionProps();
    try {
      setLoading(true);
      if (onSave_0 && (yield onSave_0())) {
        settingsRouter.pop();
      }
    } finally {
      setLoading(false);
    }
  }), [getSectionProps, settingsRouter]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.header
  }, /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "var(--primary-light-20)",
    inverted: true,
    size: "xxl",
    onClick: popRouter,
    style: {
      borderStyle: "none",
      position: "absolute",
      padding: 0,
      left: 8,
      fontSize: 18
    }
  }, /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    name: "angle left"
  }), backName), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.topTitle
  }, currentName), onSave && /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "var(--primary-light-20)",
    inverted: true,
    size: "xxl",
    loading,
    onClick: handleSave,
    style: {
      borderStyle: "none",
      position: "absolute",
      padding: 0,
      right: 24,
      fontSize: 18,
      color: emphasizeSave ? "var(--secondary)" : "var(--primary-light-20)"
    }
  }, saveText ? saveText : "Save"));
});
const useSettingsRouter = () => {
  const $ = (0, _compilerRuntime.c)(15);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const [stack, setStack] = (0, _react.useState)(t0);
  const {
    settingsRouter
  } = (0, _react.useContext)(SettingsContext);
  let t1;
  if ($[1] !== stack) {
    t1 = t22 => {
      const numElements = t22 === void 0 ? 1 : t22;
      if (stack.length === 0) {
        return [];
      }
      const newStack = [...stack];
      const poppedElements = [];
      for (let i = 0; i < numElements && newStack.length > 0; i++) {
        const poppedElement = newStack.pop();
        if (poppedElement) {
          poppedElements.push(poppedElement);
        }
      }
      setStack(newStack);
      return poppedElements;
    };
    $[1] = stack;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const pop = t1;
  let t2;
  if ($[3] !== stack) {
    t2 = key => !!stack.find(e => e.id === key);
    $[3] = stack;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  const exists = t2;
  let t3;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = (key_0, el) => {
      setStack(prevStack => [...prevStack.map(e_0 => {
        var _a;
        if (((_a = e_0 == null ? void 0 : e_0.sectionProps) == null ? void 0 : _a.key) === key_0) {
          return el;
        }
        return e_0;
      })]);
    };
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  const replace = t3;
  let t4;
  if ($[6] !== exists) {
    t4 = el_0 => {
      if ((el_0 == null ? void 0 : el_0.id) && exists(el_0.id)) {
        replace(el_0.id, el_0);
      } else {
        setStack(prevStack_0 => [...prevStack_0.filter(e_1 => (e_1 == null ? void 0 : e_1.id) !== (el_0 == null ? void 0 : el_0.id)), el_0]);
      }
    };
    $[6] = exists;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const push = t4;
  if (settingsRouter) {
    let t52;
    if ($[8] !== settingsRouter) {
      t52 = __spreadProps(__spreadValues({}, settingsRouter), {
        isRoot: false
      });
      $[8] = settingsRouter;
      $[9] = t52;
    } else {
      t52 = $[9];
    }
    return t52;
  }
  const t5 = stack[stack.length - 1];
  let t6;
  if ($[10] !== pop || $[11] !== push || $[12] !== stack || $[13] !== t5) {
    t6 = {
      pop,
      push,
      replace,
      stack,
      topItem: t5,
      isRoot: true,
      level: 0
    };
    $[10] = pop;
    $[11] = push;
    $[12] = stack;
    $[13] = t5;
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  return t6;
};
exports.useSettingsRouter = useSettingsRouter;
const useSettingsLevel = () => {
  const settingsRouter = useSettingsRouter();
  return settingsRouter.level;
};
exports.useSettingsLevel = useSettingsLevel;
const Container = t0 => {
  const $ = (0, _compilerRuntime.c)(22);
  const {
    children,
    name: parentName,
    dark: darkProp
  } = t0;
  const {
    dark
  } = (0, _react.useContext)(SettingsContext);
  const settingsRouter = useSettingsRouter();
  const level = settingsRouter.level + 1;
  const pageOpen = settingsRouter.stack.filter(p => p.level === level).length > 0;
  const [pageContainer, setPageContainer] = (0, _react.useState)(null);
  let t1;
  if ($[0] !== level || $[1] !== settingsRouter) {
    t1 = __spreadProps(__spreadValues({}, settingsRouter), {
      level
    });
    $[0] = level;
    $[1] = settingsRouter;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const t2 = darkProp || dark;
  let t3;
  if ($[3] !== pageContainer || $[4] !== parentName || $[5] !== t1 || $[6] !== t2) {
    t3 = {
      settingsRouter: t1,
      parentName,
      pageContainer,
      dark: t2
    };
    $[3] = pageContainer;
    $[4] = parentName;
    $[5] = t1;
    $[6] = t2;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  const t4 = pageOpen ? 1 : 0;
  let t5;
  if ($[8] !== children) {
    t5 = /* @__PURE__ */_react.default.createElement(_TabView.TabView.Item, {
      name: "Settings"
    }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.settingsScreen
    }, children));
    $[8] = children;
    $[9] = t5;
  } else {
    t5 = $[9];
  }
  let t6;
  if ($[10] !== settingsRouter.isRoot) {
    t6 = settingsRouter.isRoot && /* @__PURE__ */_react.default.createElement(TopNav, null);
    $[10] = settingsRouter.isRoot;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref: setPageContainer,
      style: styles.settingsScreen
    });
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  let t8;
  if ($[13] !== t6) {
    t8 = /* @__PURE__ */_react.default.createElement(_TabView.TabView.Item, {
      name: "Subpage"
    }, t6, t7);
    $[13] = t6;
    $[14] = t8;
  } else {
    t8 = $[14];
  }
  let t9;
  if ($[15] !== t4 || $[16] !== t5 || $[17] !== t8) {
    t9 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.wrapper
    }, /* @__PURE__ */_react.default.createElement(_TabView.TabView, {
      forceIndex: t4,
      menu: false
    }, t5, t8));
    $[15] = t4;
    $[16] = t5;
    $[17] = t8;
    $[18] = t9;
  } else {
    t9 = $[18];
  }
  let t10;
  if ($[19] !== t3 || $[20] !== t9) {
    t10 = /* @__PURE__ */_react.default.createElement(SettingsContext.Provider, {
      value: t3
    }, t9);
    $[19] = t3;
    $[20] = t9;
    $[21] = t10;
  } else {
    t10 = $[21];
  }
  return t10;
};
exports.Container = Container;
function _temp(child, i) {
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, i > 0 && child && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.divider
  }), child);
}