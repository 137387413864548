"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Select = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Image = require("./Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  dropdown: {
    "Select__styles.dropdown": "Select__styles.dropdown",
    cursor: "x1ypdohk",
    position: "x1n2onr6",
    display: "x1rg5ohu",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    textAlign: "xdpxx8g",
    transition: "x8g6ub2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    height: "x16wdlz0",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    visibility: "xnpuxes",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    appearance: "xjyslct",
    backgroundImage: "x17gz8yi",
    backgroundRepeat: "xiy17q3",
    backgroundPositionX: "x10mjbor",
    backgroundPositionY: "x1yzgxni",
    $$css: true
  },
  compact: {
    "Select__styles.compact": "Select__styles.compact",
    minWidth: "xeuugli",
    $$css: true
  },
  disabled: {
    "Select__styles.disabled": "Select__styles.disabled",
    cursor: "xt0e3qv",
    pointerEvents: "x47corl",
    opacity: "xyd83as",
    $$css: true
  },
  image: {
    "Select__styles.image": "Select__styles.image",
    paddingLeft: "x1dcfgta",
    $$css: true
  },
  label: {
    "Select__styles.label": "Select__styles.label",
    paddingBottom: "x1a8lsjc",
    fontWeight: "x117nqv4",
    $$css: true
  },
  selection: {
    "Select__styles.selection": "Select__styles.selection",
    cursor: "x1ypdohk",
    wordWrap: "x1vvkbs",
    lineHeight: "xtqhoxe",
    whiteSpace: "xeaf4i8",
    outline: "x1b6ykf0",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    transform: "x1atnlmd",
    minWidth: "x1fh14hl",
    minHeight: "xyt6bas",
    backgroundColor: "x128cn8a",
    display: "x1rg5ohu",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x11jfisy",
    boxShadow: "x1gnnqk1",
    borderRadius: "x1aaq583",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    transition: "x8g6ub2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    fontWeight: "x1fcty0u",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    ":hover_borderColor": "x1saclv8",
    ":hover_borderInlineColor": null,
    ":hover_borderInlineStartColor": null,
    ":hover_borderLeftColor": null,
    ":hover_borderInlineEndColor": null,
    ":hover_borderRightColor": null,
    ":hover_borderBlockColor": null,
    ":hover_borderTopColor": null,
    ":hover_borderBottomColor": null,
    ":focus_borderColor": "x1eg2tmu",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    ":focus-within_borderColor": "x6po30u",
    ":focus-within_borderInlineColor": null,
    ":focus-within_borderInlineStartColor": null,
    ":focus-within_borderLeftColor": null,
    ":focus-within_borderInlineEndColor": null,
    ":focus-within_borderRightColor": null,
    ":focus-within_borderBlockColor": null,
    ":focus-within_borderTopColor": null,
    ":focus-within_borderBottomColor": null,
    $$css: true
  },
  select: {
    "Select__styles.select": "Select__styles.select",
    display: "x78zum5",
    flex: "x98rzlu",
    width: "xh8yej3",
    height: "x5yr21d",
    borderWidth: "xc342km",
    background: "x11g6tue",
    padding: "xsu7wfv",
    cursor: "x1ypdohk",
    outline: "x1a2a7pz",
    appearance: "xjyslct",
    color: "x11jfisy",
    $$css: true
  },
  img: {
    "Select__styles.img": "Select__styles.img",
    position: "x10l6tqk",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    height: "xt7dq6l",
    zIndex: "xhtitgo",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    pointerEvents: "x47corl",
    left: "xc1hvs2",
    insetInlineStart: null,
    insetInlineEnd: null,
    width: "xgd8bvy",
    maxHeight: "xz75wtk",
    cursor: "x1ypdohk",
    $$css: true
  }
};
const Select = exports.Select = (0, _react.memo)(({
  id,
  name,
  defaultValue,
  className,
  value,
  onChange,
  options,
  disabled,
  label,
  compact,
  placeholder,
  required
}) => {
  var _a, _b;
  const image = (_a = options.find(o => !!o.image && o.value === value)) == null ? void 0 : _a.image;
  const hasImage = !!((_b = options.find(o => !!o.image)) == null ? void 0 : _b.image);
  const {
    className: selectClassName
  } = stylex.props(styles.select, hasImage && styles.image);
  const {
    className: optionClassName
  } = {
    className: "Select__styles.option x1gle93d x1dwjgk9"
  };
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
    style: styles.label
  }, label), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    id,
    style: [styles.selection, styles.dropdown, compact && styles.compact, disabled && styles.disabled, className]
  }, image && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.img
  }, /* @__PURE__ */_react.default.createElement(_Image.Image, {
    src: image,
    avatar: true
  })), /* @__PURE__ */_react.default.createElement("select", {
    defaultValue,
    disabled,
    onChange,
    value,
    className: selectClassName,
    name,
    required
  }, !value && !defaultValue && /* @__PURE__ */_react.default.createElement("option", {
    className: optionClassName,
    value: "",
    disabled: true,
    selected: !value
  }, placeholder || "Select an option"), options.map(({
    text,
    value: optionValue
  }) => /* @__PURE__ */_react.default.createElement("option", {
    key: optionValue,
    value: optionValue,
    selected: optionValue === value
  }, text, " ", optionValue === value)))));
});