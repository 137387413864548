"use strict";
import { useTracking } from "@/shared/hooks/useTracking";
import React, { useState } from "react";
import { Modal } from "@/uikit";
import RoomSettings from "./RoomSettings";
export default ({
  onOpen,
  trigger
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const track = useTracking();
  const handleOpen = () => {
    if (onOpen)
      onOpen();
    track("UI", "OpenRoomSettingsModal", "Open room settings modal");
  };
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      large: true,
      open: modalOpen,
      trigger,
      onOpen: handleOpen,
      onClose: handleClose
    },
    /* @__PURE__ */ React.createElement(Modal.Header, null, "Room Settings"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(RoomSettings, null))
  );
};
