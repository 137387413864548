"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Flag = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  ad: {
    "Flag__styles.ad": "Flag__styles.ad",
    "::before_backgroundPosition": "xnvg7c1",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ae: {
    "Flag__styles.ae": "Flag__styles.ae",
    "::before_backgroundPosition": "x18xxt3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  af: {
    "Flag__styles.af": "Flag__styles.af",
    "::before_backgroundPosition": "x931o20",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ag: {
    "Flag__styles.ag": "Flag__styles.ag",
    "::before_backgroundPosition": "x1h9ij65",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ai: {
    "Flag__styles.ai": "Flag__styles.ai",
    "::before_backgroundPosition": "x7djx6h",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  al: {
    "Flag__styles.al": "Flag__styles.al",
    "::before_backgroundPosition": "x1v3wbga",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  am: {
    "Flag__styles.am": "Flag__styles.am",
    "::before_backgroundPosition": "x1ic52kt",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  an: {
    "Flag__styles.an": "Flag__styles.an",
    "::before_backgroundPosition": "x15m7t48",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ao: {
    "Flag__styles.ao": "Flag__styles.ao",
    "::before_backgroundPosition": "x84u0x5",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ar: {
    "Flag__styles.ar": "Flag__styles.ar",
    "::before_backgroundPosition": "xssnr07",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  as: {
    "Flag__styles.as": "Flag__styles.as",
    "::before_backgroundPosition": "x4mxc2t",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  at: {
    "Flag__styles.at": "Flag__styles.at",
    "::before_backgroundPosition": "xb2q0v0",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  au: {
    "Flag__styles.au": "Flag__styles.au",
    "::before_backgroundPosition": "xurdti3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  aw: {
    "Flag__styles.aw": "Flag__styles.aw",
    "::before_backgroundPosition": "x18rcp26",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ax: {
    "Flag__styles.ax": "Flag__styles.ax",
    "::before_backgroundPosition": "xrdxzi8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  az: {
    "Flag__styles.az": "Flag__styles.az",
    "::before_backgroundPosition": "x1tqfhtl",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ba: {
    "Flag__styles.ba": "Flag__styles.ba",
    "::before_backgroundPosition": "x111ocwz",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bb: {
    "Flag__styles.bb": "Flag__styles.bb",
    "::before_backgroundPosition": "xxoi6cz",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bd: {
    "Flag__styles.bd": "Flag__styles.bd",
    "::before_backgroundPosition": "xottpoa",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  be: {
    "Flag__styles.be": "Flag__styles.be",
    "::before_backgroundPosition": "xwnym3y",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bf: {
    "Flag__styles.bf": "Flag__styles.bf",
    "::before_backgroundPosition": "x17eevwj",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bg: {
    "Flag__styles.bg": "Flag__styles.bg",
    "::before_backgroundPosition": "xgy8jgz",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bh: {
    "Flag__styles.bh": "Flag__styles.bh",
    "::before_backgroundPosition": "x16rat4f",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bi: {
    "Flag__styles.bi": "Flag__styles.bi",
    "::before_backgroundPosition": "x1kmy6dv",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bj: {
    "Flag__styles.bj": "Flag__styles.bj",
    "::before_backgroundPosition": "x1s39sb7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bm: {
    "Flag__styles.bm": "Flag__styles.bm",
    "::before_backgroundPosition": "x1f5jei4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bn: {
    "Flag__styles.bn": "Flag__styles.bn",
    "::before_backgroundPosition": "x6m87wq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bo: {
    "Flag__styles.bo": "Flag__styles.bo",
    "::before_backgroundPosition": "x1qi7vls",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  br: {
    "Flag__styles.br": "Flag__styles.br",
    "::before_backgroundPosition": "x67vtoc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bs: {
    "Flag__styles.bs": "Flag__styles.bs",
    "::before_backgroundPosition": "x1wibrih",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bt: {
    "Flag__styles.bt": "Flag__styles.bt",
    "::before_backgroundPosition": "x1xj9udh",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bv: {
    "Flag__styles.bv": "Flag__styles.bv",
    "::before_backgroundPosition": "xxrffcc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bw: {
    "Flag__styles.bw": "Flag__styles.bw",
    "::before_backgroundPosition": "x1xh4flp",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  by: {
    "Flag__styles.by": "Flag__styles.by",
    "::before_backgroundPosition": "x1dgtko9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  bz: {
    "Flag__styles.bz": "Flag__styles.bz",
    "::before_backgroundPosition": "x14ma3h2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ca: {
    "Flag__styles.ca": "Flag__styles.ca",
    "::before_backgroundPosition": "x7zwq8b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cc: {
    "Flag__styles.cc": "Flag__styles.cc",
    "::before_backgroundPosition": "x1x9uamo",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cd: {
    "Flag__styles.cd": "Flag__styles.cd",
    "::before_backgroundPosition": "xp238bk",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cf: {
    "Flag__styles.cf": "Flag__styles.cf",
    "::before_backgroundPosition": "x8rn14e",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cg: {
    "Flag__styles.cg": "Flag__styles.cg",
    "::before_backgroundPosition": "x14mmie9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ch: {
    "Flag__styles.ch": "Flag__styles.ch",
    "::before_backgroundPosition": "xjhq1ew",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ci: {
    "Flag__styles.ci": "Flag__styles.ci",
    "::before_backgroundPosition": "x1i9uk8j",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ck: {
    "Flag__styles.ck": "Flag__styles.ck",
    "::before_backgroundPosition": "x3szd0m",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cl: {
    "Flag__styles.cl": "Flag__styles.cl",
    "::before_backgroundPosition": "x46allw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cm: {
    "Flag__styles.cm": "Flag__styles.cm",
    "::before_backgroundPosition": "x17k1wbt",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cn: {
    "Flag__styles.cn": "Flag__styles.cn",
    "::before_backgroundPosition": "x17d93p3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  co: {
    "Flag__styles.co": "Flag__styles.co",
    "::before_backgroundPosition": "xwd8be5",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cr: {
    "Flag__styles.cr": "Flag__styles.cr",
    "::before_backgroundPosition": "x1wjcnz2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cs: {
    "Flag__styles.cs": "Flag__styles.cs",
    "::before_backgroundPosition": "xqqzj2t",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cu: {
    "Flag__styles.cu": "Flag__styles.cu",
    "::before_backgroundPosition": "x1x5f3r9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cv: {
    "Flag__styles.cv": "Flag__styles.cv",
    "::before_backgroundPosition": "x12ab514",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cx: {
    "Flag__styles.cx": "Flag__styles.cx",
    "::before_backgroundPosition": "x1rdqogh",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cy: {
    "Flag__styles.cy": "Flag__styles.cy",
    "::before_backgroundPosition": "xivdint",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  cz: {
    "Flag__styles.cz": "Flag__styles.cz",
    "::before_backgroundPosition": "xdrnu3d",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  de: {
    "Flag__styles.de": "Flag__styles.de",
    "::before_backgroundPosition": "xviiv08",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  dj: {
    "Flag__styles.dj": "Flag__styles.dj",
    "::before_backgroundPosition": "x1kdgqv4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  dk: {
    "Flag__styles.dk": "Flag__styles.dk",
    "::before_backgroundPosition": "xi5q8uc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  dm: {
    "Flag__styles.dm": "Flag__styles.dm",
    "::before_backgroundPosition": "xfsvm4r",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  do: {
    "Flag__styles.do": "Flag__styles.do",
    "::before_backgroundPosition": "x1u6cb4b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  dz: {
    "Flag__styles.dz": "Flag__styles.dz",
    "::before_backgroundPosition": "xgh9gb2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ec: {
    "Flag__styles.ec": "Flag__styles.ec",
    "::before_backgroundPosition": "x2rs6qd",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ee: {
    "Flag__styles.ee": "Flag__styles.ee",
    "::before_backgroundPosition": "x14di1f9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  eg: {
    "Flag__styles.eg": "Flag__styles.eg",
    "::before_backgroundPosition": "xnvzaxm",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  eh: {
    "Flag__styles.eh": "Flag__styles.eh",
    "::before_backgroundPosition": "xe5vdy5",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  er: {
    "Flag__styles.er": "Flag__styles.er",
    "::before_backgroundPosition": "xmcbofi",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  es: {
    "Flag__styles.es": "Flag__styles.es",
    "::before_backgroundPosition": "x1s0v0wj",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  et: {
    "Flag__styles.et": "Flag__styles.et",
    "::before_backgroundPosition": "x10efiv6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  eu: {
    "Flag__styles.eu": "Flag__styles.eu",
    "::before_backgroundPosition": "xq9c7m5",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fi: {
    "Flag__styles.fi": "Flag__styles.fi",
    "::before_backgroundPosition": "x89ehbw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fj: {
    "Flag__styles.fj": "Flag__styles.fj",
    "::before_backgroundPosition": "x10w8kaq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fk: {
    "Flag__styles.fk": "Flag__styles.fk",
    "::before_backgroundPosition": "x9tdibx",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fm: {
    "Flag__styles.fm": "Flag__styles.fm",
    "::before_backgroundPosition": "x93plvr",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fo: {
    "Flag__styles.fo": "Flag__styles.fo",
    "::before_backgroundPosition": "x1rp695l",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  fr: {
    "Flag__styles.fr": "Flag__styles.fr",
    "::before_backgroundPosition": "xsl0oi",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ga: {
    "Flag__styles.ga": "Flag__styles.ga",
    "::before_backgroundPosition": "xargzoq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gb: {
    "Flag__styles.gb": "Flag__styles.gb",
    "::before_backgroundPosition": "xjo3icr",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gd: {
    "Flag__styles.gd": "Flag__styles.gd",
    "::before_backgroundPosition": "x3lb7uy",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ge: {
    "Flag__styles.ge": "Flag__styles.ge",
    "::before_backgroundPosition": "x18r6uir",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gf: {
    "Flag__styles.gf": "Flag__styles.gf",
    "::before_backgroundPosition": "x1njc82b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gh: {
    "Flag__styles.gh": "Flag__styles.gh",
    "::before_backgroundPosition": "x1llgt6f",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gi: {
    "Flag__styles.gi": "Flag__styles.gi",
    "::before_backgroundPosition": "x1slt4rs",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gl: {
    "Flag__styles.gl": "Flag__styles.gl",
    "::before_backgroundPosition": "x1er9upg",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gm: {
    "Flag__styles.gm": "Flag__styles.gm",
    "::before_backgroundPosition": "x17jckcq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gn: {
    "Flag__styles.gn": "Flag__styles.gn",
    "::before_backgroundPosition": "x1oiu8ot",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gp: {
    "Flag__styles.gp": "Flag__styles.gp",
    "::before_backgroundPosition": "x1bdw2rh",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gq: {
    "Flag__styles.gq": "Flag__styles.gq",
    "::before_backgroundPosition": "xldq6y7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gr: {
    "Flag__styles.gr": "Flag__styles.gr",
    "::before_backgroundPosition": "x7vwvk2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gs: {
    "Flag__styles.gs": "Flag__styles.gs",
    "::before_backgroundPosition": "x8sps2g",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gt: {
    "Flag__styles.gt": "Flag__styles.gt",
    "::before_backgroundPosition": "xmspxp6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gu: {
    "Flag__styles.gu": "Flag__styles.gu",
    "::before_backgroundPosition": "x19gk7h9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gw: {
    "Flag__styles.gw": "Flag__styles.gw",
    "::before_backgroundPosition": "xi7roy6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gy: {
    "Flag__styles.gy": "Flag__styles.gy",
    "::before_backgroundPosition": "x1go6e3b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  hk: {
    "Flag__styles.hk": "Flag__styles.hk",
    "::before_backgroundPosition": "xlxghdt",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  hm: {
    "Flag__styles.hm": "Flag__styles.hm",
    "::before_backgroundPosition": "xk9kc8p",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  hn: {
    "Flag__styles.hn": "Flag__styles.hn",
    "::before_backgroundPosition": "x1ssxlab",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  hr: {
    "Flag__styles.hr": "Flag__styles.hr",
    "::before_backgroundPosition": "xlp208p",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ht: {
    "Flag__styles.ht": "Flag__styles.ht",
    "::before_backgroundPosition": "xyccyd9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  hu: {
    "Flag__styles.hu": "Flag__styles.hu",
    "::before_backgroundPosition": "xlm4zsu",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  id: {
    "Flag__styles.id": "Flag__styles.id",
    "::before_backgroundPosition": "xo5qah6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ie: {
    "Flag__styles.ie": "Flag__styles.ie",
    "::before_backgroundPosition": "xhkqpcs",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  il: {
    "Flag__styles.il": "Flag__styles.il",
    "::before_backgroundPosition": "x1l8uoqk",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  in: {
    "Flag__styles.in": "Flag__styles.in",
    "::before_backgroundPosition": "x19d2wlu",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  io: {
    "Flag__styles.io": "Flag__styles.io",
    "::before_backgroundPosition": "xi6f8iq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  iq: {
    "Flag__styles.iq": "Flag__styles.iq",
    "::before_backgroundPosition": "x1590nnh",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ir: {
    "Flag__styles.ir": "Flag__styles.ir",
    "::before_backgroundPosition": "x1a04ilg",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  is: {
    "Flag__styles.is": "Flag__styles.is",
    "::before_backgroundPosition": "xjgu2dw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  it: {
    "Flag__styles.it": "Flag__styles.it",
    "::before_backgroundPosition": "x1ygi4ax",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  jm: {
    "Flag__styles.jm": "Flag__styles.jm",
    "::before_backgroundPosition": "x1a49c1l",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  jo: {
    "Flag__styles.jo": "Flag__styles.jo",
    "::before_backgroundPosition": "xvxqut3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  jp: {
    "Flag__styles.jp": "Flag__styles.jp",
    "::before_backgroundPosition": "xvu9j4w",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ke: {
    "Flag__styles.ke": "Flag__styles.ke",
    "::before_backgroundPosition": "xjv99ey",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kg: {
    "Flag__styles.kg": "Flag__styles.kg",
    "::before_backgroundPosition": "x15rki7b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kh: {
    "Flag__styles.kh": "Flag__styles.kh",
    "::before_backgroundPosition": "xghdrdb",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ki: {
    "Flag__styles.ki": "Flag__styles.ki",
    "::before_backgroundPosition": "xtpgnhp",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  km: {
    "Flag__styles.km": "Flag__styles.km",
    "::before_backgroundPosition": "x1cacn4f",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kn: {
    "Flag__styles.kn": "Flag__styles.kn",
    "::before_backgroundPosition": "xs6ugm4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kp: {
    "Flag__styles.kp": "Flag__styles.kp",
    "::before_backgroundPosition": "x1kslf29",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kr: {
    "Flag__styles.kr": "Flag__styles.kr",
    "::before_backgroundPosition": "x1cg8t3i",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kw: {
    "Flag__styles.kw": "Flag__styles.kw",
    "::before_backgroundPosition": "x89ympp",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ky: {
    "Flag__styles.ky": "Flag__styles.ky",
    "::before_backgroundPosition": "xba4jyu",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  kz: {
    "Flag__styles.kz": "Flag__styles.kz",
    "::before_backgroundPosition": "xw91utm",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  la: {
    "Flag__styles.la": "Flag__styles.la",
    "::before_backgroundPosition": "xnauaq8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lb: {
    "Flag__styles.lb": "Flag__styles.lb",
    "::before_backgroundPosition": "x1wm8z3m",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lc: {
    "Flag__styles.lc": "Flag__styles.lc",
    "::before_backgroundPosition": "x1tqd2p3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  li: {
    "Flag__styles.li": "Flag__styles.li",
    "::before_backgroundPosition": "x18vlkgc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lk: {
    "Flag__styles.lk": "Flag__styles.lk",
    "::before_backgroundPosition": "xvtwd8a",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lr: {
    "Flag__styles.lr": "Flag__styles.lr",
    "::before_backgroundPosition": "xqayd4k",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ls: {
    "Flag__styles.ls": "Flag__styles.ls",
    "::before_backgroundPosition": "x1tlyz4x",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lt: {
    "Flag__styles.lt": "Flag__styles.lt",
    "::before_backgroundPosition": "x1fymb6t",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lu: {
    "Flag__styles.lu": "Flag__styles.lu",
    "::before_backgroundPosition": "xn2xpai",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  lv: {
    "Flag__styles.lv": "Flag__styles.lv",
    "::before_backgroundPosition": "xcaid5o",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ly: {
    "Flag__styles.ly": "Flag__styles.ly",
    "::before_backgroundPosition": "xluz5jl",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ma: {
    "Flag__styles.ma": "Flag__styles.ma",
    "::before_backgroundPosition": "x10tjtmi",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mc: {
    "Flag__styles.mc": "Flag__styles.mc",
    "::before_backgroundPosition": "x1dypcro",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  md: {
    "Flag__styles.md": "Flag__styles.md",
    "::before_backgroundPosition": "x1uam1er",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  me: {
    "Flag__styles.me": "Flag__styles.me",
    "::before_backgroundPosition": "x1cg9cht",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mg: {
    "Flag__styles.mg": "Flag__styles.mg",
    "::before_backgroundPosition": "xaqbry4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mh: {
    "Flag__styles.mh": "Flag__styles.mh",
    "::before_backgroundPosition": "x1ykdr2l",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mk: {
    "Flag__styles.mk": "Flag__styles.mk",
    "::before_backgroundPosition": "x1ixquss",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ml: {
    "Flag__styles.ml": "Flag__styles.ml",
    "::before_backgroundPosition": "x13homzc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mm: {
    "Flag__styles.mm": "Flag__styles.mm",
    "::before_backgroundPosition": "x1vxz7gs",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mn: {
    "Flag__styles.mn": "Flag__styles.mn",
    "::before_backgroundPosition": "xccyvx0",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mo: {
    "Flag__styles.mo": "Flag__styles.mo",
    "::before_backgroundPosition": "x1c7tsdy",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mp: {
    "Flag__styles.mp": "Flag__styles.mp",
    "::before_backgroundPosition": "x1xsnkyy",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mq: {
    "Flag__styles.mq": "Flag__styles.mq",
    "::before_backgroundPosition": "x1lcn5po",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mr: {
    "Flag__styles.mr": "Flag__styles.mr",
    "::before_backgroundPosition": "x16p068m",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ms: {
    "Flag__styles.ms": "Flag__styles.ms",
    "::before_backgroundPosition": "x11q2p4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mt: {
    "Flag__styles.mt": "Flag__styles.mt",
    "::before_backgroundPosition": "x1dqx6a",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mu: {
    "Flag__styles.mu": "Flag__styles.mu",
    "::before_backgroundPosition": "x1ji4zb8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mv: {
    "Flag__styles.mv": "Flag__styles.mv",
    "::before_backgroundPosition": "x17uf5k7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mw: {
    "Flag__styles.mw": "Flag__styles.mw",
    "::before_backgroundPosition": "x1j13ff1",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mx: {
    "Flag__styles.mx": "Flag__styles.mx",
    "::before_backgroundPosition": "xp9gb0h",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  my: {
    "Flag__styles.my": "Flag__styles.my",
    "::before_backgroundPosition": "x3t60ih",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  mz: {
    "Flag__styles.mz": "Flag__styles.mz",
    "::before_backgroundPosition": "xxcj579",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  na: {
    "Flag__styles.na": "Flag__styles.na",
    "::before_backgroundPosition": "x1upi018",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nc: {
    "Flag__styles.nc": "Flag__styles.nc",
    "::before_backgroundPosition": "x2bgl18",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ne: {
    "Flag__styles.ne": "Flag__styles.ne",
    "::before_backgroundPosition": "x1ntkvph",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nf: {
    "Flag__styles.nf": "Flag__styles.nf",
    "::before_backgroundPosition": "x1pr8ibk",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ng: {
    "Flag__styles.ng": "Flag__styles.ng",
    "::before_backgroundPosition": "x1q10ira",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ni: {
    "Flag__styles.ni": "Flag__styles.ni",
    "::before_backgroundPosition": "xn2zwo",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nl: {
    "Flag__styles.nl": "Flag__styles.nl",
    "::before_backgroundPosition": "x10e1jyt",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  no: {
    "Flag__styles.no": "Flag__styles.no",
    "::before_backgroundPosition": "x1tqwivg",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  np: {
    "Flag__styles.np": "Flag__styles.np",
    "::before_backgroundPosition": "x1ugl8a8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nr: {
    "Flag__styles.nr": "Flag__styles.nr",
    "::before_backgroundPosition": "x1jie6br",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nu: {
    "Flag__styles.nu": "Flag__styles.nu",
    "::before_backgroundPosition": "xlqb3i",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  nz: {
    "Flag__styles.nz": "Flag__styles.nz",
    "::before_backgroundPosition": "x1vsno3i",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  om: {
    "Flag__styles.om": "Flag__styles.om",
    "::before_backgroundPosition": "x1g65nu5",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pa: {
    "Flag__styles.pa": "Flag__styles.pa",
    "::before_backgroundPosition": "xe8udro",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pe: {
    "Flag__styles.pe": "Flag__styles.pe",
    "::before_backgroundPosition": "x1c97329",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pf: {
    "Flag__styles.pf": "Flag__styles.pf",
    "::before_backgroundPosition": "x1p0auqg",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pg: {
    "Flag__styles.pg": "Flag__styles.pg",
    "::before_backgroundPosition": "x1qw7mrr",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ph: {
    "Flag__styles.ph": "Flag__styles.ph",
    "::before_backgroundPosition": "xujhfzq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pk: {
    "Flag__styles.pk": "Flag__styles.pk",
    "::before_backgroundPosition": "x1br5zei",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pl: {
    "Flag__styles.pl": "Flag__styles.pl",
    "::before_backgroundPosition": "xaqyefe",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pm: {
    "Flag__styles.pm": "Flag__styles.pm",
    "::before_backgroundPosition": "xerpjn1",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pn: {
    "Flag__styles.pn": "Flag__styles.pn",
    "::before_backgroundPosition": "x13xrxoo",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pr: {
    "Flag__styles.pr": "Flag__styles.pr",
    "::before_backgroundPosition": "x3u6ir6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ps: {
    "Flag__styles.ps": "Flag__styles.ps",
    "::before_backgroundPosition": "xxaryr7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pt: {
    "Flag__styles.pt": "Flag__styles.pt",
    "::before_backgroundPosition": "xmlwil6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  pw: {
    "Flag__styles.pw": "Flag__styles.pw",
    "::before_backgroundPosition": "xyz57se",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  py: {
    "Flag__styles.py": "Flag__styles.py",
    "::before_backgroundPosition": "x1fk68cw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  qa: {
    "Flag__styles.qa": "Flag__styles.qa",
    "::before_backgroundPosition": "x1qmmm49",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  re: {
    "Flag__styles.re": "Flag__styles.re",
    "::before_backgroundPosition": "xp0w0qm",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ro: {
    "Flag__styles.ro": "Flag__styles.ro",
    "::before_backgroundPosition": "x15eeqqd",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  rs: {
    "Flag__styles.rs": "Flag__styles.rs",
    "::before_backgroundPosition": "x3wb7id",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ru: {
    "Flag__styles.ru": "Flag__styles.ru",
    "::before_backgroundPosition": "x1rhci8y",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  rw: {
    "Flag__styles.rw": "Flag__styles.rw",
    "::before_backgroundPosition": "x8s10rv",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sa: {
    "Flag__styles.sa": "Flag__styles.sa",
    "::before_backgroundPosition": "x23u3k6",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sb: {
    "Flag__styles.sb": "Flag__styles.sb",
    "::before_backgroundPosition": "x1wvby7f",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sc: {
    "Flag__styles.sc": "Flag__styles.sc",
    "::before_backgroundPosition": "xw77k3g",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gbsct: {
    "Flag__styles.gbsct": "Flag__styles.gbsct",
    "::before_backgroundPosition": "x1do57fs",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sd: {
    "Flag__styles.sd": "Flag__styles.sd",
    "::before_backgroundPosition": "x1mvfhx3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  se: {
    "Flag__styles.se": "Flag__styles.se",
    "::before_backgroundPosition": "x7j8k8i",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sg: {
    "Flag__styles.sg": "Flag__styles.sg",
    "::before_backgroundPosition": "xh4g5gr",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sh: {
    "Flag__styles.sh": "Flag__styles.sh",
    "::before_backgroundPosition": "xp7a3iw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  si: {
    "Flag__styles.si": "Flag__styles.si",
    "::before_backgroundPosition": "x1wp7j02",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sj: {
    "Flag__styles.sj": "Flag__styles.sj",
    "::before_backgroundPosition": "x1cfi028",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sk: {
    "Flag__styles.sk": "Flag__styles.sk",
    "::before_backgroundPosition": "x1rhip75",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sl: {
    "Flag__styles.sl": "Flag__styles.sl",
    "::before_backgroundPosition": "xffl3d9",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sm: {
    "Flag__styles.sm": "Flag__styles.sm",
    "::before_backgroundPosition": "x4pa0m7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sn: {
    "Flag__styles.sn": "Flag__styles.sn",
    "::before_backgroundPosition": "xfwqri8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  so: {
    "Flag__styles.so": "Flag__styles.so",
    "::before_backgroundPosition": "xemzdf8",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sr: {
    "Flag__styles.sr": "Flag__styles.sr",
    "::before_backgroundPosition": "x1f7pfr0",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  st: {
    "Flag__styles.st": "Flag__styles.st",
    "::before_backgroundPosition": "xfo6l8y",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sv: {
    "Flag__styles.sv": "Flag__styles.sv",
    "::before_backgroundPosition": "x1pr6q6h",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sy: {
    "Flag__styles.sy": "Flag__styles.sy",
    "::before_backgroundPosition": "x1y83ywa",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  sz: {
    "Flag__styles.sz": "Flag__styles.sz",
    "::before_backgroundPosition": "x1pen8x3",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tc: {
    "Flag__styles.tc": "Flag__styles.tc",
    "::before_backgroundPosition": "x1zvpv7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  td: {
    "Flag__styles.td": "Flag__styles.td",
    "::before_backgroundPosition": "x16kzzde",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tf: {
    "Flag__styles.tf": "Flag__styles.tf",
    "::before_backgroundPosition": "x1hwcgyv",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tg: {
    "Flag__styles.tg": "Flag__styles.tg",
    "::before_backgroundPosition": "xw2mj1a",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  th: {
    "Flag__styles.th": "Flag__styles.th",
    "::before_backgroundPosition": "xye1u2t",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tj: {
    "Flag__styles.tj": "Flag__styles.tj",
    "::before_backgroundPosition": "x74pkv0",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tk: {
    "Flag__styles.tk": "Flag__styles.tk",
    "::before_backgroundPosition": "xxn3hft",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tl: {
    "Flag__styles.tl": "Flag__styles.tl",
    "::before_backgroundPosition": "xsnww0z",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tm: {
    "Flag__styles.tm": "Flag__styles.tm",
    "::before_backgroundPosition": "x1whrnmo",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tn: {
    "Flag__styles.tn": "Flag__styles.tn",
    "::before_backgroundPosition": "x71a20b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  to: {
    "Flag__styles.to": "Flag__styles.to",
    "::before_backgroundPosition": "xahex7k",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tr: {
    "Flag__styles.tr": "Flag__styles.tr",
    "::before_backgroundPosition": "x1nsg3ed",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tt: {
    "Flag__styles.tt": "Flag__styles.tt",
    "::before_backgroundPosition": "xynuc3b",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tv: {
    "Flag__styles.tv": "Flag__styles.tv",
    "::before_backgroundPosition": "x1qao42p",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tw: {
    "Flag__styles.tw": "Flag__styles.tw",
    "::before_backgroundPosition": "x1tainjl",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  tz: {
    "Flag__styles.tz": "Flag__styles.tz",
    "::before_backgroundPosition": "xacffo2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ua: {
    "Flag__styles.ua": "Flag__styles.ua",
    "::before_backgroundPosition": "xg06dwx",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ug: {
    "Flag__styles.ug": "Flag__styles.ug",
    "::before_backgroundPosition": "x1f73etf",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  um: {
    "Flag__styles.um": "Flag__styles.um",
    "::before_backgroundPosition": "x1m4odfc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  us: {
    "Flag__styles.us": "Flag__styles.us",
    "::before_backgroundPosition": "xe6z3jl",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  uy: {
    "Flag__styles.uy": "Flag__styles.uy",
    "::before_backgroundPosition": "x7gpb3o",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  uz: {
    "Flag__styles.uz": "Flag__styles.uz",
    "::before_backgroundPosition": "xu30gto",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  va: {
    "Flag__styles.va": "Flag__styles.va",
    "::before_backgroundPosition": "x1g13cnx",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  vc: {
    "Flag__styles.vc": "Flag__styles.vc",
    "::before_backgroundPosition": "x1jr4syx",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ve: {
    "Flag__styles.ve": "Flag__styles.ve",
    "::before_backgroundPosition": "x1wchrl4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  vg: {
    "Flag__styles.vg": "Flag__styles.vg",
    "::before_backgroundPosition": "x1emm30c",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  vi: {
    "Flag__styles.vi": "Flag__styles.vi",
    "::before_backgroundPosition": "x1693ram",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  vn: {
    "Flag__styles.vn": "Flag__styles.vn",
    "::before_backgroundPosition": "x1fmzb1z",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  vu: {
    "Flag__styles.vu": "Flag__styles.vu",
    "::before_backgroundPosition": "xqlebz4",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  gbwls: {
    "Flag__styles.gbwls": "Flag__styles.gbwls",
    "::before_backgroundPosition": "xw6rltc",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  wf: {
    "Flag__styles.wf": "Flag__styles.wf",
    "::before_backgroundPosition": "xwoqd1q",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ws: {
    "Flag__styles.ws": "Flag__styles.ws",
    "::before_backgroundPosition": "x142wva2",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  ye: {
    "Flag__styles.ye": "Flag__styles.ye",
    "::before_backgroundPosition": "xt5t6d7",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  yt: {
    "Flag__styles.yt": "Flag__styles.yt",
    "::before_backgroundPosition": "x1kzvqct",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  za: {
    "Flag__styles.za": "Flag__styles.za",
    "::before_backgroundPosition": "x1822gcm",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  zm: {
    "Flag__styles.zm": "Flag__styles.zm",
    "::before_backgroundPosition": "x1xr07kw",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  zw: {
    "Flag__styles.zw": "Flag__styles.zw",
    "::before_backgroundPosition": "x1kovr8o",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    $$css: true
  },
  flag: {
    "Flag__styles.flag": "Flag__styles.flag",
    fontSize: "x1j61zf2",
    "::before_display": "x1fhgk32",
    "::before_content": "x1cpjm7i",
    "::before_backgroundRepeat": "xgaypha",
    "::before_backgroundPosition": "x1hp1osq",
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    "::before_backgroundImage": "x9d2jw5",
    "::before_width": "xrdwrp9",
    "::before_height": "x1mtoes2",
    $$css: true
  }
};
const Flag = exports.Flag = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(4);
  const {
    ref,
    name
  } = t0;
  let t1;
  if ($[0] !== name) {
    t1 = stylex.props(styles.flag, styles[name]);
    $[0] = name;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const {
    className
  } = t1;
  let t2;
  if ($[2] !== className) {
    t2 = /* @__PURE__ */_react.default.createElement("i", {
      ref,
      className
    });
    $[2] = className;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
});