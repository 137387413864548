"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputWrapper = exports.Input = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _lib = require("react-debounce-input/lib");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Icon = require("./Icon");
var _Loader = require("./Loader");
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  input: {
    "Input__styles.input": "Input__styles.input",
    height: "x16wdlz0",
    position: "x1n2onr6",
    fontWeight: "xo1l8bm",
    fontSize: "x4z9k3i",
    display: "x3nfvp2",
    minWidth: "xeuugli",
    maxWidth: "x193iq5w",
    $$css: true
  },
  inputTag: {
    "Input__styles.inputTag": "Input__styles.inputTag",
    maxWidth: "x193iq5w",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    lineHeight: "x1ylh3l6",
    padding: "xc7ga6q",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    color: "x11jfisy",
    borderRadius: "x1aaq583",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    flex: "xc8qplx",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    textAlign: "xdpxx8g",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    appearance: "xjyslct",
    fontSize: "xrv4cvt",
    width: "x14atkfc",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    backgroundColor: "x128cn8a",
    boxShadow: "x9gmhs3",
    ":focus_borderColor": "x1eg2tmu",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    "::placeholder_color": "x1h4wt8n",
    "::placeholder_opacity": "xrxx4p4",
    $$css: true
  },
  focusedInputTag: {
    "Input__styles.focusedInputTag": "Input__styles.focusedInputTag",
    borderColor: "x5d3vyf",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "::placeholder_color": "x1h4wt8n",
    $$css: true
  },
  disabledInput: {
    "Input__styles.disabledInput": "Input__styles.disabledInput",
    opacity: "xyd83as",
    pointerEvents: "x47corl",
    $$css: true
  },
  readOnlyInput: {
    "Input__styles.readOnlyInput": "Input__styles.readOnlyInput",
    pointerEvents: "x47corl",
    $$css: true
  },
  loadingIcon: {
    "Input__styles.loadingIcon": "Input__styles.loadingIcon",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  focusedIcon: {
    "Input__styles.focusedIcon": "Input__styles.focusedIcon",
    color: "x11jfisy",
    $$css: true
  },
  inputIcon: {
    "Input__styles.inputIcon": "Input__styles.inputIcon",
    alignItems: "x6s0dn4",
    borderRadius: "x146gtmz",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    color: "x1gtsmwv",
    display: "x3nfvp2",
    fontSize: "xrv4cvt",
    fontStyle: "x1j61x8r",
    fontWeight: "xo1l8bm",
    height: "x5yr21d",
    justifyContent: "xl56j7k",
    lineHeight: "xo5v014",
    opacity: "xbyyjgo",
    position: "x10l6tqk",
    right: "x131sewu",
    insetInlineStart: null,
    insetInlineEnd: null,
    textAlign: "x2b8uid",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    width: "x14srk22",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    $$css: true
  },
  label: {
    "Input__styles.label": "Input__styles.label",
    paddingBottom: "x1a8lsjc",
    fontWeight: "x117nqv4",
    $$css: true
  },
  large: {
    "Input__styles.large": "Input__styles.large",
    height: "x1peatla",
    fontSize: "xosj86m",
    $$css: true
  },
  fluid: {
    "Input__styles.fluid": "Input__styles.fluid",
    display: "x78zum5",
    width: "xh8yej3",
    $$css: true
  },
  prefix: {
    "Input__styles.prefix": "Input__styles.prefix",
    position: "x10l6tqk",
    left: "x12lbrt0",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1eu8d0j",
    color: "x16cd2qt",
    lineHeight: "x1evy7pa",
    $$css: true
  }
};
const InputTag = (0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement("input", __spreadValues({
  ref
}, props)));
const DebouncedInputTag = (debounce, minLength) => (0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement(_lib.DebounceInput, __spreadProps(__spreadValues({}, props), {
  debounceTimeout: debounce,
  minLength,
  ref
})));
const Input = exports.Input = (0, _react.memo)((0, _react.forwardRef)(({
  value,
  icon,
  className,
  wrapperClassName,
  autoComplete,
  placeholder,
  action,
  onChange,
  id,
  type,
  fluid,
  large,
  onFocus,
  onBlur,
  autoFocus,
  disabled,
  readOnly,
  min,
  max,
  required,
  label,
  name,
  maxLength,
  minLength,
  loading,
  debounce,
  debounceMinLength,
  prefix,
  onKeyPress,
  onKeyDown,
  onEnter
}, ref) => {
  const [focused, setFocused] = (0, _react.useState)(false);
  const {
    className: inputClassName
  } = stylex.props(styles.inputTag, focused && styles.focusedInputTag, disabled && styles.disabledInput, readOnly && styles.readOnlyInput, className);
  const inputRef = (0, _react.useRef)(null);
  const Tag = (0, _react.useMemo)(() => debounce ? DebouncedInputTag(debounce, debounceMinLength || 0) : InputTag, [debounce, debounceMinLength]);
  (0, _react.useEffect)(() => {
    let timeout;
    if (autoFocus && inputRef.current) {
      timeout = setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 200);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus]);
  const focus = (0, _react.useCallback)(() => {
    setFocused(true);
    if (onFocus) onFocus();
  }, [onFocus]);
  const blur = (0, _react.useCallback)(() => {
    setFocused(false);
    if (onBlur) onBlur();
  }, [onBlur]);
  const enterHandler = (0, _react.useMemo)(() => onEnter ? e => {
    if (e.key === "Enter") onEnter();
    if (onKeyDown) onKeyDown(e);
  } : onKeyDown, [onEnter, onKeyDown]);
  const refs = (0, _helpers.useMergedRefs)(ref, inputRef);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
    style: styles.label
  }, label), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    id,
    style: [styles.input, fluid && styles.fluid, large && styles.large, wrapperClassName]
  }, prefix && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.prefix
  }, prefix), /* @__PURE__ */_react.default.createElement(Tag, {
    onKeyDown: enterHandler,
    onKeyPress,
    name,
    maxLength,
    minLength,
    ref: refs,
    value,
    autoComplete,
    placeholder,
    className: inputClassName,
    readOnly,
    disabled,
    onChange,
    min,
    max,
    required,
    style: {
      paddingLeft: prefix ? prefix.length * 6.4 : void 0
    },
    type,
    onFocus: focus,
    onBlur: blur
  }), action, icon && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    className: [styles.inputIcon, focused && styles.focusedIcon],
    name: icon
  }), loading && /* @__PURE__ */_react.default.createElement(_Loader.Loader, {
    className: styles.loadingIcon
  })));
}));
const InputWrapper = exports.InputWrapper = (0, _react.memo)(({
  className,
  children,
  focused
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    "data-bla": focused,
    style: [styles.inputTag, focused && styles.focusedInputTag, typeof className !== "string" && className]
  }, children);
});