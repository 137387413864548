"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var _useMembersData = require("../hooks/useMembersData");
var _WebRTCUserVideoTrack = require("@/room/components/WebRTCUserVideoTrack");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  displayName: {
    "WebcamBar__styles.displayName": "WebcamBar__styles.displayName",
    position: "x10l6tqk",
    bottom: "x1ey2m1c",
    $$css: true
  },
  webcamBar: {
    "WebcamBar__styles.webcamBar": "WebcamBar__styles.webcamBar",
    position: "x1n2onr6",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    maxHeight: "xx9gmpd",
    minHeight: "x1pag821",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    padding: "x1i3ajwb",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    transition: "x1xl8y7z",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    overflowX: "xw2csxc",
    $$css: true
  },
  webcamItem: {
    "WebcamBar__styles.webcamItem": "WebcamBar__styles.webcamItem",
    width: "x14atkfc",
    height: "x5yr21d",
    maxHeight: "xmz0i5r",
    aspectRatio: "x1y5e3q9",
    position: "x1n2onr6",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  webcamVideo: {
    "WebcamBar__styles.webcamVideo": "WebcamBar__styles.webcamVideo",
    height: "x5yr21d",
    aspectRatio: "x1y5e3q9",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    objectFit: "xl1xv1r",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  expanded: {
    "WebcamBar__styles.expanded": "WebcamBar__styles.expanded",
    maxHeight: "xygamxp",
    minHeight: "x1db3i3o",
    $$css: true
  }
};
const WebcamItem = ({
  memberId
}) => {
  const member = (0, _useMembersData.useMember)(memberId);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.webcamItem
  }, /* @__PURE__ */_react.default.createElement(_WebRTCUserVideoTrack.WebRTCUserVideoTrack, {
    className: styles.webcamVideo,
    userId: memberId
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.displayName
  }, (member == null ? void 0 : member.isAnonymous) ? "Anonymous" : (member == null ? void 0 : member.displayName) || (member == null ? void 0 : member.username)));
};
var _default = () => {
  const [expanded, setExpanded] = (0, _react.useState)(true);
  const {
    webcamStreams
  } = (0, _react.useContext)(_RoomContext.default);
  const memberIds = Object.keys(webcamStreams || {});
  if (memberIds.length === 0) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.webcamBar, expanded && styles.expanded],
    onClick: () => {
      setExpanded(!expanded);
    }
  }, memberIds.map(memberId => /* @__PURE__ */_react.default.createElement(WebcamItem, {
    key: memberId,
    memberId
  })));
};
exports.default = _default;