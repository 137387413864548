"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUserRoomDataCached = exports.useUserRoomData = exports.default = exports.USER_ROOMS = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _globe = _interopRequireDefault(require("./globe.svg"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
var _components = require("@/shared/components");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  dropdownHeader: {
    "index__styles.dropdownHeader": "index__styles.dropdownHeader",
    display: "x78zum5",
    width: "xh8yej3",
    justifyContent: "x1qughib",
    padding: "x1p6vlc0",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    alignItems: "x6s0dn4",
    fontSize: "x1pvqxga",
    $$css: true
  },
  rooms: {
    "index__styles.rooms": "index__styles.rooms",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    $$css: true
  },
  buttons: {
    "index__styles.buttons": "index__styles.buttons",
    display: "x78zum5",
    $$css: true
  },
  itemsWrapper: {
    "index__styles.itemsWrapper": "index__styles.itemsWrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }
};
const ON_USER_ROOMS_UPDATE = (0, _gql.gql)(`
  subscription OnRoomListUpdate {
    roomlistUpdate {
      id
      unreadMessages
      latestMessage {
        id
        body
        time
        user {
          displayName
        }
      }
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const USER_ROOMS = exports.USER_ROOMS = (0, _gql.gql)(`
  query UserRoomQuery {
    userRooms {
      id
      unreadMessages
      latestMessage {
        id
        time
        body
        user {
          displayName
        }
      }
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const UNLINK_ROOM = (0, _gql.gql)(`
  mutation UnlinkRoom2($roomId: String!) {
    unlinkRoom(roomId: $roomId) {
      ok
    }
  }
`);
const useUserRoomData = () => {
  const {
    data,
    refetch,
    subscribeToMore
  } = (0, _gql.useQuery)(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  (0, _react.useEffect)(() => {
    const unsubscribe = subscribeToMore({
      document: ON_USER_ROOMS_UPDATE,
      updateQuery: (_prev, {
        subscriptionData: {
          data: data2
        }
      }) => {
        return {
          userRooms: data2.roomlistUpdate
        };
      }
    });
    return () => {
      unsubscribe();
    };
  }, [subscribeToMore]);
  return {
    data,
    refetch
  };
};
exports.useUserRoomData = useUserRoomData;
const useUserRoomDataCached = () => {
  const {
    data
  } = (0, _gql.useQuery)(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  return {
    data
  };
};
exports.useUserRoomDataCached = useUserRoomDataCached;
var _default = exports.default = (0, _react.memo)(({
  ref,
  onRoomSelected
}) => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const location = (0, _reactRouterDom.useLocation)();
  const {
    data
  } = useUserRoomData();
  const userRooms = (0, _react.useMemo)(() => (data == null ? void 0 : data.userRooms) || [], [data == null ? void 0 : data.userRooms]);
  const userRoomsString = (0, _react.useMemo)(() => JSON.stringify(userRooms.map(r => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
      id: r == null ? void 0 : r.id,
      state: {
        isLive: (_a = r == null ? void 0 : r.state) == null ? void 0 : _a.isLive,
        metadata: {
          roomName: (_c = (_b = r == null ? void 0 : r.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.roomName,
          avatarUrl: ((_e = (_d = r == null ? void 0 : r.state) == null ? void 0 : _d.metadata) == null ? void 0 : _e.avatarUrl) || ""
        },
        members: (_g = (_f = r == null ? void 0 : r.state) == null ? void 0 : _f.members) == null ? void 0 : _g.map(m => {
          var _a2;
          return {
            user: {
              avatarUrl: ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.avatarUrl) || ""
            }
          };
        })
      },
      unreadMessages: r == null ? void 0 : r.unreadMessages,
      latestMessage: r == null ? void 0 : r.latestMessage
    };
  })), [userRooms]);
  const roomItems = (0, _react.useMemo)(() => {
    const userRooms2 = JSON.parse(userRoomsString);
    return userRooms2.filter(r => {
      var _a;
      const roomId = (_a = r == null ? void 0 : r.id) == null ? void 0 : _a.replace(/^@\w+\//, "");
      return !location.pathname.endsWith(roomId || "");
    }).map(r => {
      var _a, _b, _c, _d, _e, _f, _g;
      const roomId = (_a = r == null ? void 0 : r.id) == null ? void 0 : _a.replace(/^@\w+\//, "");
      return {
        link: `/room/${roomId}`,
        roomId: r.id,
        key: r.id,
        isLive: !!((_b = r == null ? void 0 : r.state) == null ? void 0 : _b.isLive),
        roomName: (_d = (_c = r == null ? void 0 : r.state) == null ? void 0 : _c.metadata) == null ? void 0 : _d.roomName,
        unreadMessages: r == null ? void 0 : r.unreadMessages,
        avatarUrl: ((_f = (_e = r == null ? void 0 : r.state) == null ? void 0 : _e.metadata) == null ? void 0 : _f.avatarUrl) || "",
        memberAvatars: ((_g = r == null ? void 0 : r.state) == null ? void 0 : _g.members) ? r.state.members.map(m => {
          var _a2;
          return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.avatarUrl) || "";
        }) : [],
        latestMessage: r.latestMessage
      };
    });
  }, [userRoomsString, location.pathname]);
  const history = (0, _reactRouterDom.useHistory)();
  const [unlinkRoom] = (0, _gql.useMutation)(UNLINK_ROOM);
  const currentRoomId = location.pathname.split("/room/")[1];
  const renderItems = (0, _react.useMemo)(() => roomItems.map(({
    link,
    isLive,
    roomName,
    latestMessage,
    unreadMessages,
    avatarUrl,
    memberAvatars,
    roomId
  }) => {
    var _a;
    return {
      key: roomId,
      onClick: () => {
        history.push(link);
        onRoomSelected && onRoomSelected();
      },
      title: roomName || "",
      content: latestMessage ? (((_a = latestMessage.user) == null ? void 0 : _a.displayName) || "User") + ": " + (latestMessage == null ? void 0 : latestMessage.body) : "",
      unread: unreadMessages ? unreadMessages > 0 : false,
      avatarUrl,
      memberAvatars,
      timestamp: (latestMessage == null ? void 0 : latestMessage.time) || void 0,
      contextMenu: [{
        action: e => {
          e.preventDefault();
          e.stopPropagation();
          if (roomId) {
            unlinkRoom({
              variables: {
                roomId
              }
            });
          }
        },
        icon: "remove",
        text: "Unlink"
      }],
      inActive: !isLive
    };
  }).concat(currentRoomId !== "lobby" ? [{
    key: "lobby",
    onClick: () => {
      history.push("/room/lobby");
      onRoomSelected && onRoomSelected();
    },
    title: "No one online?",
    content: "Join the public lobby!",
    unread: false,
    avatarUrl: _globe.default,
    memberAvatars: [],
    timestamp: void 0,
    inActive: false,
    contextMenu: []
  }] : []), [onRoomSelected, currentRoomId, history, roomItems, unlinkRoom]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.rooms
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.dropdownHeader
  }, "Rooms", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttons
  }, currentRoomId !== "lobby" && /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    disabled: isMobile,
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      borderLess: true,
      size: "l",
      onClick: () => {
        history.push("/room/lobby");
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "globe"
    })),
    content: "Lobby"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: "Create a room",
    disabled: isMobile,
    trigger: /* @__PURE__ */_react.default.createElement(_components.CreateRoomModal, {
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "white",
        inverted: true,
        borderLess: true,
        size: "l"
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "plus"
      }))
    })
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.itemsWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.MessageList, {
    large: true,
    ref,
    items: renderItems
  })));
});