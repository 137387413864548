"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useQuery, gql } from "@/shared/hooks/gql";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { LoadingScreen } from "@/shared/components";
const GET_PLAYLIST_INFO = gql(`
  query GetPlaylistInfo($url: String!) {
    mediaPlayerGetPlaylistInfo(url: $url) {
      status
      result {
        totalDuration
        items {
          title
          url
          duration
        }
      }
    }
    getServerTime {
      time
    }
  }
`);
export function isYouTubePlaylist(url) {
  if (!url)
    return false;
  if (url.startsWith("magnet:"))
    return true;
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.includes("youtube.com") && (urlObj.searchParams.has("list") || urlObj.pathname.startsWith("/@") || urlObj.pathname.startsWith("/playlist") || urlObj.pathname.startsWith("/channel/"));
  } catch (e) {
    console.error(e);
    return false;
  }
}
function getPositionWithinCurrentPlaylistItem(playlistItems, position) {
  let accumulatedDuration = 0;
  for (const item of playlistItems) {
    if (!(item == null ? void 0 : item.duration))
      continue;
    if (accumulatedDuration + item.duration > position) {
      break;
    }
    accumulatedDuration += item.duration;
  }
  return position - accumulatedDuration;
}
function getCurrentPlaylistUrl(playlistItems, position) {
  let accumulatedDuration = 0;
  let currentItem = playlistItems.length ? playlistItems[0] : null;
  for (const item of playlistItems) {
    currentItem = item;
    if (!(item == null ? void 0 : item.duration))
      continue;
    if (accumulatedDuration + item.duration > position) {
      break;
    }
    accumulatedDuration += item.duration;
  }
  return currentItem == null ? void 0 : currentItem.url;
}
const usePlaylistInfo = (url) => {
  const isPlaylist = isYouTubePlaylist(url);
  const [error, setError] = useState(false);
  const startedRef = useRef(false);
  const {
    error: queryError,
    data,
    stopPolling
  } = useQuery(GET_PLAYLIST_INFO, {
    variables: {
      url
    },
    fetchPolicy: "network-only",
    pollInterval: 1e4,
    skip: !isYouTubePlaylist(url)
  });
  const {
    status,
    result
  } = (data == null ? void 0 : data.mediaPlayerGetPlaylistInfo) || {};
  const {
    time
  } = (data == null ? void 0 : data.getServerTime) || {};
  useEffect(() => {
    if (!status)
      return;
    const started = startedRef.current;
    if (status === "ready") {
      stopPolling();
    } else if (queryError) {
      setError(true);
    } else if (status === "started") {
      if (started) {
        setError(true);
      } else {
        startedRef.current = true;
      }
    }
  }, [status, queryError, time, stopPolling]);
  useEffect(() => {
    if (error)
      stopPolling();
  }, [error, stopPolling]);
  if (error) {
    return {
      error,
      loading: false
    };
  }
  return {
    playlist: result,
    loading: isPlaylist && status !== "ready"
  };
};
export default (Video) => (props) => {
  var _a;
  const {
    playlist,
    loading
  } = usePlaylistInfo(props.url);
  const [playlistIndex, setPlaylistIndex] = useState(0);
  const playlistUrl = (playlist == null ? void 0 : playlist.items) && ((_a = playlist.items[playlistIndex]) == null ? void 0 : _a.url) || "";
  const propsOnUpdate = props.onUpdate;
  const propsOnReady = props.onReady;
  const previousPlaylistItemsDuration = useMemo(() => {
    var _a2;
    return ((_a2 = playlist == null ? void 0 : playlist.items) == null ? void 0 : _a2.map((item) => (item == null ? void 0 : item.duration) || 0).slice(0, playlistIndex).reduce((accumulator, currentValue) => accumulator + currentValue, 0)) || 0;
  }, [playlist == null ? void 0 : playlist.items, playlistIndex]);
  const onUpdate = useCallback((params) => propsOnUpdate(__spreadProps(__spreadValues({}, params), {
    duration: (playlist == null ? void 0 : playlist.totalDuration) || 0,
    position: ((params == null ? void 0 : params.position) || 0) + previousPlaylistItemsDuration
  })), [playlist == null ? void 0 : playlist.totalDuration, previousPlaylistItemsDuration, propsOnUpdate]);
  const onReady = useCallback((player) => {
    const seekTo = (pos) => {
      var _a2;
      const url = getCurrentPlaylistUrl((playlist == null ? void 0 : playlist.items) || [], pos);
      const newPlaylistIndex = (_a2 = playlist == null ? void 0 : playlist.items) == null ? void 0 : _a2.map((item) => item == null ? void 0 : item.url).indexOf(url);
      if (newPlaylistIndex !== playlistIndex) {
        setPlaylistIndex(newPlaylistIndex || 0);
      } else {
        const innerPosition = getPositionWithinCurrentPlaylistItem((playlist == null ? void 0 : playlist.items) || [], pos);
        if (player.seekTo) {
          player.seekTo(innerPosition);
        }
      }
    };
    propsOnReady(__spreadProps(__spreadValues({}, player), {
      seekTo
    }));
  }, [playlist, playlistIndex, propsOnReady]);
  if (loading)
    return /* @__PURE__ */ React.createElement(LoadingScreen, { text: "Loading Playlist" });
  if (!playlist)
    return /* @__PURE__ */ React.createElement(Video, __spreadValues({}, props));
  return /* @__PURE__ */ React.createElement(Video, __spreadProps(__spreadValues({}, props), { key: playlistUrl, onReady, onUpdate, url: playlistUrl }));
};
