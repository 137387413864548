"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const VERIFY_EMAIL_CODE = (0, _gql.gql)(`
  mutation VerifyEmailCode1($email: String!, $code: String!) {
    verifyEmailCode(email: $email, code: $code) {
      ok
    }
  }
`);
const styles = {
  discover: {
    "verifyEmail__styles.discover": "verifyEmail__styles.discover",
    display: "x78zum5",
    width: "xh8yej3",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  }
};
const VerifyEmailPage = () => {
  const [message, setMessage] = (0, _react.useState)("");
  const {
    email,
    code
  } = (0, _reactRouterDom.useParams)();
  const [verifyEmailCode] = (0, _gql.useMutation)(VERIFY_EMAIL_CODE);
  (0, _react.useEffect)(() => {
    if (email && code) {
      ;
      (() => __async(void 0, null, function* () {
        try {
          yield verifyEmailCode({
            variables: {
              email,
              code
            }
          });
          setMessage("Email successfully verified!");
        } catch (e) {
          setMessage(e.graphQLErrors[0].message);
        }
      }))();
    }
  }, [email, code, verifyEmailCode]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.discover
  }, message ? message : /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    active: true
  }));
};
var _default = exports.default = VerifyEmailPage;