"use strict";
import React from "react";
export default React.createContext({
  localUrl: "",
  localStream: null,
  setLocalStream: (_stream) => {
  },
  relayStream: false,
  setRelayStream: (_relay) => {
  },
  setLocalUrl: (_url) => {
  },
  player: null,
  setPlayer: (_player) => {
  }
});
