"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMessages = exports.default = exports.UserProfileModal = exports.UserPickerModal = void 0;
var _gql = require("@/shared/hooks/gql");
var _usePersistedState = _interopRequireDefault(require("use-persisted-state"));
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _client = require("@apollo/client");
var _helpers = require("kosmi-sdk/helpers");
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
var _components = require("@/shared/components");
var _Messagebox = _interopRequireDefault(require("@/room/components/Sidebar/Messagebox"));
var _Messages = _interopRequireDefault(require("@/room/components/Sidebar/Messages"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _MobileContext = _interopRequireDefault(require("@/layout/Mobile/MobileContext"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const InviteToRoomModal = (0, _helpers.withDialogs)(({
  alert,
  userId,
  user,
  onClose
}) => {
  const roomRegex = /\/room\/([a-zA-Z0-9@]+)/;
  const matches = roomRegex.exec(window.location.pathname);
  const currentRoomId = matches ? matches[1] : null;
  const [roomId, setRoomId] = (0, _react.useState)(currentRoomId);
  const {
    data
  } = (0, _gql.useQuery)(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  const rooms = data && data.userRooms ? data.userRooms : [];
  const [inviteToRoom] = (0, _gql.useMutation)(INVITE_TO_ROOM_MUTATION);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open: !!userId,
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteToRoomModal
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteToRoomTitle
  }, "Invite ", user.displayName, " to room:"), /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    className: styles.selectRoom,
    placeholder: "Select room",
    defaultValue: roomId || "",
    value: roomId || "",
    options: rooms.map(r => {
      var _a, _b, _c, _d;
      return {
        key: (r == null ? void 0 : r.id) || "",
        value: (r == null ? void 0 : r.id) || "",
        text: ((_b = (_a = r == null ? void 0 : r.state) == null ? void 0 : _a.metadata) == null ? void 0 : _b.roomName) || "",
        image: ((_d = (_c = r == null ? void 0 : r.state) == null ? void 0 : _c.metadata) == null ? void 0 : _d.avatarUrl) || ""
      };
    }),
    onChange: e => {
      setRoomId(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    style: {
      margin: 10
    },
    onClick: () => __async(void 0, null, function* () {
      if (roomId) {
        inviteToRoom({
          variables: {
            userId: userId || "",
            roomId
          }
        });
        if (alert) yield alert("Invite sent");
      } else if (alert) {
        yield alert("No room selected");
      }
      onClose();
    })
  }, "Invite"))));
});
const UserProfileModal = exports.UserProfileModal = (0, _helpers.withDialogs)(({
  open,
  onClose,
  userId,
  currentUser,
  confirm
}) => {
  const friendIds = currentUser.friends.filter(f => f.state === "ACCEPTED").map(f => f.user.id);
  const isFriend = friendIds.indexOf(userId || "") !== -1;
  const [removeFriend] = (0, _gql.useMutation)(REMOVE_FRIEND_MUTATION);
  const [inviteToRoomUserId, setInviteToRoomUserId] = (0, _react.useState)(null);
  const {
    data
  } = (0, _gql.useQuery)(USER_QUERY, {
    variables: {
      id: userId || ""
    },
    fetchPolicy: "cache-first",
    skip: !userId
  });
  const user = (data == null ? void 0 : data.user) || {
    displayName: ""
  };
  const actions = [];
  if (isFriend) {
    actions.push({
      icon: "remove user",
      text: "Remove friend",
      color: "red",
      onClick: () => __async(void 0, null, function* () {
        if (confirm && (yield confirm(`Are you sure you want to remove ${user == null ? void 0 : user.displayName}`))) {
          yield removeFriend({
            variables: {
              userId: userId || ""
            }
          });
          onClose();
        }
      })
    });
    actions.push({
      icon: "user plus",
      onClick: () => setInviteToRoomUserId(userId),
      text: "Invite to room..."
    });
  }
  const closeInviteRoom = (0, _react.useCallback)(() => setInviteToRoomUserId(null), []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, user ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteFriendToRoom
  }, /* @__PURE__ */_react.default.createElement(InviteToRoomModal, {
    userId: inviteToRoomUserId,
    user,
    onClose: closeInviteRoom
  }), /* @__PURE__ */_react.default.createElement(_uikit.UserProfile, {
    displayName: user.displayName || "",
    username: user.username || "",
    avatarUrl: user.avatarUrl || "",
    isSubscribed: user.isSubscribed || false,
    isAnonymous: false,
    actions
  })) : /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    size: "massive"
  })));
});
const UserPickerModal = exports.UserPickerModal = (0, _helpers.withDialogs)(({
  alert,
  open,
  onClose
}) => {
  const [searchQuery, setSearchQuery] = (0, _react.useState)("");
  const {
    data,
    loading
  } = (0, _gql.useQuery)(USER_SEARCH_QUERY, {
    variables: {
      query: searchQuery
    },
    skip: searchQuery.length < 3
  });
  const _onClose = () => {
    onClose();
  };
  const [addFriend, {
    loading: addFriendLoading
  }] = (0, _gql.useMutation)(ADD_FRIEND_MUTATION);
  const [value, setValue] = (0, _react.useState)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose: _onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Add a Friend"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.userPicker
  }, /* @__PURE__ */_react.default.createElement(_uikit.SearchInput, {
    placeholder: "Search...",
    fluid: true,
    value: searchQuery,
    onChange: e => {
      setValue(null);
      setSearchQuery(e.target.value);
    },
    onSelect: value2 => {
      setValue(value2);
    },
    loading,
    results: data && data.searchUsers && data.searchUsers.map(u => ({
      key: (u == null ? void 0 : u.username) || "",
      image: (u == null ? void 0 : u.avatarUrl) || "",
      text: (u == null ? void 0 : u.username) || ""
    })) || []
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Actions, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "white",
    disabled: loading,
    onClick: () => {
      setSearchQuery("");
      setValue(null);
      _onClose();
    }
  }, "Cancel"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    disabled: !value,
    inverted: true,
    loading: addFriendLoading,
    style: {
      marginLeft: 8
    },
    onClick: () => __async(void 0, null, function* () {
      var _a;
      try {
        yield addFriend({
          variables: {
            username: value || ""
          }
        });
        if (alert) alert("Friend request sent");
        setSearchQuery("");
        setValue(null);
        _onClose();
      } catch (e) {
        if (alert) alert((_a = e == null ? void 0 : e.graphQLErrors[0]) == null ? void 0 : _a.message);
      }
    })
  }, "Add friend")));
});
function isNotNull(value) {
  return value !== null;
}
const useCurrentChatUserIdState = (0, _usePersistedState.default)("friendlist-currentChatUserId");
const useOnlyOnlineState = (0, _usePersistedState.default)("friendlist-onlyOnline");
const USER_ROOMS = (0, _gql.gql)(`
  query UserRoomsQuery {
    userRooms {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const INVITE_TO_ROOM_MUTATION = (0, _gql.gql)(`
  mutation InviteToRoomMutation2($userId: String!, $roomId: String!) {
    inviteToRoom(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const USER_SEARCH_QUERY = (0, _gql.gql)(`
  query UserSearchQuery($query: String!) {
    searchUsers(query: $query) {
      id
      username
      displayName
      avatarUrl
    }
  }
`);
const USER_QUERY = (0, _gql.gql)(`
  query UserQuery($id: String!) {
    user(id: $id) {
      id
      username
      displayName
      avatarUrl
      isConnected
      isSubscribed
    }
  }
`);
const ON_MESSAGE = (0, _gql.gql)(`
  subscription OnNewPrivateMessage {
    newPrivateMessage {
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      seen
    }
  }
`);
const ON_MESSAGE_FROM_USER = (0, _gql.gql)(`
  subscription NewPrivateMessageSubscription($userId: String!) {
    newPrivateMessageFromUser(userId: $userId) {
      seen
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const ON_PRIVATE_MESSAGE_DELETED = (0, _gql.gql)(`
  subscription PrivateMessageDeletedSubsciption {
    privateMessageDeleted {
      previousMessage {
        latestMessage {
          id
          time
          body
        }
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
      deletedMessageId
    }
  }
`);
const ADD_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation AddFriendMutation1($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const REMOVE_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation RemoveFriendMutation2($userId: String!) {
    removeFriend(userId: $userId) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const MESSAGE_LIST = (0, _gql.gql)(`
  query MessageList {
    messageList {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
        isSubscribed
      }
    }
  }
`);
const ON_MESSAGE_LIST_UPDATE = (0, _gql.gql)(`
  subscription MessageListUpdate {
    messageListUpdate {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
    }
  }
`);
const PRIVATE_MESSAGES = (0, _gql.gql)(`
  query PrivateMessageQuery($userId: String!, $cursor: String) {
    privateMessages(userId: $userId, cursor: $cursor) {
      forwardCursor
      backCursor
      results {
        id
        body
        time
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const SEND_MESSAGE = (0, _gql.gql)(`
  mutation SendPrivateMessage($body: String!, $userId: String!) {
    sendPrivateMessage(body: $body, toUserId: $userId) {
      ok
    }
  }
`);
const DELETE_PRIVATE_MESSAGE = (0, _gql.gql)(`
  mutation DetelePrivateMessage($privateMessageId: UUID!) {
    deletePrivateMessage(privateMessageId: $privateMessageId) {
      ok
    }
  }
`);
const styles = {
  inviteToRoomModal: {
    "PrivateMessages__styles.inviteToRoomModal": "PrivateMessages__styles.inviteToRoomModal",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  inviteToRoomTitle: {
    "PrivateMessages__styles.inviteToRoomTitle": "PrivateMessages__styles.inviteToRoomTitle",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  selectRoom: {
    "PrivateMessages__styles.selectRoom": "PrivateMessages__styles.selectRoom",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inviteFriendToRoom: {
    "PrivateMessages__styles.inviteFriendToRoom": "PrivateMessages__styles.inviteFriendToRoom",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    $$css: true
  },
  userPicker: {
    "PrivateMessages__styles.userPicker": "PrivateMessages__styles.userPicker",
    height: "x18dl8mb",
    $$css: true
  },
  plus: {
    "PrivateMessages__styles.plus": "PrivateMessages__styles.plus",
    fontSize: "xosj86m",
    $$css: true
  },
  tabView: {
    "PrivateMessages__styles.tabView": "PrivateMessages__styles.tabView",
    width: "xh8yej3",
    $$css: true
  },
  checkbox: {
    "PrivateMessages__styles.checkbox": "PrivateMessages__styles.checkbox",
    zoom: "xiyzoji",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xq0ohe5",
    $$css: true
  },
  pleaseLogin: {
    "PrivateMessages__styles.pleaseLogin": "PrivateMessages__styles.pleaseLogin",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    $$css: true
  },
  onlineCircle: {
    "PrivateMessages__styles.onlineCircle": "PrivateMessages__styles.onlineCircle",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    lineHeight: "xyptofi",
    position: "x10l6tqk",
    zIndex: "x1vjfegm",
    right: "x1rsgy98",
    insetInlineStart: null,
    insetInlineEnd: null,
    bottom: "x15ogkol",
    cursor: "xt0e3qv",
    fontSize: "xvwhms9",
    $$css: true
  },
  hidden: {
    "PrivateMessages__styles.hidden": "PrivateMessages__styles.hidden",
    visibility: "xlshs6z",
    $$css: true
  },
  avatar: {
    "PrivateMessages__styles.avatar": "PrivateMessages__styles.avatar",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  messageList: {
    "PrivateMessages__styles.messageList": "PrivateMessages__styles.messageList",
    width: "xh8yej3",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    $$css: true
  },
  messagesDropdown: {
    "PrivateMessages__styles.messagesDropdown": "PrivateMessages__styles.messagesDropdown",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x1cy8zhl",
    height: "x5yr21d",
    width: "xh8yej3",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  actions: {
    "PrivateMessages__styles.actions": "PrivateMessages__styles.actions",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xaw8158",
    width: "x16grhtn",
    background: "x1odt36a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xyorhqc",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  acceptFriendRequestIcon: {
    "PrivateMessages__styles.acceptFriendRequestIcon": "PrivateMessages__styles.acceptFriendRequestIcon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  privateChat: {
    "PrivateMessages__styles.privateChat": "PrivateMessages__styles.privateChat",
    backgroundImage: "xvixog2",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    height: "xqtp20y",
    position: "x1n2onr6",
    textAlign: "xdpxx8g",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  messageBox: {
    "PrivateMessages__styles.messageBox": "PrivateMessages__styles.messageBox",
    display: "x78zum5",
    $$css: true
  },
  titlebar: {
    "PrivateMessages__styles.titlebar": "PrivateMessages__styles.titlebar",
    display: "x78zum5",
    background: "x1dq10d1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    width: "xh8yej3",
    height: "x1vqgdyp",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    "@media(max-width: 1000px)_height": "x10w29gr",
    "@media(max-width: 1000px)_fontSize": "xddx7y0",
    $$css: true
  },
  userBox: {
    "PrivateMessages__styles.userBox": "PrivateMessages__styles.userBox",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    cursor: "x1ypdohk",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    position: "x1n2onr6",
    $$css: true
  },
  avatarWrapper: {
    "PrivateMessages__styles.avatarWrapper": "PrivateMessages__styles.avatarWrapper",
    position: "x1n2onr6",
    height: "x1qx5ct2",
    width: "xw4jnvo",
    marginRight: "x1db2dqx",
    marginInlineStart: null,
    marginInlineEnd: null,
    "@media(max-width: 1000px)_height": "xaf475c",
    "@media(max-width: 1000px)_width": "x1ns1j7t",
    $$css: true
  },
  backIcon: {
    "PrivateMessages__styles.backIcon": "PrivateMessages__styles.backIcon",
    cursor: "x1ypdohk",
    position: "x10l6tqk",
    left: "xcqyyxr",
    insetInlineStart: null,
    insetInlineEnd: null,
    zIndex: "x1vjfegm",
    top: "x80663w",
    right: "xwukr4l",
    fontSize: "xneq65o",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    "@media (hover: hover) and (pointer: fine)_color": "x1ym7u9m",
    $$css: true
  },
  friendsHeader: {
    "PrivateMessages__styles.friendsHeader": "PrivateMessages__styles.friendsHeader",
    display: "x78zum5",
    width: "xh8yej3",
    justifyContent: "x1qughib",
    alignItems: "x6s0dn4",
    padding: "x6w2896",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "@media(max-width: 1000px)_padding": "xqea0eg",
    "@media(max-width: 1000px)_paddingInline": null,
    "@media(max-width: 1000px)_paddingStart": null,
    "@media(max-width: 1000px)_paddingLeft": null,
    "@media(max-width: 1000px)_paddingEnd": null,
    "@media(max-width: 1000px)_paddingRight": null,
    "@media(max-width: 1000px)_paddingBlock": null,
    "@media(max-width: 1000px)_paddingTop": null,
    "@media(max-width: 1000px)_paddingBottom": null,
    "@media(max-width: 1000px)_fontSize": "x18yd3ym",
    $$css: true
  },
  noMessages: {
    "PrivateMessages__styles.noMessages": "PrivateMessages__styles.noMessages",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    alignItems: "x6s0dn4",
    justifyContent: "x1l1ennw",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "x1aoj0v",
    fontSize: "xosj86m",
    $$css: true
  }
};
const usePrivateMessages = userId => {
  var _a, _b;
  const {
    data,
    fetchMore,
    subscribeToMore,
    networkStatus
  } = (0, _gql.useQuery)(PRIVATE_MESSAGES, {
    variables: {
      userId
    },
    fetchPolicy: "cache-and-network",
    skip: !userId,
    notifyOnNetworkStatusChange: true
  });
  const privateMessages = ((_a = data == null ? void 0 : data.privateMessages) == null ? void 0 : _a.results) || [];
  const forwardCursor = (_b = data == null ? void 0 : data.privateMessages) == null ? void 0 : _b.forwardCursor;
  (0, _react.useEffect)(() => {
    if (!userId) return;
    const unsubscribeToMessages = subscribeToMore({
      document: ON_MESSAGE_FROM_USER,
      variables: {
        userId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data: subData
        }
      }) => {
        var _a2, _b2, _c, _d, _e;
        const newMessage = (_a2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _a2.message;
        const sender = newMessage == null ? void 0 : newMessage.user;
        const receiver = (_b2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _b2.receivingUser;
        if (userId === (sender == null ? void 0 : sender.id) || userId === (receiver == null ? void 0 : receiver.id)) {
          return __spreadProps(__spreadValues({}, prev), {
            privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
              results: newMessage ? (_d = (_c = prev.privateMessages) == null ? void 0 : _c.results) == null ? void 0 : _d.concat([newMessage]) : (_e = prev.privateMessages) == null ? void 0 : _e.results
            })
          });
        }
        return prev;
      }
    });
    const unsubscribeToMessageDeletion = subscribeToMore({
      document: ON_PRIVATE_MESSAGE_DELETED,
      updateQuery: (prev, {
        subscriptionData: {
          data: subData
        }
      }) => {
        var _a2, _b2, _c;
        const messageId = (_a2 = subData == null ? void 0 : subData.privateMessageDeleted) == null ? void 0 : _a2.deletedMessageId;
        const updated = (_c = (_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) == null ? void 0 : _c.filter(message => (message == null ? void 0 : message.id) != messageId);
        const updatedState = __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: updated
          })
        });
        return updatedState;
      }
    });
    return () => {
      unsubscribeToMessages();
      unsubscribeToMessageDeletion();
    };
  }, [subscribeToMore, userId]);
  const nextPage = () => __async(void 0, null, function* () {
    if (!forwardCursor) return;
    yield fetchMore({
      variables: {
        userId,
        cursor: forwardCursor
      },
      updateQuery: (prev, {
        fetchMoreResult
      }) => {
        var _a2, _b2, _c, _d, _e;
        if (!fetchMoreResult) return prev;
        return __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: (_c = (_a2 = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _a2.results) == null ? void 0 : _c.concat(((_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) || []),
            forwardCursor: (_d = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _d.forwardCursor,
            backCursor: (_e = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _e.forwardCursor
          })
        });
      }
    });
  });
  return {
    privateMessages,
    nextPage,
    loading: !data || networkStatus === 3
  };
};
const MessagesWithData = (0, _react.memo)(({
  loading,
  messages,
  nextPage,
  deleteMessage
}) => {
  return /* @__PURE__ */_react.default.createElement(_Messages.default, {
    loading,
    messages: messages || [],
    nextPage,
    deleteMessage: privateMessageId => __async(void 0, null, function* () {
      yield deleteMessage(privateMessageId);
    }),
    noProfile: true
  });
});
const PrivateChat = (0, _helpers.withDialogs)((0, _react.memo)(({
  onClose,
  user,
  viewProfile,
  alert,
  contextMenuRef
}) => {
  var _a;
  const currentUser = (0, _helpers.currentUserHook)();
  const client = (0, _client.useApolloClient)();
  const friend = (_a = currentUser == null ? void 0 : currentUser.friends) == null ? void 0 : _a.find(f => f.user.id === (user == null ? void 0 : user.id));
  const friendAccepted = (friend == null ? void 0 : friend.state) === "ACCEPTED";
  const {
    privateMessages,
    nextPage,
    loading
  } = usePrivateMessages((user == null ? void 0 : user.id) || "");
  const [sendMessage] = (0, _gql.useMutation)(SEND_MESSAGE);
  const [deleteMessage] = (0, _gql.useMutation)(DELETE_PRIVATE_MESSAGE);
  const [addFriend, {
    loading: addFriendLoading
  }] = (0, _gql.useMutation)(ADD_FRIEND_MUTATION);
  const [removeFriend, {
    loading: removeFriendLoading
  }] = (0, _gql.useMutation)(REMOVE_FRIEND_MUTATION);
  (0, _react.useEffect)(() => {
    var _a2;
    const data = client.readQuery({
      query: MESSAGE_LIST
    });
    if (data == null ? void 0 : data.messageList) {
      client.writeQuery({
        query: MESSAGE_LIST,
        data: {
          messageList: (_a2 = data == null ? void 0 : data.messageList) == null ? void 0 : _a2.map(m => {
            var _a3;
            if (((_a3 = m == null ? void 0 : m.user) == null ? void 0 : _a3.id) === (user == null ? void 0 : user.id)) return __spreadProps(__spreadValues({}, m), {
              unreadCount: 0
            });
            return m;
          })
        }
      });
    }
  }, [client, user == null ? void 0 : user.id]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.privateChat
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.titlebar
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    inverted: true,
    className: styles.backIcon,
    onClick: () => onClose(),
    icon: "angle left"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.userBox,
    onClick: () => viewProfile((user == null ? void 0 : user.id) || "")
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.avatarWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "circle",
    color: "green",
    className: [styles.onlineCircle, !(user == null ? void 0 : user.isConnected) && styles.hidden]
  }), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: (user == null ? void 0 : user.avatarUrl) || "",
    size: "mini",
    circular: true,
    className: styles.avatar,
    loading: "lazy"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, user == null ? void 0 : user.displayName))), /* @__PURE__ */_react.default.createElement(MessagesWithData, {
    key: user == null ? void 0 : user.id,
    loading,
    messages: privateMessages.filter(isNotNull),
    nextPage,
    deleteMessage: privateMessageId => __async(void 0, null, function* () {
      yield deleteMessage({
        variables: {
          privateMessageId
        }
      });
    })
  }), friendAccepted ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageBox
  }, /* @__PURE__ */_react.default.createElement(_Messagebox.default, {
    isPrivate: true,
    sendMessage: body => sendMessage({
      variables: {
        body,
        userId: (user == null ? void 0 : user.id) || ""
      }
    }),
    emojiPickerMountNode: (contextMenuRef == null ? void 0 : contextMenuRef.current) || void 0
  })) : friend && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.actions
  }, friend.state === "REQUESTED" && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Friend requested"), friend.state === "PENDING" && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Friend request pending"), friend.state === "PENDING" && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    loading: addFriendLoading,
    inverted: true,
    size: "xs",
    color: "white",
    disabled: !(user == null ? void 0 : user.username),
    onClick: e => __async(void 0, null, function* () {
      e.stopPropagation();
      try {
        yield addFriend({
          variables: {
            username: (user == null ? void 0 : user.username) || ""
          }
        });
      } catch (e2) {
        if (alert) {
          alert(e2 == null ? void 0 : e2.message);
        }
      }
    }),
    style: {
      borderRadius: 10,
      width: 25,
      height: 25,
      padding: 0,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 4,
      marginRight: 4
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.acceptFriendRequestIcon,
    name: "check"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    loading: removeFriendLoading,
    inverted: true,
    size: "xs",
    color: "white",
    onClick: e => __async(void 0, null, function* () {
      e.stopPropagation();
      onClose();
      yield removeFriend({
        variables: {
          userId: (user == null ? void 0 : user.id) || ""
        }
      });
    }),
    style: {
      borderRadius: 10,
      width: 25,
      height: 25,
      padding: 0,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 4,
      marginRight: 4
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "remove"
  }))));
}));
const MessagesDropdown = (0, _react.memo)(({
  viewProfile,
  messageList,
  currentChatUserId,
  setCurrentChatUserId,
  onlyOnline,
  loading,
  setChoosingFriend,
  contextMenuRef
}) => {
  var _a;
  const currentChatUser = (_a = messageList.find(m => {
    var _a2;
    return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) === currentChatUserId;
  })) == null ? void 0 : _a.user;
  const messageListString = JSON.stringify(messageList);
  const filteredMessageList = (0, _react.useMemo)(() => {
    const messageList2 = JSON.parse(messageListString);
    return onlyOnline ? messageList2.filter(m => {
      var _a2;
      return (_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.isConnected;
    }) : messageList2;
  }, [onlyOnline, messageListString]);
  const closePrivateChat = (0, _react.useCallback)(() => {
    setCurrentChatUserId(null);
  }, [setCurrentChatUserId]);
  if (loading) return /* @__PURE__ */_react.default.createElement(_components.LoadingScreen, null);
  return /* @__PURE__ */_react.default.createElement(_uikit.TabView, {
    menu: false,
    forceIndex: currentChatUser ? 1 : 0,
    className: styles.tabView
  }, /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "MessageList"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageList
  }, messageList.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.MessageList, {
    items: (filteredMessageList == null ? void 0 : filteredMessageList.map((member, index) => {
      var _a2, _b, _c, _d, _e, _f;
      return {
        key: (_a2 = member.user) == null ? void 0 : _a2.id,
        onClick: () => {
          var _a3;
          return setCurrentChatUserId(((_a3 = member.user) == null ? void 0 : _a3.id) || "");
        },
        title: (_b = member.user) == null ? void 0 : _b.displayName,
        content: ((_c = member.latestMessage) == null ? void 0 : _c.body) || "",
        unread: member.unreadCount && member.unreadCount > 0,
        online: !!((_d = member.user) == null ? void 0 : _d.isConnected),
        avatarUrl: ((_e = member.user) == null ? void 0 : _e.avatarUrl) || "",
        timestamp: (_f = member.latestMessage) == null ? void 0 : _f.time,
        odd: index % 2 !== 0
      };
    })) || []
  }), (messageList == null ? void 0 : messageList.length) === 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.noMessages
  }, "Add friends to use DMs!", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    onClick: () => {
      setChoosingFriend(true);
    }
  }, "Add a friend")))), /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "PrivateChat"
  }, /* @__PURE__ */_react.default.createElement(PrivateChat, {
    user: currentChatUser,
    onClose: closePrivateChat,
    viewProfile,
    contextMenuRef
  })));
});
const PrivateMessageList = (0, _react.memo)(({
  messageList,
  loading,
  contextMenuRef
}) => {
  var _a;
  const [currentChatUserId, setCurrentChatUserId] = useCurrentChatUserIdState(null);
  const currentChatUser = (_a = messageList == null ? void 0 : messageList.find(m => {
    var _a2;
    return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) === currentChatUserId;
  })) == null ? void 0 : _a.user;
  const [onlyOnline, setOnlyOnline] = useOnlyOnlineState(false);
  const currentUser = (0, _helpers.currentUserHook)();
  const [choosingFriend, setChoosingFriend] = (0, _react.useState)(false);
  const [viewUserProfileId, setViewUserProfileId] = (0, _react.useState)(null);
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    setHideBottomMenu,
    overlayMenu
  } = (0, _react.useContext)(_MobileContext.default);
  (0, _react.useEffect)(() => {
    if (setHideBottomMenu && overlayMenu === "chats") {
      setHideBottomMenu(!!currentChatUser);
    }
  }, [currentChatUser, setHideBottomMenu, overlayMenu]);
  const closeUserPicker = (0, _react.useCallback)(() => {
    setChoosingFriend(false);
  }, []);
  const closeViewProfile = (0, _react.useCallback)(() => {
    setViewUserProfileId(null);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(UserPickerModal, {
    open: choosingFriend,
    onClose: closeUserPicker
  }), /* @__PURE__ */_react.default.createElement(UserProfileModal, {
    userId: viewUserProfileId,
    open: !!viewUserProfileId,
    onClose: closeViewProfile,
    currentUser
  }), !currentChatUser && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.friendsHeader
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Chats"), messageList && messageList.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    className: styles.checkbox,
    defaultChecked: onlyOnline,
    label: "Only online",
    toggle: true,
    onChange: (_e, data) => {
      if (data.checked !== onlyOnline) {
        setOnlyOnline(!!data.checked);
      }
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: "Add a friend",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      size: isMobile ? void 0 : "s",
      style: isMobile ? {
        borderWidth: 0
      } : {
        width: 50,
        height: 25,
        padding: 0,
        borderWidth: 0
      },
      onClick: () => {
        setChoosingFriend(true);
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "plus",
      className: styles.plus
    }))
  })), /* @__PURE__ */_react.default.createElement(MessagesDropdown, {
    contextMenuRef,
    loading,
    setCurrentChatUserId: currentChatUserId2 => setCurrentChatUserId(currentChatUserId2),
    currentChatUserId,
    onlyOnline,
    messageList: messageList || [],
    setChoosingFriend,
    viewProfile: userId => {
      setViewUserProfileId(userId);
    }
  }));
});
const MessagesScreen = (0, _react.memo)(({
  refetchMessageList,
  messageList,
  loading,
  contextMenuRef
}) => {
  const currentUser = (0, _helpers.currentUserHook)();
  (0, _react.useEffect)(() => {
    if (refetchMessageList) {
      refetchMessageList();
    }
  }, [refetchMessageList]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messagesDropdown
  }, (currentUser == null ? void 0 : currentUser.isAnonymous) ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.pleaseLogin
  }, "Please Log in to add friends", /* @__PURE__ */_react.default.createElement(_components.LoginModal, {
    trigger: ({
      onClick
    }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      onClick,
      inverted: true,
      size: "xs",
      color: "white",
      style: {
        marginTop: 10
      }
    }, "Click here to log in")
  })) : /* @__PURE__ */_react.default.createElement(PrivateMessageList, {
    messageList,
    loading,
    contextMenuRef
  }));
});
const useMessages = () => {
  const {
    loading,
    data,
    subscribeToMore,
    refetch
  } = (0, _gql.useQuery)(MESSAGE_LIST, {
    fetchPolicy: "cache-first"
  });
  const currentUser = (0, _helpers.currentUserHook)();
  (0, _react.useEffect)(() => {
    if (subscribeToMore) {
      const unsubscribeToMessageList = subscribeToMore({
        document: ON_MESSAGE_LIST_UPDATE,
        updateQuery: (prev, {
          subscriptionData: {
            data: data2
          }
        }) => {
          const {
            messageListUpdate
          } = data2;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: messageListUpdate
          });
        }
      });
      const unsubscribetoMessages = subscribeToMore({
        document: ON_MESSAGE,
        updateQuery: (prev, {
          subscriptionData: {
            data: data2
          }
        }) => {
          var _a;
          const {
            newPrivateMessage
          } = data2;
          if (!newPrivateMessage) return prev;
          const {
            message,
            receivingUser,
            seen
          } = newPrivateMessage;
          const updated = __spreadProps(__spreadValues({}, prev), {
            messageList: (_a = prev == null ? void 0 : prev.messageList) == null ? void 0 : _a.map(item => {
              var _a2, _b, _c, _d;
              if (((_a2 = item == null ? void 0 : item.user) == null ? void 0 : _a2.id) === ((_b = message == null ? void 0 : message.user) == null ? void 0 : _b.id) || currentUser.id === ((_c = message == null ? void 0 : message.user) == null ? void 0 : _c.id) && (receivingUser == null ? void 0 : receivingUser.id) === ((_d = item == null ? void 0 : item.user) == null ? void 0 : _d.id)) {
                return __spreadProps(__spreadValues({}, item), {
                  latestMessage: message,
                  unreadCount: seen ? 0 : ((item == null ? void 0 : item.unreadCount) || 0) + 1
                });
              }
              return item;
            }).sort((a, b) => {
              const aTime = (a == null ? void 0 : a.latestMessage) ? a.latestMessage.time : 0;
              const bTime = (b == null ? void 0 : b.latestMessage) ? b.latestMessage.time : 0;
              return (bTime || 0) - (aTime || 0);
            })
          });
          return updated;
        }
      });
      const unsubscribetoDeletedMessages = subscribeToMore({
        document: ON_PRIVATE_MESSAGE_DELETED,
        updateQuery: (prev, {
          subscriptionData: {
            data: subData
          }
        }) => {
          var _a, _b, _c, _d;
          const messageId = (_a = subData == null ? void 0 : subData.privateMessageDeleted) == null ? void 0 : _a.deletedMessageId;
          const previousMessage = (_c = (_b = subData == null ? void 0 : subData.privateMessageDeleted) == null ? void 0 : _b.previousMessage) == null ? void 0 : _c.latestMessage;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: (_d = prev == null ? void 0 : prev.messageList) == null ? void 0 : _d.map(elem => {
              var _a2;
              if (((_a2 = elem == null ? void 0 : elem.latestMessage) == null ? void 0 : _a2.id) == messageId) return __spreadProps(__spreadValues({}, elem), {
                latestMessage: previousMessage
              });
              return elem;
            })
          });
        }
      });
      return () => {
        unsubscribeToMessageList();
        unsubscribetoMessages();
        unsubscribetoDeletedMessages();
      };
    }
  }, [subscribeToMore, currentUser.id]);
  const messageList = (data == null ? void 0 : data.messageList) || [];
  const unreadCount = messageList == null ? void 0 : messageList.reduce((acc, item) => acc + ((item == null ? void 0 : item.unreadCount) || 0), 0);
  return {
    loading,
    messageList,
    unreadCount,
    refetch
  };
};
exports.useMessages = useMessages;
var _default = exports.default = MessagesScreen;