"use strict";
import React, { memo, useCallback, useMemo, useState } from "react";
import LoginModal from "./LoginModal";
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
import { useMergedRefs } from "@/uikit";
export default memo(({
  ref,
  children,
  onClick
}) => {
  const [open, setOpen] = useState(false);
  const currentUser = currentUserHook();
  const isLoggedIn = !(currentUser == null ? void 0 : currentUser.isAnonymous);
  const openModal = () => setOpen(true);
  const closeModal = useCallback(() => setOpen(false), []);
  const loggedOutChildren = useMemo(() => {
    if (typeof children === "function") {
      return children(openModal);
    }
    return React.cloneElement(children, {
      onClick: openModal
    });
  }, [children]);
  const childrenRef = useMergedRefs(ref);
  const clonedChildren = useMemo(() => {
    if (isLoggedIn) {
      if (typeof children === "function") {
        return children(() => {
        });
      }
      return React.cloneElement(children, {
        ref: childrenRef,
        onClick: onClick || children.props.onClick
      });
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(LoginModal, { open, onClose: closeModal }), loggedOutChildren);
  }, [children, childrenRef, onClick, isLoggedIn, open, loggedOutChildren, closeModal]);
  return clonedChildren;
});
