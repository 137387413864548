"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql } from "@/shared/hooks/gql";
import { useQuery } from "@/shared/hooks/gql";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useEffect } from "react";
export const GET_STATE = gql(`
  query mediaPlayerStateQuery1($roomId: String!) {
    mediaPlayerState(roomId: $roomId) {
    id
    type
    position
    url
    paused
    live
    unixTimePositionSent
    playbackRate
    hostUser {
      id
      isAnonymous
      isConnected
      username
      avatarUrl
      countryCode
      displayName
      email
      emailConfirmed
    }
    }
  }
`);
const ON_UPDATE_STATE = gql(`
  subscription OnMediaPlayerUpdateState($roomId: String!) {
    mediaPlayerUpdateState(roomId: $roomId) {
    id
    type
    position
    url
    paused
    live
    unixTimePositionSent
    playbackRate
    hostUser {
      id
      isAnonymous
      isConnected
      username
      avatarUrl
      countryCode
      displayName
      email
      emailConfirmed
    }
    }
  }
`);
const GET_SUBTITLES = gql(`
  query MediaPlayerSubtitlesQuery($roomId: String!) {
    mediaPlayerSubtitles(roomId: $roomId) {
      id
      cues {
        end
        start
        text
      }
      filename
    }
  }
`);
const ON_UPDATE_SUBTITLES = gql(`
  subscription OnMediaPlayerUpdateSubtitles($roomId: String!) {
    mediaPlayerUpdateSubtitles(roomId: $roomId) {
      id
      cues {
        end
        start
        text
      }
      filename
    }
  }
`);
export const useMediaPlayerStateCached = () => {
  const roomId = useRoomId();
  const {
    data
  } = useQuery(GET_STATE, {
    variables: {
      roomId
    },
    fetchPolicy: "cache-first",
    skip: !roomId
  });
  const {
    data: subtitlesData
  } = useQuery(GET_SUBTITLES, {
    variables: {
      roomId
    },
    skip: !roomId
  });
  const mediaPlayerState = (data == null ? void 0 : data.mediaPlayerState) || {};
  const subtitles = subtitlesData == null ? void 0 : subtitlesData.mediaPlayerSubtitles;
  return {
    mediaPlayerState,
    subtitles
  };
};
export default () => {
  const roomId = useRoomId();
  const {
    data: mediaplayerData,
    subscribeToMore,
    loading
  } = useQuery(GET_STATE, {
    variables: {
      roomId
    },
    fetchPolicy: "cache-first",
    skip: !roomId
  });
  const {
    data: subtitlesData,
    subscribeToMore: subtitlesSubscribeToMore
  } = useQuery(GET_SUBTITLES, {
    variables: {
      roomId
    },
    skip: !roomId
  });
  const subtitles = subtitlesData == null ? void 0 : subtitlesData.mediaPlayerSubtitles;
  useEffect(() => {
    if (loading)
      return;
    const unsubscribeToUpdates = subscribeToMore({
      document: ON_UPDATE_STATE,
      variables: {
        roomId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data
        }
      }) => {
        return __spreadProps(__spreadValues({}, prev), {
          mediaPlayerState: data.mediaPlayerUpdateState
        });
      }
    });
    const unsubscribeToSubtitlesUpdates = subtitlesSubscribeToMore({
      document: ON_UPDATE_SUBTITLES,
      variables: {
        roomId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data
        }
      }) => {
        return __spreadProps(__spreadValues({}, prev), {
          mediaPlayerSubtitles: data.mediaPlayerUpdateSubtitles
        });
      }
    });
    return () => {
      unsubscribeToUpdates();
      unsubscribeToSubtitlesUpdates();
    };
  }, [subscribeToMore, loading, roomId, subtitlesSubscribeToMore]);
  const mediaPlayerState = (mediaplayerData == null ? void 0 : mediaplayerData.mediaPlayerState) || {};
  return {
    mediaPlayerState,
    subtitles
  };
};
