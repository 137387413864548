"use strict";
import { gql, useMutation } from "@/shared/hooks/gql";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useMemo } from "react";
const SET_VALUE = gql(`
  mutation MediaPlayerSetPosition1(
    $roomId: String!
    $key: String!
    $value: String!
  ) {
    spacesUpdateValue(roomId: $roomId, key: $key, value: $value) {
      ok
    }
  }
`);
const REMOVE_VALUE = gql(`
  mutation MediaPlayerSetPosition2($roomId: String!, $key: String!) {
    spacesRemoveValue(roomId: $roomId, key: $key) {
      ok
    }
  }
`);
export const useSpacesActions = () => {
  const roomId = useRoomId();
  const [_setValue] = useMutation(SET_VALUE);
  const [_removeValue] = useMutation(REMOVE_VALUE);
  return useMemo(() => {
    const setValue = (key, value) => _setValue({
      variables: {
        roomId,
        key,
        value: JSON.stringify(value)
      }
    });
    const removeValue = (key) => _removeValue({
      variables: {
        roomId,
        key
      }
    });
    return {
      setValue,
      removeValue
    };
  }, [_setValue, _removeValue, roomId]);
};
