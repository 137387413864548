"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useCallback, useEffect, useRef } from "react";
import AVEngine from "@/services/AVEngine";
import { useMediaSoup } from "./useMediaSoup";
import { useMediaSoupConference } from "./useMediaSoupConference";
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
class StreamStore {
  constructor(onUpdate) {
    __publicField(this, "onUpdate");
    __publicField(this, "streamIds");
    __publicField(this, "streams");
    this.onUpdate = onUpdate;
    this.streams = {};
    this.streamIds = {};
  }
  addStream(userId, stream, streamId) {
    this.streams[userId] = stream;
    if (streamId) {
      this.streamIds[userId] = streamId;
    }
    this.onUpdate(__spreadValues({}, this.streams));
  }
  removeStream(userId) {
    delete this.streams[userId];
    delete this.streamIds[userId];
    this.onUpdate(__spreadValues({}, this.streams));
  }
  hasStream(userId) {
    return !!this.streamIds[userId];
  }
  getStream(userId) {
    return this.streams[userId];
  }
  getStreamId(userId) {
    return this.streamIds[userId];
  }
}
export default ({
  enabled,
  mediaType,
  onStreamStoreUpdate,
  webcamOn,
  microphoneOn,
  onChannel,
  localWebcamStream,
  localMicrophoneStream
}) => {
  const currentUser = currentUserHook();
  const {
    enableAudio,
    disableAudio,
    enableVideo,
    disableVideo,
    mediasoupState
  } = useMediaSoupConference();
  const {
    produceMediaSoupStream,
    consumeMediaSoupStream,
    closeProducerTransport,
    closeConsumerTransport
  } = useMediaSoup();
  const loopRef = React.useRef(null);
  const streamStoreRef = React.useRef(new StreamStore((streams) => {
    onStreamStoreUpdate(streams);
  }));
  const oldConferenceRef = useRef(mediasoupState == null ? void 0 : mediasoupState.conference);
  const consumeStream = useCallback((userId, streamId, retries) => __async(void 0, null, function* () {
    const streamStore = streamStoreRef.current;
    retries = retries || 0;
    if (retries > 10) {
      streamStore.removeStream(userId);
      return;
    }
    const existingStreamId = streamStore.getStreamId(userId);
    if (existingStreamId && existingStreamId === streamId) {
      return;
    }
    streamStore.addStream(userId, {
      id: "LOADING"
    }, null);
    try {
      const stream = yield consumeMediaSoupStream(userId, `CONFERENCE_${mediaType}/${streamId}`, mediaType);
      streamStore.addStream(userId, stream, streamId);
    } catch (e) {
      console.error(e);
      if (streamStore.hasStream(userId)) {
        streamStore.removeStream(userId);
      }
      yield consumeStream(userId, streamId, retries + 1);
    }
  }), [consumeMediaSoupStream, mediaType]);
  const webcamActive = useCallback(() => {
    const streamStore = streamStoreRef.current;
    const localStream = streamStore.getStream(currentUser.id);
    return localStream && (localStream.id === "LOADING" || localStream.getVideoTracks().length > 0);
  }, [currentUser == null ? void 0 : currentUser.id]);
  const microphoneActive = useCallback(() => {
    const streamStore = streamStoreRef.current;
    const localStream = streamStore.getStream(currentUser.id);
    return localStream && (localStream.id === "LOADING" || localStream.getAudioTracks().length > 0);
  }, [currentUser == null ? void 0 : currentUser.id]);
  const pendingConnectionsRef = useRef({
    audio: false,
    video: false
  });
  const stopStream = useCallback((mediaType2) => {
    const streamStore = streamStoreRef.current;
    const streamId = streamStore.getStreamId(currentUser.id);
    const stream = streamStore.getStream(currentUser.id);
    const tracks = (stream == null ? void 0 : stream.getTracks) && stream.getTracks();
    if (stream && tracks) {
      for (const track of tracks) {
        track.stop();
      }
    }
    streamStore.removeStream(currentUser.id);
    closeProducerTransport(`CONFERENCE_${mediaType2}/${streamId}`);
  }, [closeProducerTransport, currentUser.id]);
  const tearDownRtcAudioLink = useCallback(() => {
    const pendingConnections = pendingConnectionsRef.current;
    if (microphoneActive()) {
      pendingConnections.audio = true;
      disableAudio();
      stopStream(mediaType);
      AVEngine.audioRequestedForCall = false;
      AVEngine.stopUserMediaAudioStream();
    } else {
      pendingConnections.audio = false;
    }
  }, [stopStream, mediaType, microphoneActive, disableAudio]);
  const tearDownRtcVideoLink = useCallback(() => {
    const pendingConnections = pendingConnectionsRef.current;
    if (webcamActive()) {
      pendingConnections.video = true;
      disableVideo();
      stopStream(mediaType);
      AVEngine.videoRequestedForCall = false;
      AVEngine.stopUserMediaVideoStream();
    } else
      pendingConnections.video = false;
  }, [webcamActive, disableVideo, stopStream, mediaType]);
  const enableWebcam = useCallback((localStream) => __async(void 0, null, function* () {
    const streamStore = streamStoreRef.current;
    if (webcamActive())
      return;
    try {
      let stream;
      if (localStream) {
        stream = localStream;
      } else if (AVEngine.userMediaVideoStream) {
        stream = AVEngine.userMediaVideoStream;
      } else {
        stream = yield AVEngine.getUserMediaVideoStream(AVEngine.getPreferredVideoInputDeviceId() || void 0);
      }
      streamStore.addStream(currentUser.id, {
        id: "LOADING"
      }, null);
      if (stream) {
        yield produceMediaSoupStream(`CONFERENCE_${mediaType}/${stream.id}`, stream);
        enableVideo(stream.id);
        streamStore.addStream(currentUser.id, stream, stream.id);
      }
      if (!localStream)
        AVEngine.videoRequestedForCall = true;
    } catch (e) {
      console.warn(e);
      streamStore.removeStream(currentUser.id);
    }
  }), [currentUser.id, enableVideo, mediaType, webcamActive, produceMediaSoupStream]);
  const enableMicrophone = useCallback((localStream) => __async(void 0, null, function* () {
    const streamStore = streamStoreRef.current;
    if (microphoneActive())
      return;
    let stream;
    if (localStream) {
      stream = localStream;
    } else {
      if (AVEngine.userMediaAudioStream) {
        stream = AVEngine.userMediaAudioStream;
      } else {
        stream = yield AVEngine.getUserMediaAudioStream(AVEngine.getPreferredAudioInputDeviceId(), true);
      }
    }
    if (!(stream == null ? void 0 : stream.id)) {
      console.error("No stream available");
      return;
    }
    streamStore.addStream(currentUser.id, stream, stream.id);
    try {
      yield produceMediaSoupStream(`CONFERENCE_${mediaType}/${stream.id}`, stream);
      enableAudio(stream.id);
      if (!localStream)
        AVEngine.audioRequestedForCall = true;
    } catch (e) {
      console.warn(e);
    }
  }), [currentUser.id, enableAudio, mediaType, microphoneActive, produceMediaSoupStream]);
  const disableWebcam = useCallback(() => __async(void 0, null, function* () {
    if (!webcamActive())
      return;
    disableVideo();
    stopStream(mediaType);
    AVEngine.videoRequestedForCall = false;
    AVEngine.stopUserMediaVideoStream();
  }), [disableVideo, stopStream, mediaType, webcamActive]);
  const disableMicrophone = useCallback(() => __async(void 0, null, function* () {
    if (!microphoneActive())
      return;
    disableAudio();
    stopStream(mediaType);
    AVEngine.audioRequestedForCall = false;
    AVEngine.stopUserMediaAudioStream();
  }), [disableAudio, stopStream, mediaType, microphoneActive]);
  const initialConferenceRef = useRef(mediasoupState == null ? void 0 : mediasoupState.conference);
  const initialConference = initialConferenceRef.current;
  const initialLocalWebcamStreamRef = useRef(localWebcamStream);
  const initialLocalWebcamStream = initialLocalWebcamStreamRef.current;
  const initialLocalMicrophoneStreamRef = useRef(localMicrophoneStream);
  const initialLocalMicrophoneStream = initialLocalMicrophoneStreamRef.current;
  const initialWebcamOnRef = useRef(webcamOn);
  const initialMicrophoneOnRef = useRef(microphoneOn);
  const initialWebcamOn = initialWebcamOnRef.current;
  const initialMicrophoneOn = initialMicrophoneOnRef.current;
  useEffect(() => {
    const streamStore = streamStoreRef.current;
    if (!enabled)
      return;
    initialConference == null ? void 0 : initialConference.forEach((member) => __async(void 0, null, function* () {
      var _a;
      if (!member)
        return;
      if (member.userId !== currentUser.id) {
        const mediaSource = (_a = member == null ? void 0 : member.mediaSources) == null ? void 0 : _a.find((m) => (m == null ? void 0 : m.type) === mediaType);
        const streamId = mediaSource && mediaSource.id;
        if (streamId && (member == null ? void 0 : member.userId)) {
          consumeStream(member.userId, streamId);
        }
      }
    }));
    if (mediaType === "video" && initialWebcamOn) {
      enableWebcam(initialLocalWebcamStream);
    }
    if (mediaType === "audio" && initialMicrophoneOn) {
      enableMicrophone(initialLocalMicrophoneStream);
    }
    onChannel({
      streamStore,
      enableWebcam,
      enableMicrophone,
      disableWebcam,
      disableMicrophone
    });
    const reesstablishRtcVideoLink = () => {
      const pendingConnections = pendingConnectionsRef.current;
      if (pendingConnections.video)
        enableWebcam(null);
    };
    const reesstablishRtcAudioLink = () => {
      const pendingConnections = pendingConnectionsRef.current;
      if (pendingConnections.audio)
        enableMicrophone(null);
    };
    AVEngine.eventEmitter.on("av-audio-source-changed", tearDownRtcAudioLink, void 0);
    AVEngine.eventEmitter.on("av-video-source-changed", tearDownRtcVideoLink, void 0);
    AVEngine.eventEmitter.on("user-video-stream-changed", reesstablishRtcVideoLink, void 0);
    AVEngine.eventEmitter.on("user-audio-stream-changed", reesstablishRtcAudioLink, void 0);
    return () => {
      AVEngine.eventEmitter.removeAllListeners("av-audio-source-changed");
      AVEngine.eventEmitter.removeAllListeners("av-video-source-changed");
      AVEngine.eventEmitter.removeAllListeners("user-audio-stream-changed");
      AVEngine.eventEmitter.removeAllListeners("user-video-stream-changed");
      tearDownRtcAudioLink();
      tearDownRtcVideoLink();
    };
  }, [enabled, initialConference, mediaType, initialWebcamOn, initialMicrophoneOn, initialLocalMicrophoneStream, initialLocalWebcamStream, consumeStream, currentUser.id, disableMicrophone, disableWebcam, enableMicrophone, enableWebcam, onChannel, tearDownRtcAudioLink, tearDownRtcVideoLink]);
  const getConferenceIds = useCallback((conference) => {
    if (!conference)
      return [];
    return conference == null ? void 0 : conference.filter((m) => {
      var _a;
      return (_a = m == null ? void 0 : m.mediaSources) == null ? void 0 : _a.find((m2) => (m2 == null ? void 0 : m2.type) === mediaType);
    }).map((m) => {
      var _a, _b;
      return [(m == null ? void 0 : m.userId) || "", ((_b = (_a = m == null ? void 0 : m.mediaSources) == null ? void 0 : _a.find((m2) => (m2 == null ? void 0 : m2.type) === mediaType)) == null ? void 0 : _b.id) || ""];
    });
  }, [mediaType]);
  useEffect(() => {
    loopRef.current = setInterval(() => {
      const streamStore = streamStoreRef.current;
      if (!(mediasoupState == null ? void 0 : mediasoupState.conference))
        return;
      getConferenceIds(mediasoupState == null ? void 0 : mediasoupState.conference).forEach(([userId, streamId]) => {
        if (userId === currentUser.id)
          return;
        const stream = streamStore.getStream(userId);
        const streamActive = stream && stream.active;
        const hasStream = streamStore.hasStream(userId);
        if (hasStream && !streamActive) {
        }
        if (!streamActive || !hasStream) {
          consumeStream(userId, streamId);
        }
      });
    }, 5e3);
    return () => {
      if (loopRef.current) {
        clearInterval(loopRef.current);
      }
    };
  }, [mediasoupState == null ? void 0 : mediasoupState.conference, consumeStream, currentUser.id, getConferenceIds]);
  useEffect(() => {
    const streamStore = streamStoreRef.current;
    if (!enabled)
      return;
    const oldConference = oldConferenceRef.current;
    const conference = mediasoupState == null ? void 0 : mediasoupState.conference;
    if (conference) {
      const oldConferenceIds = oldConference ? getConferenceIds(oldConference) : [];
      const conferenceIds = getConferenceIds(conference);
      const conferenceUserIds = conferenceIds.map((ids) => ids[0]);
      oldConferenceIds.forEach(([userId, streamId]) => {
        if (userId === currentUser.id)
          return;
        if (conferenceUserIds.indexOf(userId) === -1) {
          streamStore.removeStream(userId);
          closeConsumerTransport(userId, `CONFERENCE_${mediaType}/${streamId}`);
        }
      });
      conferenceIds.forEach(([userId, streamId]) => {
        if (userId === currentUser.id)
          return;
        if (!streamStore.hasStream(userId)) {
          consumeStream(userId, streamId);
        }
      });
    }
    oldConferenceRef.current = conference;
  }, [mediasoupState == null ? void 0 : mediasoupState.conference, enabled, getConferenceIds, consumeStream, closeConsumerTransport, currentUser.id, mediaType]);
};
