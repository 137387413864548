"use strict";
import { useEffect } from "react";
export default () => {
  useEffect(() => {
    const onBeforeInstallPrompt = (e) => {
      e.preventDefault();
      window.installPrompt = e;
    };
    window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);
    if (window.onbeforeinstallprompt === void 0) {
      ;
      window.onbeforeinstallprompt = null;
    }
    return () => {
      window.removeEventListener("beforeinstallprompt", onBeforeInstallPrompt);
      delete window.installPrompt;
    };
  }, []);
};
