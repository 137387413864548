"use strict";
import { gql } from "@apollo/client";
export const CHECK_TOKEN = gql(`
  query CheckToken($token: String!) {
    checkToken(token: $token) {
      ok
    }
  }
`);
export const ANON_LOGIN = gql(`
  mutation AnonLogin2 {
    anonLogin {
      token
    }
  }
`);
