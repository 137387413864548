"use strict";
import { c as _c } from "react/compiler-runtime";
import { useRef, useState, useEffect } from "react";
import debounce from "lodash.debounce";
export function mergeRefs(...refs) {
  return (node) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref && "current" in ref) {
        ;
        ref.current = node;
      }
    });
  };
}
export function useDebounce(fn, ms, options) {
  const $ = _c(10);
  let t0;
  if ($[0] !== fn || $[1] !== ms || $[2] !== options) {
    t0 = () => debounce(fn, ms, options);
    $[0] = fn;
    $[1] = ms;
    $[2] = options;
    $[3] = t0;
  } else {
    t0 = $[3];
  }
  const [storedFn, setStoredFn] = useState(t0);
  let t1;
  if ($[4] !== options) {
    t1 = JSON.stringify(options);
    $[4] = options;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  const optionsString = t1;
  let t2;
  let t3;
  if ($[6] !== fn || $[7] !== optionsString) {
    t2 = () => {
      const options_0 = JSON.parse(optionsString);
      setStoredFn(() => debounce(fn, 100, options_0));
    };
    t3 = [fn, optionsString];
    $[6] = fn;
    $[7] = optionsString;
    $[8] = t2;
    $[9] = t3;
  } else {
    t2 = $[8];
    t3 = $[9];
  }
  useEffect(t2, t3);
  return storedFn;
}
export function useMergedRefs(...t0) {
  const $ = _c(2);
  const refs = t0;
  let t1;
  if ($[0] !== refs) {
    t1 = () => mergeRefs(...refs);
    $[0] = refs;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const [mergedRefs] = useState(t1);
  return mergedRefs;
}
export const useOnClickOutside = (refs, onClickOutside) => {
  const $ = _c(4);
  let t0;
  let t1;
  if ($[0] !== onClickOutside || $[1] !== refs) {
    t0 = () => {
      if (!refs) {
        return;
      }
      const refsLength = refs.filter(_temp).length;
      const handleClickOutside = function handleClickOutside2(event) {
        if (document.querySelector("[data-spotlight]")) {
          return;
        }
        let outsides = 0;
        for (const ref of refs) {
          if (!ref) {
            continue;
          }
          let current = ref.current;
          if (current && current.ref) {
            current = current.ref.current;
          }
          if (current && !current.contains(event.target)) {
            outsides++;
          }
        }
        if (outsides === refsLength) {
          onClickOutside();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    };
    t1 = [refs, onClickOutside];
    $[0] = onClickOutside;
    $[1] = refs;
    $[2] = t0;
    $[3] = t1;
  } else {
    t0 = $[2];
    t1 = $[3];
  }
  useEffect(t0, t1);
};
export const useLongPress = (t0, t1) => {
  const $ = _c(12);
  const callback = t0 === void 0 ? _temp2 : t0;
  const duration = t1 === void 0 ? 300 : t1;
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timerId = useRef(null);
  let t2;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      x: 0,
      y: 0
    };
    $[0] = t2;
  } else {
    t2 = $[0];
  }
  const touchStartLocation = useRef(t2);
  let t3;
  if ($[1] !== callback || $[2] !== duration) {
    t3 = (e) => {
      timerId.current = setTimeout(() => {
        setLongPressTriggered(true);
        callback();
      }, duration);
      if (e.touches) {
        touchStartLocation.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        };
      }
    };
    $[1] = callback;
    $[2] = duration;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const startPress = t3;
  let t4;
  if ($[4] !== longPressTriggered) {
    t4 = (e_0) => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (longPressTriggered) {
        e_0.preventDefault();
      }
      setLongPressTriggered(false);
    };
    $[4] = longPressTriggered;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const clearPress = t4;
  let t5;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = (e_1) => {
      if (e_1.touches) {
        const xDiff = Math.abs(e_1.touches[0].clientX - touchStartLocation.current.x);
        const yDiff = Math.abs(e_1.touches[0].clientY - touchStartLocation.current.y);
        if (xDiff > 10 || yDiff > 10) {
          if (timerId.current) {
            clearTimeout(timerId.current);
          }
          setLongPressTriggered(false);
        }
      }
    };
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  const handleTouchMove = t5;
  let t6;
  let t7;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = () => () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
    t7 = [];
    $[7] = t6;
    $[8] = t7;
  } else {
    t6 = $[7];
    t7 = $[8];
  }
  useEffect(t6, t7);
  let t8;
  if ($[9] !== clearPress || $[10] !== startPress) {
    t8 = {
      onTouchStart: startPress,
      onTouchEnd: clearPress,
      onTouchMove: handleTouchMove
    };
    $[9] = clearPress;
    $[10] = startPress;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  return t8;
};
function _temp(r) {
  return r;
}
function _temp2() {
}
