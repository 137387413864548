"use strict";
import { useEffect } from "react";
import useColorScheme from "@/shared/hooks/useColorScheme";
import { useSpacesStateCached } from "./useRoomData";
import { initColors, updateColors } from "@/layout/colors";
import useSettings from "@/shared/hooks/useSettings";
export default () => {
  const settings = useSettings();
  const settingsColorSchemeId = settings == null ? void 0 : settings.colorScheme;
  const spacesState = useSpacesStateCached();
  const colorSchemeId = spacesState == null ? void 0 : spacesState.colorScheme;
  const settingsColorScheme = useColorScheme(settingsColorSchemeId);
  const roomColorScheme = useColorScheme(colorSchemeId);
  useEffect(() => {
    const {
      primaryColor,
      secondaryColor,
      foregroundColor
    } = roomColorScheme || settingsColorScheme || {};
    if (primaryColor && secondaryColor && foregroundColor) {
      updateColors(true, primaryColor, secondaryColor, foregroundColor);
    } else {
      initColors();
    }
    return () => {
      const {
        primaryColor: primaryColor2,
        secondaryColor: secondaryColor2,
        foregroundColor: foregroundColor2
      } = settingsColorScheme || {};
      if (primaryColor2 && secondaryColor2 && foregroundColor2) {
        updateColors(true, primaryColor2, secondaryColor2, foregroundColor2);
      } else {
        initColors();
      }
    };
  }, [roomColorScheme, settingsColorScheme]);
};
