"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "@/shared/hooks/gql";
import { Button, Icon, Image, Table } from "@/uikit";
import React, { useEffect } from "react";
import { useIsAdmin } from "@/room/hooks/useIsAdmin";
import { useRoomId } from "@/room/hooks/useRoomId";
import { withDialogs } from "@/shared/hooks/withDialogs";
const UNBAN_USER = gql(`
  mutation UnbanUser2($roomId: String!, $userId: String!) {
    unbanUserFromRoom(roomId: $roomId, userId: $userId) {
      ok
    }
  }
`);
const ON_BAN_UPDATE = gql(`
  subscription BanListUpdate($roomId: String!) {
    userBanlistUpdate(roomId: $roomId) {
      user {
        id
        username
        avatarUrl
        displayName
      }
      timestamp
    }
  }
`);
const GET_BANNED_USERS = gql(`
  query GetBannedUsers($roomId: String!) {
    userBans(roomId: $roomId) {
      user {
        id
        username
        avatarUrl
        displayName
      }
    }
  }
`);
export const BannedMembers = withDialogs(({
  confirm
}) => {
  const roomId = useRoomId();
  const isAdmin = useIsAdmin();
  const {
    data,
    subscribeToMore
  } = useQuery(GET_BANNED_USERS, {
    variables: {
      roomId
    }
  });
  const members = data ? data.userBans : [];
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ON_BAN_UPDATE,
      variables: {
        roomId
      },
      updateQuery: (_prev, {
        subscriptionData: {
          data: data2
        }
      }) => ({
        userBans: data2 == null ? void 0 : data2.userBanlistUpdate
      })
    });
    return () => unsubscribe();
  }, [roomId, subscribeToMore]);
  const [unban] = useMutation(UNBAN_USER);
  return /* @__PURE__ */ React.createElement(Table, { striped: true }, /* @__PURE__ */ React.createElement(Table.Body, null, members == null ? void 0 : members.map((member, i) => {
    var _a, _b, _c;
    return /* @__PURE__ */ React.createElement(Table.Row, { key: i }, /* @__PURE__ */ React.createElement(Table.Cell, null, /* @__PURE__ */ React.createElement(Image, { src: ((_a = member == null ? void 0 : member.user) == null ? void 0 : _a.avatarUrl) || "", size: "mini", circular: true })), /* @__PURE__ */ React.createElement(Table.Cell, null, (_b = member == null ? void 0 : member.user) == null ? void 0 : _b.displayName, " (", (_c = member == null ? void 0 : member.user) == null ? void 0 : _c.username, ")"), isAdmin && /* @__PURE__ */ React.createElement(Table.Cell, null, /* @__PURE__ */ React.createElement(Button, { inverted: true, color: "yellow", onClick: () => __async(void 0, null, function* () {
      var _a2, _b2;
      if (confirm && (yield confirm("Are you sure you want to unban " + ((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.displayName) + "?"))) {
        yield unban({
          variables: {
            roomId,
            userId: ((_b2 = member == null ? void 0 : member.user) == null ? void 0 : _b2.id) || ""
          }
        });
      }
    }) }, /* @__PURE__ */ React.createElement(Icon, { name: "remove user" }), "Unban")));
  })));
});
