"use strict";
import React from "react";
import App, { appClassName } from "./app";
import { createRoot } from "react-dom/client";
const appElement = document.createElement("div");
appElement.className = appClassName;
appElement.id = "app";
document.body.innerHTML = "";
document.body.appendChild(appElement);
document.body.style.backgroundColor = "var(--primary-dark-25)";
document.body.style.backgroundImage = "none";
const root = createRoot(appElement);
root.render(React.createElement(App, null));
