"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _gql = require("@/shared/hooks/gql");
var _useMediaPlayerActions = require("@/room/hooks/useMediaPlayerActions");
var _helpers = require("kosmi-sdk/helpers");
var _Premium = require("@/shared/components/Premium");
var _Tags = _interopRequireDefault(require("./Tags"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const RESULTS = (0, _gql.gql)(`
  query MediaContentSearch(
    $query: String!
    $categories: [MediaCategories]!
    $types: [MediaType]!
  ) {
    mediaContentSearchCategories(categories: $categories, query: $query)
    mediaContentSearch(query: $query, categories: $categories, types: $types) {
      id
      premium
      thumbnail
      title
      type
      url
      logo
    }
    __type(name: "MediaType") {
      enumValues {
        name
        description
      }
    }
  }
`);
const START_APP = (0, _gql.gql)(`
  mutation StartApp2($roomId: String!, $appName: RoomApp!) {
    startApp(roomId: $roomId, appName: $appName) {
      ok
    }
  }
`);
const OPEN_LINK_IN_WEB_BROWSER = (0, _gql.gql)(`
  mutation OpenLinkInWebBrowser(
    $roomId: String!
    $url: String!
    $kiosk: Boolean!
    $extension: String
  ) {
    openLinkInWebBrowser(
      roomId: $roomId
      url: $url
      kiosk: $kiosk
      extension: $extension
    ) {
      ok
    }
  }
`);
const OPEN_LINK_IN_WEBVIEW = (0, _gql.gql)(`
  mutation OpenLinkInWebview2($roomId: String!, $url: String!) {
    openLinkInWebview(roomId: $roomId, url: $url) {
      ok
    }
  }
`);
const useResults = (query, categories, types) => {
  const {
    data,
    loading
  } = (0, _gql.useQuery)(RESULTS, {
    variables: {
      query,
      categories,
      types
    },
    fetchPolicy: "cache-and-network"
  });
  const resultTypes = (data == null ? void 0 : data.mediaContentSearchCategories) ? [...data.mediaContentSearchCategories] : [];
  types.forEach(t => {
    if (!resultTypes.includes(t)) resultTypes.push(t);
  });
  const tags = data ? resultTypes.map(c => {
    var _a, _b;
    return ((_b = (_a = data.__type) == null ? void 0 : _a.enumValues) == null ? void 0 : _b.find(e => e.name === c)) || {};
  }).filter(c => !!c) : [];
  return {
    results: (data == null ? void 0 : data.mediaContentSearch) || [],
    tags,
    loading: loading && !(data == null ? void 0 : data.mediaContentSearch)
  };
};
const styles = {
  root: {
    "index__styles.root": "index__styles.root",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "xqtp20y",
    $$css: true
  },
  withBackground: thumbnail => [{
    "index__styles.withBackground": "index__styles.withBackground",
    backgroundImage: "x1g0zcm7",
    $$css: true
  }, {
    "--backgroundImage": `url(${thumbnail})` != null ? `url(${thumbnail})` : "initial"
  }],
  tagsWrapper: {
    "index__styles.tagsWrapper": "index__styles.tagsWrapper",
    padding: "x1lpg4po",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  contentBrowser: {
    "index__styles.contentBrowser": "index__styles.contentBrowser",
    overflowY: "x1rife3k",
    position: "x1n2onr6",
    display: "xrvj5dj",
    gridTemplateColumns: "x1a95gk1",
    justifyContent: "x1nhvcw1",
    gap: "x1c1vhfx",
    rowGap: null,
    columnGap: null,
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    gridAutoRows: "xjcim2o",
    margin: "x1k3vv4n",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    "@media(max-width: 999px)_justifyContent": "xz3v374",
    "@media(max-width: 999px)_gridTemplateColumns": "x16o5yjq",
    "@media(max-width: 999px)_gridAutoRows": "xwx2tg8",
    "@media(max-width: 999px)_padding": "xjmew7",
    "@media(max-width: 999px)_paddingInline": null,
    "@media(max-width: 999px)_paddingStart": null,
    "@media(max-width: 999px)_paddingLeft": null,
    "@media(max-width: 999px)_paddingEnd": null,
    "@media(max-width: 999px)_paddingRight": null,
    "@media(max-width: 999px)_paddingBlock": null,
    "@media(max-width: 999px)_paddingTop": null,
    "@media(max-width: 999px)_paddingBottom": null,
    $$css: true
  },
  loader: {
    "index__styles.loader": "index__styles.loader",
    display: "x78zum5",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  item: {
    "index__styles.item": "index__styles.item",
    position: "x1n2onr6",
    width: "x1oysuqx",
    height: "x18dl8mb",
    backgroundSize: "x18d0r48",
    backgroundRepeat: "xiy17q3",
    backgroundColor: "x128cn8a",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    ":hover_filter": "xd1q81k",
    ":hover_cursor": "x1277o0a",
    "@media(max-width: 999px)_position": "xo61h7p",
    "@media(max-width: 999px)_width": "x1wugq13",
    "@media(max-width: 999px)_height": "x1jzxfam",
    $$css: true
  },
  title: {
    "index__styles.title": "index__styles.title",
    padding: "xld7i00",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    position: "x10l6tqk",
    bottom: "x1ey2m1c",
    textAlign: "xdpxx8g",
    width: "x1oysuqx",
    maxWidth: "x193iq5w",
    fontWeight: "x1xlr1w8",
    fontSize: "xfifm61",
    height: "x1vqgdyp",
    backgroundColor: "x1xru9bx",
    lineHeight: "x1fc57z9",
    textShadow: "x14a9y5a",
    display: "x104kibb",
    WebkitLineClamp: "x1h7i4cw",
    WebkitBoxOrient: "x1ua5tub",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    minHeight: "x1rz828m",
    maxHeight: "x18wx58x",
    $$css: true
  },
  diamond: {
    "index__styles.diamond": "index__styles.diamond",
    position: "x10l6tqk",
    fontSize: "x1j61zf2",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  cover: {
    "index__styles.cover": "index__styles.cover",
    position: "x10l6tqk",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: "xiy17q3",
    width: "xh8yej3",
    height: "x5yr21d",
    inset: "x10a8y8t",
    insetInline: null,
    insetInlineStart: null,
    insetInlineEnd: null,
    left: null,
    right: null,
    insetBlock: null,
    top: null,
    bottom: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  blurredCoverNotSvg: {
    "index__styles.blurredCoverNotSvg": "index__styles.blurredCoverNotSvg",
    filter: "xiw55vl",
    backgroundSize: "x18d0r48",
    $$css: true
  },
  blurredCoverSvg: {
    "index__styles.blurredCoverSvg": "index__styles.blurredCoverSvg",
    backgroundColor: "x128cn8a",
    backgroundSize: "x18d0r48",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    filter: "xiw55vl",
    $$css: true
  },
  itemCover: {
    "index__styles.itemCover": "index__styles.itemCover",
    backgroundSize: "x1tbiz1a",
    backgroundPosition: "x1bz1qqp",
    backgroundPositionX: null,
    backgroundPositionY: null,
    $$css: true
  },
  itemCoverSvg: {
    "index__styles.itemCoverSvg": "index__styles.itemCoverSvg",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundSize: "x1tbiz1a",
    $$css: true
  }
};
const Item = (0, _helpers.withDialogs)(({
  id,
  title,
  thumbnail,
  logo,
  type,
  url,
  premium,
  setCategories,
  closeModal,
  setModalTitle,
  setMediaTypes,
  alert,
  openCustomScreenshare
}) => {
  const {
    setUrl
  } = (0, _useMediaPlayerActions.useMediaPlayerActions)();
  const [startApp, _loading] = (0, _gql.useMutation)(START_APP);
  const roomId = (0, _helpers.useRoomId)();
  const currentUser = (0, _helpers.currentUserHook)();
  const [openLinkInWebBrowser] = (0, _gql.useMutation)(OPEN_LINK_IN_WEB_BROWSER);
  const [openLinkInWebview] = (0, _gql.useMutation)(OPEN_LINK_IN_WEBVIEW);
  if (_Premium.hidePremium && premium && !currentUser.isSubscribed) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.item,
    title,
    onClick: () => __async(void 0, null, function* () {
      if (premium && !currentUser.isSubscribed && alert) {
        alert(/* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
          message: "Web browser is for premium users only!",
          source: "Web browser app"
        }), true);
        return;
      }
      if (type === "SCREENSHARE") {
        openCustomScreenshare({
          title,
          url,
          logo
        });
        setModalTitle(logo || title);
        return;
      }
      if (type === "CATALOG") {
        setModalTitle(logo || title);
        setCategories([id.toUpperCase()]);
        setMediaTypes([]);
        return;
      }
      if (type === "VIDEO") {
        setUrl(url);
      }
      if (type === "APP") {
        if (id === "web_browser") {
          openLinkInWebBrowser({
            variables: {
              roomId,
              url: "",
              kiosk: false
            }
          });
        } else {
          startApp({
            variables: {
              roomId,
              appName: id.toUpperCase()
            }
          });
        }
      }
      if (type === "VBROWSER_APP") {
        openLinkInWebBrowser({
          variables: {
            roomId,
            url,
            kiosk: true,
            extension: id === "tik_tok" ? "tiktok" : void 0
          }
        });
      }
      if (type === "SNES_GAME") {
        ;
        window.queuedGame = url;
        startApp({
          variables: {
            roomId,
            appName: RoomApp.SnesParty
          }
        });
      }
      if (type === "NES_GAME") {
        ;
        window.queuedGame = url;
        startApp({
          variables: {
            roomId,
            appName: RoomApp.NesParty
          }
        });
      }
      if (type === "PSX_GAME") {
        ;
        window.queuedGame = url;
        startApp({
          variables: {
            roomId,
            appName: RoomApp.Psx
          }
        });
      }
      if (type === "WEB_VIEW") {
        let webviewUrl = url;
        if (id === "whiteboard") {
          const response = yield fetch("https://cocreate.csail.mit.edu/api/roomNew");
          webviewUrl = (yield response.json()).url;
        } else if (id === "draw_battle") {
          const response = yield fetch("https://api.drawbattle.io/games", {
            method: "POST"
          });
          webviewUrl = "https://drawbattle.io/" + (yield response.json()).gameId;
        } else if (id === "shared_piano") {
          webviewUrl = `https://musiclab.chromeexperiments.com/Shared-Piano/#kosmi${roomId}`;
        }
        openLinkInWebview({
          variables: {
            roomId,
            url: webviewUrl
          }
        });
      }
      closeModal();
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.cover, thumbnail.endsWith(".svg") ? styles.blurredCoverSvg : styles.blurredCoverNotSvg]
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.cover, styles.itemCover, styles.withBackground(thumbnail), (thumbnail == null ? void 0 : thumbnail.endsWith(".svg")) && styles.itemCoverSvg]
  }), premium && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: styles.diamond
  }), (type === "VIDEO" || thumbnail.includes("cartridge")) && !thumbnail.endsWith(".svg") && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.title
  }, title));
});
var _default = ({
  categories,
  searchQuery,
  setCategories,
  closeModal,
  setModalTitle,
  openCustomScreenshare,
  setMediaTypes,
  mediaTypes,
  showTags
}) => {
  const {
    results,
    loading,
    tags
  } = useResults(searchQuery, categories, mediaTypes);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.tagsWrapper
  }, showTags && (tags.length > 1 || !!searchQuery) && /* @__PURE__ */_react.default.createElement(_Tags.default, {
    tags,
    selected: mediaTypes,
    onSelect: mediaType => setMediaTypes(mediaType ? [mediaType] : [])
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.contentBrowser
  }, loading ? /* @__PURE__ */_react.default.createElement(_uikit.Dimmer, {
    active: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    active: true,
    size: "massive",
    className: styles.loader
  })) : results.map((r, i) => /* @__PURE__ */_react.default.createElement(Item, {
    key: i,
    id: (r == null ? void 0 : r.id) || "",
    title: (r == null ? void 0 : r.title) || "",
    thumbnail: (r == null ? void 0 : r.thumbnail) || "",
    logo: (r == null ? void 0 : r.logo) || "",
    type: (r == null ? void 0 : r.type) || "",
    url: (r == null ? void 0 : r.url) || "",
    premium: !!(r == null ? void 0 : r.premium),
    setCategories,
    setModalTitle,
    setMediaTypes,
    closeModal,
    openCustomScreenshare
  }))));
};
exports.default = _default;