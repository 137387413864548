"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TopBarImage = exports.TopBarIcon = exports.TopBarButton = void 0;
var _react = _interopRequireWildcard(require("react"));
var _AccountDropdown = _interopRequireDefault(require("./AccountDropdown"));
var _KosmiDropdown = _interopRequireDefault(require("./KosmiDropdown"));
var _MessagesDropdown = _interopRequireDefault(require("./MessagesDropdown"));
var _NotificationDropdown = _interopRequireDefault(require("./NotificationDropdown"));
var _RoomsDropdown = _interopRequireDefault(require("./RoomsDropdown"));
var _DisplayNameModal = _interopRequireDefault(require("./DisplayNameModal"));
var _uikit = require("@/uikit");
var _reactRouterDom = require("react-router-dom");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _AppContext = _interopRequireDefault(require("@/app/AppContext"));
var _Premium = _interopRequireWildcard(require("@/shared/components/Premium"));
var _useCurrentUser = require("@/shared/hooks/useCurrentUser");
var _client = require("@/api/client");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function toggleFullscreen() {
  const elem = document.documentElement;
  if (!document.fullscreenElement && !elem.mozFullScreenElement && !elem.webkitFullscreenElement && !elem.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    return true;
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (elem == null ? void 0 : elem.msExitFullscreen) {
      elem == null ? void 0 : elem.msExitFullscreen();
    } else if (elem == null ? void 0 : elem.mozCancelFullScreen) {
      elem == null ? void 0 : elem.mozCancelFullScreen();
    } else if (elem == null ? void 0 : elem.webkitExitFullscreen) {
      elem == null ? void 0 : elem.webkitExitFullscreen();
    }
    return false;
  }
}
const styles = {
  topbarImage: {
    "index__styles.topbarImage": "index__styles.topbarImage",
    width: "xgd8bvy",
    height: "x1fgtraw",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  bottomBorder: {
    "index__styles.bottomBorder": "index__styles.bottomBorder",
    height: "xols6we",
    backgroundColor: "x1vaw2wd",
    width: "xh8yej3",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    bottom: "x1ey2m1c",
    transition: "x1jte46n",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  highlight: {
    "index__styles.highlight": "index__styles.highlight",
    backgroundColor: "x1hhg5nt",
    $$css: true
  },
  topBarButton: {
    "index__styles.topBarButton": "index__styles.topBarButton",
    width: "x1pju0fl",
    height: "x1vqgdyp",
    maxHeight: "x18wx58x",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    background: "x11g6tue",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    position: "x1n2onr6",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    color: "x11jfisy",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    ":hover_background": "xvmqop0",
    ":hover_backgroundAttachment": null,
    ":hover_backgroundClip": null,
    ":hover_backgroundColor": null,
    ":hover_backgroundImage": null,
    ":hover_backgroundOrigin": null,
    ":hover_backgroundPosition": null,
    ":hover_backgroundPositionX": null,
    ":hover_backgroundPositionY": null,
    ":hover_backgroundRepeat": null,
    ":hover_backgroundSize": null,
    ":hover_cursor": "x1277o0a",
    $$css: true
  },
  topbarIcon: {
    "index__styles.topbarIcon": "index__styles.topbarIcon",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "x1i1m3gp",
    $$css: true
  },
  leftSide: {
    "index__styles.leftSide": "index__styles.leftSide",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    zIndex: "x1vjfegm",
    paddingLeft: "x1uhho1l",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    transform: "x1s7amh2",
    $$css: true
  },
  header: {
    "index__styles.header": "index__styles.header",
    display: "x78zum5",
    padding: "x1ib1h6n",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    boxShadow: "x1gnnqk1",
    backgroundColor: "x128cn8a",
    textAlign: "xdpxx8g",
    height: "x14h49td",
    position: "x1n2onr6",
    zIndex: "x1n327nk",
    "@media(max-width: 1000px)_display": "x4m06ut",
    $$css: true
  },
  leftButtons: {
    "index__styles.leftButtons": "index__styles.leftButtons",
    position: "x10l6tqk",
    left: "x12lbrt0",
    insetInlineStart: null,
    insetInlineEnd: null,
    alignItems: "x6s0dn4",
    display: "x78zum5",
    $$css: true
  },
  rightSide: {
    "index__styles.rightSide": "index__styles.rightSide",
    width: "xdzyupr",
    zIndex: "x1vjfegm",
    justifyContent: "x13a6bvl",
    paddingRight: "xcicffo",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    transform: "x1s7amh2",
    $$css: true
  }
};
const TopBarButton = exports.TopBarButton = (0, _react.memo)(({
  ref,
  onClick,
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    ref,
    color: "white",
    inverted: true,
    className: styles.topBarButton,
    borderLess: true,
    onClick
  }, children);
});
const TopBarIcon = exports.TopBarIcon = (0, _react.memo)(({
  onClick,
  className,
  name
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: [styles.topbarIcon, className ? className : false],
    name,
    onClick
  });
});
const TopBarImage = exports.TopBarImage = (0, _react.memo)(({
  className,
  src,
  alt
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src,
    className: [styles.topbarImage, className ? className : false],
    alt
  });
});
const BottomBorder = () => {
  const {
    roomsDropdownisHovered
  } = (0, _react.useContext)(_AppContext.default);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.bottomBorder, roomsDropdownisHovered && styles.highlight]
  });
};
var _default = exports.default = (0, _react.memo)(() => {
  const currentUser = (0, _useCurrentUser.currentUserHook)();
  const [isFullscreen, setIsFullscreen] = (0, _react.useState)(!!document.fullscreenElement || false);
  const location = (0, _reactRouterDom.useLocation)();
  if (location.pathname.startsWith("/signup/")) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.header
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.leftSide
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.leftButtons
  }, /* @__PURE__ */_react.default.createElement(_KosmiDropdown.default, null), /* @__PURE__ */_react.default.createElement(_NotificationDropdown.default, null)), /* @__PURE__ */_react.default.createElement(_RoomsDropdown.default, null)), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.rightSide
  }, /* @__PURE__ */_react.default.createElement(_Premium.default, {
    source: "Header Button",
    trigger: !currentUser.isAnonymous && !currentUser.isSubscribed && !(0, _client.getRealm)() && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "red",
      style: {
        padding: 0,
        borderWidth: 0,
        width: 115,
        position: "absolute",
        right: "auto",
        left: 0,
        background: "linear-gradient(to top, #b6359c, #ef0a6a)"
      }
    }, /* @__PURE__ */_react.default.createElement(_Premium.Diamond, null), "Subscribe") || void 0
  }), !(0, _client.getRealm)() && !currentUser.isAnonymous && /* @__PURE__ */_react.default.createElement(_MessagesDropdown.default, null), /* @__PURE__ */_react.default.createElement(_AccountDropdown.default, null), (0, _client.getRealm)() === "comcast" && /* @__PURE__ */_react.default.createElement(_DisplayNameModal.default, null), /* @__PURE__ */_react.default.createElement(TopBarButton, {
    onClick: () => {
      const result = toggleFullscreen();
      setIsFullscreen(result);
    }
  }, /* @__PURE__ */_react.default.createElement(TopBarIcon, {
    className: styles.topbarIcon,
    name: isFullscreen ? "compress" : "expand"
  }))), /* @__PURE__ */_react.default.createElement(BottomBorder, null));
});