"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _MobileContext = _interopRequireDefault(require("./MobileContext"));
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _client = require("@apollo/client");
var _NotificationDropdown = require("../Desktop/Header/NotificationDropdown");
var _useCurrentUser = require("@/shared/hooks/useCurrentUser");
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const glowingIcon = "x2u31rq-B";
const styles = {
  bottomMenuButtonContent: {
    "BottomMenu__styles.bottomMenuButtonContent": "BottomMenu__styles.bottomMenuButtonContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "x1qughib",
    maxWidth: "x193iq5w",
    position: "x1n2onr6",
    flexDirection: "xdt5ytf",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    width: "x1dmp6jm",
    $$css: true
  },
  bottomMenuButtonIcon: {
    "BottomMenu__styles.bottomMenuButtonIcon": "BottomMenu__styles.bottomMenuButtonIcon",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    display: "x78zum5",
    fontSize: "xwsyq91",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  avatar: {
    "BottomMenu__styles.avatar": "BottomMenu__styles.avatar",
    width: "xw4jnvo",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  bottomMenuButtonLabel: {
    "BottomMenu__styles.bottomMenuButtonLabel": "BottomMenu__styles.bottomMenuButtonLabel",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xif65rj",
    whiteSpace: "xuxw1ft",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    $$css: true
  },
  bottomMenu: {
    "BottomMenu__styles.bottomMenu": "BottomMenu__styles.bottomMenu",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    textAlign: "x2b8uid",
    zIndex: "x1n327nk",
    boxShadow: "x1vik5cl",
    height: "xxz18i5",
    maxHeight: "xlrnmfh",
    transition: "xr83x4l",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    backgroundImage: "x1n9hcem",
    $$css: true
  },
  bottomMenuLandscape: {
    "BottomMenu__styles.bottomMenuLandscape": "BottomMenu__styles.bottomMenuLandscape",
    display: "x1s85apg",
    $$css: true
  },
  hideBottomMenu: {
    "BottomMenu__styles.hideBottomMenu": "BottomMenu__styles.hideBottomMenu",
    height: "xqtp20y",
    maxHeight: "xnjgh8c",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    transition: "xd8rsa0",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  menuButton: {
    "BottomMenu__styles.menuButton": "BottomMenu__styles.menuButton",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    fontSize: "x1k6wstc",
    cursor: "x1ypdohk",
    color: "x11jfisy",
    paddingBottom: "x1a8lsjc",
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    position: "x1n2onr6",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: "xmn4e3e",
    borderBottomWidth: null,
    ":active_transform": "x2cd4ws",
    $$css: true
  },
  activeMenuButton: {
    "BottomMenu__styles.activeMenuButton": "BottomMenu__styles.activeMenuButton",
    backgroundColor: "x6kzxpx",
    color: "x16cd2qt",
    borderTopColor: "xukwi11",
    ":active_transform": "x5igjdi",
    $$css: true
  },
  notificationNumber: {
    "BottomMenu__styles.notificationNumber": "BottomMenu__styles.notificationNumber",
    position: "x10l6tqk",
    left: "x1rvfpmx",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1jzctok",
    background: "x7v9ooy",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    fontWeight: "x1xlr1w8",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1k6wstc",
    display: "x1lliihq",
    lineHeight: "xo5v014",
    padding: "x14odnwx",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  glowingIcon: {
    "BottomMenu__styles.glowingIcon": "BottomMenu__styles.glowingIcon",
    color: "x16cd2qt",
    fontWeight: "xuv8nkb",
    animationName: "x19n8mo0",
    animationIterationCount: "xa4qsjk",
    animationDuration: "xmg6eyc",
    animationTimingFunction: "x4hg4is",
    animationFillMode: "x1u6ievf",
    animationDirection: "xpz12be",
    $$css: true
  }
};
const BottomMenuButton = (0, _react.memo)(({
  active,
  onClick,
  children,
  icon,
  avatarUrl,
  notifications
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.menuButton, active && styles.activeMenuButton],
    onClick
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.bottomMenuButtonContent
  }, !!notifications && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.notificationNumber
  }, notifications), !!icon && (typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: icon,
    className: [styles.bottomMenuButtonIcon, !!notifications && styles.glowingIcon]
  }) : icon), avatarUrl && /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: avatarUrl,
    circular: true,
    className: [styles.bottomMenuButtonIcon, styles.avatar]
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.bottomMenuButtonLabel
  }, children)));
});
const NOTIFICATION_QUERY = (0, _gql.gql)(`
  query NotificationQuery3 {
    userRooms {
      id
      unreadMessages
    }
    currentUser {
      id
      user {
        id
        username
        isAnonymous
        notifications {
          id
          seen
        }
      }
    }
    messageList {
      id
      unreadCount
    }
  }
`);
const useNotificationCounts = () => {
  var _a, _b, _c;
  const {
    data
  } = (0, _client.useQuery)(NOTIFICATION_QUERY);
  const notifications = (((_c = (_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.notifications) == null ? void 0 : _c.filter(n => !(n == null ? void 0 : n.seen))) || []).length;
  const roomMessages = ((data == null ? void 0 : data.userRooms) || []).reduce((acc, r) => acc + ((r == null ? void 0 : r.unreadMessages) ? 1 : 0), 0);
  const privateMessages = ((data == null ? void 0 : data.messageList) || []).reduce((acc, r) => acc + ((r == null ? void 0 : r.unreadCount) ? 1 : 0), 0);
  return {
    notifications,
    roomMessages,
    privateMessages
  };
};
var _default = exports.default = (0, _react.memo)(({
  hide
}) => {
  const {
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  const [markAsSeen] = (0, _gql.useMutation)(_NotificationDropdown.MARK_SEEN_MUTATION);
  const {
    overlayMenu,
    setOverlayMenu
  } = (0, _react.useContext)(_MobileContext.default);
  const currentUser = (0, _useCurrentUser.currentUserHook)();
  const menuRef = _react.default.useRef(null);
  (0, _react.useEffect)(() => {
    if (overlayMenu === "notifications") {
      markAsSeen();
    }
  }, [overlayMenu, markAsSeen]);
  (0, _react.useEffect)(() => {
    const menu = menuRef.current;
    if (!menu) return;
    menu.ontransitionend = () => {
      window.dispatchEvent(new Event("resize"));
    };
  }, []);
  const {
    notifications,
    roomMessages,
    privateMessages
  } = useNotificationCounts();
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    ref: menuRef,
    style: [styles.bottomMenu, isLandscape && styles.bottomMenuLandscape, hide && styles.hideBottomMenu]
  }, /* @__PURE__ */_react.default.createElement(BottomMenuButton, {
    onClick: () => setOverlayMenu("my_rooms"),
    active: overlayMenu === "my_rooms",
    icon: "list",
    notifications: roomMessages
  }, "Rooms"), /* @__PURE__ */_react.default.createElement(BottomMenuButton, {
    onClick: () => setOverlayMenu("chats"),
    active: overlayMenu === "chats",
    icon: "comment",
    notifications: privateMessages
  }, "Chats"), /* @__PURE__ */_react.default.createElement(BottomMenuButton, {
    onClick: () => {
      setOverlayMenu("notifications");
    },
    active: overlayMenu === "notifications",
    notifications,
    icon: "bell"
  }, "Notifications"), /* @__PURE__ */_react.default.createElement(BottomMenuButton, {
    onClick: () => setOverlayMenu("you"),
    active: overlayMenu === "you",
    avatarUrl: currentUser == null ? void 0 : currentUser.avatarUrl
  }, "You"));
});