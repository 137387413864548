"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _kosmihead_tinted = _interopRequireDefault(require("@/room/components/Sidebar/RoomBanner/kosmihead_tinted.svg"));
var _gql = require("@/shared/hooks/gql");
var _reactRouterDom = require("react-router-dom");
var _withDialogs = require("@/shared/hooks/withDialogs");
var _LoginModal = require("@/shared/components/LoginModal");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const GET_ROOM_ID = (0, _gql.gql)(`
  mutation GetRoomId($url: String!)  {
    getRoomId(url: $url) {
      roomId
    }
  }
`);
const styles = {
  welcomeContainer: {
    "LoginScreen__styles.welcomeContainer": "LoginScreen__styles.welcomeContainer",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    height: "xlxyqfn",
    width: "xh8yej3",
    $$css: true
  },
  logo: {
    "LoginScreen__styles.logo": "LoginScreen__styles.logo",
    width: "x1exxlbk",
    height: "xpyat2d",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  welcomeHeading: {
    "LoginScreen__styles.welcomeHeading": "LoginScreen__styles.welcomeHeading",
    fontSize: "x1pvqxga",
    fontWeight: "x1xlr1w8",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  welcomeSubHeading: {
    "LoginScreen__styles.welcomeSubHeading": "LoginScreen__styles.welcomeSubHeading",
    fontSize: "x1j61zf2",
    marginTop: "x1anpbxc",
    $$css: true
  },
  buttonCircle: {
    "LoginScreen__styles.buttonCircle": "LoginScreen__styles.buttonCircle",
    position: "x10l6tqk",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    aspectRatio: "x1plog1",
    backgroundImage: "x19jfv7d",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    width: "xw90zxv",
    left: "x1ifykwl",
    opacity: "xuzhngd",
    $$css: true
  },
  buttonWrapper: {
    "LoginScreen__styles.buttonWrapper": "LoginScreen__styles.buttonWrapper",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    height: "xk4dd5j",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    justifyContent: "xl56j7k",
    $$css: true
  },
  buttons: {
    "LoginScreen__styles.buttons": "LoginScreen__styles.buttons",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xktia5q",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x1ymw6g",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  subHeading: {
    "LoginScreen__styles.subHeading": "LoginScreen__styles.subHeading",
    fontSize: "x1j61zf2",
    fontWeight: "x1xlr1w8",
    $$css: true
  },
  screen: {
    "LoginScreen__styles.screen": "LoginScreen__styles.screen",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    flexDirection: "xdt5ytf",
    $$css: true
  },
  joinRoomWrapper: {
    "LoginScreen__styles.joinRoomWrapper": "LoginScreen__styles.joinRoomWrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    height: "x5yr21d",
    maxHeight: "x1rq5m6k",
    maxWidth: "x65f84u",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    justifyContent: "x1qughib",
    $$css: true
  },
  bright: {
    "LoginScreen__styles.bright": "LoginScreen__styles.bright",
    backgroundImage: "x1pzbnb5",
    $$css: true
  },
  inputWrapper: {
    "LoginScreen__styles.inputWrapper": "LoginScreen__styles.inputWrapper",
    display: "x78zum5",
    width: "xh8yej3",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  text: {
    "LoginScreen__styles.text": "LoginScreen__styles.text",
    opacity: "x1ks1olk",
    $$css: true
  }
};
const WelcomeGreeting = () => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.welcomeContainer
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.logo,
    src: _kosmihead_tinted.default,
    alt: "Kosmi Logo"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.welcomeHeading
  }, "Welcome to Kosmi"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.welcomeSubHeading
  }, "The entertainment hub for you and your crew."));
};
const JoinRoomScreen = (0, _withDialogs.withDialogs)(({
  alert
}) => {
  const [url, setUrl] = (0, _react.useState)("");
  const [getRoomId] = (0, _gql.useMutation)(GET_ROOM_ID);
  const inputRef = (0, _react.useRef)(null);
  const history = (0, _reactRouterDom.useHistory)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.screen, styles.bright]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.joinRoomWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subHeading
  }, "Enter a room link below to join an existing room"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inputWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    large: true,
    label: "Room link",
    placeholder: "https://kosmi.to/abcd",
    value: url,
    onChange: e => {
      setUrl(e.target.value);
    },
    fluid: true,
    required: true,
    ref: inputRef
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.text
  }, "A room link should look like kosmi.to/abcd or app.kosmi.io/room/@abcdefg"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.text
  }, "What are rooms? Rooms are the spaces on Kosmi where friends and communities can watch shows or movies and enjoy different activities together. Learn more at kosmi.io."), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    fluid: true,
    color: "white",
    style: {
      height: 52
    },
    onClick: () => __async(void 0, null, function* () {
      var _a, _b;
      if (!url) {
        (_a = inputRef.current) == null ? void 0 : _a.focus();
        return;
      }
      const {
        data
      } = yield getRoomId({
        variables: {
          url
        }
      });
      const roomId = (_b = data == null ? void 0 : data.getRoomId) == null ? void 0 : _b.roomId;
      if (roomId) {
        history.replace(`/room/${roomId}`);
      } else {
        alert && alert("Room not found");
      }
    })
  }, "Join with room link")));
});
const SignupScreen = ({
  login
}) => {
  const settingsLevel = _uikit.Settings.useSettingsLevel();
  const {
    topItem
  } = _uikit.Settings.useSettingsRouter();
  const atTopLevel = (topItem == null ? void 0 : topItem.level) === settingsLevel;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.screen, styles.bright]
  }, atTopLevel && /* @__PURE__ */_react.default.createElement(WelcomeGreeting, null), /* @__PURE__ */_react.default.createElement(_LoginModal.LoginModalInner, {
    signup: !login
  }));
};
var _default = () => {
  const joinRoomButtonRef = (0, _react.useRef)(null);
  const signUpButtonRef = (0, _react.useRef)(null);
  const loginButtonRef = (0, _react.useRef)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Welcome"
  }, /* @__PURE__ */_react.default.createElement(WelcomeGreeting, null), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttonWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttonCircle
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttons
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    fluid: true,
    ref: joinRoomButtonRef
  }, "Join a Room"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "var(--primary-dark-10)",
    fluid: true,
    ref: signUpButtonRef
  }, "Sign up free"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "var(--primary-dark-10)",
    fluid: true,
    ref: loginButtonRef
  }, "Log in"))), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Join room",
    trigger: joinRoomButtonRef
  }, /* @__PURE__ */_react.default.createElement(JoinRoomScreen, null)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Sign up",
    trigger: signUpButtonRef
  }, /* @__PURE__ */_react.default.createElement(SignupScreen, null)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Log in",
    trigger: loginButtonRef
  }, /* @__PURE__ */_react.default.createElement(SignupScreen, {
    login: true
  })));
};
exports.default = _default;