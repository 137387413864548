"use strict";
import React, { memo } from "react";
import { currentUserHook, withDialogs } from "kosmi-sdk/helpers";
export default withDialogs(memo(({
  ref,
  children,
  alert,
  onClick
}) => {
  var _a;
  const currentUser = currentUserHook();
  const emailConfirmed = currentUser == null ? void 0 : currentUser.emailConfirmed;
  const openModal = () => alert && alert("Please confirm your email");
  if (emailConfirmed) {
    return typeof children === "function" ? children(() => {
    }) : React.cloneElement(children, {
      ref,
      onClick: onClick || ((_a = children.props) == null ? void 0 : _a.onClick)
    });
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, typeof children === "function" ? children(openModal) : React.cloneElement(children, {
    onClick: openModal
  }));
}));
