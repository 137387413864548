"use strict";
import { useQuery } from "@/shared/hooks/gql";
import { INIT_QUERY } from "@/api/client";
const useRealmInfo = () => {
  const {
    data
  } = useQuery(INIT_QUERY, {
    fetchPolicy: "cache-first"
  });
  return data == null ? void 0 : data.realmInfo;
};
export default useRealmInfo;
