"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { memo, useCallback } from "react";
import VirtualBrowser from "@/room/components/VirtualBrowser";
export default memo(
  ({
    sessionId,
    onSupportedUrl,
    onBannedUrl
  }) => {
    const onTabUpdated = useCallback(
      (_a, _b, tab) => __async(void 0, null, function* () {
        const url = tab == null ? void 0 : tab.url;
        if ((url == null ? void 0 : url.startsWith("https://discord.com")) || (url == null ? void 0 : url.startsWith("https://instagram.com")) || (url == null ? void 0 : url.startsWith("https://www.discord.com")) || (url == null ? void 0 : url.startsWith("https://www.instagram.com")) || (url == null ? void 0 : url.startsWith("https://now.gg")) || (url == null ? void 0 : url.startsWith("https://www.now.gg"))) {
          if (onBannedUrl) {
            onBannedUrl(url);
            return;
          }
        }
        if (url && url.startsWith("https://videorelay.kosmi.io/")) {
          const parsedUrl = new URL(url);
          const hlsUrl = parsedUrl.origin + parsedUrl.pathname.replace("/play/", "/") + ".m3u8";
          const originalUrl = parsedUrl.searchParams.get("url") || "";
          if (onSupportedUrl) {
            onSupportedUrl(
              hlsUrl + (originalUrl ? "?url=" + encodeURIComponent(originalUrl) : "")
            );
          }
        } else if (url && url.startsWith("https://www.youtube.com/watch?") && onSupportedUrl) {
          onSupportedUrl(url);
        }
      }),
      [onSupportedUrl, onBannedUrl]
    );
    return /* @__PURE__ */ React.createElement(VirtualBrowser, { sessionId, onTabUpdated });
  }
);
