"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react = _interopRequireWildcard(require("react"));
var _ContentBrowser = _interopRequireDefault(require("./ContentBrowser"));
var _VideoChoiceMenu = _interopRequireDefault(require("./VideoChoiceMenu"));
var _CategoryMenu = _interopRequireDefault(require("./CategoryMenu"));
var _gql = require("@/shared/hooks/gql");
var _BackButton = _interopRequireDefault(require("../BackButton"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const HEADINGS = (0, _gql.gql)(`
  query MediaContentHeadings {
    mediaContentHeadings {
      name
      categories
      mediaTypes
    }
    __type(name: "MediaType") {
      enumValues {
        name
        description
      }
    }
  }
`);
const styles = {
  root: {
    "index__styles.root": "index__styles.root",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    maxHeight: "xmz0i5r",
    "@media(max-width: 999px)_flexDirection": "x14oqfsp",
    $$css: true
  },
  navigation: {
    "index__styles.navigation": "index__styles.navigation",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xop8lp1",
    "@media(max-width: 999px)_width": "x1s2u183",
    "@media(max-width: 999px)_margin": "xw493is",
    "@media(max-width: 999px)_marginInline": null,
    "@media(max-width: 999px)_marginInlineStart": null,
    "@media(max-width: 999px)_marginLeft": null,
    "@media(max-width: 999px)_marginInlineEnd": null,
    "@media(max-width: 999px)_marginRight": null,
    "@media(max-width: 999px)_marginBlock": null,
    "@media(max-width: 999px)_marginTop": null,
    "@media(max-width: 999px)_marginBottom": null,
    $$css: true
  },
  content: {
    "index__styles.content": "index__styles.content",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    "@media(max-width: 999px)_height": "x2toeuh",
    $$css: true
  },
  search: {
    "index__styles.search": "index__styles.search",
    width: "xh8yej3",
    maxWidth: "x1j9u4d2",
    display: "x78zum5",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    "@media(max-width: 999px)_margin": "xw493is",
    "@media(max-width: 999px)_marginInline": null,
    "@media(max-width: 999px)_marginInlineStart": null,
    "@media(max-width: 999px)_marginLeft": null,
    "@media(max-width: 999px)_marginInlineEnd": null,
    "@media(max-width: 999px)_marginRight": null,
    "@media(max-width: 999px)_marginBlock": null,
    "@media(max-width: 999px)_marginTop": null,
    "@media(max-width: 999px)_marginBottom": null,
    "@media(max-width: 999px)_maxWidth": "x1gfz9gl",
    $$css: true
  },
  searchInput: {
    "index__styles.searchInput": "index__styles.searchInput",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }
};
const useHeadings = () => {
  var _a, _b;
  const {
    data
  } = (0, _gql.useQuery)(HEADINGS, {
    fetchPolicy: "cache-first"
  });
  if (!((_a = data == null ? void 0 : data.mediaContentHeadings) == null ? void 0 : _a.length)) return [];
  const mediaTypes = (_b = data.__type) == null ? void 0 : _b.enumValues;
  return data.mediaContentHeadings.map(r => {
    var _a2;
    return __spreadProps(__spreadValues({}, r), {
      mediaTypes: (_a2 = r == null ? void 0 : r.mediaTypes) == null ? void 0 : _a2.map(t => mediaTypes == null ? void 0 : mediaTypes.find(e => e.name === t))
    });
  });
};
const SearchForm = (0, _react.memo)(({
  value,
  setValue
}) => {
  const onChange = (0, _react.useCallback)(e => setValue(e.target.value), [setValue]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.search
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    wrapperClassName: styles.searchInput,
    debounce: 300,
    debounceMinLength: 2,
    placeholder: "Search apps and media content",
    value,
    onChange,
    icon: "search"
  }));
});
var _default = exports.default = (0, _react.memo)(({
  setScreen,
  screen,
  closeModal,
  setModalTitle,
  openCustomScreenshare
}) => {
  const headings = useHeadings();
  const [searchQuery, setSearchQuery] = (0, _react.useState)("");
  const [categories, setCategories] = (0, _react.useState)([]);
  const [_mediaTypes, setMediaTypes] = (0, _react.useState)([]);
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  let mediaTypes = _mediaTypes;
  if (isMobile) {
    mediaTypes = !searchQuery && !categories.length ? ["CATALOG"] : ["VIDEO"];
  }
  const showNav = headings.some(h => {
    return h.categories && JSON.stringify(h.categories) === JSON.stringify(categories);
  });
  const setCategoriesFromMenu = (0, _react.useCallback)(categories2 => {
    setSearchQuery("");
    setMediaTypes([]);
    setCategories(categories2);
  }, []);
  const goBack = (0, _react.useCallback)(() => {
    setSearchQuery("");
    setCategories([]);
    setMediaTypes([]);
    setModalTitle("Load Media");
  }, [setModalTitle]);
  if (screen !== "pick") return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, showNav ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.navigation
  }, /* @__PURE__ */_react.default.createElement(_VideoChoiceMenu.default, {
    onChange: setScreen
  }), !isMobile && /* @__PURE__ */_react.default.createElement(_CategoryMenu.default, {
    categories,
    setCategories: setCategoriesFromMenu
  })) : /* @__PURE__ */_react.default.createElement(_BackButton.default, {
    onClick: goBack
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.content
  }, /* @__PURE__ */_react.default.createElement(SearchForm, {
    value: searchQuery,
    setValue: setSearchQuery
  }), /* @__PURE__ */_react.default.createElement(_ContentBrowser.default, {
    closeModal,
    setCategories,
    categories,
    searchQuery,
    setModalTitle,
    openCustomScreenshare,
    setMediaTypes,
    mediaTypes,
    showTags: showNav
  })));
});