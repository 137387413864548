"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { gql, useMutation } from "@/shared/hooks/gql";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useCallback } from "react";
const SET_PLAYBACK_RATE = gql(`
  mutation MediaPlayerSetPlaybackRate($roomId: String!, $playbackRate: Float!) {
    mediaPlayerSetPlaybackRate(roomId: $roomId, playbackRate: $playbackRate) {
      ok
    }
  }
`);
const STOP_VIDEO = gql(`
  mutation MediaPlayerStopVideo($roomId: String!) {
    mediaPlayerStopVideo(roomId: $roomId) {
      ok
    }
  }
`);
const SET_URL = gql(`
  mutation SetMediaPlayerUrlMutation(
    $roomId: String!
    $url: String!
    $type: String
  ) {
    mediaPlayerSetUrl(roomId: $roomId, url: $url, type: $type) {
      ok
    }
  }
`);
const SET_BROWSER_URL = gql(`
  mutation SetMediaPlayerBrowserUrlMutation($roomId: String!, $url: String!) {
    mediaPlayerSetVbrowserUrl(roomId: $roomId, url: $url) {
      ok
    }
  }
`);
const SET_PAUSED = gql(`
  mutation MediaPlayerSetPaused($roomId: String!, $paused: Boolean!) {
    mediaPlayerSetPaused(roomId: $roomId, paused: $paused) {
      ok
    }
  }
`);
const SET_SUBTITLES = gql(`
  mutation mediaPlayerSetSubtitles(
    $roomId: String!
    $filename: String!
    $cues: [SubtitleCue]!
  ) {
    mediaPlayerSetSubtitles(roomId: $roomId, filename: $filename, cues: $cues) {
      ok
    }
  }
`);
const REMOVE_SUBTITLES = gql(`
  mutation MediaPlayerRemoveSubtitles($roomId: String!) {
    mediaPlayerRemoveSubtitles(roomId: $roomId) {
      ok
    }
  }
`);
const SET_POSITION = gql(`
  mutation MediaPlayerSetPosition3(
    $roomId: String!
    $position: Float!
    $unixTimePositionSent: Int!
  ) {
    mediaPlayerSetPosition(
      roomId: $roomId
      position: $position
      unixTimePositionSent: $unixTimePositionSent
    ) {
      ok
    }
  }
`);
export const useMediaPlayerActions = () => {
  const roomId = useRoomId();
  const [_setPosition] = useMutation(SET_POSITION);
  const [_setPaused] = useMutation(SET_PAUSED);
  const [_setSubtitles] = useMutation(SET_SUBTITLES);
  const [_removeSubtitles] = useMutation(REMOVE_SUBTITLES);
  const [_setBrowserUrl] = useMutation(SET_BROWSER_URL);
  const [_setUrl] = useMutation(SET_URL);
  const [_setPlaybackRate] = useMutation(SET_PLAYBACK_RATE);
  const [_stopVideo] = useMutation(STOP_VIDEO);
  const simplifyMutation = useCallback(
    (func, variables) => func({ variables: __spreadValues({ roomId }, variables || {}) }),
    [roomId]
  );
  const setPosition = useCallback(
    (position) => {
      if (position || position === 0) {
        simplifyMutation(_setPosition, {
          position,
          unixTimePositionSent: window.getServerTime()
        });
      }
    },
    [_setPosition, simplifyMutation]
  );
  const setPaused = useCallback(
    (paused) => simplifyMutation(_setPaused, { paused }),
    [_setPaused, simplifyMutation]
  );
  const setSubtitles = useCallback(
    (filename, cues) => simplifyMutation(_setSubtitles, {
      filename,
      cues
    }),
    [_setSubtitles, simplifyMutation]
  );
  const removeSubtitles = useCallback(
    () => simplifyMutation(_removeSubtitles),
    [_removeSubtitles, simplifyMutation]
  );
  const setBrowserUrl = useCallback(
    (url) => simplifyMutation(_setBrowserUrl, { url }),
    [_setBrowserUrl, simplifyMutation]
  );
  const setUrl = useCallback(
    (url, type) => simplifyMutation(_setUrl, { url, type }),
    [_setUrl, simplifyMutation]
  );
  const setPlaybackRate = useCallback(
    (playbackRate) => simplifyMutation(_setPlaybackRate, {
      playbackRate
    }),
    [_setPlaybackRate, simplifyMutation]
  );
  const stopVideo = useCallback(() => {
    simplifyMutation(_stopVideo);
  }, [_stopVideo, simplifyMutation]);
  return {
    setPosition,
    setPaused,
    setSubtitles,
    removeSubtitles,
    setUrl,
    setBrowserUrl,
    setPlaybackRate,
    stopVideo
  };
};
