"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _kosmihead = _interopRequireDefault(require("./kosmihead.svg"));
var _kosmiioText = _interopRequireDefault(require("./kosmiioText.svg"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  roomTab: {
    "index__styles.roomTab": "index__styles.roomTab",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    background: "x1jvn0tm",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    color: "xphf66n",
    textAlign: "x2b8uid",
    fontWeight: "x1s688f",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    display: "x1lliihq",
    $$css: true
  },
  splashOverlay: {
    "index__styles.splashOverlay": "index__styles.splashOverlay",
    position: "xixxii4",
    inset: "x10a8y8t",
    insetInline: null,
    insetInlineStart: null,
    insetInlineEnd: null,
    left: null,
    right: null,
    insetBlock: null,
    top: null,
    bottom: null,
    background: "xayu447",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    backdropFilter: "xktjril",
    zIndex: "x1355qak",
    transition: "x2nfu8k",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    pointerEvents: "x47corl",
    $$css: true
  },
  faded: {
    "index__styles.faded": "index__styles.faded",
    opacity: "xg01cxk",
    $$css: true
  }
};
const RoomBanner = ({
  showRoomUrl
}) => {
  const roomId = (0, _helpers.useRoomId)();
  const [faded, setFaded] = (0, _react.useState)(false);
  const splashTimeoutRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    if (splashTimeoutRef.current) clearTimeout(splashTimeoutRef.current);
    splashTimeoutRef.current = setTimeout(() => setFaded(true), 2e3);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.splashOverlay, faded && styles.faded]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: _kosmihead.default,
    width: 300
  }), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: _kosmiioText.default,
    width: 300
  })), showRoomUrl && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomTab
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "kosmi.app/", roomId)));
};
var _default = exports.default = RoomBanner;