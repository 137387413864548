"use strict";
import { useEffect } from "react";
import useSettings from "@/shared/hooks/useSettings";
import { updateColors } from "@/layout/colors";
import useColorScheme from "@/shared/hooks/useColorScheme";
import useRealmInfo from "@/shared/hooks/useRealmInfo";
export default (skip) => {
  const settings = useSettings();
  const realmInfo = useRealmInfo();
  const colorSchemeId = (settings == null ? void 0 : settings.colorScheme) || (realmInfo == null ? void 0 : realmInfo.colorScheme);
  const colorScheme = useColorScheme(colorSchemeId || null);
  useEffect(() => {
    if (!colorSchemeId)
      return;
    if (skip)
      return;
    const {
      primaryColor,
      secondaryColor,
      foregroundColor
    } = colorScheme || {};
    if (primaryColor && secondaryColor && foregroundColor) {
      updateColors(true, primaryColor, secondaryColor, foregroundColor);
    }
  }, [skip, colorScheme, colorSchemeId]);
};
