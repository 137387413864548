"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _gql = require("@/shared/hooks/gql");
var _useTracking = require("@/shared/hooks/useTracking");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useRoomId = _interopRequireWildcard(require("@/room/hooks/useRoomId"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  userSelectionForm: {
    "FriendSelection__styles.userSelectionForm": "FriendSelection__styles.userSelectionForm",
    marginBottom: "xieb3on",
    width: "xxsgkw5",
    maxWidth: "x193iq5w",
    display: "x78zum5",
    $$css: true
  }
};
const INVITE_TO_ROOM_MUTATION = (0, _gql.gql)(`
  mutation InviteToRoomMutation1($userId: String!, $roomId: String!) {
    inviteToRoom(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const DropdownExampleMultipleSearchSelection = () => {
  ;
  ({
    /*<Dropdown
    placeholder="Select friends to invite"
    noResultsMessage="No more friends to add"
    fluid
    multiple
    search
    selection
    options={options}
    onChange={onChange}
    className={styles.friendSelector}
    />*/
  });
  return null;
};
const FriendsSelection = ({
  onInvited
}) => {
  const [friendSelection, _setFriendSelection] = (0, _react.useState)([]);
  const [invite] = (0, _gql.useMutation)(INVITE_TO_ROOM_MUTATION);
  const roomId = (0, _useRoomId.useRoomId)();
  const track = (0, _useTracking.useTracking)();
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.userSelectionForm
  }, /* @__PURE__ */_react.default.createElement(DropdownExampleMultipleSearchSelection, null), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    style: {
      marginLeft: 10
    },
    disabled: friendSelection.length === 0,
    onClick: () => __async(void 0, null, function* () {
      friendSelection.forEach(f => {
        invite({
          variables: {
            userId: f.user.id,
            roomId
          }
        });
      });
      track("UI", "InviteFriendsShareMenu", `Shared room with ${friendSelection.length} friends`);
      onInvited();
    })
  }, "Invite")));
};
var _default = exports.default = (0, _useTracking.withTracking)((0, _useRoomId.default)(FriendsSelection));