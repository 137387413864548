"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useMutation, useQuery, useSubscription } from "@/shared/hooks/gql";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useIceservers } from "@/room/hooks/webrtc/p2p/useIceServers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WebRTCStreamHost } from "./webrtcServer";
function isNotNull(value) {
  return value !== null && value !== void 0;
}
const ON_RECEIVE_ANSWER = gql(`
  subscription WebRTCOnReceiveAnswer2(
    $roomId: String!
    $rtcChannelId: String!
  ) {
    webrtcReceiveAnswer(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      userId
      remoteDescription
    }
  }
`);
const ON_RECEIVE_CANDIDATE = gql(`
  subscription WebRTCOnReceiveCandidate3(
    $roomId: String!
    $rtcChannelId: String!
  ) {
    webrtcReceiveCandidate(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      userId
      candidate
    }
  }
`);
const SEND_CANDIDATE = gql(`
  mutation WebRTCSendCandidate2(
    $roomId: String!
    $rtcChannelId: String!
    $userId: String!
    $candidate: String!
  ) {
    webrtcSendCandidate(
      roomId: $roomId
      rtcChannelId: $rtcChannelId
      userId: $userId
      candidate: $candidate
    ) {
      ok
    }
  }
`);
const SEND_OFFER = gql(`
  mutation WebRTCSendOffer(
    $roomId: String!
    $rtcChannelId: String!
    $userId: String!
    $localDescription: String!
  ) {
    webrtcSendOffer(
      roomId: $roomId
      rtcChannelId: $rtcChannelId
      userId: $userId
      localDescription: $localDescription
    ) {
      ok
    }
  }
`);
const MEMBERS = gql(`
  query RTCMembersQuery2($roomId: String!, $rtcChannelId: String!) {
    webrtcMembers(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      id
    }
    currentUser {
      id
      user {
        id
      }
    }
  }
`);
const ON_WEBRTC_JOIN = gql(`
  subscription OnWebRTCJoin2($roomId: String!, $rtcChannelId: String!) {
    webrtcJoinChannel(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      id
    }
  }
`);
const ON_WEBRTC_LEAVE = gql(`
  subscription OnWebRTCLeave2($roomId: String!, $rtcChannelId: String!) {
    webrtcLeaveChannel(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      id
    }
  }
`);
const useRtcMembers = (roomId, rtcChannelId) => {
  const {
    loading,
    data,
    subscribeToMore
  } = useQuery(MEMBERS, {
    skip: !roomId,
    variables: {
      roomId,
      rtcChannelId
    }
  });
  useEffect(() => {
    if (loading)
      return;
    const unsubscribeToJoins = subscribeToMore({
      document: ON_WEBRTC_JOIN,
      variables: {
        roomId,
        rtcChannelId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data: data2
        }
      }) => {
        var _a;
        const {
          webrtcJoinChannel
        } = data2;
        let newmembers = ((_a = prev == null ? void 0 : prev.webrtcMembers) == null ? void 0 : _a.slice()) || [];
        if (webrtcJoinChannel) {
          newmembers = newmembers.filter((m) => (m == null ? void 0 : m.id) !== webrtcJoinChannel.id);
          newmembers == null ? void 0 : newmembers.push({
            id: webrtcJoinChannel.id,
            __typename: "User"
          });
        }
        return __spreadProps(__spreadValues({}, prev), {
          webrtcMembers: newmembers || []
        });
      }
    });
    const unsubscribeToLeaves = subscribeToMore({
      document: ON_WEBRTC_LEAVE,
      variables: {
        roomId,
        rtcChannelId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data: data2
        }
      }) => {
        var _a;
        const {
          webrtcLeaveChannel
        } = data2;
        let newmembers = ((_a = prev == null ? void 0 : prev.webrtcMembers) == null ? void 0 : _a.slice()) || [];
        if (webrtcLeaveChannel) {
          newmembers = newmembers == null ? void 0 : newmembers.filter((m) => (m == null ? void 0 : m.id) !== webrtcLeaveChannel.id);
        }
        return __spreadProps(__spreadValues({}, prev), {
          webrtcMembers: newmembers
        });
      }
    });
    return () => {
      unsubscribeToJoins();
      unsubscribeToLeaves();
    };
  }, [loading, roomId, rtcChannelId, subscribeToMore]);
  return data;
};
export const useWebRTCProducer = (rtcChannelId) => {
  var _a, _b, _c;
  const [server, setServer] = useState(null);
  const roomId = useRoomId();
  const iceServers = useIceservers();
  const rtcMembers = useRtcMembers(roomId, rtcChannelId);
  const userIds = (_c = ((_b = (_a = rtcMembers == null ? void 0 : rtcMembers.webrtcMembers) == null ? void 0 : _a.filter((u) => {
    var _a2, _b2;
    return (u == null ? void 0 : u.id) !== ((_b2 = (_a2 = rtcMembers == null ? void 0 : rtcMembers.currentUser) == null ? void 0 : _a2.user) == null ? void 0 : _b2.id);
  })) == null ? void 0 : _b.map((u) => u == null ? void 0 : u.id)) || []) == null ? void 0 : _c.filter(isNotNull);
  const [_sendOffer] = useMutation(SEND_OFFER);
  const [_sendCandidate] = useMutation(SEND_CANDIDATE);
  const {
    data: answerData
  } = useSubscription(ON_RECEIVE_ANSWER, {
    variables: {
      roomId,
      rtcChannelId
    },
    skip: !roomId
  });
  const {
    data: candidateData
  } = useSubscription(ON_RECEIVE_CANDIDATE, {
    variables: {
      roomId,
      rtcChannelId
    },
    skip: !roomId
  });
  const sendOffer = useCallback((userId, localDescription) => {
    const localDescriptionString = JSON.stringify(localDescription);
    _sendOffer({
      variables: {
        roomId,
        rtcChannelId,
        userId,
        localDescription: localDescriptionString
      }
    });
  }, [_sendOffer, roomId, rtcChannelId]);
  const sendCandidate = useCallback((userId, candidate) => _sendCandidate({
    variables: {
      roomId,
      rtcChannelId,
      userId,
      candidate: JSON.stringify(candidate)
    }
  }), [_sendCandidate, roomId, rtcChannelId]);
  const _newestCandidate = candidateData == null ? void 0 : candidateData.webrtcReceiveCandidate;
  const newestCandidate = useMemo(() => _newestCandidate ? __spreadProps(__spreadValues({}, _newestCandidate), {
    candidate: _newestCandidate.candidate ? JSON.parse(_newestCandidate.candidate) : null
  }) : null, [_newestCandidate]);
  const newestAnswer = answerData == null ? void 0 : answerData.webrtcReceiveAnswer;
  useEffect(() => {
    if (!server && iceServers) {
      const srv = new WebRTCStreamHost(rtcChannelId, sendOffer, sendCandidate, iceServers);
      if (userIds) {
        srv.setUserIds(userIds);
      }
      setServer(srv);
    }
  }, [iceServers, rtcChannelId, sendCandidate, sendOffer, userIds, server]);
  const userIdsString = JSON.stringify(userIds);
  useEffect(() => {
    const userIds2 = JSON.parse(userIdsString);
    if (server && userIds2 !== null) {
      server.setUserIds(userIds2);
    }
  }, [userIdsString, server]);
  useEffect(() => {
    if (server && newestAnswer) {
      const {
        remoteDescription: remoteDescriptionString,
        userId
      } = newestAnswer;
      const remoteDescription = remoteDescriptionString ? JSON.parse(remoteDescriptionString) : null;
      if (userId) {
        server.handleAnswer(userId, remoteDescription);
      }
    }
  }, [newestAnswer, server]);
  useEffect(() => {
    if ((newestCandidate == null ? void 0 : newestCandidate.candidate) && server) {
      const {
        candidate: _candidate,
        userId
      } = newestCandidate;
      const candidate = new RTCIceCandidate(_candidate);
      if (userId) {
        server.addIceCandidate(userId, candidate);
      }
    }
  }, [newestCandidate, server]);
  useEffect(() => {
    return () => {
      if (server) {
        server.close();
      }
    };
  }, [server]);
  return server;
};
