"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect, useCallback, useRef } from "react";
import { gql } from "@/shared/hooks/gql";
import { useQuery } from "@/shared/hooks/gql";
import { LoadingScreen } from "@/shared/components";
import { isHttp, isProxied } from "./helpers";
const GET_PROXIED_STREAM = gql(`
  query GetProxiedStream($url: String!) {
    mediaPlayerGetProxiedStream(url: $url) {
      status
      result {
        url
      }
    }
    getServerTime {
      time
    }
  }
`);
const useProxiedStream = (url, skip) => {
  var _a, _b, _c;
  const [error, setError] = useState(false);
  const startedRef = useRef(false);
  const {
    error: queryError,
    data,
    stopPolling
  } = useQuery(GET_PROXIED_STREAM, {
    variables: {
      url
    },
    fetchPolicy: "network-only",
    pollInterval: 1e4,
    skip
  });
  const time = (_a = data == null ? void 0 : data.getServerTime) == null ? void 0 : _a.time;
  const status = (_b = data == null ? void 0 : data.mediaPlayerGetProxiedStream) == null ? void 0 : _b.status;
  const result = (_c = data == null ? void 0 : data.mediaPlayerGetProxiedStream) == null ? void 0 : _c.result;
  useEffect(() => {
    const started = startedRef.current;
    if (status === "ready") {
      stopPolling();
    } else if (queryError) {
      setError(true);
    } else if (status === "started") {
      if (started) {
        setError(true);
      } else {
        startedRef.current = true;
      }
    }
  }, [status, queryError, time, stopPolling]);
  useEffect(() => {
    if (error)
      stopPolling();
  }, [error, stopPolling]);
  if (error) {
    return {
      error,
      loading: false
    };
  }
  return {
    url: result == null ? void 0 : result.url,
    loading: !skip && status !== "ready"
  };
};
export default (Video) => (props) => {
  const [enableProxy, setEnableProxy] = useState(false);
  const {
    loading,
    url,
    error
  } = useProxiedStream(props.url, !enableProxy || !isHttp(props.url) || isProxied(props.url));
  const onError = useCallback((_error) => {
    setEnableProxy(true);
    return true;
  }, []);
  if (loading)
    return /* @__PURE__ */ React.createElement(LoadingScreen, { text: "Relaying URL" });
  if (url)
    return /* @__PURE__ */ React.createElement(Video, __spreadProps(__spreadValues({}, props), { key: url, url }));
  if (error)
    return /* @__PURE__ */ React.createElement(Video, __spreadValues({}, props));
  return /* @__PURE__ */ React.createElement(Video, __spreadProps(__spreadValues({}, props), { onError }));
};
