"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  root: {
    "DateSelector__styles.root": "DateSelector__styles.root",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBottom: "xyorhqc",
    $$css: true
  },
  label: {
    "DateSelector__styles.label": "DateSelector__styles.label",
    minWidth: "x450l9j",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  month: {
    "DateSelector__styles.month": "DateSelector__styles.month",
    minWidth: "x450l9j",
    $$css: true
  },
  day: {
    "DateSelector__styles.day": "DateSelector__styles.day",
    minWidth: "xxsxjwm",
    marginLeft: "x16ydxro",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xyo1k6t",
    $$css: true
  },
  year: {
    "DateSelector__styles.year": "DateSelector__styles.year",
    minWidth: "x450l9j",
    $$css: true
  }
};
const DateSelector = ({
  onChange,
  value: valueProp
}) => {
  const getDaysOfMonth = monthNum => {
    if (!monthNum) return 0;
    if ([4, 6, 9, 11].indexOf(monthNum) !== -1) {
      return 30;
    }
    if (monthNum === 2) return 29;
    return 31;
  };
  const value = valueProp || [null, null, null];
  const day = value[2];
  const [year, month] = [value[0], value[1]];
  const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.label
  }, "Date of birth"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    className: styles.month,
    placeholder: "Month",
    onChange: e => {
      const value2 = parseInt(e.target.value);
      let currentDay = day;
      if (currentDay && currentDay > getDaysOfMonth(value2)) currentDay = getDaysOfMonth(value2);
      onChange([year, value2, currentDay]);
    },
    options: MONTHS.map((m, i) => {
      return {
        text: m,
        value: i + 1
      };
    })
  }), /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    disabled: !month,
    className: styles.day,
    placeholder: "Day",
    value: day ? day.toString() : void 0,
    onChange: e => {
      const value2 = parseInt(e.target.value);
      onChange([year, month, value2]);
    },
    options: [...Array(getDaysOfMonth(month || 0)).keys()].map(d => ({
      text: d + 1,
      value: d + 1
    }))
  }), /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    className: styles.year,
    placeholder: "Year",
    value: year ? year.toString() : void 0,
    onChange: e => {
      const value2 = parseInt(e.target.value);
      onChange([value2, month, day]);
    },
    options: [...Array(120).keys()].map(d => {
      const year2 = (/* @__PURE__ */new Date()).getFullYear() - d;
      return {
        value: year2,
        text: year2
      };
    })
  })));
};
var _default = exports.default = DateSelector;