"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _gql = require("@/shared/hooks/gql");
var _react = _interopRequireWildcard(require("react"));
var _ConnectionContext = require("@/api/ConnectionContext");
var _Signup = _interopRequireDefault(require("./Signup"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  loginContainer: {
    "EmailLogin__styles.loginContainer": "EmailLogin__styles.loginContainer",
    display: "x78zum5",
    flexWrap: "x1a02dak",
    $$css: true
  },
  signupLoginMessage: {
    "EmailLogin__styles.signupLoginMessage": "EmailLogin__styles.signupLoginMessage",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  loginSection: {
    "EmailLogin__styles.loginSection": "EmailLogin__styles.loginSection",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    minWidth: "x1jzhcrs",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  link: {
    "EmailLogin__styles.link": "EmailLogin__styles.link",
    color: "x16tr98c",
    cursor: "x1ypdohk",
    fontWeight: "xuv8nkb",
    $$css: true
  },
  forgotPassword: {
    "EmailLogin__styles.forgotPassword": "EmailLogin__styles.forgotPassword",
    display: "x1lliihq",
    float: "x1faq86j",
    marginLeft: "xj3b58b",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "xieb3on",
    cursor: "x1ypdohk",
    $$css: true
  }
};
const LOGIN = (0, _gql.gql)(`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`);
const VERIFY_EMAIL_CODE = (0, _gql.gql)(`
  mutation VerifyEmailCode2($email: String!, $code: String!) {
    verifyEmailCode(email: $email, code: $code) {
      ok
    }
  }
`);
const SEND_RESET_PASSWORD_CODE = (0, _gql.gql)(`
  mutation SendResetPasswordCode($email: String!) {
    sendResetPasswordCode(email: $email) {
      ok
    }
  }
`);
const RESET_PASSWORD = (0, _gql.gql)(`
  mutation ResetPassword($email: String!, $code: String!, $password: String!) {
    resetPassword(email: $email, code: $code, password: $password) {
      ok
    }
  }
`);
const ForgotPasswordForm = () => {
  const [error, setError] = (0, _react.useState)("");
  const [code, setCode] = (0, _react.useState)("");
  const [email, setEmail] = (0, _react.useState)("");
  const [password, setPassword] = (0, _react.useState)("");
  const [codeVerified, setCodeVerified] = (0, _react.useState)(false);
  const [passwordRepeat, setPasswordRepeat] = (0, _react.useState)("");
  const [emailSent, setEmailSent] = (0, _react.useState)(false);
  const [resetPassword, {
    loading
  }] = (0, _gql.useMutation)(RESET_PASSWORD);
  const [verifyEmailCode, {
    loading: verifyEmailCodeLoading
  }] = (0, _gql.useMutation)(VERIFY_EMAIL_CODE);
  const [sendResetPasswordCode, {
    loading: sendResetPasswordCodeLoading
  }] = (0, _gql.useMutation)(SEND_RESET_PASSWORD_CODE);
  const errorMessage = /* @__PURE__ */_react.default.createElement(_uikit.Message, {
    error: true,
    header: "Reset Password Failed!",
    content: error,
    onDismiss: () => setError("")
  });
  if (codeVerified) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Form, {
      error: !!error,
      key: "resetpasswordform",
      onSubmit: () => __async(void 0, null, function* () {
        try {
          yield resetPassword({
            variables: {
              code,
              email,
              password
            }
          });
        } catch (e) {
          if (e.graphQLErrors) {
            setError(e.graphQLErrors[0].message);
          }
        }
      })
    }, errorMessage, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
      placeholder: "Password",
      type: "password",
      value: password,
      large: true,
      onChange: e => {
        e.preventDefault();
        setPassword(e.target.value);
      }
    }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
      placeholder: "Password Repeat",
      type: "password",
      large: true,
      value: passwordRepeat,
      onChange: e => {
        e.preventDefault();
        setPasswordRepeat(e.target.value);
      }
    }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "yellow",
      inverted: true,
      loading,
      type: "submit",
      disabled: !password || password !== passwordRepeat
    }, "Reset Password"));
  }
  if (emailSent) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Form, {
      error: !!error,
      key: "codesubmit",
      onSubmit: () => __async(void 0, null, function* () {
        try {
          yield verifyEmailCode({
            variables: {
              code,
              email
            }
          });
          setCodeVerified(true);
        } catch (e) {
          if (e.graphQLErrors) {
            setError(e.graphQLErrors[0].message);
          }
        }
      })
    }, errorMessage, /* @__PURE__ */_react.default.createElement(_uikit.Message, {
      info: true,
      header: "Reset Code Sent!",
      content: `We've sent a reset code to ${email}`
    }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
      placeholder: "Reset Password Code",
      value: code || "",
      onChange: e => {
        e.preventDefault();
        setCode(e.target.value);
      }
    }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "yellow",
      inverted: true,
      loading: verifyEmailCodeLoading,
      disabled: !code,
      type: "submit"
    }, "Submit"));
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    error: !!error,
    key: "sendcodeform",
    onSubmit: () => __async(void 0, null, function* () {
      try {
        yield sendResetPasswordCode({
          variables: {
            email
          }
        });
        setEmailSent(true);
      } catch (e) {
        if (e.graphQLErrors) {
          setError(e.graphQLErrors[0].message);
        }
      }
    })
  }, errorMessage, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    placeholder: "Email",
    type: "email",
    value: email,
    onChange: e => {
      e.preventDefault();
      setEmail(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    loading: sendResetPasswordCodeLoading,
    disabled: !email,
    type: "submit",
    style: {
      margin: "auto"
    }
  }, "Send Reset Password Code"));
};
var _default = ({
  inviteCode,
  onLogin,
  signup
}) => {
  const [username, setUsername] = (0, _react.useState)("");
  const [password, setPassword] = (0, _react.useState)("");
  const [loginFailed, setLoginFailed] = (0, _react.useState)(false);
  const {
    updateClient
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  const [login, {
    loading
  }] = (0, _gql.useMutation)(LOGIN);
  const signupButtonRef = (0, _react.useRef)(null);
  const forgotPasswordButtonRef = (0, _react.useRef)(null);
  if (signup) {
    return /* @__PURE__ */_react.default.createElement(_Signup.default, {
      inviteCode
    });
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Login"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.loginContainer
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.loginSection
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    onSubmit: () => __async(void 0, null, function* () {
      var _a;
      try {
        const {
          data
        } = yield login({
          variables: {
            username,
            password
          }
        });
        const token = (_a = data == null ? void 0 : data.login) == null ? void 0 : _a.token;
        if (token) {
          window.localStorage.setItem("token", token);
          updateClient();
          onLogin && onLogin();
        }
      } catch (e) {
        setLoginFailed(true);
      }
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    name: "Username or Email",
    value: username,
    setValue: setUsername
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    name: "Password",
    value: password,
    setValue: setPassword,
    type: "password",
    autoFocus: false
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.forgotPassword,
    ref: forgotPasswordButtonRef
  }, "Forgot your password?")), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    type: "submit",
    color: "yellow",
    inverted: true,
    centered: true,
    size: "xl",
    style: {
      width: 200
    },
    disabled: !username || !password,
    loading
  }, loginFailed ? "Login Failed!" : "Login"))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.signupLoginMessage
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Dont have an account?"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.link,
    ref: signupButtonRef
  }, "Click here to Signup!")))), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Signup",
    trigger: signupButtonRef
  }, /* @__PURE__ */_react.default.createElement(_Signup.default, {
    inviteCode
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Forgot Password",
    trigger: forgotPasswordButtonRef
  }, /* @__PURE__ */_react.default.createElement(ForgotPasswordForm, null)));
};
exports.default = _default;