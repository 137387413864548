"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = exports.styles = {
  tabView: {
    "TabView__styles.tabView": "TabView__styles.tabView",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    maxWidth: "x193iq5w",
    $$css: true
  },
  empty: {
    "TabView__styles.empty": "TabView__styles.empty",
    display: "x1s85apg",
    $$css: true
  },
  menu: {
    "TabView__styles.menu": "TabView__styles.menu",
    padding: "xfawy5m",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  menuItem: {
    "TabView__styles.menuItem": "TabView__styles.menuItem",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: "x2lah0s",
    flexBasis: null,
    fontWeight: "x1xlr1w8",
    alignItems: "x6s0dn4",
    width: "x3hqpx7",
    position: "x1n2onr6",
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    justifyContent: "xl56j7k",
    transition: "xxksqoq",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  swipableWrapper: {
    "TabView__styles.swipableWrapper": "TabView__styles.swipableWrapper",
    display: "x78zum5",
    minHeight: "x2lwn1j",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    position: "x1n2onr6",
    alignItems: "x6s0dn4",
    width: "xh8yej3",
    $$css: true
  },
  tabviewItem: {
    "TabView__styles.tabviewItem": "TabView__styles.tabviewItem",
    maxWidth: "x193iq5w",
    minHeight: "x2lwn1j",
    display: "x78zum5",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    transform: "xmyttsv",
    transition: "x1alzeyj",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexShrink: null,
    flex: "x1b0bnr5",
    flexGrow: null,
    flexBasis: "x1l7klhg",
    minWidth: "xgqtt45",
    justifyContent: "x1nhvcw1",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  underline: {
    "TabView__styles.underline": "TabView__styles.underline",
    position: "x10l6tqk",
    bottom: "xbfrwjf",
    backgroundColor: "x14xb6xe",
    opacity: "x1iy03kw",
    transition: "xxdbxwx",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "x36qwtl",
    zIndex: "xhtitgo",
    $$css: true
  },
  setWidth: (percentage, width) => [{
    "TabView__styles.setWidth": "TabView__styles.setWidth",
    width: "x17fnjtu",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${width}%`),
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${percentage - width / 2}%`)
  }]
};