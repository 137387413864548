"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _helpers = require("kosmi-sdk/helpers");
var _uikit = require("@/uikit");
var _Premium = require("@/shared/components/Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Diamond = _interopRequireDefault(require("./Diamond"));
var _PremiumScreen = _interopRequireDefault(require("./PremiumScreen"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  diamondHeader: {
    "PremiumModal__styles.diamondHeader": "PremiumModal__styles.diamondHeader",
    fontSize: "x1pvqxga",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  trigger,
  source,
  straightToPayment
}) => {
  const track = (0, _helpers.useTracking)();
  const [opened, setOpened] = (0, _react.useState)(false);
  const onOpen = (0, _react.useCallback)(() => {
    track("Premium", "OpenPremiumModal", "Open Premium Modal");
    setOpened(true);
  }, [track]);
  if (_Premium.hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    huge: true,
    preventClosing: true,
    trigger,
    onOpen
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, /* @__PURE__ */_react.default.createElement(_Diamond.default, {
    className: styles.diamondHeader
  }), "Kosmi Premium"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, opened && /* @__PURE__ */_react.default.createElement(_PremiumScreen.default, {
    source,
    dark: true,
    straightToPayment
  })));
});