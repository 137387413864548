"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Button = require("./Button");
var _Icon = require("./Icon");
var _Image = require("./Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  diamond: {
    "UserProfile__styles.diamond": "UserProfile__styles.diamond",
    bottom: "xqo3gd",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    marginBottom: "xat24cr",
    fontSize: "x1pvqxga",
    position: "x10l6tqk",
    $$css: true
  },
  userProfile: {
    "UserProfile__styles.userProfile": "UserProfile__styles.userProfile",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    textAlign: "x2b8uid",
    minHeight: "xbwy6ji",
    flexDirection: "xdt5ytf",
    width: "xeq5yr9",
    $$css: true
  },
  avatarWrapper: {
    "UserProfile__styles.avatarWrapper": "UserProfile__styles.avatarWrapper",
    position: "x1n2onr6",
    width: "xezivpi",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  actions: {
    "UserProfile__styles.actions": "UserProfile__styles.actions",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    flexWrap: "x1a02dak",
    margin: "x1wxscg9",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  avatar: {
    "UserProfile__styles.avatar": "UserProfile__styles.avatar",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: "xyorhqc",
    width: "x1exxlbk",
    height: "xpyat2d",
    maxWidth: "x193iq5w",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  nameWrapper: {
    "UserProfile__styles.nameWrapper": "UserProfile__styles.nameWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    margin: "xz4czej",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    alignItems: "x1cy8zhl",
    $$css: true
  },
  displayName: {
    "UserProfile__styles.displayName": "UserProfile__styles.displayName",
    display: "x1rg5ohu",
    fontSize: "x1pvqxga",
    fontWeight: "x1s688f",
    maxWidth: "x18qnofl",
    wordBreak: "x13faqbe",
    lineBreak: "x1c724ye",
    textAlign: "xdpxx8g",
    textOverflow: "xlyipyv",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    userSelect: "x1hx0egp",
    $$css: true
  },
  username: {
    "UserProfile__styles.username": "UserProfile__styles.username",
    margin: "x1hlb6i2",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "xj3b58b",
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    userSelect: "x1hx0egp",
    fontSize: "x1j61zf2",
    $$css: true
  },
  flex: {
    "UserProfile__styles.flex": "UserProfile__styles.flex",
    display: "x78zum5",
    $$css: true
  }
};
var _default = ({
  isAdmin,
  displayName,
  username,
  isAnonymous,
  actions,
  avatarUrl,
  isSubscribed
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.userProfile
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.flex
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.avatarWrapper
  }, /* @__PURE__ */_react.default.createElement(_Image.Image, {
    src: avatarUrl || "",
    circular: true,
    loading: "lazy",
    width: 100,
    height: 100,
    className: styles.avatar
  }), isSubscribed && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    name: "kosmi diamond",
    className: styles.diamond
  })), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.nameWrapper
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.displayName
  }, isAdmin && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    color: "yellow",
    name: "key"
  }), displayName), !isAnonymous && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.username
  }, "@", username))), actions && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.actions
  }, actions.map((action, i) => /* @__PURE__ */_react.default.createElement(_Button.Button, {
    key: i,
    icon: action.icon,
    size: "xs",
    inverted: true,
    onClick: action.onClick,
    color: action.color || "yellow",
    loading: action.loading,
    style: {
      margin: 4,
      width: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left"
    }
  }, action.text))));
};
exports.default = _default;