"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { codePush } from "capacitor-codepush";
import createPersistedState from "use-persisted-state";
import { App as CapacitorApp } from "@capacitor/app";
import { Keyboard } from "@capacitor/keyboard";
import { gql, useMutation } from "@/shared/hooks/gql";
import { PushNotifications } from "@capacitor/push-notifications";
import { useEffect } from "react";
import { currentUserHook } from "@/shared/hooks/useCurrentUser";
import { useHistory } from "react-router-dom";
const useMobileOverlayState = createPersistedState("mobileTab");
const useCurrentChatUserIdState = createPersistedState("friendlist-currentChatUserId");
const REGISTER_PUSH_NOTIFICATIONS = gql(`
  mutation RegisterPushNotificationsMutation(
    $endpoint: String!
    $keys: String!
  ) {
    registerPushNotifications(endpoint: $endpoint, keys: $keys) {
      ok
    }
  }
`);
const getSlug = (url) => url.split("https://app.kosmi.io").pop();
const useAppUrlListener = () => {
  const history = useHistory();
  useEffect(() => {
    ;
    () => __async(void 0, null, function* () {
      const launchUrl = yield App.getLaunchUrl();
      if (launchUrl) {
        const slug = getSlug(launchUrl.url);
        if (slug) {
          history.replace(slug);
        }
      }
    });
    CapacitorApp.addListener("appUrlOpen", (event) => {
      const slug = getSlug(event.url);
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);
};
const usePushNotificationListener = () => {
  const history = useHistory();
  const [_overlayMenu, setOverlayMenu] = useMobileOverlayState("");
  const [_privateChatCurrentUserId, setPrivateChatCurrentUserId] = useCurrentChatUserIdState(null);
  useEffect(() => {
    const handleNotificationPath = (path) => {
      var _a;
      if (!path)
        return;
      if (path.startsWith("/room/") && path.endsWith("/chat")) {
        const roomId = (path.match(/\/room\/(.+?)\/chat/) || [])[1];
        history.push(`/room/${roomId}`);
      } else if (path.startsWith("/privatechat/")) {
        const userId = (_a = path.match(/\/privatechat\/(.+)/)) == null ? void 0 : _a[1];
        setOverlayMenu("chats");
        setPrivateChatCurrentUserId(userId);
      } else {
        history.push(path);
      }
    };
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
        var _a;
        const {
          path
        } = ((_a = notification == null ? void 0 : notification.notification) == null ? void 0 : _a.data) || {};
        handleNotificationPath(path);
      });
    }
  }, [history, setOverlayMenu, setPrivateChatCurrentUserId]);
};
export function getPaddingValues(element) {
  const computedStyle = window.getComputedStyle(element);
  const paddingTop = parseInt(computedStyle.getPropertyValue("padding-top").replace("px", ""));
  const paddingRight = parseInt(computedStyle.getPropertyValue("padding-right").replace("px", ""));
  const paddingBottom = parseInt(computedStyle.getPropertyValue("padding-bottom").replace("px", ""));
  const paddingLeft = parseInt(computedStyle.getPropertyValue("padding-left").replace("px", ""));
  return {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft
  };
}
const useKeyboardFix = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
      const app = document.getElementById("app");
      const dispatchResize = () => window.dispatchEvent(new Event("resize"));
      let keyboardActive = true;
      Keyboard.addListener("keyboardWillShow", (info) => {
        if (keyboardActive)
          return;
        if (!app)
          return;
        const newHeight = app.clientHeight - info.keyboardHeight;
        if (app && newHeight) {
          app.style.height = newHeight + "px";
          app.style.paddingBottom = "0px";
        }
        dispatchResize();
      });
      Keyboard.addListener("keyboardDidShow", (_info) => {
        if (!app)
          return;
        if (app) {
          app.style.height = "";
          app.style.paddingBottom = "";
        }
        keyboardActive = true;
        dispatchResize();
      });
      Keyboard.addListener("keyboardWillHide", () => {
        keyboardActive = false;
        dispatchResize();
      });
      Keyboard.addListener("keyboardDidHide", dispatchResize);
    }
  }, []);
};
const usePushNotfications = () => {
  const [registerPushNotificationsMutation] = useMutation(REGISTER_PUSH_NOTIFICATIONS);
  const currentUser = currentUserHook();
  useEffect(() => {
    if (!(currentUser == null ? void 0 : currentUser.id))
      return;
    if (currentUser == null ? void 0 : currentUser.isAnonymous)
      return;
    const registerPushNotifications = (endpoint, keys) => __async(void 0, null, function* () {
      yield registerPushNotificationsMutation({
        variables: {
          endpoint,
          keys
        }
      });
    });
    if (Capacitor.isNativePlatform()) {
      ;
      (() => __async(void 0, null, function* () {
        const result = yield PushNotifications.requestPermissions();
        if (result.receive === "granted") {
          PushNotifications.register();
        } else {
          console.warn("PushNotifications not granted");
        }
        PushNotifications.addListener("registration", (token) => __async(void 0, null, function* () {
          if (Capacitor.getPlatform() === "ios") {
            registerPushNotifications("apns://", JSON.stringify({
              token
            }));
          } else if (Capacitor.getPlatform() === "android") {
            registerPushNotifications("firebase://", JSON.stringify({
              token
            }));
          }
        }));
        PushNotifications.addListener("registrationError", (error) => {
          throw Error("Error on registration: " + JSON.stringify(error));
        });
        PushNotifications.addListener("pushNotificationReceived", (notification) => {
          console.warn("Push received: " + JSON.stringify(notification));
        });
        PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
          console.warn("Push action performed: " + JSON.stringify(notification));
        });
        PushNotifications.removeAllDeliveredNotifications();
      }))();
    }
  }, [currentUser == null ? void 0 : currentUser.id, currentUser == null ? void 0 : currentUser.isAnonymous, registerPushNotificationsMutation]);
};
const loadCodePush = () => __async(void 0, null, function* () {
  if (yield codePush.sync(() => {
  })) {
    codePush.restartApplication();
  }
});
export default () => {
  useAppUrlListener();
  usePushNotfications();
  useKeyboardFix();
  usePushNotificationListener();
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      __webpack_public_path__ = "https://app.kosmi.io/";
      loadCodePush();
    }
  }, []);
};
