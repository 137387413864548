"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("../Html"));
var _Image = require("../Image");
var _momentMini = _interopRequireDefault(require("moment-mini"));
var _react = _interopRequireWildcard(require("react"));
var _Spotlight = _interopRequireDefault(require("../Spotlight"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _Button = require("../Button");
var _ContextMenu = require("../ContextMenu");
var _reactResponsive = require("react-responsive");
var _Premium = require("../../shared/components/Premium");
var _MessageContainer = _interopRequireDefault(require("./MessageContainer"));
var _helpers = require("../helpers");
var _Messagebox = _interopRequireDefault(require("./Messagebox"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const formatTimeOrDate = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : yourDate.format("YYYY-MM-DD");
};
const formatTime = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : `${yourDate.fromNow()} at ${yourDate.format("hh:mm")}`;
};
const TouchableOpacityWithClass = t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(24);
  let className;
  let onLongPress;
  let props;
  let ref;
  if ($[0] !== t0) {
    _a = t0, {
      ref,
      className,
      onLongPress
    } = _a, props = __objRest(_a, ["ref", "className", "onLongPress"]);
    $[0] = t0;
    $[1] = className;
    $[2] = onLongPress;
    $[3] = props;
    $[4] = ref;
  } else {
    className = $[1];
    onLongPress = $[2];
    props = $[3];
    ref = $[4];
  }
  const innerRef = (0, _react.useRef)(null);
  const [isPressed, setIsPressed] = (0, _react.useState)(false);
  const [clicked, setClicked] = (0, _react.useState)(false);
  let t1;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      setIsPressed(true);
    };
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  const callback = t1;
  const {
    onTouchStart,
    onTouchEnd,
    onTouchMove
  } = (0, _helpers.useLongPress)(callback);
  let t2;
  let t3;
  if ($[6] !== isPressed || $[7] !== onLongPress) {
    t2 = () => {
      if (onLongPress && isPressed) {
        onLongPress();
        setClicked(false);
        setIsPressed(false);
      }
    };
    t3 = [isPressed, onLongPress];
    $[6] = isPressed;
    $[7] = onLongPress;
    $[8] = t2;
    $[9] = t3;
  } else {
    t2 = $[8];
    t3 = $[9];
  }
  (0, _react.useEffect)(t2, t3);
  const refs = (0, _helpers.useMergedRefs)(ref, innerRef);
  let t4;
  if ($[10] !== onTouchStart) {
    t4 = e => {
      onTouchStart(e);
      setClicked(true);
      return false;
    };
    $[10] = onTouchStart;
    $[11] = t4;
  } else {
    t4 = $[11];
  }
  let t5;
  if ($[12] !== onTouchEnd) {
    t5 = e_0 => {
      onTouchEnd(e_0);
      setClicked(false);
      return false;
    };
    $[12] = onTouchEnd;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  const t6 = clicked && styles.scale;
  let t7;
  if ($[14] !== className || $[15] !== t6) {
    t7 = [t6, className];
    $[14] = className;
    $[15] = t6;
    $[16] = t7;
  } else {
    t7 = $[16];
  }
  const t8 = refs;
  let t9;
  if ($[17] !== onTouchMove || $[18] !== props || $[19] !== t4 || $[20] !== t5 || $[21] !== t7 || $[22] !== t8) {
    t9 = /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadValues({
      onTouchStart: t4,
      onTouchEnd: t5,
      onTouchMove,
      style: t7,
      ref: t8
    }, props));
    $[17] = onTouchMove;
    $[18] = props;
    $[19] = t4;
    $[20] = t5;
    $[21] = t7;
    $[22] = t8;
    $[23] = t9;
  } else {
    t9 = $[23];
  }
  return t9;
};
const styles = {
  diamond: {
    "index__styles.diamond": "index__styles.diamond",
    bottom: "x1ey2m1c",
    right: "x6smm1b",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "x1t8mvi6",
    fontSize: "x1j61zf2",
    position: "x10l6tqk",
    width: "x1fsd2vl",
    height: "x170jfvy",
    $$css: true
  },
  messageOptions: {
    "index__styles.messageOptions": "index__styles.messageOptions",
    position: "x10l6tqk",
    top: "x13vifvy",
    width: "x1pju0fl",
    height: "xg7h5cd",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  ownMessageOptions: {
    "index__styles.ownMessageOptions": "index__styles.ownMessageOptions",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  messageOptionsHasPrev: {
    "index__styles.messageOptionsHasPrev": "index__styles.messageOptionsHasPrev",
    paddingTop: "xexx8yu",
    $$css: true
  },
  scale: {
    "index__styles.scale": "index__styles.scale",
    transform: "x9mn55f",
    $$css: true
  },
  chatMessage: {
    "index__styles.chatMessage": "index__styles.chatMessage",
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    background: "x11g6tue",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "x1y1aw1k",
    paddingBottom: "x1120s5i",
    borderTopWidth: "x972fbf",
    lineHeight: "x1u7k74",
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    userSelect: "x87ps6o",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "xvr3rsr xlihjin",
    $$css: true
  },
  hasPrevMessage: {
    "index__styles.hasPrevMessage": "index__styles.hasPrevMessage",
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    $$css: true
  },
  avatar: {
    "index__styles.avatar": "index__styles.avatar",
    display: "x1lliihq",
    width: "xvy4d1p",
    height: "xxk0z11",
    float: "x1faq86j",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: "x1db2dqx",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x1n2onr6",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  rubyGlow: {
    "index__styles.rubyGlow": "index__styles.rubyGlow",
    color: "xozz0dy",
    $$css: true
  },
  chatMessageMenu: {
    "index__styles.chatMessageMenu": "index__styles.chatMessageMenu",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  chatMessageMenuButtons: {
    "index__styles.chatMessageMenuButtons": "index__styles.chatMessageMenuButtons",
    display: "x78zum5",
    $$css: true
  },
  mobileTime: {
    "index__styles.mobileTime": "index__styles.mobileTime",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  avatarImg: {
    "index__styles.avatarImg": "index__styles.avatarImg",
    display: "x1lliihq",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: "xack27t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xh8yej3",
    height: "x5yr21d",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  author: {
    "index__styles.author": "index__styles.author",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    color: "x11jfisy",
    fontWeight: "x117nqv4",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    width: "xeq5yr9",
    $$css: true
  },
  authorName: {
    "index__styles.authorName": "index__styles.authorName",
    maxWidth: "x1ncir08",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    $$css: true
  },
  authorClickable: {
    "index__styles.authorClickable": "index__styles.authorClickable",
    cursor: "x1ypdohk",
    ":active_transform": "xvruv2t",
    $$css: true
  },
  date: {
    "index__styles.date": "index__styles.date",
    display: "x78zum5",
    color: "xrkdtlx",
    fontSize: "xfifm61",
    marginBottom: "x12nagc",
    marginTop: "x1gslohp",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    ":last-child_marginRight": "x552jh6",
    ":last-child_marginInlineStart": null,
    ":last-child_marginInlineEnd": null,
    "@media (max-width: 1000px)_fontSize": "xzgahhe",
    $$css: true
  },
  text: {
    "index__styles.text": "index__styles.text",
    marginTop: "xr9ek0c",
    marginBottom: "xjpr12u",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    marginLeft: "x1gh5o4n",
    marginInlineStart: null,
    marginInlineEnd: null,
    overflowWrap: "xj0a0fe",
    flexDirection: "xdt5ytf",
    color: "x11jfisy",
    backgroundColor: "x8897o3",
    lineHeight: "xwn7fz2",
    userSelect: "x1hx0egp",
    WebkitUserSelect: "x1rh3ycu",
    borderRadius: "xgqmno8",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "xe8ttls",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "xf18ygs",
    paddingEnd: null,
    paddingRight: "xnuq7ks",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    paddingInlineStart: null,
    paddingInlineEnd: null,
    width: "xeq5yr9",
    maxWidth: "xv0phki",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_userSelect": "x1de8jf8",
    "@media (max-width: 1000px)_WebkitUserSelect": "x10d2bzd",
    $$css: true
  },
  emoji: {
    "index__styles.emoji": "index__styles.emoji",
    fontSize: "x1s3cmhv",
    $$css: true
  },
  isOwn: {
    "index__styles.isOwn": "index__styles.isOwn",
    backgroundColor: "x8qxh4v",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  }
};
const ChatMessageMenu = t0 => {
  const $ = (0, _compilerRuntime.c)(17);
  const {
    ref,
    actions,
    className,
    onClick,
    onClose,
    time,
    styleProps
  } = t0;
  let t1;
  if ($[0] !== className) {
    t1 = [styles.chatMessageMenu, className];
    $[0] = className;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== time) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.mobileTime
    }, time);
    $[2] = time;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] !== actions || $[5] !== onClose) {
    let t42;
    if ($[7] !== onClose) {
      t42 = (a, i) => a.onClick && /* @__PURE__ */_react.default.createElement(_Button.Button, {
        color: "white",
        inverted: true,
        key: i,
        icon: a.icon,
        onClick: () => __async(void 0, null, function* () {
          if (a.onClick && (yield a.onClick())) {
            onClose();
          }
        }),
        style: {
          marginLeft: 4,
          marginRight: 4
        }
      });
      $[7] = onClose;
      $[8] = t42;
    } else {
      t42 = $[8];
    }
    t3 = actions.map(t42);
    $[4] = actions;
    $[5] = onClose;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[9] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.chatMessageMenuButtons
    }, t3);
    $[9] = t3;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  let t5;
  if ($[11] !== onClick || $[12] !== styleProps || $[13] !== t1 || $[14] !== t2 || $[15] !== t4) {
    t5 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref,
      style: t1,
      onClick,
      styleProps
    }, t2, t4);
    $[11] = onClick;
    $[12] = styleProps;
    $[13] = t1;
    $[14] = t2;
    $[15] = t4;
    $[16] = t5;
  } else {
    t5 = $[16];
  }
  return t5;
};
const ChatMessageDate = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(8);
  const {
    className,
    time,
    onClick
  } = t0;
  let t1;
  if ($[0] !== className) {
    t1 = [styles.date, className];
    $[0] = className;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== time) {
    t2 = formatTimeOrDate(time);
    $[2] = time;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] !== onClick || $[5] !== t1 || $[6] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: t1
    }, t2);
    $[4] = onClick;
    $[5] = t1;
    $[6] = t2;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  return t3;
});
const ChatMessageText = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(41);
  const {
    children,
    isOwn,
    removeMessage,
    text,
    time,
    rootNode
  } = t0;
  let t1;
  if ($[0] !== time) {
    t1 = formatTime(time);
    $[0] = time;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const timeStr = t1;
  let t2;
  if ($[2] !== text) {
    t2 = Array.from(text).every(_temp);
    $[2] = text;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const isEmoji = t2;
  const ref = (0, _react.useRef)(null);
  const [focused, setFocused] = _react.default.useState(false);
  const childrenRef = (0, _helpers.useMergedRefs)(ref);
  let t3;
  if ($[4] !== children || $[5] !== childrenRef) {
    let t42;
    if ($[7] !== childrenRef) {
      t42 = {
        ref: childrenRef
      };
      $[7] = childrenRef;
      $[8] = t42;
    } else {
      t42 = $[8];
    }
    t3 = _react.default.cloneElement(children, t42);
    $[4] = children;
    $[5] = childrenRef;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  const clonedChildren = t3;
  let t4;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      query: "(hover: hover) and (pointer: fine)"
    };
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  const isHover = (0, _reactResponsive.useMediaQuery)(t4);
  let t5;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = () => {
      setFocused(true);
    };
    $[10] = t5;
  } else {
    t5 = $[10];
  }
  const onLongPress = t5;
  let t6;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = () => {
      setFocused(false);
    };
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  const outsideClickHandler = t6;
  let t7;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = () => {
      setFocused(false);
    };
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  const closeMessageMenu = t7;
  let t8;
  if ($[13] !== removeMessage) {
    t8 = {
      icon: "trash",
      text: "Remove message",
      onClick: removeMessage
    };
    $[13] = removeMessage;
    $[14] = t8;
  } else {
    t8 = $[14];
  }
  let t9;
  if ($[15] !== text) {
    t9 = {
      icon: "copy",
      text: "Copy message",
      onClick: () => __async(void 0, null, function* () {
        navigator.clipboard.writeText(text);
        return true;
      })
    };
    $[15] = text;
    $[16] = t9;
  } else {
    t9 = $[16];
  }
  let t10;
  if ($[17] !== t8 || $[18] !== t9) {
    t10 = [t8, t9];
    $[17] = t8;
    $[18] = t9;
    $[19] = t10;
  } else {
    t10 = $[19];
  }
  let t11;
  if ($[20] !== t10 || $[21] !== timeStr) {
    t11 = /* @__PURE__ */_react.default.createElement(ChatMessageMenu, {
      time: timeStr,
      onClose: closeMessageMenu,
      actions: t10
    });
    $[20] = t10;
    $[21] = timeStr;
    $[22] = t11;
  } else {
    t11 = $[22];
  }
  const t12 = !isHover;
  const t13 = isOwn && styles.isOwn;
  const t14 = isEmoji && styles.emoji;
  let t15;
  if ($[23] !== t13 || $[24] !== t14) {
    t15 = [styles.text, t13, t14];
    $[23] = t13;
    $[24] = t14;
    $[25] = t15;
  } else {
    t15 = $[25];
  }
  let t16;
  if ($[26] !== clonedChildren || $[27] !== t15) {
    t16 = /* @__PURE__ */_react.default.createElement(TouchableOpacityWithClass, {
      onLongPress,
      className: t15
    }, clonedChildren);
    $[26] = clonedChildren;
    $[27] = t15;
    $[28] = t16;
  } else {
    t16 = $[28];
  }
  let t17;
  if ($[29] !== rootNode || $[30] !== t12 || $[31] !== t16 || $[32] !== timeStr) {
    t17 = /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
      content: timeStr,
      position: "left",
      disabled: t12,
      mountNode: rootNode,
      trigger: t16
    });
    $[29] = rootNode;
    $[30] = t12;
    $[31] = t16;
    $[32] = timeStr;
    $[33] = t17;
  } else {
    t17 = $[33];
  }
  let t18;
  if ($[34] !== focused || $[35] !== t11 || $[36] !== t17) {
    t18 = /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
      controlled: true,
      open: focused,
      content: t11,
      trigger: t17
    });
    $[34] = focused;
    $[35] = t11;
    $[36] = t17;
    $[37] = t18;
  } else {
    t18 = $[37];
  }
  let t19;
  if ($[38] !== focused || $[39] !== t18) {
    t19 = /* @__PURE__ */_react.default.createElement(_Spotlight.default, {
      brightness: 50,
      enabled: focused,
      onClickOutside: outsideClickHandler
    }, t18);
    $[38] = focused;
    $[39] = t18;
    $[40] = t19;
  } else {
    t19 = $[40];
  }
  return t19;
});
const ChatMessageAuthor = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    children,
    className,
    onClick,
    isSubscribed
  } = t0;
  const t1 = isSubscribed && styles.rubyGlow;
  let t2;
  if ($[0] !== className || $[1] !== t1) {
    t2 = [styles.author, t1, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== children || $[4] !== onClick || $[5] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: t2
    }, children);
    $[3] = children;
    $[4] = onClick;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  return t3;
});
const ChatMessageAvatar = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(10);
  const {
    children,
    className,
    src,
    onClick,
    clickable
  } = t0;
  const t1 = clickable && styles.authorClickable;
  let t2;
  if ($[0] !== className || $[1] !== t1) {
    t2 = [styles.avatar, t1, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== src) {
    t3 = src && /* @__PURE__ */_react.default.createElement(_Image.Image, {
      className: styles.avatarImg,
      src,
      loading: "lazy"
    });
    $[3] = src;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== children || $[6] !== onClick || $[7] !== t2 || $[8] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: t2
    }, t3, children);
    $[5] = children;
    $[6] = onClick;
    $[7] = t2;
    $[8] = t3;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  return t4;
});
const ChatMessageAuthorName = t0 => {
  const $ = (0, _compilerRuntime.c)(5);
  const {
    children,
    clickable
  } = t0;
  const t1 = clickable && styles.authorClickable;
  let t2;
  if ($[0] !== t1) {
    t2 = [styles.authorName, t1];
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== children || $[3] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t2
    }, children);
    $[2] = children;
    $[3] = t2;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  return t3;
};
const ChatMessage = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(53);
  const {
    ref,
    id,
    isOwn,
    removeMessage,
    hasPrevMessage,
    prevTime,
    time,
    content,
    rootNode,
    text,
    isSubscribed,
    viewUserProfile,
    avatarUrl,
    authorName,
    authorId
  } = t0;
  const [showOptions, setShowOptions] = (0, _react.useState)(false);
  const [openOptions, setOpenOptions] = (0, _react.useState)(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      query: "(hover: hover) and (pointer: fine)"
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const isHover = (0, _reactResponsive.useMediaQuery)(t1);
  const hideTime = prevTime && time - prevTime < 3600;
  let t2;
  if ($[1] !== openOptions) {
    t2 = () => setOpenOptions(!openOptions);
    $[1] = openOptions;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const toggleOptions = t2;
  let t3;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => setOpenOptions(true);
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const setOptionsOpen = t3;
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = () => setOpenOptions(false);
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  const setOptionsClosed = t4;
  let t5;
  if ($[5] !== id || $[6] !== removeMessage) {
    t5 = () => __async(void 0, null, function* () {
      if (!removeMessage) {
        return false;
      }
      return yield removeMessage(id);
    });
    $[5] = id;
    $[6] = removeMessage;
    $[7] = t5;
  } else {
    t5 = $[7];
  }
  const removeThisMessage = t5;
  let t6;
  if ($[8] !== authorId || $[9] !== viewUserProfile) {
    t6 = () => {
      if (viewUserProfile) {
        viewUserProfile(authorId);
      }
    };
    $[8] = authorId;
    $[9] = viewUserProfile;
    $[10] = t6;
  } else {
    t6 = $[10];
  }
  const viewThisUserProfile = t6;
  let t7;
  if ($[11] !== isHover) {
    t7 = () => isHover && setShowOptions(true);
    $[11] = isHover;
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  const onMouseEnter = t7;
  let t8;
  if ($[13] !== isHover) {
    t8 = () => isHover && setShowOptions(false);
    $[13] = isHover;
    $[14] = t8;
  } else {
    t8 = $[14];
  }
  const onMouseLeave = t8;
  const t9 = hasPrevMessage && styles.hasPrevMessage;
  let t10;
  if ($[15] !== t9) {
    t10 = [styles.chatMessage, t9];
    $[15] = t9;
    $[16] = t10;
  } else {
    t10 = $[16];
  }
  let t11;
  if ($[17] !== hasPrevMessage || $[18] !== hideTime || $[19] !== time) {
    t11 = !hasPrevMessage && !hideTime && /* @__PURE__ */_react.default.createElement(ChatMessageDate, {
      time
    });
    $[17] = hasPrevMessage;
    $[18] = hideTime;
    $[19] = time;
    $[20] = t11;
  } else {
    t11 = $[20];
  }
  let t12;
  if ($[21] !== hasPrevMessage || $[22] !== id || $[23] !== isOwn || $[24] !== openOptions || $[25] !== removeMessage || $[26] !== removeThisMessage || $[27] !== showOptions || $[28] !== toggleOptions) {
    t12 = (showOptions || openOptions) && removeMessage && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.messageOptions, isOwn && styles.ownMessageOptions, hasPrevMessage && styles.messageOptionsHasPrev]
    }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu, {
      open: openOptions,
      onOpen: setOptionsOpen,
      onClose: setOptionsClosed,
      position: isOwn ? "center" : "left",
      trigger: /* @__PURE__ */_react.default.createElement(_Button.Button, {
        key: id,
        style: {
          borderWidth: 0
        },
        color: "white",
        inverted: true,
        icon: "ellipsis horizontal",
        onClick: toggleOptions
      })
    }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu.Item, {
      icon: "trash",
      text: "Delete Message",
      onClick: removeThisMessage
    })));
    $[21] = hasPrevMessage;
    $[22] = id;
    $[23] = isOwn;
    $[24] = openOptions;
    $[25] = removeMessage;
    $[26] = removeThisMessage;
    $[27] = showOptions;
    $[28] = toggleOptions;
    $[29] = t12;
  } else {
    t12 = $[29];
  }
  let t13;
  if ($[30] !== authorName || $[31] !== avatarUrl || $[32] !== hasPrevMessage || $[33] !== isOwn || $[34] !== isSubscribed || $[35] !== viewThisUserProfile || $[36] !== viewUserProfile) {
    t13 = !hasPrevMessage && !isOwn && /* @__PURE__ */_react.default.createElement(ChatMessageAuthor, {
      isSubscribed,
      onClick: viewUserProfile ? viewThisUserProfile : void 0
    }, /* @__PURE__ */_react.default.createElement(ChatMessageAvatar, {
      clickable: !!viewUserProfile,
      src: avatarUrl
    }, isSubscribed && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
      className: styles.diamond
    })), /* @__PURE__ */_react.default.createElement(ChatMessageAuthorName, {
      clickable: !!viewUserProfile
    }, authorName));
    $[30] = authorName;
    $[31] = avatarUrl;
    $[32] = hasPrevMessage;
    $[33] = isOwn;
    $[34] = isSubscribed;
    $[35] = viewThisUserProfile;
    $[36] = viewUserProfile;
    $[37] = t13;
  } else {
    t13 = $[37];
  }
  let t14;
  if ($[38] !== content || $[39] !== isOwn || $[40] !== removeThisMessage || $[41] !== rootNode || $[42] !== text || $[43] !== time) {
    t14 = /* @__PURE__ */_react.default.createElement(ChatMessageText, {
      isOwn,
      text,
      time,
      removeMessage: removeThisMessage,
      rootNode
    }, content);
    $[38] = content;
    $[39] = isOwn;
    $[40] = removeThisMessage;
    $[41] = rootNode;
    $[42] = text;
    $[43] = time;
    $[44] = t14;
  } else {
    t14 = $[44];
  }
  let t15;
  if ($[45] !== onMouseEnter || $[46] !== onMouseLeave || $[47] !== t10 || $[48] !== t11 || $[49] !== t12 || $[50] !== t13 || $[51] !== t14) {
    t15 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref,
      style: t10,
      onMouseEnter,
      onMouseLeave
    }, t11, t12, t13, t14);
    $[45] = onMouseEnter;
    $[46] = onMouseLeave;
    $[47] = t10;
    $[48] = t11;
    $[49] = t12;
    $[50] = t13;
    $[51] = t14;
    $[52] = t15;
  } else {
    t15 = $[52];
  }
  return t15;
});
const ChatMessages = t0 => {
  const $ = (0, _compilerRuntime.c)(8);
  const {
    messages,
    nextPage,
    loading
  } = t0;
  const [rootNode, setRootNode] = (0, _react.useState)(void 0);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = node => {
      setRootNode(node);
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const rootRef = t1;
  let t2;
  if ($[1] !== messages || $[2] !== rootNode) {
    t2 = rootNode && (messages == null ? void 0 : messages.map(t32 => {
      const {
        id,
        isOwn,
        time,
        hasPrevMessage,
        prevTime,
        text,
        avatarUrl,
        authorId,
        isSubscribed,
        authorName,
        content,
        removeMessage,
        viewUserProfile
      } = t32;
      return /* @__PURE__ */_react.default.createElement(ChatMessage, {
        key: id,
        id,
        isOwn,
        time,
        hasPrevMessage,
        prevTime,
        text,
        rootNode,
        avatarUrl,
        authorId,
        isSubscribed,
        authorName,
        content,
        removeMessage,
        viewUserProfile
      });
    }));
    $[1] = messages;
    $[2] = rootNode;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] !== loading || $[5] !== nextPage || $[6] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(_MessageContainer.default, {
      nextPage,
      loading,
      ref: rootRef
    }, t2);
    $[4] = loading;
    $[5] = nextPage;
    $[6] = t2;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  return t3;
};
ChatMessages.Messagebox = _Messagebox.default;
var _default = exports.default = ChatMessages;
function _temp(c) {
  return new RegExp("\\p{Emoji}", "u").test(c) && isNaN(parseInt(c));
}