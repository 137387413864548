"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Html = _interopRequireDefault(require("./Html"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const slideBackandForth = "x1a044tm-B";
const styles = {
  topStripLoader: {
    "TopStripLoader__styles.topStripLoader": "TopStripLoader__styles.topStripLoader",
    height: "x36qwtl",
    zIndex: "xfo81ep",
    width: "xh8yej3",
    position: "x10l6tqk",
    top: "x13vifvy",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    background: "x1dc95m1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: "xakli9p",
    animationName: "x3ygoqc",
    animationDuration: "x1qy094b",
    animationTimingFunction: "x1esw782",
    animationDirection: "xpz12be",
    animationIterationCount: "xa4qsjk",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  }
};
var _default = () => {
  const $ = (0, _compilerRuntime.c)(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.topStripLoader
    });
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};
exports.default = _default;