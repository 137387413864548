"use strict";
import { useEffect } from "react";
export default () => {
  useEffect(() => {
    window.onbeforeunload = function() {
      return false;
    };
    return () => {
      window.onbeforeunload = null;
      delete window.installPrompt;
    };
  }, []);
};
