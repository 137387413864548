"use strict";
import { useEffect, useState } from "react";
import { useIceservers } from "../p2p/useIceServers";
import { parseLinkHeader } from "@web3-storage/parse-link-header";
export const useObsStream = (host, streamKey) => {
  const [mediaSrcObject, setMediaSrcObject] = useState(null);
  const iceServers = useIceservers();
  useEffect(() => {
    if (!iceServers)
      return;
    if (!streamKey)
      return;
    if (!host)
      return;
    const peerConnection = new RTCPeerConnection({ iceServers });
    peerConnection.ontrack = (event) => {
      setMediaSrcObject(event.streams[0]);
    };
    peerConnection.addTransceiver("audio", { direction: "recvonly" });
    peerConnection.addTransceiver("video", { direction: "recvonly" });
    peerConnection.createOffer().then((offer) => {
      if (!offer["sdp"])
        return;
      offer["sdp"] = offer["sdp"].replace(
        "useinbandfec=1",
        "useinbandfec=1;stereo=1"
      );
      peerConnection.setLocalDescription(offer);
      fetch(`https://${host}/api/whep`, {
        method: "POST",
        body: offer.sdp,
        headers: {
          Authorization: `Bearer ${streamKey}`,
          "Content-Type": "application/sdp"
        }
      }).then((r) => {
        const parsedLinkHeader = parseLinkHeader(r.headers.get("Link"));
        if (!parsedLinkHeader)
          return;
        const evtSource = new EventSource(
          `https://${parsedLinkHeader["urn:ietf:params:whep:ext:core:server-sent-events"].url}`
        );
        evtSource.onerror = (_err) => evtSource.close();
        return r.text();
      }).then((answer) => {
        peerConnection.setRemoteDescription({
          sdp: answer,
          type: "answer"
        });
      });
    });
    return () => {
      peerConnection.close();
    };
  }, [iceServers, streamKey, host]);
  return mediaSrcObject;
};
