"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useQuery } from "@/shared/hooks/gql";
import { sendNotification } from "@/pwa/notifications";
import { useEffect } from "react";
const ROOM_CHAT = gql(`
  query RoomChatQuery($roomId: String!, $channelId: String!, $cursor: String) {
    chatArchive(roomId: $roomId, channelId: $channelId, cursor: $cursor) {
      forwardCursor
      backCursor
      results {
        id
        user {
          id
          isSubscribed
          username
          displayName
          avatarUrl
        }
        member {
          id
          role
        }
        body
        time
      }
    }
    room(id: $roomId) {
      id
      state {
        members {
          id
        }
      }
    }
  }
`);
const NEW_MESSAGE = gql(`
  subscription NewMessageSubscription($roomId: String!, $channelId: String) {
    newMessage(roomId: $roomId, channelId: $channelId) {
      id
      user {
        id
        isSubscribed
        username
        displayName
        avatarUrl
      }
      member {
        id
        role
      }
      body
      time
    }
  }
`);
const MESSAGE_DELETED = gql(`
  subscription MessageDeletedSubscription($roomId: String!) {
    messageDeleted(roomId: $roomId) {
      messageId
    }
  }
`);
const ON_CLEAR_MESSAGES = gql(`
  subscription ClearMessagesSubscription($roomId: String!) {
    clearMessages(roomId: $roomId, channelId: "general") {
      ok
    }
  }
`);
export const useMessagesCached = (roomId, channelId) => {
  const { data, fetchMore, loading, networkStatus } = useQuery(ROOM_CHAT, {
    variables: { roomId, channelId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true
  });
  const nextPage = () => __async(void 0, null, function* () {
    if (loading)
      return;
    const chatArchive = (data == null ? void 0 : data.chatArchive) || {};
    if (!chatArchive.forwardCursor)
      return;
    yield fetchMore({
      variables: {
        roomId,
        channelId,
        cursor: chatArchive.forwardCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        var _a, _b, _c, _d, _e;
        if (!fetchMoreResult)
          return prev;
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev == null ? void 0 : prev.chatArchive), {
            results: (_c = (_a = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _c.concat(
              ((_b = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _b.results) || []
            ),
            forwardCursor: (_d = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _d.forwardCursor,
            backCursor: (_e = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _e.forwardCursor
          })
        });
      }
    });
  });
  return {
    data,
    nextPage,
    loading: !data || networkStatus === 3
  };
};
export default (roomId, channelId) => {
  const { subscribeToMore, data, loading } = useQuery(ROOM_CHAT, {
    variables: { roomId, channelId },
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    const unsubscribeToMessages = subscribeToMore({
      document: NEW_MESSAGE,
      variables: { roomId, channelId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a, _b;
        const MAX_MESSAGES = 100;
        const message = subscriptionData == null ? void 0 : subscriptionData.data.newMessage;
        const prevResults = ((_a = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _a.results) || [];
        const messageExists = prevResults.find(
          (m) => (m == null ? void 0 : m.id) === (message == null ? void 0 : message.id)
        );
        let updated_messages = messageExists ? prevResults : [...prevResults, message];
        if (message && document.hidden && roomId !== "lobby" && !roomId.endsWith("/lobby")) {
          sendNotification(
            (message == null ? void 0 : message.id) || "",
            "Message from " + ((_b = message == null ? void 0 : message.user) == null ? void 0 : _b.displayName),
            (message == null ? void 0 : message.body) || ""
          );
        }
        if (prevResults.length <= MAX_MESSAGES && updated_messages.length > MAX_MESSAGES) {
          updated_messages = updated_messages.slice(
            Math.max(updated_messages.length - MAX_MESSAGES, 0)
          );
        }
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: updated_messages
          })
        });
      }
    });
    const unsubscribeToMessageDeletion = subscribeToMore({
      document: MESSAGE_DELETED,
      variables: { roomId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a, _b;
        const { messageDeleted } = subscriptionData.data;
        if (!messageDeleted)
          return prev;
        const { messageId } = messageDeleted;
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: (_b = (_a = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _b.filter(
              (m) => (m == null ? void 0 : m.id) !== messageId
            )
          })
        });
      }
    });
    const unsubscribeToClearMessages = subscribeToMore({
      document: ON_CLEAR_MESSAGES,
      variables: { roomId },
      updateQuery: (prev) => {
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: []
          })
        });
      }
    });
    return () => {
      unsubscribeToClearMessages();
      unsubscribeToMessageDeletion();
      unsubscribeToMessages();
    };
  }, [subscribeToMore, roomId, channelId]);
  return {
    loading,
    data
  };
};
