"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Table = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Table = require("./Table.stylex");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const Table = exports.Table = Object.assign((0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(5);
  const {
    children,
    className
  } = t0;
  let t1;
  if ($[0] !== className) {
    t1 = [_Table.styles.table, className];
    $[0] = className;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== children || $[3] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t1
    }, children);
    $[2] = children;
    $[3] = t1;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
}), {
  Cell: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(9);
    const {
      className,
      children,
      colSpan,
      centered
    } = t0;
    const t1 = centered && _Table.styles.centered;
    let t2;
    if ($[0] !== colSpan) {
      t2 = !!colSpan && _Table.styles.colSpan(colSpan);
      $[0] = colSpan;
      $[1] = t2;
    } else {
      t2 = $[1];
    }
    let t3;
    if ($[2] !== className || $[3] !== t1 || $[4] !== t2) {
      t3 = [_Table.styles.td, t1, t2, className];
      $[2] = className;
      $[3] = t1;
      $[4] = t2;
      $[5] = t3;
    } else {
      t3 = $[5];
    }
    let t4;
    if ($[6] !== children || $[7] !== t3) {
      t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        style: t3
      }, children);
      $[6] = children;
      $[7] = t3;
      $[8] = t4;
    } else {
      t4 = $[8];
    }
    return t4;
  }),
  HeaderRow: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(9);
    const {
      className,
      children,
      onClick,
      colSpan
    } = t0;
    let t1;
    if ($[0] !== colSpan) {
      t1 = !!colSpan && _Table.styles.colSpan(colSpan);
      $[0] = colSpan;
      $[1] = t1;
    } else {
      t1 = $[1];
    }
    let t2;
    if ($[2] !== className || $[3] !== t1) {
      t2 = [_Table.styles.trHead, t1, className];
      $[2] = className;
      $[3] = t1;
      $[4] = t2;
    } else {
      t2 = $[4];
    }
    let t3;
    if ($[5] !== children || $[6] !== onClick || $[7] !== t2) {
      t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        onClick,
        style: t2
      }, children);
      $[5] = children;
      $[6] = onClick;
      $[7] = t2;
      $[8] = t3;
    } else {
      t3 = $[8];
    }
    return t3;
  }),
  Row: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(7);
    const {
      className,
      children,
      onClick
    } = t0;
    const t1 = !!onClick && _Table.styles.clickable;
    let t2;
    if ($[0] !== className || $[1] !== t1) {
      t2 = [_Table.styles.tr, t1, className];
      $[0] = className;
      $[1] = t1;
      $[2] = t2;
    } else {
      t2 = $[2];
    }
    let t3;
    if ($[3] !== children || $[4] !== onClick || $[5] !== t2) {
      t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        onClick,
        style: t2
      }, children);
      $[3] = children;
      $[4] = onClick;
      $[5] = t2;
      $[6] = t3;
    } else {
      t3 = $[6];
    }
    return t3;
  }),
  Header: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(6);
    const {
      className,
      children
    } = t0;
    let t1;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t1 = _Table.styles.paddingRight((0, _Table.getScrollbarWidth)());
      $[0] = t1;
    } else {
      t1 = $[0];
    }
    let t2;
    if ($[1] !== className) {
      t2 = [_Table.styles.thead, t1, className];
      $[1] = className;
      $[2] = t2;
    } else {
      t2 = $[2];
    }
    let t3;
    if ($[3] !== children || $[4] !== t2) {
      t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        style: t2
      }, children);
      $[3] = children;
      $[4] = t2;
      $[5] = t3;
    } else {
      t3 = $[5];
    }
    return t3;
  }),
  HeaderCell: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(9);
    const {
      className,
      children,
      colSpan,
      centered
    } = t0;
    const t1 = centered && _Table.styles.centered;
    let t2;
    if ($[0] !== colSpan) {
      t2 = !!colSpan && _Table.styles.colSpan(colSpan);
      $[0] = colSpan;
      $[1] = t2;
    } else {
      t2 = $[1];
    }
    let t3;
    if ($[2] !== className || $[3] !== t1 || $[4] !== t2) {
      t3 = [_Table.styles.td, t1, t2, className];
      $[2] = className;
      $[3] = t1;
      $[4] = t2;
      $[5] = t3;
    } else {
      t3 = $[5];
    }
    let t4;
    if ($[6] !== children || $[7] !== t3) {
      t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        style: t3
      }, children);
      $[6] = children;
      $[7] = t3;
      $[8] = t4;
    } else {
      t4 = $[8];
    }
    return t4;
  }),
  Body: (0, _react.memo)(t0 => {
    const $ = (0, _compilerRuntime.c)(5);
    const {
      children,
      className
    } = t0;
    let t1;
    if ($[0] !== className) {
      t1 = [_Table.styles.tbody, className];
      $[0] = className;
      $[1] = t1;
    } else {
      t1 = $[1];
    }
    let t2;
    if ($[2] !== children || $[3] !== t1) {
      t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        style: t1
      }, children);
      $[2] = children;
      $[3] = t1;
      $[4] = t2;
    } else {
      t2 = $[4];
    }
    return t2;
  })
});