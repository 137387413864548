"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useSubscription } from "@/shared/hooks/gql";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useIceservers } from "@/room/hooks/webrtc/p2p/useIceServers";
import { useCallback, useEffect, useMemo, useState } from "react";
const ON_RECEIVE_CANDIDATE = gql(`
  subscription WebRTCOnReceiveCandidate2(
    $roomId: String!
    $rtcChannelId: String!
  ) {
    webrtcReceiveCandidate(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      userId
      candidate
    }
  }
`);
const SEND_ANSWER = gql(`
  mutation WebRTCSendAnswer2(
    $roomId: String!
    $rtcChannelId: String!
    $userId: String!
    $localDescription: String!
  ) {
    webrtcSendAnswer(
      roomId: $roomId
      rtcChannelId: $rtcChannelId
      userId: $userId
      localDescription: $localDescription
    ) {
      ok
    }
  }
`);
const SEND_CANDIDATE = gql(`
  mutation WebRTCSendCandidate(
    $roomId: String!
    $rtcChannelId: String!
    $userId: String!
    $candidate: String!
  ) {
    webrtcSendCandidate(
      roomId: $roomId
      rtcChannelId: $rtcChannelId
      userId: $userId
      candidate: $candidate
    ) {
      ok
    }
  }
`);
const ON_RECEIVE_OFFER = gql(`
  subscription WebRTCOnReceiveOffer2($roomId: String!, $rtcChannelId: String!) {
    webrtcReceiveOffer(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      userId
      remoteDescription
    }
  }
`);
const WEBRTC_JOIN = gql(`
  mutation WebRTCJoin2($roomId: String!, $rtcChannelId: String!) {
    webrtcJoin(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      ok
    }
  }
`);
const WEBRTC_LEAVE = gql(`
  mutation WebRTCLeave2($roomId: String!, $rtcChannelId: String!) {
    webrtcLeave(roomId: $roomId, rtcChannelId: $rtcChannelId) {
      ok
    }
  }
`);
export const useWebRTCConsumer = (userId, rtcChannelId, timeout = 1e4) => {
  const [dataChannel, setDataChannel] = useState(null);
  const [stream, setStream] = useState(null);
  const [connection, setConnection] = useState(null);
  const [error, setError] = useState(null);
  const roomId = useRoomId();
  const iceServers = useIceservers();
  const [_joinChannel] = useMutation(WEBRTC_JOIN);
  const [_leaveChannel] = useMutation(WEBRTC_LEAVE);
  const [_sendAnswer] = useMutation(SEND_ANSWER);
  const [_sendCandidate] = useMutation(SEND_CANDIDATE);
  const {
    data: offerData
  } = useSubscription(ON_RECEIVE_OFFER, {
    variables: {
      roomId,
      rtcChannelId
    },
    skip: !roomId
  });
  const {
    data: candidateData
  } = useSubscription(ON_RECEIVE_CANDIDATE, {
    variables: {
      roomId,
      rtcChannelId
    },
    skip: !roomId
  });
  const joinChannel = useCallback(() => {
    _joinChannel({
      variables: {
        roomId,
        rtcChannelId
      }
    });
  }, [_joinChannel, roomId, rtcChannelId]);
  const leaveChannel = useCallback(() => _leaveChannel({
    variables: {
      roomId,
      rtcChannelId
    }
  }), [_leaveChannel, roomId, rtcChannelId]);
  const sendAnswer = useCallback((userId2, localDescription) => {
    const localDescriptionString = JSON.stringify(localDescription.toJSON());
    _sendAnswer({
      variables: {
        roomId,
        rtcChannelId,
        userId: userId2,
        localDescription: localDescriptionString
      }
    });
  }, [_sendAnswer, roomId, rtcChannelId]);
  const sendCandidate = useCallback((candidate) => _sendCandidate({
    variables: {
      roomId,
      rtcChannelId,
      userId,
      candidate: JSON.stringify(candidate)
    }
  }), [_sendCandidate, roomId, rtcChannelId, userId]);
  const newestOffer = offerData == null ? void 0 : offerData.webrtcReceiveOffer;
  const _newestCandidate = candidateData == null ? void 0 : candidateData.webrtcReceiveCandidate;
  const newestCandidate = useMemo(() => _newestCandidate ? __spreadProps(__spreadValues({}, _newestCandidate), {
    candidate: _newestCandidate.candidate ? JSON.parse(_newestCandidate.candidate) : null
  }) : null, [_newestCandidate]);
  useEffect(() => {
    if (connection && newestOffer) {
      ;
      (() => __async(void 0, null, function* () {
        const {
          remoteDescription: remoteDescriptionString
        } = newestOffer;
        const remoteDescription = remoteDescriptionString ? JSON.parse(remoteDescriptionString) : null;
        yield connection.setRemoteDescription(remoteDescription);
        const answer = yield connection.createAnswer();
        yield connection.setLocalDescription(answer);
        const localDescription = connection.localDescription;
        if (localDescription) {
          sendAnswer(userId, localDescription);
        } else {
          throw "localDescription missing";
        }
      }))();
    } else if (newestOffer && !connection) {
      throw "received offer before connection";
    }
  }, [newestOffer, sendAnswer, connection, userId]);
  useEffect(() => {
    if (newestCandidate == null ? void 0 : newestCandidate.candidate) {
      if (connection) {
        if (newestCandidate.userId === userId) {
          ;
          (() => __async(void 0, null, function* () {
            const candidate = new RTCIceCandidate(newestCandidate.candidate);
            try {
              yield connection.addIceCandidate(candidate);
            } catch (e) {
              console.error(e);
            }
          }))();
        }
      } else {
        throw "received candidate before connection";
      }
    }
  }, [newestCandidate, connection, userId]);
  useEffect(() => {
    let timeoutId = null;
    if (iceServers && !connection) {
      const conn = new RTCPeerConnection({
        iceServers
      });
      setConnection(conn);
      conn.onicecandidate = ({
        candidate
      }) => {
        if (candidate)
          sendCandidate(candidate);
      };
      conn.ondatachannel = ({
        channel
      }) => setDataChannel(channel);
      conn.ontrack = (event) => {
        const streams = event.streams.filter((a) => typeof a == "object");
        const stream2 = streams.pop();
        setStream(stream2);
      };
    } else if (connection) {
      joinChannel();
      timeoutId = setTimeout(() => {
        if (connection.connectionState !== "connected") {
          setError("Connection timeout");
        }
      }, timeout);
    }
    return () => {
      if (connection) {
        connection == null ? void 0 : connection.close();
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [iceServers, connection, joinChannel, sendCandidate, timeout]);
  useEffect(() => {
    return () => {
      leaveChannel();
    };
  }, [leaveChannel]);
  return {
    connection,
    stream,
    dataChannel,
    error
  };
};
