"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColorSchemeSelector = void 0;
Object.defineProperty(exports, "DefaultTVPlaceholder", {
  enumerable: true,
  get: function () {
    return _TV.DefaultTVPlaceholder;
  }
});
Object.defineProperty(exports, "Themes", {
  enumerable: true,
  get: function () {
    return _Themes.default;
  }
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _useMediaPlayerData = require("@/room/hooks/useMediaPlayerData");
var _WebcamOverlay = _interopRequireDefault(require("@/room/components/WebcamOverlay"));
var _hooks = require("./hooks");
var _useRoomData = require("@/room/hooks/useRoomData");
var _Settings = require("@/shared/components/Settings");
var _Themes = _interopRequireDefault(require("./Themes"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _bg = _interopRequireDefault(require("./bg.svg"));
var _Sofa = _interopRequireDefault(require("./components/Sofa"));
var _TV = _interopRequireWildcard(require("./components/TV"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
var _uikit = require("@/uikit");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  root: {
    "app__styles.root": "app__styles.root",
    height: "xt7dq6l",
    width: "xh8yej3",
    maxWidth: "x193iq5w",
    maxHeight: "xmz0i5r",
    position: "x1n2onr6",
    backgroundRepeat: "xiy17q3",
    backgroundPosition: "x1xsqp64",
    backgroundPositionX: null,
    backgroundPositionY: null,
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    aspectRatio: "xgwsg93",
    backgroundSize: "x18d0r48",
    "@media (min-aspect-ratio: 16/9)_height": "x76f1ws",
    "@media (min-aspect-ratio: 16/9)_width": "x1otbfl8",
    $$css: true
  },
  fullScreenRoot: {
    "app__styles.fullScreenRoot": "app__styles.fullScreenRoot",
    position: "x10l6tqk",
    aspectRatio: "x3kh6z4",
    height: "x5yr21d",
    width: "xh8yej3",
    "@media (min-aspect-ratio: 16/9)_height": "x76f1ws",
    "@media (min-aspect-ratio: 16/9)_width": "x1x33od3",
    $$css: true
  },
  defaultBg: {
    "app__styles.defaultBg": "app__styles.defaultBg",
    backgroundColor: "x8qxh4v",
    backgroundBlendMode: "xgddvq7",
    $$css: true
  },
  setBackground: bg => [{
    "app__styles.setBackground": "app__styles.setBackground",
    backgroundImage: "x1g0zcm7",
    $$css: true
  }, {
    "--backgroundImage": `url(${bg})` != null ? `url(${bg})` : "initial"
  }]
};
const ColorSchemeSelector = () => {
  const spacesState = (0, _useRoomData.useSpacesStateCached)();
  const {
    setValue
  } = (0, _hooks.useSpacesActions)();
  return /* @__PURE__ */_react.default.createElement(_Settings.ColorSelector, {
    selectedColor: (spacesState == null ? void 0 : spacesState.colorScheme) || null,
    includeEmpty: true,
    setColorScheme: id => setValue("colorScheme", id || null)
  });
};
exports.ColorSchemeSelector = ColorSchemeSelector;
var _default = exports.default = (0, _react.memo)(() => {
  const {
    mediaPlayerState
  } = (0, _useMediaPlayerData.useMediaPlayerStateCached)();
  const {
    setValue
  } = (0, _hooks.useSpacesActions)();
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  const setFullscreen = (0, _react.useMemo)(() => val => {
    setValue("fullscreen", val);
  }, [setValue]);
  const spacesState = (0, _useRoomData.useSpacesStateCached)();
  if (!spacesState) {
    return null;
  }
  const fullScreen = spacesState.fullscreen === 1 && Boolean(mediaPlayerState == null ? void 0 : mediaPlayerState.url) || isMobile && !isLandscape;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.root, fullScreen && styles.fullScreenRoot, !spacesState.background && styles.defaultBg, !(isMobile && !isLandscape) && styles.setBackground(spacesState.background || _bg.default)]
  }, /* @__PURE__ */_react.default.createElement(_TV.default, {
    fullScreen,
    setFullscreen
  }), spacesState.enableSofa !== false && !fullScreen && !(isMobile && !isLandscape) && /* @__PURE__ */_react.default.createElement(_Sofa.default, {
    backgroundImage: spacesState.sofa
  }), /* @__PURE__ */_react.default.createElement(_WebcamOverlay.default, {
    hidden: isMobile && !isLandscape || !fullScreen && spacesState.enableSofa !== false
  }));
});