"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { lazy, memo, Suspense, useEffect } from "react";
import { Route, Switch, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import AVEngine from "@/services/AVEngine";
import CreateRoomPage from "@/pages/createRoom";
import Dashboard from "@/pages/dashboard";
import MobileHandler from "@/pages/mobileApp";
import RoomPage from "@/room";
import VerifyEmailPage from "@/pages/verifyEmail";
import Welcome from "@/pages/welcome";
import InviteSignupPage from "@/pages/signup";
const GraphiQL = lazy(() => import(
  /* webpackChunkName: "graphiql" */
  "@/pages/graphql"
));
let hasNavigated = false;
const inPwa = ["fullscreen", "standalone", "minimal-ui"].some((displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches) || "standalone" in window.navigator && window.navigator.standalone;
function usePageListener() {
  const location2 = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (inPwa && location2.pathname === "/" && !hasNavigated) {
      const prevLocation = window.localStorage.getItem("prevLocation");
      if (prevLocation) {
        history.replace(prevLocation);
      }
    }
    if (location2.pathname !== "/") {
      window.localStorage.setItem("prevLocation", location2.pathname);
    }
    hasNavigated = true;
  }, [history, location2.pathname]);
}
const RoomRoute = memo((props) => {
  const computedMatch = __spreadValues({}, props.computedMatch);
  if (location.host.endsWith(".kosmi.business")) {
    const realm = location.host.split(".kosmi.business")[0];
    if (computedMatch.params) {
      computedMatch.params.room = `@${realm}/` + computedMatch.params.room;
    }
  }
  const updatedProps = __spreadProps(__spreadValues({}, props), {
    computedMatch
  });
  return /* @__PURE__ */ React.createElement(Route, __spreadValues({}, updatedProps));
});
const RoomRedirect = () => {
  const {
    room
  } = useParams();
  return /* @__PURE__ */ React.createElement(Redirect, { to: `/room/${room}` });
};
export const RouterListeners = () => {
  usePageListener();
  useEffect(() => {
    document.addEventListener("click", () => {
      if (AVEngine.getAudioContextState() === "suspended")
        AVEngine.resumeAudioContext();
    }, false);
  }, []);
  return null;
};
export default memo(() => {
  return /* @__PURE__ */ React.createElement(Suspense, { fallback: null }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { path: "/createRoom", component: CreateRoomPage }), /* @__PURE__ */ React.createElement(Route, { path: "/dashboard4815162342", component: Dashboard }), /* @__PURE__ */ React.createElement(Route, { path: "/graphql", component: GraphiQL }), /* @__PURE__ */ React.createElement(Route, { path: "/mobileApp", component: MobileHandler }), /* @__PURE__ */ React.createElement(RoomRoute, { path: "/signup/:inviteCode", component: InviteSignupPage }), /* @__PURE__ */ React.createElement(Route, { path: "/verifyEmail/:email/:code", component: VerifyEmailPage }), /* @__PURE__ */ React.createElement(RoomRoute, { path: "/room/:room", component: RoomPage }), /* @__PURE__ */ React.createElement(RoomRoute, { path: "/:room", component: RoomRedirect }), /* @__PURE__ */ React.createElement(Route, { path: "/", component: Welcome }), ";"));
});
