"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useWindowSize = _interopRequireDefault(require("./useWindowSize"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  zoomed: zoom => [{
    "useAutoZoomForHighresolutions__styles.zoomed": "useAutoZoomForHighresolutions__styles.zoomed",
    zoom: "x1swctcs",
    $$css: true
  }, {
    "--zoom": zoom != null ? zoom : "initial"
  }]
};
var _default = () => {
  const windowSize = (0, _useWindowSize.default)();
  const isWideScreen = windowSize.width > windowSize.height;
  const maxWindowHeightBeforeScaling = 1e3;
  const ratioOf1000 = isWideScreen && windowSize.height > maxWindowHeightBeforeScaling ? windowSize.height / maxWindowHeightBeforeScaling : 1;
  return styles.zoomed(ratioOf1000);
};
exports.default = _default;