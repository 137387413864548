"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react2 = require("@floating-ui/react");
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  tooltip: {
    "Tooltip__styles.tooltip": "Tooltip__styles.tooltip",
    minWidth: "x13qp9f6",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    backgroundColor: "xa2rin4",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    boxShadow: "xvxy23r",
    lineHeight: "x1kq7lva",
    maxWidth: "x256u9z",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontWeight: "x1fcty0u",
    fontStyle: "x1j61x8r",
    color: "x11jfisy",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    zIndex: "x1vjfegm",
    $$css: true
  }
};
var _default = t0 => {
  const $ = (0, _compilerRuntime.c)(57);
  const {
    ref,
    trigger,
    content,
    position,
    mountNode,
    controlled,
    onClick,
    open,
    disabled,
    className,
    onClickOutside
  } = t0;
  const [isOpen, setIsOpen] = _react.default.useState(false);
  let placement = "top";
  if (position === "top center") {
    placement = "top-start";
  }
  if (position === "left") {
    placement = "left";
  }
  if (position === "bottom center") {
    placement = "bottom-start";
  }
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [(0, _react2.offset)(5), (0, _react2.flip)({
      fallbackAxisSideDirection: "start"
    }), (0, _react2.shift)()];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== isOpen || $[2] !== placement) {
    t2 = {
      open: isOpen,
      onOpenChange: setIsOpen,
      placement,
      whileElementsMounted: _react2.autoUpdate,
      middleware: t1
    };
    $[1] = isOpen;
    $[2] = placement;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const {
    refs,
    floatingStyles,
    context
  } = (0, _react2.useFloating)(t2);
  let t3;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      move: false
    };
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  const hover = (0, _react2.useHover)(context, t3);
  const focus = (0, _react2.useFocus)(context);
  const dismiss = (0, _react2.useDismiss)(context);
  let t4;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      role: "tooltip"
    };
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const role = (0, _react2.useRole)(context, t4);
  let t5;
  if ($[6] !== controlled || $[7] !== dismiss || $[8] !== focus || $[9] !== hover || $[10] !== role) {
    t5 = controlled ? [] : [hover, focus, dismiss, role];
    $[6] = controlled;
    $[7] = dismiss;
    $[8] = focus;
    $[9] = hover;
    $[10] = role;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  const {
    getReferenceProps,
    getFloatingProps
  } = (0, _react2.useInteractions)(t5);
  const mergedRefs = (0, _helpers.useMergedRefs)(trigger.props.ref, ref, refs.setReference);
  let t6;
  if ($[12] !== getReferenceProps || $[13] !== mergedRefs || $[14] !== trigger) {
    let t72;
    if ($[16] !== getReferenceProps) {
      t72 = getReferenceProps();
      $[16] = getReferenceProps;
      $[17] = t72;
    } else {
      t72 = $[17];
    }
    let t82;
    if ($[18] !== mergedRefs || $[19] !== t72) {
      t82 = __spreadValues({
        ref: mergedRefs
      }, t72);
      $[18] = mergedRefs;
      $[19] = t72;
      $[20] = t82;
    } else {
      t82 = $[20];
    }
    t6 = _react.default.cloneElement(trigger, t82);
    $[12] = getReferenceProps;
    $[13] = mergedRefs;
    $[14] = trigger;
    $[15] = t6;
  } else {
    t6 = $[15];
  }
  const enhancedChild = t6;
  let t7;
  if ($[21] !== content) {
    t7 = typeof content === "string" ? /* @__PURE__ */_react.default.createElement(_Html.default.div, null, content) : content;
    $[21] = content;
    $[22] = t7;
  } else {
    t7 = $[22];
  }
  const contentComponent = t7;
  const mergedContentRef = (0, _helpers.useMergedRefs)(contentComponent.props.ref, refs.setFloating);
  let t8;
  if ($[23] !== className || $[24] !== contentComponent || $[25] !== floatingStyles || $[26] !== getFloatingProps || $[27] !== mergedContentRef || $[28] !== onClick) {
    let t92;
    if ($[30] !== floatingStyles) {
      t92 = __spreadValues({}, floatingStyles);
      $[30] = floatingStyles;
      $[31] = t92;
    } else {
      t92 = $[31];
    }
    let t102;
    if ($[32] !== className) {
      t102 = [styles.tooltip, className];
      $[32] = className;
      $[33] = t102;
    } else {
      t102 = $[33];
    }
    let t112;
    if ($[34] !== getFloatingProps) {
      t112 = getFloatingProps();
      $[34] = getFloatingProps;
      $[35] = t112;
    } else {
      t112 = $[35];
    }
    let t12;
    if ($[36] !== mergedContentRef || $[37] !== onClick || $[38] !== t102 || $[39] !== t112 || $[40] !== t92) {
      t12 = __spreadValues({
        ref: mergedContentRef,
        styleProps: t92,
        style: t102,
        onClick
      }, t112);
      $[36] = mergedContentRef;
      $[37] = onClick;
      $[38] = t102;
      $[39] = t112;
      $[40] = t92;
      $[41] = t12;
    } else {
      t12 = $[41];
    }
    t8 = _react.default.cloneElement(contentComponent, t12);
    $[23] = className;
    $[24] = contentComponent;
    $[25] = floatingStyles;
    $[26] = getFloatingProps;
    $[27] = mergedContentRef;
    $[28] = onClick;
    $[29] = t8;
  } else {
    t8 = $[29];
  }
  const enhancedContent = t8;
  const _open = controlled ? open : isOpen;
  let t9;
  if ($[42] !== refs.floating || $[43] !== refs.reference) {
    t9 = [refs.floating, refs.reference];
    $[42] = refs.floating;
    $[43] = refs.reference;
    $[44] = t9;
  } else {
    t9 = $[44];
  }
  (0, _helpers.useOnClickOutside)(t9, onClickOutside || _temp);
  const disabledRef = (0, _helpers.useMergedRefs)(ref);
  if (disabled) {
    let t102;
    if ($[45] !== disabledRef || $[46] !== trigger) {
      let t112;
      if ($[48] !== disabledRef) {
        t112 = {
          ref: disabledRef
        };
        $[48] = disabledRef;
        $[49] = t112;
      } else {
        t112 = $[49];
      }
      t102 = _react.default.cloneElement(trigger, t112);
      $[45] = disabledRef;
      $[46] = trigger;
      $[47] = t102;
    } else {
      t102 = $[47];
    }
    return t102;
  }
  let t10;
  if ($[50] !== _open || $[51] !== enhancedContent || $[52] !== mountNode) {
    t10 = _open && /* @__PURE__ */_react.default.createElement(_react2.FloatingPortal, {
      root: mountNode || void 0
    }, enhancedContent);
    $[50] = _open;
    $[51] = enhancedContent;
    $[52] = mountNode;
    $[53] = t10;
  } else {
    t10 = $[53];
  }
  let t11;
  if ($[54] !== enhancedChild || $[55] !== t10) {
    t11 = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, enhancedChild, t10);
    $[54] = enhancedChild;
    $[55] = t10;
    $[56] = t11;
  } else {
    t11 = $[56];
  }
  return t11;
};
exports.default = _default;
function _temp() {}