"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Form = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Input = require("./Input");
var _Select = require("./Select");
var _Button = require("./Button");
var _Checkbox = require("./Checkbox");
var _TextArea = require("./TextArea");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const styles = {
  form: {
    "Form__styles.form": "Form__styles.form",
    textAlign: "xdpxx8g",
    userSelect: "x87ps6o",
    color: "x11jfisy",
    lineHeight: "x37zpob",
    boxSizing: "x1p6iq4g",
    textSizeAdjust: "x1javzw2",
    touchAction: "xggy1nq",
    position: "x1n2onr6",
    maxWidth: "x193iq5w",
    padding: "xggk2y7",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontSize: "x1jchvi3",
    $$css: true
  },
  grouped: {
    "Form__styles.grouped": "Form__styles.grouped",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    justifyContent: "x1nhvcw1",
    "@media (max-width: 1000px)_flexDirection": "xm6fj9k",
    $$css: true
  },
  label: {
    "Form__styles.label": "Form__styles.label",
    paddingBottom: "x1a8lsjc",
    whiteSpace: "xuxw1ft",
    fontWeight: "x117nqv4",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inlineLabel: {
    "Form__styles.inlineLabel": "Form__styles.inlineLabel",
    paddingBottom: "x18d9i69",
    whiteSpace: "xuxw1ft",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  field: {
    "Form__styles.field": "Form__styles.field",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    clear: "x18xfj1p",
    color: "x11jfisy",
    flex: "x12lumcd",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    marginTop: "xr9ek0c",
    marginRight: "x1yf7rl7",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "x12nagc",
    marginLeft: "xj3b58b",
    padding: "x1i3ajwb",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  disabled: {
    "Form__styles.disabled": "Form__styles.disabled",
    pointerEvents: "x47corl",
    opacity: "xyd83as",
    $$css: true
  },
  required: {
    "Form__styles.required": "Form__styles.required",
    "::after_display": "x1e1aq9x",
    "::after_verticalAlign": "xc5i1tb",
    $$css: true
  },
  vertical: {
    "Form__styles.vertical": "Form__styles.vertical",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  inline: {
    "Form__styles.inline": "Form__styles.inline",
    display: "x3nfvp2",
    $$css: true
  },
  formErrorLabel: {
    "Form__styles.formErrorLabel": "Form__styles.formErrorLabel",
    color: "x19lk4tq",
    $$css: true
  },
  formErrorInputIcon: {
    "Form__styles.formErrorInputIcon": "Form__styles.formErrorInputIcon",
    borderColor: "x1bmaitd",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  equal: {
    "Form__styles.equal": "Form__styles.equal",
    $$css: true
  },
  error: {
    "Form__styles.error": "Form__styles.error",
    $$css: true
  },
  small: {
    "Form__styles.small": "Form__styles.small",
    fontSize: "xrd5znx",
    $$css: true
  }
};
const Group = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(11);
  const {
    children,
    className,
    inline,
    label
  } = t0;
  const t1 = !inline && styles.vertical;
  const t2 = inline && styles.inline;
  let t3;
  if ($[0] !== className || $[1] !== t1 || $[2] !== t2) {
    t3 = [styles.field, styles.grouped, t1, t2, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] !== inline || $[5] !== label) {
    t4 = label && /* @__PURE__ */_react.default.createElement(_Html.default.label, {
      style: [styles.label, inline && styles.inlineLabel]
    }, label);
    $[4] = inline;
    $[5] = label;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== children || $[8] !== t3 || $[9] !== t4) {
    t5 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t3
    }, t4, children);
    $[7] = children;
    $[8] = t3;
    $[9] = t4;
    $[10] = t5;
  } else {
    t5 = $[10];
  }
  return t5;
});
const Field = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(8);
  const {
    children,
    disabled,
    required,
    className,
    onClick
  } = t0;
  const t1 = disabled && styles.disabled;
  const t2 = required && styles.required;
  let t3;
  if ($[0] !== className || $[1] !== t1 || $[2] !== t2) {
    t3 = [styles.field, t1, t2, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] !== children || $[5] !== onClick || $[6] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: t3
    }, children);
    $[4] = children;
    $[5] = onClick;
    $[6] = t3;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  return t4;
});
const FormSelect = (0, _react.memo)(props => {
  const $ = (0, _compilerRuntime.c)(2);
  let t0;
  if ($[0] !== props) {
    t0 = /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Select.Select, __spreadValues({}, props)));
    $[0] = props;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  return t0;
});
const FormButton = (0, _react.memo)(props => {
  const $ = (0, _compilerRuntime.c)(2);
  let t0;
  if ($[0] !== props) {
    t0 = /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Button.Button, __spreadValues({}, props)));
    $[0] = props;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  return t0;
});
const FormInput = (0, _react.memo)(t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(6);
  let props;
  let ref;
  if ($[0] !== t0) {
    _a = t0, {
      ref
    } = _a, props = __objRest(_a, ["ref"]);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] !== props || $[4] !== ref) {
    t1 = /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Input.Input, __spreadProps(__spreadValues({}, props), {
      ref
    })));
    $[3] = props;
    $[4] = ref;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  return t1;
});
const FormLabel = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(2);
  const {
    children
  } = t0;
  let t1;
  if ($[0] !== children) {
    t1 = /* @__PURE__ */_react.default.createElement(_Html.default.label, {
      style: styles.label
    }, children);
    $[0] = children;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
});
const FormTextArea = (0, _react.memo)(props => {
  const $ = (0, _compilerRuntime.c)(2);
  let t0;
  if ($[0] !== props) {
    t0 = /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_TextArea.TextArea, __spreadValues({}, props)));
    $[0] = props;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  return t0;
});
const FormCheckbox = (0, _react.memo)(props => {
  const $ = (0, _compilerRuntime.c)(2);
  let t0;
  if ($[0] !== props) {
    t0 = /* @__PURE__ */_react.default.createElement(Field, null, /* @__PURE__ */_react.default.createElement(_Checkbox.Checkbox, __spreadValues({}, props)));
    $[0] = props;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  return t0;
});
const Form = exports.Form = Object.assign((0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(12);
  const {
    children,
    className,
    onSubmit,
    autoComplete,
    size,
    disabled,
    error
  } = t0;
  const t1 = size && styles[size];
  const t2 = disabled && styles.disabled;
  const t3 = error && styles.error;
  let t4;
  if ($[0] !== className || $[1] !== t1 || $[2] !== t2 || $[3] !== t3) {
    t4 = [styles.form, t1, t2, t3, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  let t5;
  if ($[5] !== onSubmit) {
    t5 = onSubmit ? e => {
      e.preventDefault();
      onSubmit(e);
    } : void 0;
    $[5] = onSubmit;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  let t6;
  if ($[7] !== autoComplete || $[8] !== children || $[9] !== t4 || $[10] !== t5) {
    t6 = /* @__PURE__ */_react.default.createElement(_Html.default.form, {
      style: t4,
      onSubmit: t5,
      autoComplete
    }, children);
    $[7] = autoComplete;
    $[8] = children;
    $[9] = t4;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  return t6;
}), {
  Select: FormSelect,
  Button: FormButton,
  Input: FormInput,
  InputWrapper: _Input.InputWrapper,
  Group,
  Label: FormLabel,
  Field,
  TextArea: FormTextArea,
  Checkbox: FormCheckbox
});