"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "@/shared/hooks/gql";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../shared/components/LoadingScreen";
const CreateRoomPage = () => {
  const [createRoom] = useMutation(CREATE_ROOM_MUTATION);
  const history = useHistory();
  useEffect(() => {
    const app = location && location.search && queryString.parse(location.search).app || "SPACES";
    const _createRoom = () => __async(void 0, null, function* () {
      var _a, _b;
      const result = yield createRoom({
        variables: { appName: app, public: false }
      });
      const roomId = (_b = (_a = result == null ? void 0 : result.data) == null ? void 0 : _a.createRoom) == null ? void 0 : _b.id;
      window.localStorage.setItem("AppMenuTooltipSeen", "true");
      history.replace("/room/" + roomId);
    });
    _createRoom();
  }, [createRoom, history]);
  return /* @__PURE__ */ React.createElement(LoadingScreen, { key: "loader", text: "Creating a Room" });
};
const CREATE_ROOM_MUTATION = gql(`
  mutation CreateRoomMutation1(
    $appName: RoomApp
    $description: String
    $public: Boolean!
  ) {
    createRoom(
      appName: $appName
      description: $description
      public: $public
      promoteNewUsersToAdmin: true
    ) {
      id
    }
  }
`);
export default CreateRoomPage;
