"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react2 = require("@floating-ui/react");
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  tooltip: {
    "Tooltip__styles.tooltip": "Tooltip__styles.tooltip",
    minWidth: "x13qp9f6",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    backgroundColor: "xa2rin4",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    boxShadow: "xvxy23r",
    lineHeight: "x1kq7lva",
    maxWidth: "x256u9z",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontWeight: "x1fcty0u",
    fontStyle: "x1j61x8r",
    color: "x11jfisy",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    zIndex: "x1vjfegm",
    $$css: true
  }
};
var _default = exports.default = (0, _react.forwardRef)(({
  trigger,
  content,
  position,
  mountNode,
  controlled,
  onClick,
  open,
  disabled,
  className,
  onClickOutside
}, ref) => {
  const [isOpen, setIsOpen] = _react.default.useState(false);
  let placement = "top";
  if (position === "top center") {
    placement = "top-start";
  }
  if (position === "left") {
    placement = "left";
  }
  const {
    refs,
    floatingStyles,
    context
  } = (0, _react2.useFloating)({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: _react2.autoUpdate,
    middleware: [(0, _react2.offset)(5), (0, _react2.flip)({
      fallbackAxisSideDirection: "start"
    }), (0, _react2.shift)()]
  });
  const hover = (0, _react2.useHover)(context, {
    move: false
  });
  const focus = (0, _react2.useFocus)(context);
  const dismiss = (0, _react2.useDismiss)(context);
  const role = (0, _react2.useRole)(context, {
    role: "tooltip"
  });
  const {
    getReferenceProps,
    getFloatingProps
  } = (0, _react2.useInteractions)(controlled ? [] : [hover, focus, dismiss, role]);
  const mergedRefs = (0, _helpers.useMergedRefs)(trigger.ref, ref, refs.setReference);
  const enhancedChild = _react.default.cloneElement(trigger, __spreadValues({
    ref: mergedRefs
  }, getReferenceProps()));
  const contentComponent = typeof content === "string" ? /* @__PURE__ */_react.default.createElement(_Html.default.div, null, content) : content;
  const enhancedContent = _react.default.cloneElement(contentComponent, __spreadValues({
    ref: (0, _helpers.mergeRefs)(contentComponent.ref, refs.setFloating),
    styleProps: __spreadValues({}, floatingStyles),
    style: [styles.tooltip, className],
    onClick
  }, getFloatingProps()));
  const _open = controlled ? open : isOpen;
  (0, _helpers.useOnClickOutside)([refs.floating, refs.reference], onClickOutside || (() => {}));
  const disabledRef = (0, _helpers.useMergedRefs)(ref);
  if (disabled) return _react.default.cloneElement(trigger, {
    ref: disabledRef
  });
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, enhancedChild, _open && /* @__PURE__ */_react.default.createElement(_react2.FloatingPortal, {
    root: mountNode || void 0
  }, enhancedContent));
});