"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import platform from "platform";
import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";
export const useIsMobile = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1000px)"
  });
  const isIOS = platform.os && platform.os.family === "iOS";
  const isLandscape = useMediaQuery({
    query: (isIOS ? "" : "(min-aspect-ratio: 16/10) and ") + "(orientation:landscape) and (max-height: 625px)"
  });
  return { isIOS, isMobile, isLandscape };
};
export default (Component) => (
  // eslint-disable-next-line
  memo((props) => {
    const { isMobile, isLandscape } = useIsMobile();
    return /* @__PURE__ */ React.createElement(Component, __spreadValues({ isMobile, isLandscape }, props));
  })
);
