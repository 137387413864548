"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "@/shared/hooks/gql";
import { Button, Icon, Table } from "@/uikit";
import moment from "moment-mini";
import React, { useEffect } from "react";
import { useIsAdmin } from "@/room/hooks/useIsAdmin";
import { useRoomId } from "@/room/hooks/useRoomId";
import { withDialogs } from "@/shared/hooks/withDialogs";
const UNBAN_USER = gql(`
  mutation UnbanUser1($roomId: String!, $id: String!) {
    unbanIpFromRoom(roomId: $roomId, id: $id) {
      ok
    }
  }
`);
const ON_BAN_UPDATE = gql(`
  subscription IPBanListUpdate($roomId: String!) {
    ipBanlistUpdate(roomId: $roomId) {
      id
      timestamp
    }
  }
`);
const GET_BANNED_IPS = gql(`
  query GetBannedIPs($roomId: String!) {
    ipBans(roomId: $roomId) {
      id
      timestamp
    }
  }
`);
export const BannedIps = withDialogs(({
  confirm
}) => {
  const roomId = useRoomId();
  const isAdmin = useIsAdmin();
  const [unban] = useMutation(UNBAN_USER);
  const {
    data,
    subscribeToMore
  } = useQuery(GET_BANNED_IPS, {
    variables: {
      roomId
    }
  });
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ON_BAN_UPDATE,
      variables: {
        roomId
      },
      updateQuery: (_prev, {
        subscriptionData: {
          data: data2
        }
      }) => ({
        ipBans: data2.ipBanlistUpdate
      })
    });
    return () => unsubscribe();
  }, [subscribeToMore, roomId]);
  const members = data ? data.ipBans : [];
  return /* @__PURE__ */ React.createElement(Table, { striped: true }, /* @__PURE__ */ React.createElement(Table.Body, null, members == null ? void 0 : members.map((member, i) => {
    return /* @__PURE__ */ React.createElement(Table.Row, { key: i }, /* @__PURE__ */ React.createElement(Table.Cell, null, "XX.XX.XX.XX"), /* @__PURE__ */ React.createElement(Table.Cell, null, "Banned ", moment.unix((member == null ? void 0 : member.timestamp) || 0).format("LLLL")), isAdmin && /* @__PURE__ */ React.createElement(Table.Cell, null, /* @__PURE__ */ React.createElement(Button, { inverted: true, color: "yellow", style: {
      margin: 5
    }, onClick: () => __async(void 0, null, function* () {
      if (confirm && (yield confirm("Are you sure you want to unban this IP?"))) {
        yield unban({
          variables: {
            roomId,
            id: (member == null ? void 0 : member.id) || ""
          }
        });
      }
    }) }, /* @__PURE__ */ React.createElement(Icon, { name: "remove user" }), "Unban")));
  })));
});
