"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _via_tenor_logo_white = _interopRequireDefault(require("./via_tenor_logo_white.svg"));
var _useTenorGifs = _interopRequireDefault(require("./useTenorGifs"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const styles = {
  gifPicker: {
    "EmojiPicker__styles.gifPicker": "EmojiPicker__styles.gifPicker",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  gifPickerInner: {
    "EmojiPicker__styles.gifPickerInner": "EmojiPicker__styles.gifPickerInner",
    flex: "x1b0bnr5",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "x1oysuqx",
    margin: "x1d527b7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "x1fqp7bg",
    height: "x1gnnpzl",
    minHeight: "x2lwn1j",
    $$css: true
  },
  gifSearch: {
    "EmojiPicker__styles.gifSearch": "EmojiPicker__styles.gifSearch",
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  poweredByTenor: {
    "EmojiPicker__styles.poweredByTenor": "EmojiPicker__styles.poweredByTenor",
    width: "xefyazp",
    height: "x17rw0jw",
    maxWidth: "xazcve0",
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  gifPickerInnerWrapper: {
    "EmojiPicker__styles.gifPickerInnerWrapper": "EmojiPicker__styles.gifPickerInnerWrapper",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    maxHeight: "xkgu5aw",
    $$css: true
  },
  gifs: {
    "EmojiPicker__styles.gifs": "EmojiPicker__styles.gifs",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    textAlign: "x2b8uid",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    $$css: true
  },
  image: {
    "EmojiPicker__styles.image": "EmojiPicker__styles.image",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "x1oysuqx",
    cursor: "x1ypdohk",
    $$css: true
  }
};
const GifPicker = (0, _react.forwardRef)(({
  onGifSelect
}, ref) => {
  const [searchString, setSearchString] = (0, _react.useState)("");
  const {
    results
  } = (0, _useTenorGifs.default)(searchString);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    ref,
    style: styles.gifPicker
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.gifPickerInnerWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.gifPickerInner
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    placeholder: "Search GIFs...",
    debounce: 1e3,
    className: styles.gifSearch,
    onChange: e => {
      const value = e.target.value;
      setSearchString(value);
    }
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.img, {
    style: [styles.poweredByTenor],
    src: _via_tenor_logo_white.default
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.gifs
  }, results.map((gif, i) => /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    key: i,
    className: styles.image,
    src: gif,
    onClick: () => {
      if (onGifSelect) {
        onGifSelect(gif);
      }
    }
  }))));
});
const InnerEmojiPicker = ({
  onSelect
}) => {
  const pickerRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    const picker = pickerRef.current;
    picker.className = "dark";
    picker.addEventListener("emoji-click", event => {
      onSelect({
        native: event.detail.unicode
      });
    });
    const style = document.createElement("style");
    style.textContent = ".favorites.emoji-menu {display: none !important;}";
    picker.shadowRoot.appendChild(style);
  }, [onSelect]);
  return /* @__PURE__ */_react.default.createElement("emoji-picker", {
    ref: pickerRef,
    style: {
      width: "100%",
      height: "100%"
    }
  });
};
const EmojiPicker = (0, _react.forwardRef)(({
  hideGifPicker,
  onGifSelect,
  onSelect
}, ref) => {
  if (isMobile) {
    return /* @__PURE__ */_react.default.createElement(GifPicker, {
      onGifSelect
    });
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.TabView, {
    ref
  }, /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "Emojis"
  }, /* @__PURE__ */_react.default.createElement(InnerEmojiPicker, {
    onSelect
  })), !hideGifPicker && /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "GIFs"
  }, /* @__PURE__ */_react.default.createElement(GifPicker, {
    onGifSelect
  })));
});
var _default = exports.default = EmojiPicker;