"use strict";
export function getShorthandTime(timestamp) {
  const currentTime = Math.floor(Date.now() / 1e3);
  const timePassed = Math.abs(currentTime - timestamp);
  if (timePassed < 3600) {
    return `${Math.floor(timePassed / 60)}m`;
  } else if (timePassed < 86400) {
    return `${Math.floor(timePassed / 3600)}h`;
  } else {
    return `${Math.floor(timePassed / 86400)}d`;
  }
}
