"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _web = require("@react-spring/web");
var _react2 = require("@use-gesture/react");
var _usehooksTs = require("usehooks-ts");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  throwable: {
    "Throwable__styles.throwable": "Throwable__styles.throwable",
    position: "x10l6tqk",
    touchAction: "x5ve5x3",
    pointerEvents: "x71s49j",
    $$css: true
  }
};
const ThrowableContext = (0, _react.createContext)({
  containerWidth: 0,
  containerHeight: 0,
  containerOffset: [0, 0]
});
const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};
const AnimatedDiv = _web.animated.div;
const ThrowableInner = t0 => {
  const $ = (0, _compilerRuntime.c)(13);
  const {
    ref,
    children,
    className,
    x,
    y,
    width,
    height,
    dragging,
    animate
  } = t0;
  const t1 = `translate3d(${x}px, ${y}px, 0)`;
  let t2;
  if ($[0] !== height || $[1] !== t1 || $[2] !== width) {
    t2 = {
      width,
      height,
      transform: t1
    };
    $[0] = height;
    $[1] = t1;
    $[2] = width;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const t3 = dragging || animate === false ? 0 : 100;
  let t4;
  if ($[4] !== t3) {
    t4 = {
      duration: t3
    };
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== t2 || $[7] !== t4) {
    t5 = {
      to: t2,
      config: t4
    };
    $[6] = t2;
    $[7] = t4;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const sizeSpringStyles = (0, _web.useSpring)(t5);
  let t6;
  if ($[9] !== children || $[10] !== className || $[11] !== sizeSpringStyles) {
    t6 = /* @__PURE__ */_react.default.createElement(AnimatedDiv, {
      ref,
      className,
      style: sizeSpringStyles
    }, children);
    $[9] = children;
    $[10] = className;
    $[11] = sizeSpringStyles;
    $[12] = t6;
  } else {
    t6 = $[12];
  }
  return t6;
};
const Throwable = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(34);
  const {
    children,
    className: classNameProp,
    disabled,
    size,
    animate
  } = t0;
  let t1;
  if ($[0] !== classNameProp) {
    t1 = stylex.props(...(Array.isArray(classNameProp) ? classNameProp : [classNameProp]), styles.throwable);
    $[0] = classNameProp;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const {
    className
  } = t1;
  const {
    containerWidth,
    containerHeight,
    containerOffset
  } = (0, _react.useContext)(ThrowableContext);
  const containerOffsetX = containerOffset[0];
  const containerOffsetY = containerOffset[1];
  const [percentageX, setPercentageX] = (0, _react.useState)(50);
  const [percentageY, setPercentageY] = (0, _react.useState)(50);
  const ref = (0, _react.useRef)(null);
  const [dragging, setDragging] = (0, _react.useState)(false);
  let w = size[0];
  let h = size[1];
  if (typeof w === "string" && (w == null ? void 0 : w.endsWith("%"))) {
    let t22;
    if ($[2] !== w) {
      t22 = w.replace("%", "");
      $[2] = w;
      $[3] = t22;
    } else {
      t22 = $[3];
    }
    w = t22;
    w = parseInt(w) / 100 * containerWidth;
  }
  if (typeof h === "string" && (h == null ? void 0 : h.endsWith("%"))) {
    let t22;
    if ($[4] !== h) {
      t22 = h.replace("%", "");
      $[4] = h;
      $[5] = t22;
    } else {
      t22 = $[5];
    }
    h = t22;
    h = parseInt(h) / 100 * containerHeight;
  }
  const width = parseInt(w);
  const height = parseInt(h);
  let t2;
  if ($[6] !== containerWidth || $[7] !== disabled || $[8] !== percentageX || $[9] !== width) {
    t2 = disabled ? 0 : clamp(0, percentageX / 100 * containerWidth, containerWidth - width);
    $[6] = containerWidth;
    $[7] = disabled;
    $[8] = percentageX;
    $[9] = width;
    $[10] = t2;
  } else {
    t2 = $[10];
  }
  const x = t2;
  let t3;
  if ($[11] !== containerHeight || $[12] !== disabled || $[13] !== height || $[14] !== percentageY) {
    t3 = disabled ? 0 : clamp(0, percentageY / 100 * containerHeight, Math.max(containerHeight - height, 0));
    $[11] = containerHeight;
    $[12] = disabled;
    $[13] = height;
    $[14] = percentageY;
    $[15] = t3;
  } else {
    t3 = $[15];
  }
  const y = t3;
  let t4;
  if ($[16] !== containerHeight || $[17] !== containerOffsetX || $[18] !== containerOffsetY || $[19] !== containerWidth || $[20] !== disabled || $[21] !== height || $[22] !== width) {
    t4 = t52 => {
      const {
        down,
        xy: t62,
        velocity: t72,
        direction: t8,
        elapsedTime
      } = t52;
      let [x_0, y_0] = t62;
      const [vx, vy] = t72;
      const [dx, dy] = t8;
      if (disabled) {
        return;
      }
      if (elapsedTime < 100) {
        return;
      }
      x_0 = x_0 - width / 2 - containerOffsetX;
      y_0 = y_0 - height / 2 - containerOffsetY;
      const maxX = containerWidth - width;
      const maxY = containerHeight - height;
      if (!down) {
        let endX = x_0 + vx * dx * 100;
        let endY = y_0 + vy * dy * 100;
        endX = clamp(endX, 0, maxX);
        endY = clamp(endY, 0, maxY);
        setPercentageX(Math.round(endX / containerWidth * 100));
        setPercentageY(Math.round(endY / containerHeight * 100));
        setDragging(false);
      } else {
        const newX = clamp(x_0, 0, maxX);
        const newY = clamp(y_0, 0, maxY);
        setPercentageX(Math.round(newX / containerWidth * 100));
        setPercentageY(Math.round(newY / containerHeight * 100));
        setDragging(true);
      }
    };
    $[16] = containerHeight;
    $[17] = containerOffsetX;
    $[18] = containerOffsetY;
    $[19] = containerWidth;
    $[20] = disabled;
    $[21] = height;
    $[22] = width;
    $[23] = t4;
  } else {
    t4 = $[23];
  }
  const dragListener = t4;
  let t5;
  if ($[24] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = {
      target: ref,
      filterTaps: true,
      tapsThreshold: 20,
      pointer: {
        touch: true
      }
    };
    $[24] = t5;
  } else {
    t5 = $[24];
  }
  (0, _react2.useDrag)(dragListener, t5);
  const t6 = !!animate;
  let t7;
  if ($[25] !== children || $[26] !== className || $[27] !== dragging || $[28] !== height || $[29] !== t6 || $[30] !== width || $[31] !== x || $[32] !== y) {
    t7 = /* @__PURE__ */_react.default.createElement(ThrowableInner, {
      ref,
      className,
      x,
      y,
      width,
      height,
      animate: t6,
      dragging
    }, children);
    $[25] = children;
    $[26] = className;
    $[27] = dragging;
    $[28] = height;
    $[29] = t6;
    $[30] = width;
    $[31] = x;
    $[32] = y;
    $[33] = t7;
  } else {
    t7 = $[33];
  }
  return t7;
});
const useRefWrapped = ref => {
  const $ = (0, _compilerRuntime.c)(3);
  const [storedRef, setStoredRef] = (0, _react.useState)(ref);
  let t0;
  let t1;
  if ($[0] !== ref) {
    t0 = () => {
      setStoredRef(ref);
    };
    t1 = [ref];
    $[0] = ref;
    $[1] = t0;
    $[2] = t1;
  } else {
    t0 = $[1];
    t1 = $[2];
  }
  (0, _react.useEffect)(t0, t1);
  return storedRef;
};
const ThrowableContainer = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(16);
  const {
    children,
    className
  } = t0;
  const [containerHeight, setContainerHeight] = (0, _react.useState)(0);
  const [containerWidth, setContainerWidth] = (0, _react.useState)(0);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [0, 0];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const [containerOffset, setContainerOffset] = (0, _react.useState)(t1);
  const containerRef = (0, _react.useRef)(null);
  const storedRef = useRefWrapped(containerRef);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = t32 => {
      const {
        width,
        height
      } = t32;
      const currentContainer = containerRef.current;
      setContainerWidth(width || 0);
      setContainerHeight(height || 0);
      if (currentContainer) {
        const rect = currentContainer.getBoundingClientRect();
        const offset = [rect.left + window.scrollX, rect.top + window.scrollY];
        setContainerOffset(offset);
      }
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const _onResize = t2;
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      leading: true,
      maxWait: 100
    };
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  const onResize = (0, _helpers.useDebounce)(_onResize, 100, t3);
  const t4 = storedRef;
  let t5;
  if ($[3] !== onResize || $[4] !== t4) {
    t5 = {
      ref: t4,
      box: "border-box",
      onResize
    };
    $[3] = onResize;
    $[4] = t4;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  (0, _usehooksTs.useResizeObserver)(t5);
  let t6;
  if ($[6] !== containerHeight || $[7] !== containerOffset || $[8] !== containerWidth) {
    t6 = {
      containerWidth,
      containerHeight,
      containerOffset
    };
    $[6] = containerHeight;
    $[7] = containerOffset;
    $[8] = containerWidth;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  let t7;
  if ($[10] !== children || $[11] !== className) {
    t7 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref: containerRef,
      style: className
    }, children);
    $[10] = children;
    $[11] = className;
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  let t8;
  if ($[13] !== t6 || $[14] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(ThrowableContext.Provider, {
      value: t6
    }, t7);
    $[13] = t6;
    $[14] = t7;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  return t8;
});
var _default = exports.default = Object.assign(Throwable, {
  Container: ThrowableContainer
});