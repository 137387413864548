"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
const styles = {
  defaultCursor: {
    "Seekbar__styles.defaultCursor": "Seekbar__styles.defaultCursor",
    cursor: "xt0e3qv",
    $$css: true
  },
  leftPos: pos => [{
    "Seekbar__styles.leftPos": "Seekbar__styles.leftPos",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  }, {
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(pos - (pos > 80 ? 80 : -20) + "px")
  }],
  videoProgress: {
    "Seekbar__styles.videoProgress": "Seekbar__styles.videoProgress",
    display: "x78zum5",
    position: "x1n2onr6",
    width: "xh8yej3",
    height: "x4jzdop",
    marginBottom: "xyorhqc",
    pointerEvents: "x71s49j",
    $$css: true
  },
  progress: {
    "Seekbar__styles.progress": "Seekbar__styles.progress",
    appearance: "xjyslct",
    borderRadius: "x1cum3z5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    width: "xh8yej3",
    height: "xciyald",
    pointerEvents: "x47corl",
    position: "x10l6tqk",
    top: "x13vifvy",
    transition: "xbiiimk",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "::-webkit-progress-bar_backgroundColor": "x9b4esh",
    "::-webkit-progress-bar_borderRadius": "x6f5vqo",
    "::-webkit-progress-bar_borderStartStartRadius": null,
    "::-webkit-progress-bar_borderStartEndRadius": null,
    "::-webkit-progress-bar_borderEndStartRadius": null,
    "::-webkit-progress-bar_borderEndEndRadius": null,
    "::-webkit-progress-bar_borderTopLeftRadius": null,
    "::-webkit-progress-bar_borderTopRightRadius": null,
    "::-webkit-progress-bar_borderBottomLeftRadius": null,
    "::-webkit-progress-bar_borderBottomRightRadius": null,
    "::-webkit-progress-value_background": "xqg4fcm",
    "::-webkit-progress-value_backgroundAttachment": null,
    "::-webkit-progress-value_backgroundClip": null,
    "::-webkit-progress-value_backgroundColor": null,
    "::-webkit-progress-value_backgroundImage": null,
    "::-webkit-progress-value_backgroundOrigin": null,
    "::-webkit-progress-value_backgroundPosition": null,
    "::-webkit-progress-value_backgroundPositionX": null,
    "::-webkit-progress-value_backgroundPositionY": null,
    "::-webkit-progress-value_backgroundRepeat": null,
    "::-webkit-progress-value_backgroundSize": null,
    "::-webkit-progress-value_borderRadius": "xuf63e7",
    "::-webkit-progress-value_borderStartStartRadius": null,
    "::-webkit-progress-value_borderStartEndRadius": null,
    "::-webkit-progress-value_borderEndStartRadius": null,
    "::-webkit-progress-value_borderEndEndRadius": null,
    "::-webkit-progress-value_borderTopLeftRadius": null,
    "::-webkit-progress-value_borderTopRightRadius": null,
    "::-webkit-progress-value_borderBottomLeftRadius": null,
    "::-webkit-progress-value_borderBottomRightRadius": null,
    "::-moz-progress-bar_border": "x1vqm64h",
    "::-moz-progress-bar_borderWidth": null,
    "::-moz-progress-bar_borderInlineWidth": null,
    "::-moz-progress-bar_borderInlineStartWidth": null,
    "::-moz-progress-bar_borderLeftWidth": null,
    "::-moz-progress-bar_borderInlineEndWidth": null,
    "::-moz-progress-bar_borderRightWidth": null,
    "::-moz-progress-bar_borderBlockWidth": null,
    "::-moz-progress-bar_borderTopWidth": null,
    "::-moz-progress-bar_borderBottomWidth": null,
    "::-moz-progress-bar_borderStyle": null,
    "::-moz-progress-bar_borderInlineStyle": null,
    "::-moz-progress-bar_borderInlineStartStyle": null,
    "::-moz-progress-bar_borderLeftStyle": null,
    "::-moz-progress-bar_borderInlineEndStyle": null,
    "::-moz-progress-bar_borderRightStyle": null,
    "::-moz-progress-bar_borderBlockStyle": null,
    "::-moz-progress-bar_borderTopStyle": null,
    "::-moz-progress-bar_borderBottomStyle": null,
    "::-moz-progress-bar_borderColor": null,
    "::-moz-progress-bar_borderInlineColor": null,
    "::-moz-progress-bar_borderInlineStartColor": null,
    "::-moz-progress-bar_borderLeftColor": null,
    "::-moz-progress-bar_borderInlineEndColor": null,
    "::-moz-progress-bar_borderRightColor": null,
    "::-moz-progress-bar_borderBlockColor": null,
    "::-moz-progress-bar_borderTopColor": null,
    "::-moz-progress-bar_borderBottomColor": null,
    "::-moz-progress-bar_background": "x1fmszc9",
    "::-moz-progress-bar_backgroundAttachment": null,
    "::-moz-progress-bar_backgroundClip": null,
    "::-moz-progress-bar_backgroundColor": null,
    "::-moz-progress-bar_backgroundImage": null,
    "::-moz-progress-bar_backgroundOrigin": null,
    "::-moz-progress-bar_backgroundPosition": null,
    "::-moz-progress-bar_backgroundPositionX": null,
    "::-moz-progress-bar_backgroundPositionY": null,
    "::-moz-progress-bar_backgroundRepeat": null,
    "::-moz-progress-bar_backgroundSize": null,
    $$css: true
  },
  progressHovering: {
    "Seekbar__styles.progressHovering": "Seekbar__styles.progressHovering",
    height: "x4jzdop",
    $$css: true
  },
  seek: {
    "Seekbar__styles.seek": "Seekbar__styles.seek",
    appearance: "xjyslct",
    height: "x4jzdop",
    background: "x1md70p1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    position: "x10l6tqk",
    bottom: "x1ey2m1c",
    width: "xh8yej3",
    cursor: "x1ypdohk",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    "::-webkit-slider-thumb_visibility": "x1s1s64g",
    "::-webkit-slider-thumb_opacity": "xdx36j",
    "::-webkit-slider-thumb_height": "x1v4l21s",
    "::-webkit-slider-thumb_width": "x1xow3sz",
    "::-webkit-slider-thumb_borderRadius": "xoyqh4a",
    "::-webkit-slider-thumb_borderStartStartRadius": null,
    "::-webkit-slider-thumb_borderStartEndRadius": null,
    "::-webkit-slider-thumb_borderEndStartRadius": null,
    "::-webkit-slider-thumb_borderEndEndRadius": null,
    "::-webkit-slider-thumb_borderTopLeftRadius": null,
    "::-webkit-slider-thumb_borderTopRightRadius": null,
    "::-webkit-slider-thumb_borderBottomLeftRadius": null,
    "::-webkit-slider-thumb_borderBottomRightRadius": null,
    "::-webkit-slider-thumb_background": "x14bre5e",
    "::-webkit-slider-thumb_backgroundAttachment": null,
    "::-webkit-slider-thumb_backgroundClip": null,
    "::-webkit-slider-thumb_backgroundColor": null,
    "::-webkit-slider-thumb_backgroundImage": null,
    "::-webkit-slider-thumb_backgroundOrigin": null,
    "::-webkit-slider-thumb_backgroundPosition": null,
    "::-webkit-slider-thumb_backgroundPositionX": null,
    "::-webkit-slider-thumb_backgroundPositionY": null,
    "::-webkit-slider-thumb_backgroundRepeat": null,
    "::-webkit-slider-thumb_backgroundSize": null,
    "::-webkit-slider-thumb_cursor": "x5pni9w",
    "::-webkit-slider-thumb_appearance": "xibs45w",
    "::-webkit-slider-thumb_marginLeft": "x1m4ixft",
    "::-webkit-slider-thumb_marginInlineStart": null,
    "::-webkit-slider-thumb_marginInlineEnd": null,
    ":focus::-webkit-slider-runnable-track_background": "x1cay55c",
    ":focus::-webkit-slider-runnable-track_backgroundAttachment": null,
    ":focus::-webkit-slider-runnable-track_backgroundClip": null,
    ":focus::-webkit-slider-runnable-track_backgroundColor": null,
    ":focus::-webkit-slider-runnable-track_backgroundImage": null,
    ":focus::-webkit-slider-runnable-track_backgroundOrigin": null,
    ":focus::-webkit-slider-runnable-track_backgroundPosition": null,
    ":focus::-webkit-slider-runnable-track_backgroundPositionX": null,
    ":focus::-webkit-slider-runnable-track_backgroundPositionY": null,
    ":focus::-webkit-slider-runnable-track_backgroundRepeat": null,
    ":focus::-webkit-slider-runnable-track_backgroundSize": null,
    "::-moz-range-track_width": "xcet9i",
    "::-moz-range-track_height": "x1rj9at6",
    "::-moz-range-track_cursor": "x11lxgmc",
    "::-moz-range-track_border": "xc7wcw1",
    "::-moz-range-track_borderWidth": null,
    "::-moz-range-track_borderInlineWidth": null,
    "::-moz-range-track_borderInlineStartWidth": null,
    "::-moz-range-track_borderLeftWidth": null,
    "::-moz-range-track_borderInlineEndWidth": null,
    "::-moz-range-track_borderRightWidth": null,
    "::-moz-range-track_borderBlockWidth": null,
    "::-moz-range-track_borderTopWidth": null,
    "::-moz-range-track_borderBottomWidth": null,
    "::-moz-range-track_borderStyle": null,
    "::-moz-range-track_borderInlineStyle": null,
    "::-moz-range-track_borderInlineStartStyle": null,
    "::-moz-range-track_borderLeftStyle": null,
    "::-moz-range-track_borderInlineEndStyle": null,
    "::-moz-range-track_borderRightStyle": null,
    "::-moz-range-track_borderBlockStyle": null,
    "::-moz-range-track_borderTopStyle": null,
    "::-moz-range-track_borderBottomStyle": null,
    "::-moz-range-track_borderColor": null,
    "::-moz-range-track_borderInlineColor": null,
    "::-moz-range-track_borderInlineStartColor": null,
    "::-moz-range-track_borderLeftColor": null,
    "::-moz-range-track_borderInlineEndColor": null,
    "::-moz-range-track_borderRightColor": null,
    "::-moz-range-track_borderBlockColor": null,
    "::-moz-range-track_borderTopColor": null,
    "::-moz-range-track_borderBottomColor": null,
    "::-moz-range-track_background": "x1pd72hi",
    "::-moz-range-track_backgroundAttachment": null,
    "::-moz-range-track_backgroundClip": null,
    "::-moz-range-track_backgroundColor": null,
    "::-moz-range-track_backgroundImage": null,
    "::-moz-range-track_backgroundOrigin": null,
    "::-moz-range-track_backgroundPosition": null,
    "::-moz-range-track_backgroundPositionX": null,
    "::-moz-range-track_backgroundPositionY": null,
    "::-moz-range-track_backgroundRepeat": null,
    "::-moz-range-track_backgroundSize": null,
    "::-moz-range-track_borderRadius": "x1bi06sl",
    "::-moz-range-track_borderStartStartRadius": null,
    "::-moz-range-track_borderStartEndRadius": null,
    "::-moz-range-track_borderEndStartRadius": null,
    "::-moz-range-track_borderEndEndRadius": null,
    "::-moz-range-track_borderTopLeftRadius": null,
    "::-moz-range-track_borderTopRightRadius": null,
    "::-moz-range-track_borderBottomLeftRadius": null,
    "::-moz-range-track_borderBottomRightRadius": null,
    "::-moz-range-thumb_height": "x1ai2ryv",
    "::-moz-range-thumb_width": "xaxxyxf",
    "::-moz-range-thumb_borderRadius": "xbscnv2",
    "::-moz-range-thumb_borderStartStartRadius": null,
    "::-moz-range-thumb_borderStartEndRadius": null,
    "::-moz-range-thumb_borderEndStartRadius": null,
    "::-moz-range-thumb_borderEndEndRadius": null,
    "::-moz-range-thumb_borderTopLeftRadius": null,
    "::-moz-range-thumb_borderTopRightRadius": null,
    "::-moz-range-thumb_borderBottomLeftRadius": null,
    "::-moz-range-thumb_borderBottomRightRadius": null,
    "::-moz-range-thumb_border": "x1daa4l8",
    "::-moz-range-thumb_borderWidth": null,
    "::-moz-range-thumb_borderInlineWidth": null,
    "::-moz-range-thumb_borderInlineStartWidth": null,
    "::-moz-range-thumb_borderLeftWidth": null,
    "::-moz-range-thumb_borderInlineEndWidth": null,
    "::-moz-range-thumb_borderRightWidth": null,
    "::-moz-range-thumb_borderBlockWidth": null,
    "::-moz-range-thumb_borderTopWidth": null,
    "::-moz-range-thumb_borderBottomWidth": null,
    "::-moz-range-thumb_borderStyle": null,
    "::-moz-range-thumb_borderInlineStyle": null,
    "::-moz-range-thumb_borderInlineStartStyle": null,
    "::-moz-range-thumb_borderLeftStyle": null,
    "::-moz-range-thumb_borderInlineEndStyle": null,
    "::-moz-range-thumb_borderRightStyle": null,
    "::-moz-range-thumb_borderBlockStyle": null,
    "::-moz-range-thumb_borderTopStyle": null,
    "::-moz-range-thumb_borderBottomStyle": null,
    "::-moz-range-thumb_borderColor": null,
    "::-moz-range-thumb_borderInlineColor": null,
    "::-moz-range-thumb_borderInlineStartColor": null,
    "::-moz-range-thumb_borderLeftColor": null,
    "::-moz-range-thumb_borderInlineEndColor": null,
    "::-moz-range-thumb_borderRightColor": null,
    "::-moz-range-thumb_borderBlockColor": null,
    "::-moz-range-thumb_borderTopColor": null,
    "::-moz-range-thumb_borderBottomColor": null,
    "::-moz-range-thumb_background": "x1eiz26c",
    "::-moz-range-thumb_backgroundAttachment": null,
    "::-moz-range-thumb_backgroundClip": null,
    "::-moz-range-thumb_backgroundColor": null,
    "::-moz-range-thumb_backgroundImage": null,
    "::-moz-range-thumb_backgroundOrigin": null,
    "::-moz-range-thumb_backgroundPosition": null,
    "::-moz-range-thumb_backgroundPositionX": null,
    "::-moz-range-thumb_backgroundPositionY": null,
    "::-moz-range-thumb_backgroundRepeat": null,
    "::-moz-range-thumb_backgroundSize": null,
    "::-moz-range-thumb_cursor": "xvs8oyn",
    "::-moz-range-thumb_marginTop": "xwjrlxt",
    ":focus::-moz-range-track_outline": "xcomxyd",
    ":focus::-moz-range-track_outlineColor": null,
    ":focus::-moz-range-track_outlineOffset": null,
    ":focus::-moz-range-track_outlineStyle": null,
    ":focus::-moz-range-track_outlineWidth": null,
    $$css: true
  },
  seekHovering: {
    "Seekbar__styles.seekHovering": "Seekbar__styles.seekHovering",
    opacity: "x1hc1fzr",
    "::-webkit-slider-thumb_opacity": "xedwvev",
    $$css: true
  },
  output: {
    "Seekbar__styles.output": "Seekbar__styles.output",
    position: "x10l6tqk",
    zIndex: "x1q2oy4v",
    top: "x2rgi",
    pointerEvents: "x47corl",
    padding: "x9ms5i6",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1cum3z5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    background: "x1nqgf1u",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  position,
  seekTo,
  duration,
  disabled
}) => {
  const inputRef = (0, _react.useRef)(null);
  const [rect, setRect] = (0, _react.useState)(null);
  const [inputWidth, setInputWidth] = (0, _react.useState)(0);
  const currentPos = position / duration * (inputWidth || 0);
  const [hoverPos, setHoverPos] = (0, _react.useState)(null);
  const [hovering, setHovering] = (0, _react.useState)(false);
  let positionStr;
  let skipTo = null;
  const pos = hoverPos === null ? currentPos : hoverPos;
  const onMouseMove = (0, _react.useCallback)(e => {
    const {
      offsetX
    } = e.nativeEvent;
    setHoverPos(offsetX);
  }, []);
  const onMouseLeave = (0, _react.useCallback)(() => {
    setHoverPos(null);
    setHovering(false);
  }, []);
  const onChange = (0, _react.useCallback)(e => {
    if (seekTo === void 0) return;
    if (skipTo !== null) {
      seekTo(skipTo);
    } else {
      seekTo(parseInt(e.target.value));
    }
  }, [seekTo, skipTo]);
  if (inputWidth && rect) {
    skipTo = Math.round(pos / inputWidth * parseInt(duration == null ? void 0 : duration.toString(), 10));
    try {
      if (isNaN(skipTo) && isNaN(duration)) {
        positionStr = "";
      } else if (isNaN(skipTo)) {
        positionStr = formatTime(duration);
      } else {
        positionStr = formatTime(skipTo);
      }
    } catch (e) {
      console.warn(e);
    }
  }
  (0, _react.useEffect)(() => {
    var _a, _b;
    const boundingRect = (_a = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _a.getBoundingClientRect();
    if (boundingRect) {
      setRect(boundingRect);
    }
    setInputWidth(((_b = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _b.clientWidth) || 0);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    onMouseEnter: () => {
      setHovering(true);
    },
    style: styles.videoProgress,
    onMouseMove,
    onMouseLeave
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.progress, {
    style: [styles.progress, hovering && styles.progressHovering],
    value: position,
    max: duration
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.input, {
    style: [styles.seek, !!hovering && styles.seekHovering, disabled && styles.defaultCursor],
    disabled,
    value: position,
    min: "0",
    max: duration,
    type: "range",
    step: "1",
    onChange,
    ref: inputRef
  }), positionStr && pos !== null && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.output, styles.leftPos(pos)]
  }, positionStr));
});