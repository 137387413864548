"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import EventEmitter from "eventemitter3";
import StreamAnalyser from "./StreamAnalyser";
const SCREEN_RESIZE_TIMING_THRESHOLD_MS = 2e3;
let INITIAL_EMBED_VOLUME = 0.3;
try {
  INITIAL_EMBED_VOLUME = parseFloat(localStorage.getItem("embedVolume") || "");
  if (typeof INITIAL_EMBED_VOLUME !== "number" || !isFinite(INITIAL_EMBED_VOLUME)) {
    throw "Not a number";
  }
  if (isNaN(INITIAL_EMBED_VOLUME)) {
    throw "number is NaN";
  }
} catch (_e) {
  INITIAL_EMBED_VOLUME = 0.3;
}
const LOGGING_ENABLED = false;
const USER_AUDIO_FFT_SIZE = 2048;
class AVEngine {
  constructor() {
    // Private properties
    __publicField(this, "_audioContext", new AudioContext());
    // Centralised audioContext to pass all webrtc streams through to the speakers
    __publicField(this, "_osc");
    // Test tone node
    __publicField(this, "_oscGain");
    // Test tone gain node
    __publicField(this, "_webRTCPrioritySourcesGain");
    // WebRTC gain node (priority channels)
    __publicField(this, "_webRTCGuestSourcesGain");
    // WebRTC gain node (guest channels)
    __publicField(this, "_displayMediaStreamSummingGainNode");
    __publicField(this, "_outputStageGain");
    // Central audiocontext output level
    __publicField(this, "_userMediaAudioAnalyser");
    // Central analyser node for user audio input
    __publicField(this, "_mediaElementSources", {});
    // reference to media elemtns currently being piped through the audiocontext
    __publicField(this, "_embedsMuted", false);
    // keeps track of embed uted
    __publicField(this, "_speechDetectionThresholdDb", -70);
    // db threshold for speech detection
    __publicField(this, "_speechDetectionInterval", 100);
    // How often webrtc signals are checked for activity
    __publicField(this, "_speechDetectionSmoothing", 0.1);
    // smooths out the speech detection
    __publicField(this, "_speechDetectionHistory", 3);
    // How long to wait for a speech detection trigger
    __publicField(this, "_registeredEmbedVolumeCallbacks", {});
    // registered volum change callbacks from embeds
    __publicField(this, "_currentlySpeakingUserIds", /* @__PURE__ */ new Set());
    // currently speaking userIds
    __publicField(this, "_displayMediaStream");
    __publicField(this, "_mediaRecorder");
    __publicField(this, "_mediaDisplayStreamSourceNode");
    __publicField(this, "_mediaDisplayStreamSourceGainNode");
    __publicField(this, "_mediaStreamSinkNode");
    __publicField(this, "_userAudioStreamDestinationGainNode");
    __publicField(this, "_splashVideo");
    __publicField(this, "_capturedDisplayMediaVideoTrackSettings");
    __publicField(this, "_splashCanvasStream");
    __publicField(this, "userMediaAudioStream");
    __publicField(this, "userMediaVideoStream");
    __publicField(this, "userMediaStreamNode");
    __publicField(this, "screenRecordingPreviewUrl");
    __publicField(this, "_cancelScreenRecordingCallback");
    __publicField(this, "_mediaRecorderStartedAt");
    __publicField(this, "_userAudioAnalyserDataArray");
    __publicField(this, "_userAudioAnalyserBufferLength");
    __publicField(this, "videoRequestedForCall", false);
    __publicField(this, "audioRequestedForCall", false);
    //Public properties
    __publicField(this, "eventEmitter", new EventEmitter());
    // Containers/componets subscribe to this events emitter to get events
    __publicField(this, "dimLevel", 0.2);
    __publicField(this, "initialEmbedVolume", INITIAL_EMBED_VOLUME);
    __publicField(this, "loggingEnabled", LOGGING_ENABLED);
    this._osc = this._audioContext.createOscillator();
    this._oscGain = this._audioContext.createGain();
    this._outputStageGain = this._audioContext.createGain();
    this._displayMediaStreamSummingGainNode = this._audioContext.createGain();
    this._webRTCPrioritySourcesGain = this._audioContext.createGain();
    this._webRTCGuestSourcesGain = this._audioContext.createGain();
    this._mediaDisplayStreamSourceGainNode = this._audioContext.createGain();
    this._userAudioStreamDestinationGainNode = this._audioContext.createGain();
    this._userMediaAudioAnalyser = this._audioContext.createAnalyser();
    this._setupUserAudioAnalyser();
    this._setupTestTone();
    this._setupGraph();
    this._setupListeners();
    this.startRecordingDisplayMediaStream = this.startRecordingDisplayMediaStream.bind(this);
    this.stopRecordingDisplayMediaStream = this.stopRecordingDisplayMediaStream.bind(this);
    this.handleWindowResized = this.handleWindowResized.bind(this);
    this._setDefaultAvSources();
  }
  _setDefaultAvSources() {
    if (!this.getPreferredAudioInputDeviceId)
      this.setPreferredAudioInputDeviceId("default");
    if (!this.getPreferredVideoInputDeviceId)
      this.setPreferredVideoInputDeviceId("default");
  }
  _setupUserAudioAnalyser() {
    this._userMediaAudioAnalyser.fftSize = USER_AUDIO_FFT_SIZE;
    this._userAudioAnalyserBufferLength = this._userMediaAudioAnalyser.frequencyBinCount;
    this._userAudioAnalyserDataArray = new Uint8Array(this._userAudioAnalyserBufferLength);
    this._userMediaAudioAnalyser.getByteTimeDomainData(this._userAudioAnalyserDataArray);
  }
  // Useful to have to quickly check if audiocontext is running
  _setupTestTone() {
    this._oscGain.gain.value = 0;
    this._osc.frequency.value = 440;
    this._osc.start();
  }
  // Wires up the WebAudio API graph
  _setupGraph() {
    this._osc.connect(this._oscGain);
    this._oscGain.connect(this._outputStageGain);
    this._webRTCPrioritySourcesGain.connect(this._outputStageGain);
    this._webRTCGuestSourcesGain.connect(this._outputStageGain);
    this._outputStageGain.connect(this._audioContext.destination);
  }
  // Own internal listener to the currently_speaking events to duck emebeds
  _setupListeners() {
    this.eventEmitter.on("currently_speaking", this._handleSpeakingDuckingEvents, this);
  }
  // Internal event speech detection event emitter
  _emitCurrentlySpeaking() {
    this.eventEmitter.emit("currently_speaking", this._currentlySpeakingUserIds);
  }
  // Called when an one of the StreamAnalysers detects speech
  _startedSpeakingHandler(userId) {
    if (!this._currentlySpeakingUserIds.has(userId))
      this._currentlySpeakingUserIds.add(userId);
    this._emitCurrentlySpeaking();
  }
  // Called when an one of the StreamAnalysers stops detecting speech
  _stoppedSpeakingHandler(userId) {
    this._currentlySpeakingUserIds.delete(userId);
    this._emitCurrentlySpeaking();
  }
  // Handler called when internal currently_speaking is called
  // Determines whether to duck of unduck the embeds based on the set size
  _handleSpeakingDuckingEvents(currentlySpeaking) {
    if (currentlySpeaking.size) {
      this.duckEmbeds();
    } else {
      this.unduckEmbeds();
    }
  }
  /**
   * Get the current state of the audio context (used to resume on any page interaction)
   */
  getAudioContextState() {
    return this._audioContext.state;
  }
  /**
   * Gets the users tab audio and video
   */
  getDisplayMediaStream() {
    return __async(this, null, function* () {
      this._displayMediaStream = yield navigator.mediaDevices.getDisplayMedia({
        video: {
          frameRate: {
            max: 30
          },
          width: {
            max: 1920
          },
          height: {
            max: 1080
          }
        },
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false
        }
      });
      this._capturedDisplayMediaVideoTrackSettings = this._displayMediaStream.getVideoTracks()[0].getSettings();
    });
  }
  /**
   * Calls a registered callback when the stream is interupted
   */
  _handleRecorderAutoStop() {
    var _a;
    if (((_a = this._mediaRecorder) == null ? void 0 : _a.state) === "recording") {
      if (this._cancelScreenRecordingCallback)
        this._cancelScreenRecordingCallback();
    }
  }
  /**
   * Called by mounted react component in RoomTool bar when window size changes
   */
  handleWindowResized() {
    var _a;
    if (((_a = this._mediaRecorder) == null ? void 0 : _a.state) === "recording" && this._mediaRecorderStartedAt) {
      if (this._mediaRecorderStartedAt < Date.now() - SCREEN_RESIZE_TIMING_THRESHOLD_MS) {
        if (this._cancelScreenRecordingCallback)
          this._cancelScreenRecordingCallback();
      }
    }
  }
  /**
   * Experimenting with splash screens and overlays
   */
  _createSplashVideoForCapture() {
    return new Promise((resolve) => {
      var _a, _b;
      this._splashVideo = document.createElement("video");
      this._splashVideo.src = "/assets/4827527855b9bf7035bd3b1e4d398b06.mp4";
      this._splashVideo.width = ((_a = this._capturedDisplayMediaVideoTrackSettings) == null ? void 0 : _a.width) || 0;
      this._splashVideo.height = ((_b = this._capturedDisplayMediaVideoTrackSettings) == null ? void 0 : _b.height) || 0;
      this._splashVideo.play();
      document.body.appendChild(this._splashVideo);
      this._splashVideo.onplay = () => {
        this._splashCanvasStream = this._splashVideo.captureStream();
        resolve(true);
      };
    });
  }
  /**
   * Get list of devices available to the browser
   */
  getUserMediaDevices() {
    return __async(this, null, function* () {
      try {
        let tempDeviceRequest = null;
        if (!this.videoRequestedForCall || !this.audioRequestedForCall) {
          tempDeviceRequest = yield this.getUserMediaStreams();
        }
        const devices = yield navigator.mediaDevices.enumerateDevices();
        if (tempDeviceRequest) {
          for (const track of tempDeviceRequest.getTracks()) {
            track.stop();
          }
          tempDeviceRequest = null;
        }
        const splitDevices = {
          audioDevices: devices.filter((v) => v.kind === "audioinput"),
          videoDevices: devices.filter((v) => v.kind === "videoinput")
        };
        if (splitDevices.audioDevices.length > 0 && !this.getPreferredAudioInputDeviceId()) {
          this.setPreferredAudioInputDeviceId(splitDevices.audioDevices[0].deviceId);
        }
        if (splitDevices.videoDevices.length > 0 && !this.getPreferredVideoInputDeviceId()) {
          this.setPreferredVideoInputDeviceId(splitDevices.videoDevices[0].deviceId);
        }
        this.eventEmitter.emit("av-permissions-granted", true);
        return splitDevices;
      } catch (_e) {
        this.eventEmitter.emit("av-permissions-granted", false);
        return null;
      }
    });
  }
  setPreferredAudioInputDeviceId(deviceId) {
    return __async(this, null, function* () {
      localStorage.setItem("preferedAudioInputDeviceId", deviceId);
      this.eventEmitter.emit("av-audio-source-changed");
    });
  }
  getPreferredAudioInputDeviceId() {
    return localStorage.getItem("preferedAudioInputDeviceId");
  }
  setPreferredVideoInputDeviceId(deviceId) {
    return __async(this, null, function* () {
      localStorage.setItem("preferedVideoInputDeviceId", deviceId);
      this.eventEmitter.emit("av-video-source-changed");
    });
  }
  getPreferredVideoInputDeviceId() {
    return localStorage.getItem("preferedVideoInputDeviceId");
  }
  getUserMediaStreams() {
    return __async(this, null, function* () {
      const constraints = {
        audio: true,
        video: true
      };
      return yield navigator.mediaDevices.getUserMedia(constraints);
    });
  }
  /**
   * Centralised place to grab user microphone streamwith optional deviceId and cleanAudio parameters
   */
  getUserMediaAudioStream(deviceId, cleanAudio = true) {
    return __async(this, null, function* () {
      var _a;
      try {
        if (this.userMediaAudioStream)
          yield this.stopUserMediaAudioStream();
        const customConstraints = {
          audio: {
            autoGainControl: cleanAudio,
            echoCancellation: cleanAudio,
            noiseSuppression: cleanAudio,
            sampleRate: this._audioContext.sampleRate,
            deviceId: void 0
          }
        };
        if (deviceId)
          customConstraints.audio.deviceId = deviceId;
        this.userMediaAudioStream = yield navigator.mediaDevices.getUserMedia(customConstraints);
        this.userMediaStreamNode = this._audioContext.createMediaStreamSource(this.userMediaAudioStream);
        if (this._userAudioStreamDestinationGainNode) {
          this.userMediaStreamNode.connect(this._userAudioStreamDestinationGainNode);
        }
        (_a = this._userAudioStreamDestinationGainNode) == null ? void 0 : _a.connect(this._userMediaAudioAnalyser);
        this.eventEmitter.emit("av-permissions-granted", true);
        this.eventEmitter.emit("user-audio-stream-changed");
        return this.userMediaAudioStream;
      } catch (_e) {
        this.eventEmitter.emit("av-permissions-granted", false);
        return null;
      }
    });
  }
  /**
   * Centralised place to grab user microphone streamwith optional deviceId and cleanAudio parameters
   */
  getUserMediaVideoStream(deviceId) {
    return __async(this, null, function* () {
      try {
        if (this.userMediaVideoStream)
          yield this.stopUserMediaVideoStream();
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        const videoConstraints = {
          video: {
            width: {
              min: 100,
              ideal: 320,
              max: 320
            },
            height: {
              min: 100,
              ideal: 240,
              max: 240
            },
            deviceId
          }
        };
        this.userMediaVideoStream = yield navigator.mediaDevices.getUserMedia(isChrome ? videoConstraints : {
          video: {
            deviceId
          }
        });
        this.eventEmitter.emit("av-permissions-granted", true);
        this.eventEmitter.emit("user-video-stream-changed");
        return this.userMediaVideoStream;
      } catch (_e) {
        this.eventEmitter.emit("av-permissions-granted", false);
        return null;
      }
    });
  }
  /**
   * Stop the grabbed user audio stream (to turn off the browser microphone symbol)
   */
  stopUserMediaAudioStream() {
    return __async(this, null, function* () {
      var _a;
      if (this.userMediaAudioStream && this._userAudioStreamDestinationGainNode) {
        (_a = this.userMediaStreamNode) == null ? void 0 : _a.disconnect(this._userAudioStreamDestinationGainNode);
        for (const t of this.userMediaAudioStream.getTracks()) {
          t.stop();
        }
      }
      this.userMediaAudioStream = void 0;
      this.eventEmitter.emit("user-audio-stream-stopped");
    });
  }
  /**
   * Stop the grabbed user video stream (to turn off the browser webcam symbol)
   */
  stopUserMediaVideoStream() {
    return __async(this, null, function* () {
      if (this.userMediaVideoStream)
        for (const t of this.userMediaVideoStream.getTracks()) {
          t.stop();
        }
      this.userMediaVideoStream = void 0;
      this.eventEmitter.emit("user-video-stream-stopped");
    });
  }
  /**
   * Start recording the users tab after getDisplayMediaStream has been called successuflly
   * Users microphone singal is summed togther with the tab audio
   */
  startRecordingDisplayMediaStream(cancelCallback, startedCallback, errorCallback) {
    var _a, _b, _c, _d;
    try {
      this._cancelScreenRecordingCallback = cancelCallback;
      if (this._displayMediaStream) {
        this._mediaDisplayStreamSourceNode = this._audioContext.createMediaStreamSource(this._displayMediaStream);
      }
      if (this._mediaDisplayStreamSourceNode) {
        (_a = this._mediaDisplayStreamSourceNode) == null ? void 0 : _a.connect(this._mediaDisplayStreamSourceGainNode);
      }
      (_b = this._mediaDisplayStreamSourceGainNode) == null ? void 0 : _b.connect(this._displayMediaStreamSummingGainNode);
      this._displayMediaStreamSummingGainNode.gain.value = 0.5;
      (_c = this._userAudioStreamDestinationGainNode) == null ? void 0 : _c.connect(this._displayMediaStreamSummingGainNode);
      this._mediaStreamSinkNode = this._audioContext.createMediaStreamDestination();
      this._displayMediaStreamSummingGainNode.connect(this._mediaStreamSinkNode);
      const mergedStream = new MediaStream();
      const tabVideoTrack = (_d = this._displayMediaStream) == null ? void 0 : _d.getVideoTracks()[0];
      const mixedAudioTrack = this._mediaStreamSinkNode.stream.getAudioTracks()[0];
      if (tabVideoTrack) {
        mergedStream.addTrack(tabVideoTrack);
      }
      mergedStream.addTrack(mixedAudioTrack);
      this._mediaRecorder = new MediaRecorder(mergedStream, {
        mimeType: "video/webm;codecs=h264",
        audioBitsPerSecond: 48e3,
        videoBitsPerSecond: 1e6
      });
      const chunks = [];
      this._mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          chunks.push(e.data);
        }
      };
      this._mediaRecorder.onstop = () => {
        var _a2;
        if (this._mediaRecorder) {
          const recording = new Blob(chunks, {
            type: this._mediaRecorder.mimeType
          });
          this.screenRecordingPreviewUrl = URL.createObjectURL(recording);
          (_a2 = this._displayMediaStream) == null ? void 0 : _a2.getTracks().forEach((t) => t.stop());
          this._mediaRecorderStartedAt = void 0;
        }
      };
      setTimeout(() => {
        if (this._mediaRecorder) {
          this._mediaRecorder.start();
          startedCallback();
        }
      }, 2e3);
      this._mediaRecorderStartedAt = Date.now();
    } catch (_e) {
      errorCallback();
    }
  }
  /**
   * Get MediaRecorder's latest tab recording blob url
   */
  getScreenRecordingPreviewUrl() {
    return this.screenRecordingPreviewUrl;
  }
  /**
   * Stop recording the users tab
   */
  stopRecordingDisplayMediaStream() {
    var _a;
    (_a = this._mediaRecorder) == null ? void 0 : _a.stop();
  }
  /**
   * Returns the currently speaking set, covert to an array with Array.from(set)
   */
  getCurrentlySpeaking() {
    return this._currentlySpeakingUserIds;
  }
  /**
   * Toggles mute for both the embeds and central audio context (which pipes the webrtc streams)
   */
  toggleMute() {
    this._outputStageGain.gain.value = this._outputStageGain.gain.value === 1 ? 0 : 1;
    this._embedsMuted ? this.unmuteEmbeds() : this.muteEmbeds();
  }
  /**
   * Resumes (unmutes) the central audio context
   */
  resumeAudioContext() {
    this._audioContext.resume();
  }
  /**
   * Register a callback to change an embeds volume eg (newVolume) => setState({volume:newVolume})
   */
  registerEmbedVolumeCallback(id, callback) {
    this._registeredEmbedVolumeCallbacks[id] = callback;
    this.log(`Setting initial video volume to ${this.initialEmbedVolume}`);
    callback(this.initialEmbedVolume);
    this.log(`Registered embed to AVEngine with ID: ${id}`);
  }
  /**
   * Remove a previosuly registered embed callback by id
   */
  removeEmbedVolumeCallback(id) {
    delete this._registeredEmbedVolumeCallbacks[id];
  }
  /**
   * Duck all the registered embeds
   */
  duckEmbeds() {
  }
  /**
   * Unduck all the registered embeds
   */
  unduckEmbeds() {
  }
  /**
   * Mute all the registred embeds
   */
  muteEmbeds() {
    for (const callbackId of Object.getOwnPropertyNames(this._registeredEmbedVolumeCallbacks)) {
      this._registeredEmbedVolumeCallbacks[callbackId](0);
    }
    this._embedsMuted = true;
    this.eventEmitter.emit("embeds_muted");
  }
  /**
   * Unmute all the registred embeds
   */
  unmuteEmbeds() {
    for (const callbackId of Object.getOwnPropertyNames(this._registeredEmbedVolumeCallbacks)) {
      this._registeredEmbedVolumeCallbacks[callbackId](null);
    }
    this._embedsMuted = false;
    this.eventEmitter.emit("embeds_unmuted");
  }
  /**
   * Duck the volume of all the WebRTC streams piped tgrough the audio context
   */
  duckWebRtcStreams(gainValue, duckPriorityTracks = true, rampTimeMs = 500) {
    if (duckPriorityTracks)
      this._webRTCPrioritySourcesGain.gain.linearRampToValueAtTime(gainValue, this._audioContext.currentTime + rampTimeMs / 1e3);
    this._webRTCGuestSourcesGain.gain.linearRampToValueAtTime(gainValue, this._audioContext.currentTime + rampTimeMs / 1e3);
    this.eventEmitter.emit("streams_ducked");
  }
  /**
   * Unduck the volume of all the WebRTC streams piped tgrough the audio context
   */
  unduckWebRtcStreams(rampTimeMs = 500) {
    this._webRTCPrioritySourcesGain.gain.linearRampToValueAtTime(1, this._audioContext.currentTime + rampTimeMs / 1e3);
    this._webRTCGuestSourcesGain.gain.linearRampToValueAtTime(1, this._audioContext.currentTime + rampTimeMs / 1e3);
    this.eventEmitter.emit("streams_unducked");
  }
  /**
   * Mute the volume of all the WebRTC streams piped tgrough the audio context
   */
  muteWebRtcStreams(mutePriorityTracks = true, rampTimeMs = 100) {
    if (mutePriorityTracks)
      this._webRTCPrioritySourcesGain.gain.linearRampToValueAtTime(0, this._audioContext.currentTime + rampTimeMs / 1e3);
    this._webRTCGuestSourcesGain.gain.linearRampToValueAtTime(0, this._audioContext.currentTime + rampTimeMs / 1e3);
    this.eventEmitter.emit("streams_muted");
  }
  /**
   * Unmute the volume of all the WebRTC streams piped tgrough the audio context
   */
  unmuteWebRtcStreams(rampTimeMs = 100) {
    this._webRTCPrioritySourcesGain.gain.linearRampToValueAtTime(1, this._audioContext.currentTime + rampTimeMs / 1e3);
    this._webRTCGuestSourcesGain.gain.linearRampToValueAtTime(1, this._audioContext.currentTime + rampTimeMs / 1e3);
    this.eventEmitter.emit("streams_unmuted");
  }
  /**
   * Add a MediaStream to the centralised audio engine
   */
  addMediaStreamAudioToEngine(stream, streamId, userId, priorityTrack = false) {
    var _a, _b, _c;
    const mediaElementNode = this._audioContext.createMediaStreamSource(stream);
    priorityTrack ? mediaElementNode.connect(this._webRTCPrioritySourcesGain) : mediaElementNode.connect(this._webRTCGuestSourcesGain);
    this._mediaElementSources[streamId] = {
      stream,
      mediaElementNode,
      priorityTrack,
      streamAnalyser: new StreamAnalyser(this._audioContext, mediaElementNode, {
        smoothing: this._speechDetectionSmoothing,
        interval: this._speechDetectionInterval,
        threshold: this._speechDetectionThresholdDb,
        history: this._speechDetectionHistory
      })
    };
    (_a = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _a.addEventListener("started_speaking", () => this._startedSpeakingHandler(userId));
    (_b = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _b.addEventListener("stopped_speaking", () => this._stoppedSpeakingHandler(userId));
    (_c = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _c.start();
  }
  /**
   * Add a MediaElement to the centralised audio engine (currently how WebRTC streams are added)
   */
  addMediaElementAudioToEngine(mediaElement, streamId, userId, priorityTrack = false) {
    var _a, _b, _c;
    const mediaElementNode = this._audioContext.createMediaElementSource(mediaElement);
    priorityTrack ? mediaElementNode.connect(this._webRTCPrioritySourcesGain) : mediaElementNode.connect(this._webRTCGuestSourcesGain);
    this._mediaElementSources[streamId] = {
      mediaElement,
      mediaElementNode,
      priorityTrack,
      streamAnalyser: new StreamAnalyser(this._audioContext, mediaElementNode)
    };
    (_a = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _a.addEventListener("started_speaking", () => this._startedSpeakingHandler(userId));
    (_b = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _b.addEventListener("stopped_speaking", () => this._stoppedSpeakingHandler(userId));
    (_c = this._mediaElementSources[streamId].streamAnalyser) == null ? void 0 : _c.start();
  }
  /**
   * Remove MediaElement audio from the central audio engine
   */
  removeMediaElementAudioFromEngine(id, userId) {
    var _a, _b, _c, _d, _e;
    const streamToRemove = this._mediaElementSources[id];
    if (streamToRemove) {
      (_a = this._mediaElementSources[id].streamAnalyser) == null ? void 0 : _a.removeEventListener("started_speaking");
      (_b = this._mediaElementSources[id].streamAnalyser) == null ? void 0 : _b.removeEventListener("stopped_speaking");
      (_c = this._mediaElementSources[id].streamAnalyser) == null ? void 0 : _c.stop();
      const tracks = ((_d = this._mediaElementSources[id].mediaElementNode) == null ? void 0 : _d.getStream().getTracks()) || [];
      for (const track of tracks) {
        track.enabled = false;
        track.stop();
      }
      (_e = this._mediaElementSources[id].mediaElementNode) == null ? void 0 : _e.disconnect();
      this._mediaElementSources[id].mediaElementNode = null;
      this._mediaElementSources[id].streamAnalyser = null;
      this._currentlySpeakingUserIds.delete(userId);
      this._emitCurrentlySpeaking();
    }
  }
  /**
   * Add the hosts audio strema to the engine (not in use yet)
   */
  // addHostAudioStream(stream: MediaStream, streamId: string, userId: string, priorityTrack: boolean = false) {
  // }
  /**
   * Starts test tone on centralised audio context
   */
  startTestTone() {
    this._oscGain.gain.value = 0.5;
  }
  /**
   * Stops test tone on centralised audio context
   */
  stopTestTone() {
    this._oscGain.gain.value = 0;
  }
  log(arg) {
    if (this.loggingEnabled) {
      console.log(arg);
    }
  }
}
const avEngine = new AVEngine();
export default avEngine;
