"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useQuery } from "@apollo/client";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useContext, useEffect, useMemo } from "react";
import { useRoomStateCached } from "./useRoomData";
import RoomContext from "../contexts/RoomContext";
const LINKED_MEMBERS = gql(`
  query LinkedMembers($roomId: String!) {
    linkedMembers(roomId: $roomId) {
      id
      memberCount
      members {
        id
        role
        lastVisited
        user {
          id
          displayName
          email
          emailConfirmed
          avatarUrl
          isSubscribed
          isAnonymous
        }
      }
    }
  }
`);
const MEMBER_LEAVES_SUBSCRIPTION = gql(`
  subscription MemberLeavesABC($id: String!) {
    memberLeaves(id: $id) {
id
role
user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
      email
      emailConfirmed
    }
    }
  }
`);
const NEW_MEMBER_SUBSCRIPTION = gql(`
  subscription NewMemberSubscription($roomId: String!) {
    newMember(roomId: $roomId) {
      id
    }
  }
`);
const MEMBER_UNLINKS_SUBSCRIPTION = gql(`
  subscription MemberUnlinksSubscription($roomId: String!) {
    memberUnlinks(roomId: $roomId) {
      id
    }
  }
`);
const SET_ROLE_SUBSCRIPTION = gql(`
  subscription SetRole2($id: String!) {
    setRole(id: $id) {
id
role
user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
    }
    }
  }
`);
export const useMembers = (roomId) => {
  var _a;
  const room = useRoomStateCached(roomId);
  let {
    webcamStreams,
    microphoneStreams
  } = useContext(RoomContext);
  webcamStreams = webcamStreams || {};
  microphoneStreams = microphoneStreams || {};
  const members = useMemo(() => {
    var _a2;
    return (((_a2 = room == null ? void 0 : room.state) == null ? void 0 : _a2.members) || []).slice().sort((a, b) => {
      var _a3, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
      if ((a == null ? void 0 : a.role) === "ADMIN" && (b == null ? void 0 : b.role) !== "ADMIN")
        return -1;
      if ((b == null ? void 0 : b.role) === "ADMIN" && (a == null ? void 0 : a.role) !== "ADMIN")
        return 1;
      if (!((_a3 = a == null ? void 0 : a.user) == null ? void 0 : _a3.id) || !((_b = b == null ? void 0 : b.user) == null ? void 0 : _b.id))
        return 0;
      if (webcamStreams[(_c = a == null ? void 0 : a.user) == null ? void 0 : _c.id] && !webcamStreams[(_d = b == null ? void 0 : b.user) == null ? void 0 : _d.id])
        return -1;
      if (webcamStreams[(_e = b == null ? void 0 : b.user) == null ? void 0 : _e.id] && !webcamStreams[(_f = a == null ? void 0 : a.user) == null ? void 0 : _f.id])
        return 1;
      if (microphoneStreams[(_g = a == null ? void 0 : a.user) == null ? void 0 : _g.id] && !microphoneStreams[(_h = b == null ? void 0 : b.user) == null ? void 0 : _h.id])
        return -1;
      if (microphoneStreams[(_i = b == null ? void 0 : b.user) == null ? void 0 : _i.id] && !microphoneStreams[(_j = a == null ? void 0 : a.user) == null ? void 0 : _j.id])
        return 1;
      if (((_k = a == null ? void 0 : a.user) == null ? void 0 : _k.isSubscribed) && !((_l = b == null ? void 0 : b.user) == null ? void 0 : _l.isSubscribed))
        return -1;
      if (((_m = b == null ? void 0 : b.user) == null ? void 0 : _m.isSubscribed) && !((_n = a == null ? void 0 : a.user) == null ? void 0 : _n.isSubscribed))
        return 1;
      return (((_o = a == null ? void 0 : a.user) == null ? void 0 : _o.username) || "").localeCompare(((_p = b == null ? void 0 : b.user) == null ? void 0 : _p.username) || "");
    });
  }, [(_a = room == null ? void 0 : room.state) == null ? void 0 : _a.members, webcamStreams, microphoneStreams]);
  return members;
};
export const useMember = (userId) => {
  const roomId = useRoomId();
  const members = useMembers(roomId);
  return useMemo(() => {
    var _a;
    return (_a = members.find((m) => {
      var _a2;
      return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) === userId;
    })) == null ? void 0 : _a.user;
  }, [members, userId]);
};
export const useLinkedMembersCached = (roomId) => {
  var _a, _b;
  const members = useMembers(roomId);
  const {
    data
  } = useQuery(LINKED_MEMBERS, {
    variables: {
      roomId
    },
    fetchPolicy: "cache-first",
    skip: members.length > 50
  });
  const linkedMembers = useMemo(() => {
    var _a2;
    return (((_a2 = data == null ? void 0 : data.linkedMembers) == null ? void 0 : _a2.members) || []).filter((m) => !members.map((m2) => m2 == null ? void 0 : m2.id).includes(m.id));
  }, [(_a = data == null ? void 0 : data.linkedMembers) == null ? void 0 : _a.members, members]);
  const linkedMemberCount = useMemo(() => {
    var _a2;
    return (((_a2 = data == null ? void 0 : data.linkedMembers) == null ? void 0 : _a2.memberCount) || 0) - members.filter((m) => {
      var _a3;
      return !((_a3 = m == null ? void 0 : m.user) == null ? void 0 : _a3.isAnonymous);
    }).length;
  }, [(_b = data == null ? void 0 : data.linkedMembers) == null ? void 0 : _b.memberCount, members]);
  return {
    linkedMembers,
    linkedMemberCount
  };
};
export const useLinkedMembers = (roomId) => {
  const members = useMembers(roomId);
  const {
    subscribeToMore
  } = useQuery(LINKED_MEMBERS, {
    variables: {
      roomId
    },
    fetchPolicy: "cache-and-network",
    skip: members.length > 50
  });
  useEffect(() => {
    const unsubscribeMemberLeaves = subscribeToMore({
      document: MEMBER_LEAVES_SUBSCRIPTION,
      variables: {
        id: roomId
      },
      updateQuery: (prev, {
        subscriptionData
      }) => {
        var _a, _b, _c;
        const {
          user,
          role,
          id
        } = ((_a = subscriptionData == null ? void 0 : subscriptionData.data) == null ? void 0 : _a.memberLeaves) || {};
        const leavingMember = {
          user,
          role,
          id,
          lastVisited: Math.abs(Math.round(Date.now() / 1e3)),
          __typename: "OfflineMember"
        };
        if ((_b = leavingMember == null ? void 0 : leavingMember.user) == null ? void 0 : _b.isAnonymous)
          return prev;
        const linkedMembers = (_c = prev == null ? void 0 : prev.linkedMembers) == null ? void 0 : _c.members;
        if (linkedMembers && leavingMember) {
          const allMembers = linkedMembers.filter((m) => m.id !== leavingMember.id);
          let admins = allMembers.filter((m) => m.role === "ADMIN");
          let users = allMembers.filter((m) => m.role === "USER");
          if (leavingMember.role === "ADMIN") {
            admins = [leavingMember].concat(admins);
          } else {
            users = [leavingMember].concat(users);
          }
          return __spreadProps(__spreadValues({}, prev), {
            linkedMembers: __spreadProps(__spreadValues({}, prev.linkedMembers), {
              members: admins.concat(users).slice(0, 50)
            })
          });
        }
      }
    });
    const unsubscribeNewMember = subscribeToMore({
      document: NEW_MEMBER_SUBSCRIPTION,
      variables: {
        roomId
      },
      updateQuery: (prev) => __spreadProps(__spreadValues({}, prev), {
        linkedMembers: __spreadProps(__spreadValues({}, prev.LinkedMembers), {
          memberCount: prev.linkedMembers.memberCount + 1
        })
      })
    });
    const unsubscribeMemberUnlinks = subscribeToMore({
      document: MEMBER_UNLINKS_SUBSCRIPTION,
      variables: {
        roomId
      },
      updateQuery: (prev) => __spreadProps(__spreadValues({}, prev), {
        linkedMembers: __spreadProps(__spreadValues({}, prev.LinkedMembers), {
          memberCount: prev.linkedMembers.memberCount - 1
        })
      })
    });
    const unsubscribeRoleChange = subscribeToMore({
      document: SET_ROLE_SUBSCRIPTION,
      variables: {
        id: roomId
      },
      updateQuery: (prev, {
        subscriptionData
      }) => {
        return __spreadProps(__spreadValues({}, prev), {
          linkedMembers: __spreadProps(__spreadValues({}, prev.linkedMembers), {
            members: prev.linkedMembers.members.map((m) => {
              if (m.id !== subscriptionData.data.setRole.id)
                return m;
              return __spreadProps(__spreadValues({}, m), {
                role: subscriptionData.data.setRole.role
              });
            })
          })
        });
      }
    });
    return () => {
      unsubscribeNewMember();
      unsubscribeMemberUnlinks();
      unsubscribeMemberLeaves();
      unsubscribeRoleChange();
    };
  }, [roomId, subscribeToMore]);
};
