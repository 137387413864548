"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const baseStyles = {
  base: {
    "Html__baseStyles.base": "Html__baseStyles.base",
    boxSizing: "x9f619",
    textSizeAdjust: "x1javzw2",
    touchAction: "xggy1nq",
    userSelect: "x87ps6o",
    WebKitUserSelect: "x1bib5ie",
    outline: "x1a2a7pz",
    WebKitTapHighlightColor: "x1gp3oje",
    WebKitAppearance: "x67wf27",
    appearance: "xjyslct",
    colorScheme: "xntwwlm",
    fontFamily: "x1o3ll5r",
    fontSize: "x1qlqyl8",
    ":focus_outlineColor": "x1xwnvu1",
    ":focus_outlineStyle": "xhlp2gg",
    "::before_boxSizing": "xtql2tq",
    "::before_textSizeAdjust": "x1wooiz5",
    "::before_touchAction": "xpefml7",
    "::before_userSelect": "x1rbqspl",
    "::before_WebKitUserSelect": "x1hxrwom",
    "::after_boxSizing": "xx38sju",
    "::after_textSizeAdjust": "xxcbro4",
    "::after_touchAction": "x3f4zlw",
    "::after_userSelect": "x1jbr1xh",
    "::after_WebKitUserSelect": "x1gxgjh0",
    "::selection_color": "x1pfyes6",
    "::selection_background": "x86bo8",
    $$css: true
  },
  videoBase: {
    "Html__baseStyles.videoBase": "Html__baseStyles.videoBase",
    "::-internal-media-controls-overlay-cast-button_display": "xupis34",
    $$css: true
  }
};
const isDev = process.env.NODE_ENV === "development";
var _default = exports.default = {
  div: t0 => {
    var _a, _b, _c2, _d, _e;
    const $ = (0, _compilerRuntime.c)(20);
    let children;
    let props;
    let ref;
    let style;
    let styleProps;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style,
        styleProps,
        children
      } = _a, props = __objRest(_a, ["ref", "style", "styleProps", "children"]);
      $[0] = t0;
      $[1] = children;
      $[2] = props;
      $[3] = ref;
      $[4] = style;
      $[5] = styleProps;
    } else {
      children = $[1];
      props = $[2];
      ref = $[3];
      style = $[4];
      styleProps = $[5];
    }
    let className;
    let styleProp;
    let t1;
    if ($[6] !== style) {
      ({
        className,
        style: styleProp
      } = stylex.props(baseStyles.base, style));
      t1 = isDev ? (((_e = (_d = (_c2 = (_b = className == null ? void 0 : className.split(" ")) == null ? void 0 : _b.filter(_temp)) == null ? void 0 : _c2.filter(_temp2)) == null ? void 0 : _d.map(_temp3)) == null ? void 0 : _e.join(" ")) + " " || "").trim() : void 0;
      $[6] = style;
      $[7] = className;
      $[8] = styleProp;
      $[9] = t1;
    } else {
      className = $[7];
      styleProp = $[8];
      t1 = $[9];
    }
    const visualClasses = t1;
    let t2;
    if ($[10] !== styleProp || $[11] !== styleProps) {
      t2 = __spreadValues(__spreadValues({}, styleProp), styleProps);
      $[10] = styleProp;
      $[11] = styleProps;
      $[12] = t2;
    } else {
      t2 = $[12];
    }
    let t3;
    if ($[13] !== children || $[14] !== className || $[15] !== props || $[16] !== ref || $[17] !== t2 || $[18] !== visualClasses) {
      t3 = /* @__PURE__ */_react.default.createElement("div", __spreadProps(__spreadValues({
        ref
      }, props), {
        "data-id": visualClasses,
        className,
        style: t2
      }), children);
      $[13] = children;
      $[14] = className;
      $[15] = props;
      $[16] = ref;
      $[17] = t2;
      $[18] = visualClasses;
      $[19] = t3;
    } else {
      t3 = $[19];
    }
    return t3;
  },
  a: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("a", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  video: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(12);
    let props;
    let ref;
    let style;
    let styleProps;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style,
        styleProps
      } = _a, props = __objRest(_a, ["ref", "style", "styleProps"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
      $[4] = styleProps;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
      styleProps = $[4];
    }
    let t1;
    if ($[5] !== style) {
      t1 = stylex.props(baseStyles.base, baseStyles.videoBase, style);
      $[5] = style;
      $[6] = t1;
    } else {
      t1 = $[6];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[7] !== className || $[8] !== props || $[9] !== ref || $[10] !== styleProps) {
      t2 = /* @__PURE__ */_react.default.createElement("video", __spreadProps(__spreadValues({
        playsInline: true,
        ref
      }, props), {
        style: styleProps,
        className
      }));
      $[7] = className;
      $[8] = props;
      $[9] = ref;
      $[10] = styleProps;
      $[11] = t2;
    } else {
      t2 = $[11];
    }
    return t2;
  },
  iframe: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("iframe", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  canvas: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("canvas", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  dialog: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("dialog", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  label: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("label", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  input: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("input", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  output: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("output", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  progress: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("progress", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  img: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(11);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className,
      style: styleProp
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref || $[9] !== styleProp) {
      t2 = /* @__PURE__ */_react.default.createElement("img", __spreadProps(__spreadValues({
        ref
      }, props), {
        style: styleProp,
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = styleProp;
      $[10] = t2;
    } else {
      t2 = $[10];
    }
    return t2;
  },
  form: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("form", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  h2: t0 => {
    var _a;
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      _a = t0, {
        ref,
        style
      } = _a, props = __objRest(_a, ["ref", "style"]);
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("h2", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  ol: t0 => {
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      const _a = t0,
        {
          ref: t12,
          style: t22,
          type: _type
        } = _a,
        t3 = __objRest(_a, ["ref", "style", "type"]);
      ref = t12;
      style = t22;
      props = t3;
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("ol", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  ul: t0 => {
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      const _a = t0,
        {
          ref: t12,
          style: t22,
          type: _type
        } = _a,
        t3 = __objRest(_a, ["ref", "style", "type"]);
      ref = t12;
      style = t22;
      props = t3;
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("ul", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  },
  li: t0 => {
    const $ = (0, _compilerRuntime.c)(10);
    let props;
    let ref;
    let style;
    if ($[0] !== t0) {
      const _a = t0,
        {
          ref: t12,
          style: t22,
          type: _type
        } = _a,
        t3 = __objRest(_a, ["ref", "style", "type"]);
      ref = t12;
      style = t22;
      props = t3;
      $[0] = t0;
      $[1] = props;
      $[2] = ref;
      $[3] = style;
    } else {
      props = $[1];
      ref = $[2];
      style = $[3];
    }
    let t1;
    if ($[4] !== style) {
      t1 = stylex.props(baseStyles.base, style);
      $[4] = style;
      $[5] = t1;
    } else {
      t1 = $[5];
    }
    const {
      className
    } = t1;
    let t2;
    if ($[6] !== className || $[7] !== props || $[8] !== ref) {
      t2 = /* @__PURE__ */_react.default.createElement("li", __spreadProps(__spreadValues({
        ref
      }, props), {
        className
      }));
      $[6] = className;
      $[7] = props;
      $[8] = ref;
      $[9] = t2;
    } else {
      t2 = $[9];
    }
    return t2;
  }
};
function _temp(c) {
  return c == null ? void 0 : c.includes("__");
}
function _temp2(c_0) {
  return !(c_0 == null ? void 0 : c_0.includes("baseStyles.base"));
}
function _temp3(c_1) {
  return c_1 == null ? void 0 : c_1.replace(/^.*[.]/, "").trim();
}