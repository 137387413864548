"use strict";
var _a, _b, _c, _d;
import platform from "platform";
export { default as PremiumModal } from "./PremiumModal";
export { default as PaywallMessage } from "./PaywallMessage";
export { default as PremiumScreen } from "./PremiumScreen";
export { default as Diamond } from "./Diamond";
export { default as InviteModal } from "./InviteModal";
const isIOS = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = platform) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
export const hidePremium = (isIOS || isMacOS) && isCapacitor;
import PremiumModal from "./PremiumModal";
export default PremiumModal;
