"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextMenu = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Transition = require("./Transition");
var _Icon = require("./Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("./helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const styles = {
  menuWrapper: {
    "ContextMenu__styles.menuWrapper": "ContextMenu__styles.menuWrapper",
    display: "x78zum5",
    position: "x1n2onr6",
    pointerEvents: "x71s49j",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  menu: {
    "ContextMenu__styles.menu": "ContextMenu__styles.menu",
    position: "x10l6tqk",
    backgroundColor: "x18zb6r9",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "xu96u03",
    zIndex: "x1n327nk",
    width: "xeq5yr9",
    flexDirection: "xdt5ytf",
    boxShadow: "xvxy23r",
    display: "x78zum5",
    minWidth: "xgqtt45",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "x1rea2x4",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  fluid: {
    "ContextMenu__styles.fluid": "ContextMenu__styles.fluid",
    width: "xh8yej3",
    minWidth: "xgqtt45",
    maxWidth: "x193iq5w",
    $$css: true
  },
  slideUp: {
    "ContextMenu__styles.slideUp": "ContextMenu__styles.slideUp",
    bottom: "x10w3d4m",
    $$css: true
  },
  slideDown: {
    "ContextMenu__styles.slideDown": "ContextMenu__styles.slideDown",
    top: "xdsb8wn",
    $$css: true
  },
  center: {
    "ContextMenu__styles.center": "ContextMenu__styles.center",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  right: {
    "ContextMenu__styles.right": "ContextMenu__styles.right",
    marginRight: "xack27t",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginLeft: "xj3b58b",
    $$css: true
  },
  left: {
    "ContextMenu__styles.left": "ContextMenu__styles.left",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    $$css: true
  },
  menuItem: {
    "ContextMenu__styles.menuItem": "ContextMenu__styles.menuItem",
    position: "x1n2onr6",
    fontSize: "xif65rj",
    color: "x11jfisy",
    whiteSpace: "xuxw1ft",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: "x1igx7t4",
    paddingEnd: null,
    paddingRight: "x172e5tb",
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    paddingInlineStart: null,
    paddingInlineEnd: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    minWidth: "xgqtt45",
    "@media (max-width: 1000px)_fontSize": "x15saapg",
    "@media (max-width: 1000px)_padding": "x1byiabj",
    "@media (max-width: 1000px)_paddingInline": null,
    "@media (max-width: 1000px)_paddingStart": null,
    "@media (max-width: 1000px)_paddingLeft": "xn8cnpx",
    "@media (max-width: 1000px)_paddingEnd": null,
    "@media (max-width: 1000px)_paddingRight": "x16t5keo",
    "@media (max-width: 1000px)_paddingBlock": null,
    "@media (max-width: 1000px)_paddingTop": null,
    "@media (max-width: 1000px)_paddingBottom": null,
    "@media (max-width: 1000px)_paddingInlineStart": null,
    "@media (max-width: 1000px)_paddingInlineEnd": null,
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x1j37zrf",
    ":hover_cursor": "x1277o0a",
    ":active_backgroundColor": "xn6vc48",
    $$css: true
  }
};
const ContextMenuItem = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(6);
  const {
    ref,
    onClick,
    icon,
    text
  } = t0;
  let t1;
  if ($[0] !== icon) {
    t1 = icon && (typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: icon
    }) : icon);
    $[0] = icon;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== onClick || $[3] !== t1 || $[4] !== text) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref,
      style: styles.menuItem,
      onClick
    }, t1, text);
    $[2] = onClick;
    $[3] = t1;
    $[4] = text;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  return t2;
});
const ContextMenuRoot = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(31);
  const {
    ref,
    open,
    onClose,
    trigger,
    children,
    direction,
    position,
    onOpen,
    fluid,
    className: classNameProp
  } = t0;
  const containerRef = (0, _react.useRef)(null);
  const btnRef = (0, _react.useRef)(null);
  const t1 = "current" in trigger ? trigger : btnRef;
  let t2;
  if ($[0] !== t1) {
    t2 = [containerRef, t1];
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  (0, _helpers.useOnClickOutside)(t2, onClose || _temp);
  let t3;
  let t4;
  if ($[2] !== onOpen || $[3] !== open) {
    t3 = () => {
      if (onOpen && open) {
        onOpen();
      }
    };
    t4 = [open, onOpen];
    $[2] = onOpen;
    $[3] = open;
    $[4] = t3;
    $[5] = t4;
  } else {
    t3 = $[4];
    t4 = $[5];
  }
  (0, _react.useEffect)(t3, t4);
  let t5;
  let t6;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = () => containerRef.current;
    t6 = [containerRef];
    $[6] = t5;
    $[7] = t6;
  } else {
    t5 = $[6];
    t6 = $[7];
  }
  (0, _react.useImperativeHandle)(ref, t5, t6);
  const btnRefStored = (0, _helpers.useMergedRefs)(btnRef);
  const t7 = classNameProp instanceof Object && classNameProp;
  let t8;
  if ($[8] !== t7) {
    t8 = [styles.menuWrapper, t7];
    $[8] = t7;
    $[9] = t8;
  } else {
    t8 = $[9];
  }
  let t9;
  if ($[10] !== btnRefStored || $[11] !== trigger) {
    t9 = "current" in trigger ? null : _react.default.cloneElement(trigger, {
      ref: btnRefStored
    });
    $[10] = btnRefStored;
    $[11] = trigger;
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = {
      hide: 100,
      show: 100
    };
    $[13] = t10;
  } else {
    t10 = $[13];
  }
  const t11 = "slide " + (direction || "down");
  const t12 = direction === "up" ? styles.slideUp : styles.slideDown;
  const t13 = position === "right" && styles.right;
  const t14 = (!position || position === "left") && styles.left;
  const t15 = position === "center" && styles.center;
  const t16 = fluid && styles.fluid;
  let t17;
  if ($[14] !== t12 || $[15] !== t13 || $[16] !== t14 || $[17] !== t15 || $[18] !== t16) {
    t17 = [styles.menu, t12, t13, t14, t15, t16];
    $[14] = t12;
    $[15] = t13;
    $[16] = t14;
    $[17] = t15;
    $[18] = t16;
    $[19] = t17;
  } else {
    t17 = $[19];
  }
  let t18;
  if ($[20] !== children || $[21] !== t17) {
    t18 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      ref: containerRef,
      style: t17
    }, children);
    $[20] = children;
    $[21] = t17;
    $[22] = t18;
  } else {
    t18 = $[22];
  }
  let t19;
  if ($[23] !== open || $[24] !== t11 || $[25] !== t18) {
    t19 = /* @__PURE__ */_react.default.createElement(_Transition.Transition, {
      duration: t10,
      animation: t11,
      visible: open
    }, t18);
    $[23] = open;
    $[24] = t11;
    $[25] = t18;
    $[26] = t19;
  } else {
    t19 = $[26];
  }
  let t20;
  if ($[27] !== t19 || $[28] !== t8 || $[29] !== t9) {
    t20 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t8
    }, t9, t19);
    $[27] = t19;
    $[28] = t8;
    $[29] = t9;
    $[30] = t20;
  } else {
    t20 = $[30];
  }
  return t20;
});
const ContextMenu = exports.ContextMenu = Object.assign(ContextMenuRoot, {
  Item: ContextMenuItem
});
function _temp() {}