"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import * as SPACES from "@/room/apps/spaces/index";
import * as LOBBY from "@/room/apps/lobby/index";
export default {
  PSX: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.psx" */
      "@/room/apps/psx"
    );
  }),
  CARDTABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.cardtable" */
      "@/room/apps/cardtable"
    );
  }),
  SNES_PARTY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.snes" */
      "@/room/apps/snes"
    );
  }),
  NES_PARTY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.nes" */
      "@/room/apps/nes"
    );
  }),
  OPENARENA: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.openarena" */
      "@/room/apps/openarena"
    );
  }),
  POKER: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.poker" */
      "@/room/apps/poker"
    );
  }),
  CARDS_AGAINST_HUMANITY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.cards_against_humanity" */
      "@/room/apps/cards_against_humanity"
    );
  }),
  LOBBY: () => __async(void 0, null, function* () {
    return LOBBY;
  }),
  VIDEO_CHAT: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.videochat" */
      "@/room/apps/videochat"
    );
  }),
  WORLD_SINGLES: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.worldsingles" */
      "@/room/apps/worldsingles"
    );
  }),
  POOLTABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.pooltable" */
      "@/room/apps/pooltable"
    );
  }),
  WEBVIEW: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.webview" */
      "@/room/apps/webview"
    );
  }),
  CRAZYGAMES: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.crazygames" */
      "@/room/apps/crazygames"
    );
  }),
  KOSMI_CLOUD: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.kosmi_cloud" */
      "@/room/apps/kosmi_cloud"
    );
  }),
  WEB_BROWSER: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.web_browser" */
      "@/room/apps/web_browser"
    );
  }),
  PAINT: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.paint" */
      "@/room/apps/paint"
    );
  }),
  TABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.table" */
      "@/room/apps/table"
    );
  }),
  SPACES: () => __async(void 0, null, function* () {
    return SPACES;
  }),
  WORTLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.wortle" */
      "@/room/apps/wortle"
    );
  }),
  QUIZ: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.wortle" */
      "@/room/apps/quiz"
    );
  })
};
