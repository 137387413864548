"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FriendsIcon = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _ = require("./");
var _PrivateMessages = _interopRequireWildcard(require("@/shared/components/PrivateMessages"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const glowingIcon = "x2u31rq-B";
const styles = {
  messagesDropdownMenu: {
    "MessagesDropdown__styles.messagesDropdownMenu": "MessagesDropdown__styles.messagesDropdownMenu",
    position: "x1n2onr6",
    height: "x16nrsnc",
    width: "xdzyupr",
    maxHeight: "x1phlbz0",
    $$css: true
  },
  messageNumber: {
    "MessagesDropdown__styles.messageNumber": "MessagesDropdown__styles.messageNumber",
    position: "x10l6tqk",
    left: "x1rvfpmx",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1jzctok",
    width: "x1a00udw",
    height: "xx3o462",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    background: "x7v9ooy",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    fontWeight: "x1xlr1w8",
    padding: "x46zyou",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1k6wstc",
    lineHeight: "x6kjo9u",
    $$css: true
  },
  glowingIcon: {
    "MessagesDropdown__styles.glowingIcon": "MessagesDropdown__styles.glowingIcon",
    color: "x16cd2qt",
    fontWeight: "xuv8nkb",
    animationName: "x19n8mo0",
    animationDuration: "xmg6eyc",
    animationTimingFunction: "x4hg4is",
    animationIterationCount: "xa4qsjk",
    animationDirection: "xpz12be",
    $$css: true
  }
};
const FriendsIcon = ({
  ref,
  unreadCount,
  onClick
}) => {
  return /* @__PURE__ */_react.default.createElement(_.TopBarButton, {
    ref,
    onClick
  }, unreadCount > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageNumber
  }, unreadCount), /* @__PURE__ */_react.default.createElement(_.TopBarIcon, {
    name: "comment",
    className: [unreadCount > 0 && styles.glowingIcon]
  }));
};
exports.FriendsIcon = FriendsIcon;
const MessagesDropdownWrapper = (0, _react.memo)(() => {
  const {
    messageList,
    refetch: refetchMessageList,
    unreadCount
  } = (0, _PrivateMessages.useMessages)();
  const [showDropdown, setShowDropdown] = (0, _react.useState)(false);
  const contextMenuRef = (0, _react.useRef)(null);
  const closeDropdown = (0, _react.useCallback)(() => setShowDropdown(false), []);
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    open: showDropdown,
    onClose: closeDropdown,
    position: "left",
    ref: contextMenuRef,
    trigger: /* @__PURE__ */_react.default.createElement(FriendsIcon, {
      onClick: () => {
        setShowDropdown(!showDropdown);
      },
      unreadCount
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messagesDropdownMenu
  }, /* @__PURE__ */_react.default.createElement(_PrivateMessages.default, {
    refetchMessageList,
    messageList,
    contextMenuRef
  })));
});
var _default = exports.default = MessagesDropdownWrapper;