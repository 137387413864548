"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _helpers = require("kosmi-sdk/helpers");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMembers = require("@/room/hooks/useMembers");
var _MediaPlayerContext = _interopRequireDefault(require("@/room/contexts/MediaPlayerContext"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const relayServerMsg = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Relay server offers better performance for", /* @__PURE__ */_react.default.createElement(_uikit.Html.ul, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Slow connections"), /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Rooms with a large number of members")));
const styles = {
  bottom: {
    "LocalFileModal__styles.bottom": "LocalFileModal__styles.bottom",
    width: "xh8yej3",
    maxWidth: "x65f84u",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xieb3on",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  outerWrapper: {
    "LocalFileModal__styles.outerWrapper": "LocalFileModal__styles.outerWrapper",
    display: "x78zum5",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  innerWrapper: {
    "LocalFileModal__styles.innerWrapper": "LocalFileModal__styles.innerWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    justifyContent: "xaw8158",
    maxHeight: "xuyqlj2",
    $$css: true
  },
  advancedSettings: {
    "LocalFileModal__styles.advancedSettings": "LocalFileModal__styles.advancedSettings",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    alignItems: "x6s0dn4",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    $$css: true
  },
  questionMark: {
    "LocalFileModal__styles.questionMark": "LocalFileModal__styles.questionMark",
    cursor: "x1ypdohk",
    color: "x16cd2qt",
    textDecoration: "x1bvjpef",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    marginLeft: "xd8780z",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  loadVideoFileButton: {
    "LocalFileModal__styles.loadVideoFileButton": "LocalFileModal__styles.loadVideoFileButton",
    fontWeight: "x1xlr1w8",
    padding: "xdfu9ks",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    cursor: "x1ypdohk",
    backgroundColor: "x1ut2sa4",
    color: "xk4qdrg",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "x4n8cb0",
    $$css: true
  },
  hidden: {
    "LocalFileModal__styles.hidden": "LocalFileModal__styles.hidden",
    display: "x1s85apg",
    $$css: true
  }
};
var _default = exports.default = (0, _helpers.withDialogs)(({
  onClose,
  alert
}) => {
  const members = (0, _useMembers.useMembers)();
  const {
    setLocalUrl,
    setRelayStream,
    setLocalStream
  } = (0, _react.useContext)(_MediaPlayerContext.default);
  const [relay, setRelay] = (0, _react.useState)(members && members.length > 2);
  const track = (0, _helpers.useTracking)();
  (0, _react.useEffect)(() => {
    track("UI", "LocalFileModalOpened", "File modal opened");
  }, [track]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.outerWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.innerWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.label, {
    style: styles.loadVideoFileButton,
    htmlFor: "videofile"
  }, "Load a Video File..."), /* @__PURE__ */_react.default.createElement(_uikit.Html.input, {
    type: "file",
    id: "videofile",
    name: "videofile",
    style: styles.hidden,
    onChange: e => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const fileURL = URL.createObjectURL(file);
        e.target.value = "";
        setLocalUrl(fileURL);
        setRelayStream(!!relay);
        setLocalStream(null);
        onClose();
      }
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.bottom
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.advancedSettings
  }, /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    label: "Enable Relay Server",
    defaultChecked: relay || false,
    toggle: true,
    onChange: (_e, data) => {
      if (data.checked) {
        track("UI", "RelayServerEnabled", "Relay server enabled");
      } else {
        track("UI", "RelayServerDisabled", "Relay server disabled");
      }
      setRelay(!!data.checked);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.questionMark,
    onClick: () => alert && alert(relayServerMsg)
  }, "(???)"))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "For list of supported video formats look", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    rel: "noreferrer noopener",
    target: "_blank",
    href: "https://www.chromium.org/audio-video"
  }, "here")), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "If you have problems with your file(e.g. audio missing) try converting your file using the", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    rel: "noreferrer noopener",
    target: "_blank",
    href: "https://kosmi.io/converter.html"
  }, "Kosmi Video Converter"))));
});