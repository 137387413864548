"use strict";
import { INIT_QUERY } from "@/api/client";
import { useQuery } from "@/shared/hooks/gql";
export default () => {
  const {
    data
  } = useQuery(INIT_QUERY, {
    fetchPolicy: "cache-first"
  });
  return (data == null ? void 0 : data.colorSchemes) || [];
};
