"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ReactPlayer from "react-player";
import spinnerGif from "./spinner.gif";
import AVEngine from "@/services/AVEngine";
export const pausePlayer = (player) => {
  if (!player)
    return;
  if (player.pause) {
    try {
      return player.pause();
    } catch (e) {
      console.warn(e);
    }
  }
  if (player.pauseVideo) {
    try {
      return player.pauseVideo();
    } catch (e) {
      console.warn(e);
    }
  }
};
export const unPausePlayer = (player) => __async(void 0, null, function* () {
  if (!player)
    return;
  if (player.play) {
    try {
      return yield player.play();
    } catch (e) {
      console.warn(e);
    }
  }
  if (player.playVideo) {
    try {
      return player.playVideo();
    } catch (e) {
      console.warn(e);
    }
  }
});
export const getIsMuted = (player) => __async(void 0, null, function* () {
  if (!player)
    return false;
  if (player.muted !== void 0) {
    return player.muted;
  }
  if (player.isMuted) {
    return player.isMuted();
  }
  if (player.getMuted) {
    return player.getMuted();
  }
});
export const getIsPaused = (player) => __async(void 0, null, function* () {
  if (!player)
    return true;
  if (player.paused !== void 0) {
    return player.paused;
  }
  if (player.getPlayerState) {
    const state = player.getPlayerState();
    if (typeof state === "number") {
      return [-1, 2, 5].indexOf(state) !== -1;
    }
  }
  if (player.isPaused) {
    const val = player.isPaused();
    if (typeof val === "boolean")
      return val;
  }
  if (player.isPaused) {
    return new Promise((resolve) => {
      if (player.isPaused)
        player.isPaused((result) => {
          resolve(result);
        });
    });
  }
  if (player.getIsPaused) {
    return player.getIsPaused();
  }
  if (player.getPaused) {
    return player.getPaused();
  }
  if (player.state) {
    return player.state() === "paused";
  }
  return true;
});
export const getInternalPlayerVolume = (player) => __async(void 0, null, function* () {
  let vol;
  if (player == null ? void 0 : player.getVolume) {
    vol = player.getVolume();
  } else if (typeof (player == null ? void 0 : player.volume) === "number") {
    vol = player.volume;
  } else {
    vol = AVEngine.initialEmbedVolume;
  }
  if (vol > 1) {
    vol = vol / 100;
  }
  return vol;
});
export const isCinesendUrl = (url) => url.startsWith("https://screeners.cinesend.com/view/") || url.startsWith("https://cinesend.kosmi.io");
export const facebookAndVidyardWorkaround = (internalPlayer, setPaused) => {
  var _a;
  if (internalPlayer == null ? void 0 : internalPlayer.subscribe) {
    internalPlayer.subscribe("paused", () => {
      setPaused(true);
    });
    internalPlayer.subscribe("startedPlaying", () => {
      setPaused(false);
    });
  } else if ((_a = internalPlayer == null ? void 0 : internalPlayer.org) == null ? void 0 : _a.on) {
    if (!internalPlayer.on)
      return;
    internalPlayer.on("pause", () => {
      setPaused(true);
    });
    internalPlayer.on("play", () => {
      setPaused(false);
    });
  }
};
export const ignoredErrors = ["playinterrupted", "notallowederror", "playbackerror", "aborterror", "hlsError", "rangeerror", "error"];
export const getPlayerOptions = (autoPlay) => ({
  youtube: {
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
      controls: 1,
      origin: "https://app.kosmi.io"
    }
  },
  soundcloud: {
    options: {
      auto_play: autoPlay
    }
  },
  vimeo: {
    playerOptions: {
      autoplay: autoPlay
    }
  },
  wistia: {
    options: {
      autoPlay
    }
  },
  mixcloud: {
    options: {
      autoplay: autoPlay
    }
  },
  dailymotion: {
    params: {
      autoplay: autoPlay
    }
  },
  twitch: {
    options: {
      autoplay: autoPlay,
      parent: ["app.kosmi.io", "localhost"]
    }
  },
  file: {
    attributes: {
      autoPlay,
      poster: spinnerGif
    },
    hlsVersion: "1.0.4",
    hlsOptions: {
      maxBufferLength: 300,
      fragLoadingTimeOut: 6e4,
      manifestLoadingTimeOut: 6e4,
      levelLoadingTimeOut: 6e4
    }
  }
});
export const clearSubtitleCues = (internalPlayer) => {
  var _a;
  if ((_a = internalPlayer == null ? void 0 : internalPlayer.textTracks) == null ? void 0 : _a.length) {
    const cues = internalPlayer.textTracks[0].cues || [];
    [...cues].forEach((cue) => {
      internalPlayer.textTracks[0].removeCue(cue);
    });
  }
};
export const addSubtitles = (internalPlayer, subtitles, offset = 0) => {
  var _a;
  if (subtitles) {
    clearSubtitleCues(internalPlayer);
    let track = internalPlayer.textTracks[0];
    if (!track && internalPlayer) {
      track = internalPlayer.addTextTrack("captions", "Subs", "en_EN");
    }
    const cues = (_a = subtitles.cues) == null ? void 0 : _a.map((cue) => {
      const {
        start,
        end,
        text
      } = cue || {};
      if (!start || !end || !text)
        return null;
      return new VTTCue(start / 1e3 - offset, end / 1e3 - offset, text);
    });
    cues == null ? void 0 : cues.forEach((cue) => {
      if (cue) {
        track.addCue(cue);
      }
    });
    track.mode = "showing";
  }
};
export const OBS_URL_PROTOCOL = "obs://";
export const RTC_SCREENSHARE_PROTOCOL = "RTCScreenshare://";
export const RTC_URL_PROTOCOL = "RTCUrl://";
export const RELAYED_RTC_SCREENSHARE_PROTOCOL = `Relayed${RTC_SCREENSHARE_PROTOCOL}`;
export const RELAYED_RTC_URL_PROTOCOL = `Relayed${RTC_URL_PROTOCOL}`;
export function isScreenShare(url) {
  return Boolean(url && (url.startsWith(RTC_SCREENSHARE_PROTOCOL) || url.startsWith(RELAYED_RTC_SCREENSHARE_PROTOCOL)));
}
export function isVbrowser(url) {
  return Boolean(url && url.startsWith("VBrowser://"));
}
export function isObsUrl(url) {
  return Boolean(url && url.toLowerCase().startsWith(OBS_URL_PROTOCOL));
}
export function isRtcUrl(url) {
  return Boolean(url && (url.startsWith(RTC_URL_PROTOCOL) || url.startsWith(RELAYED_RTC_URL_PROTOCOL)));
}
export function isRelayed(url) {
  return Boolean(url && (url.startsWith(RELAYED_RTC_SCREENSHARE_PROTOCOL) || url.startsWith(RELAYED_RTC_URL_PROTOCOL)));
}
export function preloadProxy(url) {
  return __async(this, null, function* () {
    const proxiedUrl = prependProxy(url);
    if (proxiedUrl) {
      return yield fetch(proxiedUrl);
    }
  });
}
export function removeProxy(url) {
  const originalUrl = new URLSearchParams(url).get("url");
  if (isProxied(url) && originalUrl) {
    return originalUrl;
  }
}
export function isProxied(url) {
  return !!(url && url.startsWith("https://videorelay.kosmi.io"));
}
export function canbeProxied(url) {
  return __async(this, null, function* () {
    if (url && (url.startsWith("https://") || url.startsWith("http://")) && !isProxied(url)) {
      try {
        yield preloadProxy(url);
        return true;
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  });
}
export function prependProxy(url) {
  return `https://videorelay.kosmi.io/dlp.m3u8?url=${encodeURIComponent(url)}`;
}
export function isHttp(url) {
  return url && (url.startsWith("https://") || url.startsWith("http://"));
}
export function getVideoTitle(url) {
  let videoInfo = "Remote stream";
  if (url) {
    if (isRtcUrl(url)) {
      videoInfo = "Local file";
      if (isRelayed(url)) {
        videoInfo = "Local file (Relayed)";
      } else {
        videoInfo = "Local file";
      }
    } else if (isScreenShare(url)) {
      if (isRelayed(url)) {
        videoInfo = "Screenshare(Relayed)";
      } else {
        videoInfo = "Screenshare";
      }
    } else if (url.startsWith("http")) {
      const truncateString = (str, maxSize) => str.length > maxSize ? str.slice(-maxSize) : str;
      if (ReactPlayer.canPlay(url)) {
        try {
          const parsedUrl = new URL(url);
          videoInfo = truncateString(parsedUrl.hostname.replace(/^www\./, ""), 20);
        } catch (e) {
          console.error(e);
        }
      }
    } else if (url.startsWith("VBrowser://")) {
      videoInfo = "Virtual browser";
    }
  }
  return videoInfo;
}
