"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useQuery } from "@/shared/hooks/gql";
import React from "react";
import { INIT_QUERY } from "@/api/client";
const useCurrentUser = () => {
  var _a;
  const {
    data
  } = useQuery(INIT_QUERY, {
    fetchPolicy: "cache-first"
  });
  return (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user;
};
export const currentUserHook = useCurrentUser;
export const withCurrentUser = (
  // eslint-disable-next-line
  (WrappedComponent, placeholder) => (props) => {
    const currentUser = currentUserHook();
    if (!currentUser) {
      if (placeholder)
        return placeholder;
      return null;
    }
    return /* @__PURE__ */ React.createElement(WrappedComponent, __spreadProps(__spreadValues({}, props), { currentUser }));
  }
);
export default withCurrentUser;
