"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MARK_SEEN_MUTATION = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _ = require("./");
var _useRegisterWebPushNotifications = _interopRequireDefault(require("@/shared/hooks/useRegisterWebPushNotifications"));
var _LoginWall = _interopRequireDefault(require("@/shared/components/LoginWall"));
var _Notifications = _interopRequireDefault(require("@/shared/components/Notifications"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const glowingIcon = "x2u31rq-B";
const styles = {
  notificationNumber: {
    "NotificationDropdown__styles.notificationNumber": "NotificationDropdown__styles.notificationNumber",
    position: "x10l6tqk",
    left: "x1rvfpmx",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1jzctok",
    height: "xx3o462",
    width: "x1a00udw",
    background: "x7v9ooy",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    padding: "x46zyou",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1k6wstc",
    lineHeight: "x6kjo9u",
    $$css: true
  },
  glowingIcon: {
    "NotificationDropdown__styles.glowingIcon": "NotificationDropdown__styles.glowingIcon",
    color: "x16cd2qt",
    fontWeight: "xuv8nkb",
    animationName: "x19n8mo0",
    animationDuration: "xmg6eyc",
    animationTimingFunction: "x4hg4is",
    animationIterationCount: "xa4qsjk",
    animationDirection: "xpz12be",
    $$css: true
  }
};
const NOTIFICATION_QUERY = (0, _gql.gql)(`
  query NotificationQuery2 {
    currentUser {
      id
      connectionId
      user {
        id
        username
        isAnonymous
        notifications {
          id
          title
          text
          type
          timestamp
          seen
          autoOpen
          sender {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const MARK_SEEN_MUTATION = exports.MARK_SEEN_MUTATION = (0, _gql.gql)(`
  mutation MarkAllNotificationsAsSeen {
    markAllNotificationsAsSeen {
      id
      seen
    }
  }
`);
const NotificationIcon = (0, _react.memo)(() => {
  var _a, _b, _c, _d, _e;
  const {
    data
  } = (0, _gql.useQuery)(NOTIFICATION_QUERY);
  const unseenNotifications = !((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.isAnonymous) && ((_e = (_d = (_c = data == null ? void 0 : data.currentUser) == null ? void 0 : _c.user) == null ? void 0 : _d.notifications) == null ? void 0 : _e.filter(n => !(n == null ? void 0 : n.seen))) || [];
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, unseenNotifications.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.notificationNumber
  }, unseenNotifications.length), /* @__PURE__ */_react.default.createElement(_.TopBarIcon, {
    name: "bell",
    className: [unseenNotifications.length > 0 && styles.glowingIcon]
  }));
});
const NotificationDropdown = () => {
  const [showDropdown, setShowDropdown] = (0, _react.useState)(false);
  const [markAsSeen] = (0, _gql.useMutation)(MARK_SEEN_MUTATION);
  const registerWebPushNotifications = (0, _useRegisterWebPushNotifications.default)();
  const onOpen = (0, _react.useCallback)(() => {
    markAsSeen();
    registerWebPushNotifications();
  }, [markAsSeen, registerWebPushNotifications]);
  const onClose = (0, _react.useCallback)(() => {
    setShowDropdown(false);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_.TopBarButton, {
      onClick: () => {
        setShowDropdown(!showDropdown);
      }
    }, /* @__PURE__ */_react.default.createElement(NotificationIcon, null))),
    open: showDropdown,
    onOpen,
    onClose,
    position: "right"
  }, /* @__PURE__ */_react.default.createElement(_Notifications.default, {
    onClose
  }));
};
var _default = exports.default = NotificationDropdown;