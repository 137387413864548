"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getDisplayMediaWithAutoCrop = getDisplayMediaWithAutoCrop;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
var _useMembers = require("@/room/hooks/useMembers");
var _Premium = require("@/shared/components/Premium");
var _MediaPlayerContext = _interopRequireDefault(require("@/room/contexts/MediaPlayerContext"));
var _BackButton = _interopRequireDefault(require("./BackButton"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  outerWrapper: {
    "ScreenshareModal__styles.outerWrapper": "ScreenshareModal__styles.outerWrapper",
    display: "x78zum5",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  innerWrapper: {
    "ScreenshareModal__styles.innerWrapper": "ScreenshareModal__styles.innerWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    justifyContent: "xaw8158",
    maxHeight: "xuyqlj2",
    alignItems: "x6s0dn4",
    $$css: true
  },
  advancedSettings: {
    "ScreenshareModal__styles.advancedSettings": "ScreenshareModal__styles.advancedSettings",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x1s85apg",
    alignItems: "x6s0dn4",
    $$css: true
  },
  advancedSettingsOpen: {
    "ScreenshareModal__styles.advancedSettingsOpen": "ScreenshareModal__styles.advancedSettingsOpen",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  questionMark: {
    "ScreenshareModal__styles.questionMark": "ScreenshareModal__styles.questionMark",
    cursor: "x1ypdohk",
    color: "x16cd2qt",
    textDecoration: "x1bvjpef",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    marginLeft: "xd8780z",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  info: {
    "ScreenshareModal__styles.info": "ScreenshareModal__styles.info",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  simple: {
    "ScreenshareModal__styles.simple": "ScreenshareModal__styles.simple",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xieb3on",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  center: {
    "ScreenshareModal__styles.center": "ScreenshareModal__styles.center",
    textAlign: "x2b8uid",
    $$css: true
  },
  marginTop10: {
    "ScreenshareModal__styles.marginTop10": "ScreenshareModal__styles.marginTop10",
    marginTop: "x1anpbxc",
    $$css: true
  }
};
const videoConstraints = quality => {
  if (quality === "360p") {
    return {
      width: {
        max: 640
      },
      height: {
        max: 360
      },
      framerate: 24
    };
  }
  if (quality === "480p") {
    return {
      width: {
        max: 853
      },
      height: {
        max: 480
      },
      framerate: 24
    };
  }
  if (quality === "720p") {
    return {
      width: {
        max: 1280
      },
      height: {
        max: 720
      },
      framerate: 24
    };
  }
  if (quality === "1080p") {
    return {
      width: {
        max: 1920
      },
      height: {
        max: 1080
      },
      framerate: 24
    };
  }
  return {
    framerate: 24
  };
};
function getDisplayMediaWithAutoCrop(opts) {
  return __async(this, null, function* () {
    const stream = yield navigator.mediaDevices.getDisplayMedia(opts);
    const video = document.createElement("video");
    video.srcObject = stream;
    video.play();
    video.muted = true;
    video.style.display = "none";
    yield new Promise(resolve => video.onloadedmetadata = resolve);
    const sourceCanvas = document.createElement("canvas");
    const displayCanvas = document.createElement("canvas");
    const sourceContext = sourceCanvas.getContext("2d");
    const displayContext = displayCanvas.getContext("2d");
    const height = video.videoHeight;
    const width = height * (16 / 9);
    const x = (video.videoWidth - width) / 2;
    const y = 0;
    sourceCanvas.width = video.videoWidth;
    sourceCanvas.height = video.videoHeight;
    displayCanvas.width = opts.video.width.max;
    displayCanvas.height = opts.video.height.max;
    const crop = {
      x,
      y,
      width,
      height
    };
    const drawVideo = () => {
      if (!video.paused && !video.ended) {
        displayContext == null ? void 0 : displayContext.drawImage(sourceCanvas, crop.x, crop.y, crop.width, crop.height, 0, 0, displayCanvas.width, displayCanvas.height);
        requestAnimationFrame(drawVideo);
      }
    };
    const updateSourceCanvas = () => {
      if (!video.paused && !video.ended) {
        sourceContext == null ? void 0 : sourceContext.drawImage(video, 0, 0, sourceCanvas.width, sourceCanvas.height);
        requestAnimationFrame(updateSourceCanvas);
      }
    };
    updateSourceCanvas();
    drawVideo();
    const audioTrack = stream.getAudioTracks()[0];
    const newStream = new MediaStream(displayCanvas.captureStream().getTracks().concat([audioTrack]));
    return newStream;
  });
}
const audioConstraints = {
  channelCount: 2,
  noiseSuppression: false,
  autoGainControl: false,
  echoCancellation: false,
  latency: 0,
  sampleRate: 44100
  //suppressLocalAudioPlayback: true,
};
const EnableAudioModal = ({
  open,
  shareScreen,
  onClose,
  addAudioTrack,
  videoConstraints: videoConstraints2,
  stream,
  relay
}) => {
  const [showWindowWoraround, setShowWindowWoraround] = (0, _react.useState)(false);
  const mediaPlayerContext = (0, _react.useContext)(_MediaPlayerContext.default);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose,
    preventClosing: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Did you forget to enable audio?"), showWindowWoraround ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_BackButton.default, {
    onClick: () => setShowWindowWoraround(false)
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, 'Click the button below, then select "Your entire screen" and make sure to tick the "Share audio" box on the bottom left.'), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Once that is done, click the Share button and the audio track will be added, keeping the window as video."), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    onClick: () => __async(void 0, null, function* () {
      const stream2 = yield navigator.mediaDevices.getDisplayMedia({
        video: videoConstraints2,
        audio: audioConstraints
      });
      const audioTrack = [...stream2.getAudioTracks()][0];
      stream2.getVideoTracks().map(t => t.stop());
      if (audioTrack) {
        addAudioTrack(audioTrack);
        onClose();
      } else {
        stream2.getTracks().map(t => t.stop());
      }
    })
  }, "Get Audio from Entire screen")) : /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.center
  }, "Currently only Google Chrome supports streaming audio. In Chrome you need to tick the box shown in the image below:"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.center, styles.marginTop10]
  }, 'Note that for "Application Window" sharing this box is not available.', /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    onClick: () => setShowWindowWoraround(true)
  }, "Click here for a workaround"))), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Actions, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "white",
    onClick: () => {
      mediaPlayerContext.setLocalStream(stream);
      mediaPlayerContext.setRelayStream(!!relay);
      onClose();
    }
  }, "Continue without audio"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    onClick: () => {
      shareScreen();
    }
  }, "Reselect Screensharing")));
};
const relayServerMsg = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Relay server offers better performance for", /* @__PURE__ */_react.default.createElement(_uikit.Html.ul, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Slow connections"), /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Rooms with a large number of members")));
var _default = exports.default = (0, _helpers.withDialogs)(({
  localStream,
  onClose,
  alert,
  confirm,
  simple,
  customLabel
}) => {
  const members = (0, _useMembers.useMembers)();
  const currentUser = (0, _helpers.currentUserHook)();
  const [relay, setRelay] = (0, _react.useState)(members && members.length > 2);
  const [quality, setQuality] = (0, _react.useState)("480p");
  const [stream, setStream] = (0, _react.useState)(null);
  const [audioMissing, setAudioMissing] = (0, _react.useState)(false);
  const [advancedSettingsOpen, _setAdvancedSettingsOpen] = (0, _react.useState)(true);
  const track = (0, _helpers.useTracking)();
  const mediaPlayerContext = (0, _react.useContext)(_MediaPlayerContext.default);
  const addAudioTrack = audioTrack => {
    if (!stream) {
      throw Error("Adding track to non existing stream");
    }
    ;
    stream.oninactive = null;
    const newStream = new MediaStream(stream.getTracks().concat([audioTrack]));
    mediaPlayerContext.setLocalStream(newStream);
    setStream(newStream);
  };
  const shareScreen = () => __async(void 0, null, function* () {
    setAudioMissing(false);
    try {
      if (stream) {
        stream.getVideoTracks().map(t => t.stop());
      }
      let controller;
      if (window.CaptureController) {
        controller = new window.CaptureController();
        controller.setFocusBehavior("no-focus-change");
      }
      const newStream = yield navigator.mediaDevices.getDisplayMedia({
        controller,
        selfBrowserSurface: "exclude",
        video: videoConstraints(quality),
        audio: audioConstraints
      });
      if (!newStream) throw "Stream missing";
      const hasAudio = newStream.getAudioTracks().length > 0;
      if (hasAudio) {
        mediaPlayerContext.setLocalUrl(null);
        mediaPlayerContext.setLocalStream(newStream);
        mediaPlayerContext.setRelayStream(!!relay);
        setStream(newStream);
        onClose();
      } else {
        setStream(newStream);
        setAudioMissing(true);
      }
    } catch (e) {
      console.error(e);
    }
  });
  (0, _react.useEffect)(() => {
    track("UI", "ScreenshareModalOpened", "Screenshare modal opened");
  }, [track]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(EnableAudioModal, {
    relay: !!relay,
    stream,
    open: audioMissing,
    onClose: () => {
      setAudioMissing(false);
      onClose();
    },
    shareScreen,
    addAudioTrack,
    videoConstraints: videoConstraints(quality)
  }), !!window.chrome && navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.outerWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.innerWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    onClick: () => {
      localStream == null ? void 0 : localStream.getTracks().map(t => t.stop());
      shareScreen();
    }
  }, customLabel || "Click here to share your screen"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.advancedSettings, advancedSettingsOpen && styles.advancedSettingsOpen]
  }, !simple && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.simple
  }, /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    label: "Enable Relay Server",
    defaultChecked: relay || false,
    toggle: true,
    onChange: (_e, data) => {
      if (data.checked) {
        track("UI", "RelayServerEnabled", "Relay server enabled");
      } else {
        track("UI", "RelayServerDisabled", "Relay server disabled");
      }
      setRelay(!!data.checked);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.questionMark,
    onClick: () => alert && alert(relayServerMsg)
  }, "(???)")), /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    value: quality,
    onChange: e => __async(void 0, null, function* () {
      const value = e.target.value;
      if (value === "1080p" || value === "unlimited") {
        if (!currentUser.isSubscribed) {
          alert && alert(/* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
            message: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Please get Premium to get 1080p+ screensharing.", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Please keep in mind that you will need a high speed internet connection to take advantage of it.")),
            source: "ScreenshareModal"
          }), true);
          return;
        } else {
          if (confirm && !(yield confirm("Quality over 720p requires a very high speed internet connection and might cause video choppiness. Continue?"))) {
            return;
          }
        }
      }
      track("UI", "QualitySelected", "Quality Selected");
      setQuality(value);
    }),
    options: [{
      text: "360p",
      value: "360p"
    }, {
      text: "480p",
      value: "480p"
    }, {
      text: "720p",
      value: "720p"
    }, {
      text: "1080p",
      value: "1080p"
    }, {
      text: "Unlimited",
      value: "unlimited"
    }]
  })))) : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.info
  }, "Unfortunately your browser does not support screensharing with audio. Please switch to a Chromium based browser such as Google Chrome."));
});