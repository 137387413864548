"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const slideInY = "xddk0gz-B";
const slideInX = "x1dyssvo-B";
const slideOutY = "x194ms9s-B";
const slideOutX = "x6tv30u-B";
const fadeIn = "xekv6nw-B";
const styles = exports.styles = {
  transition: {
    "Transition__styles.transition": "Transition__styles.transition",
    animationIterationCount: "x1v7wizp",
    animationTimingFunction: "x4hg4is",
    animationFillMode: "x1u6ievf",
    $$css: true
  },
  loading: {
    "Transition__styles.loading": "Transition__styles.loading",
    position: "x10l6tqk",
    top: "x1kcz52b",
    left: "x13a6lz9",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  disabled: {
    "Transition__styles.disabled": "Transition__styles.disabled",
    animationPlayState: "xorstpt",
    $$css: true
  },
  slideDownIn: {
    "Transition__styles.slideDownIn": "Transition__styles.slideDownIn",
    animationName: "xt8xfk9",
    transformOrigin: "x1dp6rp6",
    $$css: true
  },
  slideDownOut: {
    "Transition__styles.slideDownOut": "Transition__styles.slideDownOut",
    animationName: "x28wfl8",
    transformOrigin: "x1dp6rp6",
    $$css: true
  },
  slideUpIn: {
    "Transition__styles.slideUpIn": "Transition__styles.slideUpIn",
    animationName: "xt8xfk9",
    transformOrigin: "x18ouj0k",
    $$css: true
  },
  slideUpOut: {
    "Transition__styles.slideUpOut": "Transition__styles.slideUpOut",
    animationName: "x28wfl8",
    transformOrigin: "x18ouj0k",
    $$css: true
  },
  slideLeftIn: {
    "Transition__styles.slideLeftIn": "Transition__styles.slideLeftIn",
    animationName: "x1j15sg",
    transformOrigin: "xoe7o5k",
    $$css: true
  },
  slideLeftOut: {
    "Transition__styles.slideLeftOut": "Transition__styles.slideLeftOut",
    animationName: "x1eukugu",
    transformOrigin: "xoe7o5k",
    $$css: true
  },
  fadeIn: {
    "Transition__styles.fadeIn": "Transition__styles.fadeIn",
    animationName: "x127lhb5",
    $$css: true
  },
  withDuration: duration => [{
    "Transition__styles.withDuration": "Transition__styles.withDuration",
    animationDuration: "x3msm2f",
    $$css: true
  }, {
    "--animationDuration": (val => typeof val === "number" ? val + "ms" : val != null ? val : "initial")(`${duration || 300}ms`)
  }],
  visible: {
    "Transition__styles.visible": "Transition__styles.visible",
    display: "x78zum5",
    visibility: "xnpuxes",
    $$css: true
  },
  invisible: {
    "Transition__styles.invisible": "Transition__styles.invisible",
    display: "x1s85apg",
    visibility: "xlshs6z",
    $$css: true
  }
};