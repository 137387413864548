"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useApolloClient } from "@apollo/client";
import { ConnectionContext } from "@/api/ConnectionContext";
import { gql, useMutation, useSubscription } from "@/shared/hooks/gql";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
const JOIN_ROOM = gql(`
  mutation JoinRoom($id: String!, $disconnectOtherConnections: Boolean) {
    joinRoom(id: $id, disconnectOtherConnections: $disconnectOtherConnections) {
      ok
    }
  }
`);
const LEAVE_ROOM = gql(`
  mutation LeaveRoom($id: String!) {
    leaveRoom(id: $id) {
      ok
    }
  }
`);
const ON_ROOM_DISCONNECT = gql(`
  subscription RoomDisconnect($roomId: String!) {
    roomDisconnect(id: $roomId) {
      ok
    }
  }
`);
export default (roomId) => {
  const [joinRoomMutation] = useMutation(JOIN_ROOM);
  const [leaveRoom] = useMutation(LEAVE_ROOM);
  const [error, setError] = useState(null);
  const disconnected = error == "DISCONNECTED";
  const [_joining, setJoining] = useState(true);
  const joining = _joining && !disconnected;
  const { connectionId } = useContext(ConnectionContext);
  const prevConnectionId = useRef(connectionId);
  const [rejoining, setRejoining] = useState(false);
  const client = useApolloClient();
  const hasJoined = useRef(false);
  useSubscription(ON_ROOM_DISCONNECT, {
    variables: { roomId },
    skip: joining || rejoining,
    onData: () => {
      setJoining(false);
      setError("DISCONNECTED");
    }
  });
  const joinRoomInner = useCallback(
    (disconnectOtherConnections) => __async(void 0, null, function* () {
      var _a;
      if (!hasJoined.current) {
        setJoining(true);
      } else {
        setRejoining(true);
      }
      setError(null);
      try {
        yield joinRoomMutation({
          variables: {
            id: roomId,
            disconnectOtherConnections: !!disconnectOtherConnections
          }
        });
        if (hasJoined.current) {
          client.reFetchObservableQueries();
        }
        hasJoined.current = true;
        setError(null);
      } catch (e) {
        const errorMessage = ((_a = e == null ? void 0 : e.graphQLErrors[0]) == null ? void 0 : _a.message) || null;
        setError(errorMessage);
      } finally {
        setJoining(false);
        setRejoining(false);
        prevConnectionId.current = connectionId;
      }
    }),
    [client, connectionId, joinRoomMutation, roomId]
  );
  useEffect(() => {
    if (!connectionId)
      return;
    joinRoomInner();
    return () => {
      leaveRoom({ variables: { id: roomId } });
    };
  }, [leaveRoom, roomId, connectionId, joinRoomInner]);
  return {
    error,
    rejoin: joinRoomInner,
    loading: joining,
    rejoining
  };
};
