"use strict";
import { useEffect } from "react";
import useColorScheme from "@/shared/hooks/useColorScheme";
import { useSpacesStateCached } from "./useRoomData";
import { updateColors } from "@/layout/colors";
import useSettings from "@/shared/hooks/useSettings";
import useRealmInfo from "@/shared/hooks/useRealmInfo";
export default () => {
  const settings = useSettings();
  const settingsColorSchemeId = settings == null ? void 0 : settings.colorScheme;
  const realmInfo = useRealmInfo();
  const realmColorSchemeId = realmInfo == null ? void 0 : realmInfo.colorScheme;
  const realmColorScheme = useColorScheme(realmColorSchemeId || null);
  const spacesState = useSpacesStateCached();
  const colorSchemeId = spacesState == null ? void 0 : spacesState.colorScheme;
  const settingsColorScheme = useColorScheme(settingsColorSchemeId || null);
  const roomColorScheme = useColorScheme(colorSchemeId);
  useEffect(() => {
    const currentSettingsColorScheme = settingsColorSchemeId ? settingsColorScheme : realmColorScheme;
    const currentColorScheme = colorSchemeId ? roomColorScheme : currentSettingsColorScheme;
    const {
      primaryColor,
      secondaryColor,
      foregroundColor
    } = currentColorScheme || {};
    if (primaryColor && secondaryColor && foregroundColor) {
      updateColors(true, primaryColor, secondaryColor, foregroundColor);
    }
    return () => {
      const {
        primaryColor: primaryColor2,
        secondaryColor: secondaryColor2,
        foregroundColor: foregroundColor2
      } = settingsColorScheme || {};
      if (primaryColor2 && secondaryColor2 && foregroundColor2) {
        updateColors(true, primaryColor2, secondaryColor2, foregroundColor2);
      }
    };
  }, [colorSchemeId, realmColorScheme, realmColorSchemeId, roomColorScheme, settingsColorScheme, settingsColorSchemeId]);
};
