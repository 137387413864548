"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "@/shared/hooks/gql";
import * as mediasoup from "mediasoup-client";
import { useCallback, useEffect, useRef } from "react";
import { useRoomId } from "@/room/hooks/useRoomId";
import { useIceservers } from "../p2p/useIceServers";
import { useApolloClient } from "@apollo/client";
const handlerName = mediasoup.detectDevice() || "Safari12";
const GET_ROUTER_RTP_CAPABILITIES = gql(`
  query MediasoupGetRouterRtpCapabilities($key: String!, $roomId: String!) {
    mediasoupGetRouterRtpCapabilities(roomId: $roomId, key: $key) {
      ok
      mediasoupResponse
    }
  }
`);
const CREATE_PRODUCER_TRANSPORT = gql(`
  mutation MediasoupCreateProducerTransport($roomId: String!, $key: String!) {
    mediasoupCreateProducerTransport(roomId: $roomId, key: $key) {
      ok
      mediasoupResponse
    }
  }
`);
const CONNECT_PRODUCER_TRANSPORT = gql(`
  mutation MediasoupConnectProducerTransport(
    $roomId: String!
    $key: String!
    $dtlsParameters: String!
  ) {
    mediasoupConnectProducerTransport(
      roomId: $roomId
      key: $key
      dtlsParameters: $dtlsParameters
    ) {
      ok
      mediasoupResponse
    }
  }
`);
const PRODUCE = gql(`
  mutation MediaSoupProduce(
    $roomId: String!
    $key: String!
    $kind: String!
    $rtpParameters: String!
  ) {
    mediasoupProduce(
      roomId: $roomId
      key: $key
      kind: $kind
      rtpParameters: $rtpParameters
    ) {
      ok
      mediasoupResponse
    }
  }
`);
const CONSUME = gql(`
  mutation MediasoupConsume(
    $roomId: String!
    $key: String!
    $kind: String!
    $targetUserId: String!
    $rtpCapabilities: String!
  ) {
    mediasoupConsume(
      roomId: $roomId
      key: $key
      kind: $kind
      targetUserId: $targetUserId
      rtpCapabilities: $rtpCapabilities
    ) {
      ok
      mediasoupResponse
    }
  }
`);
const CREATE_CONSUMER_TRANSPORT = gql(`
  mutation MediasoupCreateConsumerTransport(
    $roomId: String!
    $key: String!
    $targetUserId: String!
  ) {
    mediasoupCreateConsumerTransport(
      roomId: $roomId
      key: $key
      targetUserId: $targetUserId
    ) {
      ok
      mediasoupResponse
    }
  }
`);
const CONNECT_CONSUMER_TRANSPORT = gql(`
  mutation MediasoupConnectConsumerTransport(
    $roomId: String!
    $key: String!
    $targetUserId: String!
    $dtlsParameters: String!
  ) {
    mediasoupConnectConsumerTransport(
      roomId: $roomId
      key: $key
      targetUserId: $targetUserId
      dtlsParameters: $dtlsParameters
    ) {
      ok
      mediasoupResponse
    }
  }
`);
const CLOSE_PRODUCER_TRANSPORT = gql(`
  mutation MediasoupCloseProducerTransport($roomId: String!, $key: String!) {
    mediasoupCloseProducerTransport(key: $key, roomId: $roomId) {
      ok
    }
  }
`);
const CLOSE_CONSUMER_TRANSPORT = gql(`
  mutation MediasoupCloseConsumerTransport(
    $roomId: String!
    $targetUserId: String!
    $key: String!
  ) {
    mediasoupCloseConsumerTransport(
      targetUserId: $targetUserId
      key: $key
      roomId: $roomId
    ) {
      ok
    }
  }
`);
const PRODUCER_HEARTBEAT = gql(`
  mutation MediasoupProducerHeartbeat($roomId: String!, $key: String!) {
    mediasoupProducerHeartbeat(key: $key, roomId: $roomId) {
      ok
    }
  }
`);
const CONSUMER_HEARTBEAT = gql(`
  mutation MediasoupConsumerHeartbeat(
    $roomId: String!
    $key: String!
    $targetUserId: String!
  ) {
    mediasoupConsumerHeartbeat(
      key: $key
      roomId: $roomId
      targetUserId: $targetUserId
    ) {
      ok
    }
  }
`);
const HEARTBEAT_INTERVAL = 3e4;
const useMediaSoupActions = () => {
  const client = useApolloClient();
  const roomId = useRoomId();
  const [_createProducerTransport] = useMutation(CREATE_PRODUCER_TRANSPORT);
  const [_connectProducerTransport] = useMutation(CONNECT_PRODUCER_TRANSPORT);
  const [_produce] = useMutation(PRODUCE);
  const [_createConsumerTransport] = useMutation(CREATE_CONSUMER_TRANSPORT);
  const [_connectConsumerTransport] = useMutation(CONNECT_CONSUMER_TRANSPORT);
  const [_consume] = useMutation(CONSUME);
  const [_closeProducerTransport] = useMutation(CLOSE_PRODUCER_TRANSPORT);
  const [_closeConsumerTransport] = useMutation(CLOSE_CONSUMER_TRANSPORT);
  const [_producerHeartbeat] = useMutation(PRODUCER_HEARTBEAT);
  const [_consumerHeartbeat] = useMutation(CONSUMER_HEARTBEAT);
  const getRouterRtpCapabilities = useCallback((key) => __async(void 0, null, function* () {
    var _a;
    const response = yield client.query({
      query: GET_ROUTER_RTP_CAPABILITIES,
      variables: {
        roomId,
        key
      }
    });
    const data = response.data;
    return JSON.parse(((_a = data == null ? void 0 : data.mediasoupGetRouterRtpCapabilities) == null ? void 0 : _a.mediasoupResponse) || "");
  }), [client, roomId]);
  const createProducerTransport = useCallback((key) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _createProducerTransport({
      variables: {
        key,
        roomId
      }
    });
    return JSON.parse((_b = (_a = response.data) == null ? void 0 : _a.mediasoupCreateProducerTransport) == null ? void 0 : _b.mediasoupResponse);
  }), [_createProducerTransport, roomId]);
  const connectProducerTransport = useCallback((key, dtlsParameters) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _connectProducerTransport({
      variables: {
        roomId,
        key,
        dtlsParameters: JSON.stringify(dtlsParameters)
      }
    });
    return JSON.parse((_b = (_a = response.data) == null ? void 0 : _a.mediasoupConnectProducerTransport) == null ? void 0 : _b.mediasoupResponse);
  }), [_connectProducerTransport, roomId]);
  const produce = useCallback((key, kind, rtpParameters) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _produce({
      variables: {
        roomId,
        key,
        kind,
        rtpParameters: JSON.stringify(rtpParameters)
      }
    });
    return JSON.parse((_b = (_a = response.data) == null ? void 0 : _a.mediasoupProduce) == null ? void 0 : _b.mediasoupResponse);
  }), [_produce, roomId]);
  const createConsumerTransport = useCallback((targetUserId, key) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _createConsumerTransport({
      variables: {
        roomId,
        key,
        targetUserId
      }
    });
    return JSON.parse((_b = (_a = response.data) == null ? void 0 : _a.mediasoupCreateConsumerTransport) == null ? void 0 : _b.mediasoupResponse);
  }), [_createConsumerTransport, roomId]);
  const connectConsumerTransport = useCallback((targetUserId, key, dtlsParameters) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _connectConsumerTransport({
      variables: {
        roomId,
        key,
        targetUserId,
        dtlsParameters: JSON.stringify(dtlsParameters)
      }
    });
    return JSON.parse((_b = (_a = response.data) == null ? void 0 : _a.mediasoupConnectConsumerTransport) == null ? void 0 : _b.mediasoupResponse);
  }), [_connectConsumerTransport, roomId]);
  const consume = useCallback((targetUserId, key, kind, rtpCapabilities) => __async(void 0, null, function* () {
    var _a, _b;
    const response = yield _consume({
      variables: {
        roomId,
        kind,
        key,
        targetUserId,
        rtpCapabilities: JSON.stringify(rtpCapabilities)
      }
    });
    return JSON.parse((_b = (_a = response == null ? void 0 : response.data) == null ? void 0 : _a.mediasoupConsume) == null ? void 0 : _b.mediasoupResponse);
  }), [_consume, roomId]);
  const closeProducerTransport = useCallback((key) => __async(void 0, null, function* () {
    return yield _closeProducerTransport({
      variables: {
        roomId,
        key
      }
    });
  }), [_closeProducerTransport, roomId]);
  const closeConsumerTransport = useCallback((targetUserId, key) => __async(void 0, null, function* () {
    return yield _closeConsumerTransport({
      variables: {
        targetUserId,
        roomId,
        key
      }
    });
  }), [_closeConsumerTransport, roomId]);
  const sendProducerHeartbeat = useCallback((key) => __async(void 0, null, function* () {
    return yield _producerHeartbeat({
      variables: {
        roomId,
        key
      }
    });
  }), [_producerHeartbeat, roomId]);
  const sendConsumerHeartbeat = useCallback((targetUserId, key) => __async(void 0, null, function* () {
    return yield _consumerHeartbeat({
      variables: {
        roomId,
        targetUserId,
        key
      }
    });
  }), [roomId, _consumerHeartbeat]);
  return {
    closeConsumerTransport,
    closeProducerTransport,
    connectConsumerTransport,
    connectProducerTransport,
    consume,
    createConsumerTransport,
    createProducerTransport,
    getRouterRtpCapabilities,
    produce,
    sendConsumerHeartbeat,
    sendProducerHeartbeat
  };
};
export const useMediaSoup = () => {
  const iceServers = useIceservers();
  const {
    createProducerTransport,
    connectProducerTransport,
    produce,
    createConsumerTransport,
    connectConsumerTransport,
    consume,
    closeProducerTransport,
    closeConsumerTransport,
    sendProducerHeartbeat,
    sendConsumerHeartbeat,
    getRouterRtpCapabilities
  } = useMediaSoupActions();
  const producerHeartbeatLoopsRef = useRef({});
  const consumerHeartbeatLoopsRef = useRef({});
  const startProducerHeartbeat = useCallback((key) => {
    const producerHeartbeatLoops = producerHeartbeatLoopsRef.current;
    if (!producerHeartbeatLoops[key]) {
      producerHeartbeatLoops[key] = setInterval(() => {
        sendProducerHeartbeat(key);
      }, HEARTBEAT_INTERVAL);
    }
  }, [sendProducerHeartbeat]);
  const stopProducerHeartbeat = useCallback((key) => {
    const producerHeartbeatLoops = producerHeartbeatLoopsRef.current;
    const heartBeatLoop = producerHeartbeatLoops[key];
    if (heartBeatLoop) {
      clearInterval(heartBeatLoop);
      delete producerHeartbeatLoops[key];
      closeProducerTransport(key);
    }
  }, [closeProducerTransport]);
  const startConsumerHeartbeat = useCallback((targetUserId, key) => {
    const consumerHeartbeatLoops = consumerHeartbeatLoopsRef.current;
    if (!consumerHeartbeatLoops[JSON.stringify([targetUserId, key])]) {
      consumerHeartbeatLoops[JSON.stringify([targetUserId, key])] = setInterval(() => {
        sendConsumerHeartbeat(targetUserId, key);
      }, HEARTBEAT_INTERVAL);
    }
  }, [sendConsumerHeartbeat]);
  const stopConsumerHeartbeat = useCallback((targetUserId, key) => {
    const consumerHeartbeatLoops = consumerHeartbeatLoopsRef.current;
    const heartBeatLoop = consumerHeartbeatLoops[JSON.stringify([targetUserId, key])];
    if (heartBeatLoop) {
      clearInterval(heartBeatLoop);
      delete consumerHeartbeatLoops[JSON.stringify([targetUserId, key])];
      closeConsumerTransport(targetUserId, key);
    }
  }, [closeConsumerTransport]);
  const produceMediaSoupStream = useCallback((key, stream) => {
    return new Promise((resolve, reject) => __async(void 0, null, function* () {
      const routerRtpCapabilities = yield getRouterRtpCapabilities(key);
      if (routerRtpCapabilities.error) {
        reject(routerRtpCapabilities.error);
        return;
      }
      const device = new mediasoup.Device({
        handlerName
      });
      yield device.load({
        routerRtpCapabilities
      });
      const transportOptions = yield createProducerTransport(key);
      if (transportOptions.error) {
        reject(transportOptions.error);
        return;
      }
      const producerTransport = device.createSendTransport(__spreadProps(__spreadValues({}, transportOptions), {
        iceServers: iceServers == null ? void 0 : iceServers.concat(transportOptions.iceServers || [])
      }));
      producerTransport.on("connectionstatechange", (_state) => {
      });
      const videoTrack = stream.getVideoTracks()[0];
      const audioTrack = stream.getAudioTracks()[0];
      startProducerHeartbeat(key);
      const tracks = [videoTrack, audioTrack].filter((t) => t);
      let readyTracks = 0;
      producerTransport.on("connect", (_0, _1, _2) => __async(void 0, [_0, _1, _2], function* ({
        dtlsParameters
      }, callback, errback) {
        try {
          const response = yield connectProducerTransport(key, dtlsParameters);
          if (response.error) {
            throw response.error;
          }
          callback();
        } catch (e) {
          console.error(e);
          errback(e);
          reject(e);
        }
      }));
      producerTransport.on("produce", (_0, _1, _2) => __async(void 0, [_0, _1, _2], function* ({
        kind,
        rtpParameters
      }, callback, errback) {
        try {
          readyTracks++;
          const {
            id,
            error
          } = yield produce(key, kind, rtpParameters);
          if (error) {
            throw error;
          }
          callback({
            id
          });
          if (readyTracks >= tracks.length) {
            resolve();
          }
        } catch (err) {
          console.error(err);
          errback(err);
          reject(err);
        }
      }));
      if (videoTrack) {
        try {
          yield producerTransport.produce({
            track: videoTrack
          });
        } catch (e) {
          reject(e);
          return;
        }
      }
      if (audioTrack) {
        try {
          yield producerTransport.produce({
            track: audioTrack
          });
        } catch (e) {
          reject(e);
          return;
        }
      }
    }));
  }, [connectProducerTransport, createProducerTransport, getRouterRtpCapabilities, iceServers, produce, startProducerHeartbeat]);
  const consumeMediaSoupStream = useCallback((targetUserId, key, kind) => __async(void 0, null, function* () {
    let missingTracks = 0;
    const device = new mediasoup.Device({
      handlerName
    });
    const routerRtpCapabilities = yield getRouterRtpCapabilities(key);
    if (routerRtpCapabilities.error) {
      throw routerRtpCapabilities.error;
    }
    yield device.load({
      routerRtpCapabilities
    });
    const stream = new MediaStream();
    const transportOptions = yield createConsumerTransport(targetUserId, key);
    if (transportOptions.error) {
      throw transportOptions.error;
    }
    const consumerTransport = device.createRecvTransport(__spreadProps(__spreadValues({}, transportOptions), {
      iceServers: iceServers == null ? void 0 : iceServers.concat(transportOptions.iceServers || [])
    }));
    consumerTransport.on("connectionstatechange", (_state) => {
    });
    consumerTransport.on("connect", (_0, _1, _2) => __async(void 0, [_0, _1, _2], function* ({
      dtlsParameters
    }, callback, errback) {
      try {
        const response = yield connectConsumerTransport(targetUserId, key, dtlsParameters);
        if (response.error) {
          throw response.error;
        }
        callback();
      } catch (e) {
        errback(e);
      }
    }));
    const consumeTrack = (streamKind) => __async(void 0, null, function* () {
      const {
        rtpCapabilities
      } = device;
      const {
        producerId,
        id,
        kind: kind2,
        rtpParameters,
        error
      } = yield consume(targetUserId, key, streamKind, rtpCapabilities);
      if (error) {
        throw error;
      }
      const consumer = yield consumerTransport.consume({
        id,
        producerId,
        kind: kind2,
        rtpParameters
      });
      return consumer.track;
    });
    if (!kind || kind === "video") {
      try {
        const track = yield consumeTrack("video");
        stream.addTrack(track);
      } catch (e) {
        if (!kind) {
          console.warn(e);
          missingTracks++;
          if (missingTracks > 1) {
            throw e;
          }
        } else {
          throw e;
        }
      }
    }
    if (!kind || kind === "audio") {
      try {
        const track = yield consumeTrack("audio");
        stream.addTrack(track);
      } catch (e) {
        if (!kind) {
          console.warn(e);
          missingTracks++;
          if (missingTracks > 1) {
            throw e;
          }
        } else {
          throw e;
        }
      }
    }
    startConsumerHeartbeat(targetUserId, key);
    return stream;
  }), [connectConsumerTransport, consume, createConsumerTransport, getRouterRtpCapabilities, iceServers, startConsumerHeartbeat]);
  useEffect(() => {
    const consumerHeartbeatLoops = consumerHeartbeatLoopsRef.current;
    const producerHeartbeatLoops = producerHeartbeatLoopsRef.current;
    return () => {
      Object.keys(producerHeartbeatLoops).forEach((key) => {
        stopProducerHeartbeat(key);
        closeProducerTransport(key);
      });
      Object.keys(consumerHeartbeatLoops).forEach((json) => {
        const [targetUserId, key] = JSON.parse(json);
        stopConsumerHeartbeat(targetUserId, key);
        closeConsumerTransport(targetUserId, key);
      });
    };
  }, [stopProducerHeartbeat, stopConsumerHeartbeat, closeConsumerTransport, closeProducerTransport]);
  return {
    produceMediaSoupStream,
    consumeMediaSoupStream,
    closeProducerTransport: stopProducerHeartbeat,
    closeConsumerTransport: stopConsumerHeartbeat,
    loading: !iceServers
  };
};
