"use strict";
import platform from "platform";
import { useIsMobile } from "../hooks/useMobileDetection";
import { useRef } from "react";
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (_e) {
    return true;
  }
}
const isBeingEmbedded = inIframe();
function usePlatform() {
  var _a, _b, _c;
  const {
    isIOS,
    isMobile,
    isLandscape
  } = useIsMobile();
  const hasScreenshareWithAudio = window.chrome && !!navigator.mediaDevices.getDisplayMedia;
  const isAndroid = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "Android";
  const videoElement = useRef(document.createElement("video"));
  return {
    isMobile,
    isIOS,
    isAndroid,
    isMobileOS: isIOS || isAndroid,
    isLandscape,
    isBeingEmbedded,
    hasScreenshareWithAudio,
    // eslint-disable-next-line
    canCaptureVideoElement: !!videoElement.current.captureStream,
    supportsRestrictionTarget: !!((_c = window.RestrictionTarget) == null ? void 0 : _c.fromElement)
  };
}
export default usePlatform;
