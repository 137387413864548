"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextArea = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _lib = require("react-debounce-input/lib");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const styles = {
  textarea: {
    "TextArea__styles.textarea": "TextArea__styles.textarea",
    width: "xh8yej3",
    margin: "x1ghz6dp",
    appearance: "xjyslct",
    outline: "x1a2a7pz",
    padding: "x17smxli",
    backgroundColor: "x128cn8a",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "xzgz5g3",
    color: "x11jfisy",
    borderRadius: "x1aaq583",
    boxShadow: "x9gmhs3",
    resize: "x288g5",
    verticalAlign: "x16dsc37",
    ":focus_borderColor": "x1eg2tmu",
    $$css: true
  }
};
const TextAreaElement = _a => {
  var _b = _a,
    {
      ref
    } = _b,
    props = __objRest(_b, ["ref"]);
  return /* @__PURE__ */_react.default.createElement("textarea", __spreadProps(__spreadValues({}, props), {
    ref
  }));
};
const DebounceTextArea = (debounce, minLength) => _a => {
  var _b = _a,
    {
      ref
    } = _b,
    props = __objRest(_b, ["ref"]);
  return /* @__PURE__ */_react.default.createElement(_lib.DebounceInput, __spreadValues({
    element: "textarea",
    minLength,
    debounceTimeout: debounce,
    ref
  }, props));
};
const TextArea = exports.TextArea = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(12);
  const {
    ref,
    onChange,
    value,
    rows,
    maxLength,
    debounce,
    debounceMinLength,
    placeholder,
    disabled,
    className
  } = t0;
  const {
    className: styleXClassName
  } = stylex.props(styles.textarea, typeof className !== "string" && className);
  let t1;
  let t2;
  if ($[0] !== debounce || $[1] !== debounceMinLength) {
    t2 = debounce ? DebounceTextArea(debounce, debounceMinLength || 0) : TextAreaElement;
    $[0] = debounce;
    $[1] = debounceMinLength;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  t1 = t2;
  const Tag = t1;
  const t3 = styleXClassName + (typeof className === "string" ? ` ${className}` : "");
  let t4;
  if ($[3] !== Tag || $[4] !== disabled || $[5] !== maxLength || $[6] !== onChange || $[7] !== placeholder || $[8] !== rows || $[9] !== t3 || $[10] !== value) {
    t4 = /* @__PURE__ */_react.default.createElement(Tag, {
      className: t3,
      placeholder,
      maxLength,
      disabled,
      rows,
      onChange,
      value,
      ref
    });
    $[3] = Tag;
    $[4] = disabled;
    $[5] = maxLength;
    $[6] = onChange;
    $[7] = placeholder;
    $[8] = rows;
    $[9] = t3;
    $[10] = value;
    $[11] = t4;
  } else {
    t4 = $[11];
  }
  return t4;
});